import { useMemo, type PropsWithChildren, type FC } from 'react'
import { type SelectProps, Select, type FormControlProps } from '@mui/material'
import { useTheme } from '../../../../hooks'
import { HEIGHTS } from '../../../../theme'
import { LeafFormControl } from '../../Forms/LeafFormControl'

export type LeafSelectProps = SelectProps & {
    label: string
    id: string
    helperText?: string
    placeholderText?: string
    formControlProps?: FormControlProps
}

export const LeafSelect: FC<PropsWithChildren<LeafSelectProps>> = ({
    label,
    id,
    helperText,
    placeholderText,
    formControlProps,
    color = 'primary',
    ...props
}) => {
    const theme = useTheme()

    const isEmptyValue = useMemo<boolean>(
        () =>
            typeof props.value === 'string' || Array.isArray(props.value)
                ? props.value.length === 0
                : props.value === null || props.value === undefined,
        [props.value]
    )

    return (
        <LeafFormControl
            label={label}
            id={id}
            helperText={helperText}
            fullWidth={props.fullWidth}
            error={props.error}
            disabled={props.disabled}
            color={color}
            {...formControlProps}
        >
            <Select
                {...props}
                {...{ notched: false }}
                id={id}
                color={color}
                sx={{
                    ...props.sx,
                    height: HEIGHTS.LARGE_COMPONENT_HEIGHT
                }}
            >
                {props.children}
            </Select>
            {placeholderText && isEmptyValue && (
                <span
                    style={{
                        position: 'absolute',
                        top: 45,
                        left: 16,
                        right: 32,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        pointerEvents: 'none',
                        color: theme.palette.text.secondary
                    }}
                >
                    {placeholderText}
                </span>
            )}
        </LeafFormControl>
    )
}

export default LeafSelect
