import {
    createTheme,
    Direction,
    responsiveFontSizes,
    Theme,
    ThemeOptions
} from '@mui/material'
import merge from 'lodash/merge'
import { THEMES, BORDER_RADIUS } from './constants'
import { darkTheme, demoTheme, highContrastTheme, thriveTheme } from './themes'
import { componentOverrides } from './componentOverrides'
import { defaultTypography } from './typography'
import { pxToNum } from '../utils/units/px'

export {}

interface ThemeConfig {
    direction?: Direction
    responsiveFontSizes?: boolean
    roundedCorners?: boolean
    theme?: string
}
type TypeCardOverlay = string

interface GradientPaletteColor {
    light: string
    main: string
}

interface GradientPaletteColorOptions {
    light?: string
    main?: string
}

export interface ExtendedTheme extends Theme {
    Logo: () => JSX.Element
    gradient: GradientPaletteColor
    videoTextProtectionOverlay: TypeCardOverlay
}

export interface ExtendedThemeOptions extends ThemeOptions {
    Logo: () => JSX.Element
    gradient?: GradientPaletteColorOptions
    videoTextProtectionOverlay?: string
}

const baseOptions: ThemeOptions = {
    direction: 'ltr',
    components: componentOverrides,
    typography: defaultTypography
}

const themesOptions: Record<string, ExtendedThemeOptions> = {
    [THEMES.THRIVE]: thriveTheme,
    [THEMES.HIGH_CONTRAST]: highContrastTheme,
    [THEMES.DARK]: darkTheme,
    [THEMES.DEMO]: demoTheme
}

const createCustomTheme = (config: ThemeConfig = {}): ExtendedTheme => {
    let themeOptions = themesOptions[config.theme]
    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`))
        themeOptions = themesOptions[THEMES.THRIVE]
    }
    let theme = createTheme(
        merge(
            {},
            baseOptions,
            themeOptions,
            {
                ...(config.roundedCorners && {
                    shape: {
                        borderRadius: pxToNum(BORDER_RADIUS.DEFAULT)
                    }
                })
            },
            {
                direction: config.direction
            }
        )
    ) as ExtendedTheme //casting is safe here as createTheme and responsiveFontSizes actually preserve additional properties
    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme) as ExtendedTheme
    }
    return theme
}
// Main exported theme shared for all the micro apps
const defaultThemeConfig = {
    direction: 'ltr',
    responsiveFontSizes: false,
    roundedCorners: true,
    theme: THEMES.THRIVE
}

export const getApplicationTheme = (config: ThemeConfig = {}) =>
    createCustomTheme(merge(defaultThemeConfig, config))

export const defaultApplicationTheme = getApplicationTheme()

// there are microfrontends that import `applicationTheme`; let's
// reexport the default theme under that name so nothing breaks
export const applicationTheme = defaultApplicationTheme

export function getAllThemes(): Record<string, ExtendedTheme> {
    const themes = {}
    Object.values(THEMES).forEach((element) => {
        themes[element] = createCustomTheme({
            roundedCorners: true,
            theme: element
        })
    })

    return themes
}
