import React, { useState } from 'react'
import { Checkbox, FormControlLabel, Typography, styled } from '@mui/material'
import { useIntl, defineMessages } from 'react-intl'

import LeafPrivacyPolicyLink from '../../../elements/Links/LeafPolicyLinks/LeafPrivacyPolicyLink/'
import LeafToSLink from '../../../elements/Links/LeafPolicyLinks/LeafToSLink/'

const Row = styled('div')`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 0;
`

const messages = defineMessages({
    generalDataCheckboxText: {
        defaultMessage:
            '<b>General Data:</b> I agree to the collection and use of my personal information (e.g., name, email, engagement metrics) for account setup, service delivery, and communication as described in the <PrivacyPolicy>Privacy Policy</PrivacyPolicy> and <ToS>Terms of Service.</ToS> (Required)',
        description: ''
    },
    sensitiveDataCheckboxText: {
        defaultMessage:
            '<b>Sensitive Data:</b> I consent to the collection and use of my sensitive personal information (e.g., health and well-being data) for personalized recommendations and insights, as described in the <PrivacyPolicy>Privacy Policy</PrivacyPolicy> and <ToS>Terms of Service.</ToS> (Required)',
        description: ''
    },
    marketingCommunicationsCheckboxText: {
        defaultMessage:
            '<b>Marketing Communications:</b> I agree to receive marketing communications and updates from Thrive.',
        description: ''
    }
})

export type APACConsentState = {
    generalData?: boolean
    sensitiveData?: boolean
    marketingCommunications?: boolean
}

export type APACConsentProps = {
    defaultValue?: boolean
    onAccepted: (accepted: boolean) => any
}

export const APACConsent: React.FC<APACConsentProps> = ({
    defaultValue,
    onAccepted
}) => {
    const intl = useIntl()
    const [state, setState] = useState<APACConsentState>({
        generalData: defaultValue,
        sensitiveData: defaultValue,
        marketingCommunications: defaultValue
    })

    const handleCheckboxChange =
        (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const newState = { ...state, [field]: event.target.checked }
            setState(newState)
            onAccepted(Object.values(newState).every(Boolean))
        }

    return (
        <div>
            <Row>
                <Checkbox
                    required
                    id="thrive-consent-general-data"
                    checked={state.generalData}
                    onChange={handleCheckboxChange('generalData')}
                    color="primary"
                    sx={{
                        paddingTop: '0px',
                        '& .MuiSvgIcon-root': { fontSize: 28 }
                    }}
                />
                <Typography
                    variant="body2"
                    component="label"
                    htmlFor="thrive-consent-general-data"
                >
                    {intl.formatMessage(messages.generalDataCheckboxText, {
                        b: (chunks) => <b>{chunks}</b>,
                        PrivacyPolicy: () => <LeafPrivacyPolicyLink />,
                        ToS: () => <LeafToSLink />
                    })}
                </Typography>
            </Row>

            <Row>
                <Checkbox
                    required
                    id="thrive-consent-sensitive-data"
                    checked={state.sensitiveData}
                    onChange={handleCheckboxChange('sensitiveData')}
                    color="primary"
                    sx={{
                        paddingTop: '0px',
                        '& .MuiSvgIcon-root': { fontSize: 28 }
                    }}
                />
                <Typography
                    variant="body2"
                    component="label"
                    htmlFor="thrive-consent-sensitive-data"
                >
                    {intl.formatMessage(messages.sensitiveDataCheckboxText, {
                        b: (chunks) => <b>{chunks}</b>,
                        PrivacyPolicy: () => <LeafPrivacyPolicyLink />,
                        ToS: () => <LeafToSLink />
                    })}
                </Typography>
            </Row>

            <Row>
                <Checkbox
                    id="thrive-consent-marketing-communication"
                    checked={state.marketingCommunications}
                    onChange={handleCheckboxChange('marketingCommunications')}
                    color="primary"
                    sx={{
                        paddingTop: '0px',
                        '& .MuiSvgIcon-root': { fontSize: 28 }
                    }}
                />
                <Typography
                    variant="body2"
                    component="label"
                    htmlFor="thrive-consent-marketing-communication"
                >
                    {intl.formatMessage(
                        messages.marketingCommunicationsCheckboxText,
                        {
                            b: (chunks) => <b>{chunks}</b>
                        }
                    )}
                </Typography>
            </Row>
        </div>
    )
}
