import {
    Box,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModalProps } from '../../../../components/buttons'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import BrandInput, {
    BrandFormInputs
} from '../../../../components/inputs/BrandInput/BrandInput'
import {
    useDeleteBrandMutation,
    useUpdateBrandMutation
} from '../../../../graphql/generated/autogenerated'
import { GET_COMPANY_DATA } from '../../../../graphql/queries/companies'
import { useParams } from 'react-router-dom'
import { LogoInput } from '../../../../components/inputs'

export const EditBrandModal: React.FC<ModalProps> = ({
    closeModal,
    trigger
}) => {
    const { id } = useParams<{ id: string }>()
    const { brand } = trigger
    const [logoUrl, setLogoUrl] = useState(brand.imageUrl)
    const [showDeletePrompt, setShowDeletePrompt] = useState(false)

    const [
        updateBrandMutation,
        { loading: updateLoading, error: updateError }
    ] = useUpdateBrandMutation({
        onCompleted: () => {
            closeModal && closeModal()
        },
        refetchQueries: [
            {
                query: GET_COMPANY_DATA,
                variables: { companyId: id }
            },
            'GetCompanyData'
        ]
    })

    const [
        deleteBrandMutation,
        { data: deleteData, loading: deleteLoading, error: deleteError }
    ] = useDeleteBrandMutation({
        onCompleted: () => {
            closeModal && closeModal()
        },
        refetchQueries: [
            {
                query: GET_COMPANY_DATA,
                variables: { companyId: id }
            },
            'GetCompanyData'
        ]
    })

    const error = useMemo(() => {
        return updateError || deleteError
    }, [updateError, deleteError])

    const defaultValues = {
        name: brand.name,
        imageUrl: brand.imageUrl,
        // Reverse checkbox value since hide = checkbox: true, isPublic:false
        // and show = checkbox:false, isPublic:true
        isPublic: !brand.isPublic
    }

    const handleEditBrandClick: SubmitHandler<BrandFormInputs> = async (
        data,
        event
    ) => {
        const newBrand = {
            originalName: brand.name,
            brand: {
                name: data.name.trim(),
                imageUrl: logoUrl || null,
                // Reverse checkbox value since hide = checkbox: true, isPublic:false
                // and show = checkbox:false, isPublic:true
                isPublic: !data.isPublic
            }
        }
        updateBrandMutation({
            variables: {
                name: newBrand.originalName,
                input: { ...newBrand.brand, companyId: id }
            }
        })
    }

    const handleDeleteBrandClick = () => {
        setShowDeletePrompt(true)
    }

    const handleCancelClick = () => {
        setShowDeletePrompt(false)
    }

    const handleConfirmDeleteClick = () => {
        deleteBrandMutation({ variables: { name: brand.name } })
    }

    const formMethods = useForm<BrandFormInputs>({
        defaultValues: defaultValues,
        reValidateMode: 'onSubmit'
    })
    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>
                {showDeletePrompt ? (
                    <FormattedMessage
                        defaultMessage="Delete brand"
                        description="Title for delete brand modal"
                    />
                ) : (
                    <FormattedMessage
                        defaultMessage="Edit brand"
                        description="Title for edit brand modal"
                    />
                )}
            </DialogTitle>
            <DialogContent>
                {!error && !showDeletePrompt && (
                    <Stack
                        spacing={2}
                        component="form"
                        id="edit-brand-form"
                        noValidate
                    >
                        <FormProvider {...formMethods}>
                            <LogoInput
                                type="brand"
                                logoUrl={logoUrl}
                                setLogoUrl={setLogoUrl}
                            />
                            <BrandInput fullsize />
                        </FormProvider>
                    </Stack>
                )}
                {!error && showDeletePrompt && (
                    <CoreTypography variant="body2">
                        <FormattedMessage
                            description="Label for text asking to confirm deletion"
                            defaultMessage="Are you sure you want to delete this brand?"
                        />
                    </CoreTypography>
                )}
                {error && (
                    <CoreTypography variant="body2" sx={{ color: 'red' }}>
                        <FormattedMessage
                            description="Brand update error text"
                            defaultMessage="There was an error with your request."
                        />
                        {error?.message}
                    </CoreTypography>
                )}
            </DialogContent>
            <DialogActions>
                {!error && !showDeletePrompt && (
                    <Box sx={{ p: 2 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleDeleteBrandClick}
                            sx={{ mr: 2 }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for button to delete brand"
                                    defaultMessage="Delete"
                                />
                            </CoreTypography>
                        </Button>
                        <Button
                            form="edit-brand-code-form"
                            variant="contained"
                            color="primary"
                            onClick={formMethods.handleSubmit(
                                handleEditBrandClick
                            )}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for button to save subscription"
                                    defaultMessage="Save"
                                />
                            </CoreTypography>
                        </Button>
                    </Box>
                )}
                {!error && showDeletePrompt && (
                    <Box sx={{ p: 2 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleConfirmDeleteClick}
                            sx={{ mr: 2 }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for button to delete brandn"
                                    defaultMessage="Delete"
                                />
                            </CoreTypography>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancelClick}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for button to cancel edit"
                                    defaultMessage="Cancel"
                                />
                            </CoreTypography>
                        </Button>
                    </Box>
                )}
            </DialogActions>
        </>
    )
}
