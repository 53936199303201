/* eslint-disable no-duplicate-imports */
import Avo from './Avo'
import type * as AvoTypes from './Avo'
import {
    captureException,
    getPlatform,
    getBestFitLocale
} from '@thriveglobal/thrive-web-core'
import {
    initializeSegment,
    identifyUser,
    resetSegment,
    getSegmentSession
} from './segment'

const defaultTrackingProps = {
    thriveLocale: getBestFitLocale?.() ?? 'en-US',
    appSource: getPlatform?.() ?? 'unknown',
    client: window?.getThriveClient?.() ?? 'unknown',
    sessionId: getSegmentSession?.() ?? 'unknown'
}

const AVO_DISABLE_FLAG = 'disableAvoDebugger'

const destinationSetup = {
    make: async function () {
        if (!navigator.webdriver) {
            try {
                if (process.env.DEPLOYMENT_ENV !== 'uat') {
                    await initializeSegment()
                }
            } catch (err) {
                captureException(err, {
                    message: 'Avo failed to init Segment'
                })
            }
        }
    },
    logEvent: async function (eventName, eventProperties) {
        if (!navigator.webdriver) {
            try {
                if (process.env.DEPLOYMENT_ENV !== 'uat') {
                    await window.analytics.track(eventName, eventProperties)
                } else {
                    console.log('Skipped Event: ', eventName, eventProperties)
                }
            } catch (err) {
                captureException(err, {
                    message: 'Avo failed to log Segment'
                })
            }
        }
    },
    identify: async function () {
        if (!navigator.webdriver) {
            await identifyUser()
        }
    }
}

const clearTracking = () => {
    resetSegment()
}

function disableAvoDebugger(): boolean {
    try {
        const sessionStorageFlag =
            sessionStorage.getItem(AVO_DISABLE_FLAG) || false
        const urlParams = new URLSearchParams(window.location.search)
        const urlFlag = urlParams.get(AVO_DISABLE_FLAG) || false
        return !!(sessionStorageFlag || urlFlag)
    } catch (e) {
        return false
    }
}

// Here be dragons, be careful with Proxy code
const AvoProxy = new Proxy(Avo, {
    // To prevent Avo from crashing an app if code tries to call a function that doesn't exist, we proxy the Avo object and log error to datadog if this happens.
    get: function (target, prop, receiver) {
        const value = Reflect.get(target, prop, receiver)
        if (!value) {
            const errorMessage = `Missing Avo: Avo.${String(
                prop
            )} function is not defined`
            console.error(errorMessage)
            captureException(new Error(errorMessage))
            return () => {}
        }
        return value
    }
})

AvoProxy.initAvo(
    {
        env:
            process.env.DEPLOYMENT_ENV === 'master' ||
            window.location.hostname.indexOf('engineering') > -1
                ? Avo.AvoEnv.Prod
                : Avo.AvoEnv.Dev,
        ...(disableAvoDebugger() && { webDebugger: false })
    },
    {
        appSource: getPlatform?.() as unknown as AvoTypes.AppSourceValueType,
        thriveLocale: getBestFitLocale?.(),
        sessionId: getSegmentSession?.(),
        client: window?.getThriveClient?.() as AvoTypes.ClientValueType
    },
    destinationSetup,
    destinationSetup
)

export {
    AvoProxy as Avo,
    AvoTypes,
    defaultTrackingProps,
    clearTracking,
    getSegmentSession as getTrackingSessionId
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
