import { memo, type PropsWithChildren } from 'react'
import { Tooltip } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'

type ButtonTooltipProps = PropsWithChildren<{
    title: string
    disabled?: boolean
}>

function _ButtonTooltip({ title, children, disabled }: ButtonTooltipProps) {
    if (disabled) {
        return <>{children}</>
    }

    return (
        <Tooltip
            title={<CoreTypography variant="caption">{title}</CoreTypography>}
            arrow={true}
            disableFocusListener
            disableTouchListener
        >
            <span>{children}</span>
        </Tooltip>
    )
}

export const ButtonTooltip = memo(_ButtonTooltip)
