import React from 'react'
import { ModalButtonVariant } from '../../../types'
import { Box, Typography, Paper, Divider } from '@mui/material'
import { ModalButton, ModalProps } from '../../buttons/ModalButton/ModalButton'
import { IconName } from '@fortawesome/fontawesome-common-types'

export interface CardProps {
    title: string
    titleId?: string
    buttonLabel?: string
    iconName?: IconName
    modalButtonVariant?: ModalButtonVariant
    children?: React.ReactNode
    ModalComponent?: React.FC<ModalProps>
    trigger?: any
}

export const Card: React.FC<CardProps> = ({
    children,
    modalButtonVariant,
    title,
    titleId,
    buttonLabel,
    iconName,
    ModalComponent,
    trigger
}) => {
    return (
        <>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <Box
                    sx={{
                        px: 4,
                        pt: modalButtonVariant ? 2 : 4,
                        pb: modalButtonVariant ? 2 : 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="h3" id={titleId}>
                        {title}
                    </Typography>
                    {modalButtonVariant && (
                        <ModalButton
                            modalButtonVariant={modalButtonVariant}
                            iconName={iconName}
                            buttonLabel={buttonLabel}
                            ModalComponent={ModalComponent}
                            trigger={trigger}
                        />
                    )}
                </Box>
                <Divider />
                <Box sx={{ p: { xs: 2, sm: 4 } }}>{children}</Box>
            </Paper>
        </>
    )
}
