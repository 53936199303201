// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    /* 1rem === 10px, but remain responsive to user font size settings */
    font-size: 62.5%;

    --sidebar-width: 0px;
    --navbar-height: 88px;
    --navbar-row-height-small: 48px;
    --navbar-row-height-collapsed: 56px;
    --navbar-height-small: calc(
        2 * var(--navbar-row-height-small) + 0.8rem
    ); /* accommodates two rows and top padding */
    --navbar-height-small-native: calc(var(--navbar-row-height-small) + 1.6rem);
    --px-sm: 1.6rem;
    --px-md: 2.4rem;
    --content-max-width: 1200px;
}

body {
    font-family: 'Montserrat', sans-serif;
    /* default font size is 16px */
    font-size: 1.6rem;
}

* {
    forced-color-adjust: none;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAEA;IACI,oEAAoE;IACpE,gBAAgB;;IAEhB,oBAAoB;IACpB,qBAAqB;IACrB,+BAA+B;IAC/B,mCAAmC;IACnC;;KAEC,EAAE,0CAA0C;IAC7C,2EAA2E;IAC3E,eAAe;IACf,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,qCAAqC;IACrC,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');\n\n:root {\n    /* 1rem === 10px, but remain responsive to user font size settings */\n    font-size: 62.5%;\n\n    --sidebar-width: 0px;\n    --navbar-height: 88px;\n    --navbar-row-height-small: 48px;\n    --navbar-row-height-collapsed: 56px;\n    --navbar-height-small: calc(\n        2 * var(--navbar-row-height-small) + 0.8rem\n    ); /* accommodates two rows and top padding */\n    --navbar-height-small-native: calc(var(--navbar-row-height-small) + 1.6rem);\n    --px-sm: 1.6rem;\n    --px-md: 2.4rem;\n    --content-max-width: 1200px;\n}\n\nbody {\n    font-family: 'Montserrat', sans-serif;\n    /* default font size is 16px */\n    font-size: 1.6rem;\n}\n\n* {\n    forced-color-adjust: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
