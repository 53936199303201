import * as configcat from 'configcat-js'
import { getReduxStore } from './redux'
import { configCatLogLevel, configCatSdkKey } from '../Env'

const DISABLE_CONFIG_CAT_LOGGING_FLAG_KEY = 'disableConfigCatLogging'

declare global {
  interface Window {
    getThriveClient: () => string
    cc_js: any
    cc_client: any
  }
}

function getSmokeTestValueFromLocalStorage(key: string): string | null {
  try {
    return localStorage.getItem(key) || ''
  } catch (e) {
    return null
  }
}

function getConfigCatLoggerLevel(): number {
  const defaultLevel = Number(configCatLogLevel)
  const isSmokeTest =
    getSmokeTestValueFromLocalStorage(DISABLE_CONFIG_CAT_LOGGING_FLAG_KEY) ===
    'true'
  return isSmokeTest ? configcat?.LogLevel?.Off : defaultLevel
}

export const setupConfigCat = () => {
  window.cc_js = configcat
  const logLevel = getConfigCatLoggerLevel()
  const logger = window.cc_js.createConsoleLogger(logLevel)

  window.cc_client = window.cc_js.getClient(
    configCatSdkKey,
    window.cc_js.PollingMode.AutoPoll,
    {
      logger,
      requestTimeoutMs: 10000,
      baseUrl: 'https://f-config.thriveglobal.com',
    }
  )
}

export enum FeatureFlagFeatures {
  isAppDownForMaintenance = 'isAppDownForMaintenance',
}

export const isFeatureEnabled = async (
  feature: FeatureFlagFeatures,
  defaultValue: boolean = false,
  checkUser: boolean = false
) => {
  if (!window.cc_client) setupConfigCat()
  let store = null

  try {
    await window.cc_client.forceRefreshAsync()
    store = getReduxStore()

    if (checkUser && store?.user?.userId && store?.settings?.locale) {
      return await window.cc_client.getValueAsync(feature, defaultValue, {
        identifier: store?.user?.userId,
        custom: {
          companyId: store?.user?.companyId,
          client: window.getThriveClient(),
          locale: store?.settings?.locale ?? null,
          platform: 'Web Root',
        },
      })
    } else {
      return await window.cc_client.getValueAsync(feature, defaultValue)
    }
  } catch (err) {
    console.error(`ConfigCat Feature Flag Check Failed: ${feature} - ${err}`)
    return defaultValue
  }
}
