import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Box, CircularProgress, ThemeProvider } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    selectIsLoggedIn,
    AdminLoginCallback,
    selectIsAccessTokenExpired,
    KeyCloakProtectedRoute
} from '@thriveglobal/thrive-web-auth-core'
import {
    useTheme,
    ThriveFullscreenLoading
} from '@thriveglobal/thrive-web-leafkit'

const Home = lazy(() => import('../pages/Home/Home'))
const CompanyPage = lazy(() => import('../pages/Company/Company'))
const CreateCompanyPage = lazy(
    () => import('../pages/CreateCompany/CreateCompany')
)
import { ROUTES } from './routes'

const Router: React.FC = () => {
    const theme = useTheme()
    const authState = useAppSelector((state) => state.auth)
    const isLoggedIn = selectIsLoggedIn(authState)
    const accessTokenExpired = selectIsAccessTokenExpired(authState)
    if (!isLoggedIn || accessTokenExpired) {
        return (
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Suspense fallback={<CircularProgress />}>
                        <Route
                            path={ROUTES.LOGIN_CALLBACK}
                            component={AdminLoginCallback}
                        />
                    </Suspense>
                </BrowserRouter>
                <ThriveFullscreenLoading />
            </ThemeProvider>
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <Box>
                <BrowserRouter>
                    <Suspense fallback={<CircularProgress />}>
                        <Switch>
                            <KeyCloakProtectedRoute
                                path={ROUTES.HOME}
                                component={Home}
                                exact
                            />
                            <KeyCloakProtectedRoute
                                path={ROUTES.COMPANY}
                                component={CompanyPage}
                            />
                            <KeyCloakProtectedRoute
                                path={ROUTES.CREATE_COMPANY}
                                component={CreateCompanyPage}
                            />
                            <Route
                                path={ROUTES.LOGIN_CALLBACK}
                                component={AdminLoginCallback}
                            />
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </Box>
        </ThemeProvider>
    )
}

export { ROUTES }

export default Router
