import Bowser from 'bowser'
import { ThriveAppPlatform } from './platformTypes'

const NODE_ENV = process.env.NODE_ENV
const SAFARI = Bowser.BROWSER_MAP.safari
const CHROME = Bowser.BROWSER_MAP.chrome
const MAC_OS = Bowser.OS_MAP.MacOS
const IOS = Bowser.OS_MAP.iOS
const ANDROID = Bowser.OS_MAP.Android

type ParsedResult = Bowser.Parser.ParsedResult

let browser: ParsedResult | unknown = null

export const setUserAgent = (userAgent: string) => {
    browser = Bowser.parse(userAgent)
}

/**
 * only set the `browser` value on module load when not mocking the user agent
 * during unit-tests
 */
if (NODE_ENV !== 'test') {
    browser = Bowser.parse(window.navigator.userAgent)
}

/**
 * test functions — determining `parsed` value at function invocation instead of
 * on JS module load. otherwise unit-tests pick-up JSDom's default user-agent value
 * and cannot be reset
 */

export const isSafariTest = () => {
    const parsed = browser as ParsedResult
    return parsed.browser.name === SAFARI
}

export const isSafariDesktopTest = () => {
    const parsed = browser as ParsedResult
    return parsed.browser.name === SAFARI && parsed.os.name === MAC_OS
}

export const isSafariMobileTest = () => {
    const parsed = browser as ParsedResult
    return parsed.browser.name === SAFARI && parsed.os.name === IOS
}

export const isChromeIOSTest = () => {
    const parsed = browser as ParsedResult
    return parsed.browser.name === CHROME && parsed.os.name === IOS
}

export const isMobileDeviceTest = () => {
    const parsed = browser as ParsedResult
    return parsed.os.name === ANDROID || parsed.os.name === IOS
}

export const isMicrosoftTeamsTest = () => {
    const hostname = window.location.hostname
    return (
        hostname === 'app.msteams.thriveglobal.com' ||
        hostname === 'app.msteams.stag.thriveglobal.com'
    )
}

export const getPlatform = (): ThriveAppPlatform => {
    try {
        const platform = window?.getThriveClient()
        if (platform === 'RN_ANDROID') {
            return ThriveAppPlatform.ANDROID
        }
        if (platform === 'RN_IOS') {
            return ThriveAppPlatform.IOS
        }
        if (isMicrosoftTeamsTest()) {
            return ThriveAppPlatform.MS_TEAMS
        }
        return ThriveAppPlatform.WEB
    } catch (err) {
        return ThriveAppPlatform.WEB
    }
}
