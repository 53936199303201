import { ThriveApplication } from '@thriveglobal/thrive-web-core'
import { ComponentProps } from 'react'

export type ApolloCacheConfig = ComponentProps<
    typeof ThriveApplication
>['apolloClientOptions']['cacheOptions']

export const apolloCacheConfig: ApolloCacheConfig = {
    typePolicies: {
        Query: {
            merge: true
        },
        IdentityQuery: {
            merge: true
        },
        IdentityCompanyQuery: {
            merge: true
        },
        DailyExperienceQuery: {
            merge: true
        },
        GoalQuery: {
            merge: true
        },
        AdminGoalQuery: {
            merge: true
        }
    }
}
