import { Analytics, AnalyticsSnippet } from '@segment/analytics-next'
import {
    addBreadcrumb,
    captureEvent,
    captureException,
    getBestFitLocale,
    getPlatform,
    store
} from '@thriveglobal/thrive-web-core'
import { getSegmentSession } from './session'

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS
    }
}

const addSegmentMiddlewareProperty = (key, value): Analytics => {
    try {
        const mwfunc = ({ payload, next }) => {
            const params = new URLSearchParams(window.location.search)

            if (params.has('utm_source')) {
                payload.obj.context.referrer = {
                    type: params.get('utm_source'),
                    link: window.location.href
                }
            }

            if (params.has('utm_campaign')) {
                payload.obj.context.campaign = {
                    type: params.get('utm_campaign'),
                    link: window.location.href
                }
            }

            payload.obj.properties[key] = value
            next(payload)
        }
        if (window.analytics) {
            window.analytics.addSourceMiddleware(mwfunc)
        } else {
            addBreadcrumb({
                message: `Segment middleware not initialized, could not set ${key} in middleware`
            })
        }
        return window.analytics
    } catch (e) {
        captureException(e, { message: 'segment middleware update error' })
    }
}

export const identifyUser = async () => {
    const state = store.getState()
    if (!state.user.userId) {
        captureEvent(
            'core - Identify with no user id',
            window.btoa(JSON.stringify(state))
        )
    }
    try {
        return await window.analytics.identify(state.user.userId, {
            company_id: state.user.companyId,
            user_id: state.user.userId,
            app_source: getPlatform(),
            core_commit_hash: process.env.GIT_REF,
            bacon: state.user.userId ? true : false,
            thrive_locale: getBestFitLocale(),
            client: window?.getThriveClient?.()
        })
    } catch (error) {
        captureException(error, { message: 'Segment failed to identify user' })
    }
}

export const initializeSegment = async (): Promise<Analytics> => {
    if (window.analytics) {
        const state = store.getState()
        addSegmentMiddlewareProperty('app_source', getPlatform())
        addSegmentMiddlewareProperty('thrive_locale', getBestFitLocale())
        addSegmentMiddlewareProperty('client', window?.getThriveClient?.())
        addSegmentMiddlewareProperty('session_id', getSegmentSession())

        if (state.user.userId && state.user.companyId) {
            await identifyUser()
        } else {
            store.subscribe(async () => {
                if (
                    store.getState().user.userId &&
                    store.getState().user.companyId &&
                    window.analytics?.user?.()?.id?.() === null
                ) {
                    await identifyUser()
                }
            })
        }

        return window.analytics as AnalyticsSnippet
    } else {
        return Promise.reject()
    }
}

export const resetSegment = () => {
    if (window.analytics) {
        window.analytics?.reset?.()
    }
}

export { getSegmentSession }
