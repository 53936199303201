import CoreTypography from '../../CoreTypography'
import { ToggleButtonGroup, type ToggleButtonGroupProps } from '@mui/material'
import { type FC, useEffect, MouseEvent } from 'react'
import {
    leafDateRangePickerPresets,
    type LeafDateRangeProps,
    type LeafDateRangeTogglePresetProps
} from '../../../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { LeafToggleButton } from '../../ToggleButton/LeafToggleButton'
import noop from 'lodash/noop'

interface LeafDateRangeToggleProps extends ToggleButtonGroupProps {
    orientation?: 'vertical' | 'horizontal'
    color?: 'primary' | 'secondary'
    presets?: LeafDateRangeTogglePresetProps[]
    selectedPreset: LeafDateRangeTogglePresetProps
    setSelectedPreset: (preset: LeafDateRangeTogglePresetProps) => void
    setStartDate: (startDate: string) => void
    setEndDate: (endDate: string) => void
    setStartTime?: (startTime: string) => void
    setEndTime?: (endTime: string) => void
    enableCustomPreset?: boolean
    size?: 'large' | 'medium'
}

const messages = defineMessages({
    customRangeLabel: {
        defaultMessage: 'Custom date range',
        description: 'Label for button which will select a custom date range.'
    }
})

function formatDateString(date: Date): string | null {
    if (!date) {
        return null
    }

    const monthStr =
        (Number(date.getMonth()) < 9 ? '0' : '') + (date.getMonth() + 1)
    const dateStr = (Number(date.getDate()) < 10 ? '0' : '') + date.getDate()
    return date.getFullYear() + '-' + monthStr + '-' + dateStr
}

function formatTimeString(date: Date): string | null {
    if (!date) {
        return null
    }

    const hoursStr = (Number(date.getHours()) < 10 ? '0' : '') + date.getHours()
    const minutesStr =
        (Number(date.getMinutes()) < 10 ? '0' : '') + date.getMinutes()
    return hoursStr + ':' + minutesStr
}

const CUSTOM_RANGE_PRESET: LeafDateRangeTogglePresetProps = {
    displayName: messages.customRangeLabel,
    dateRangeCalculator: (currentDate) => {
        return {
            startDate: null,
            endDate: null
        }
    },
    isCustom: true
}

export const LeafDateRangeToggle: FC<LeafDateRangeToggleProps> = ({
    presets = leafDateRangePickerPresets,
    selectedPreset,
    setSelectedPreset,
    setStartDate,
    setEndDate,
    setStartTime = noop,
    setEndTime = noop,
    orientation = 'horizontal',
    color = 'primary',
    enableCustomPreset = false,
    sx,
    disabled,
    size
}) => {
    const intl = useIntl()

    const handleToggleChange = (
        event: MouseEvent<HTMLElement>,
        selectedValue: LeafDateRangeTogglePresetProps
    ) => {
        setSelectedPreset(selectedValue)
        if (selectedValue !== null) {
            const today = Number(Date.now())
            const dateRange: LeafDateRangeProps =
                selectedValue.dateRangeCalculator(today)
            setStartDate(formatDateString(dateRange.startDate))
            setEndDate(formatDateString(dateRange.endDate))
            setStartTime(formatTimeString(dateRange.startDate))
            setEndTime(formatTimeString(dateRange.endDate))
        }
    }

    useEffect(function updateWithCustomPreset() {
        if (
            enableCustomPreset &&
            presets.find((p) => p.isCustom) === undefined
        ) {
            presets.push(CUSTOM_RANGE_PRESET)
        }
    }, [])

    return (
        <ToggleButtonGroup
            fullWidth
            value={selectedPreset}
            exclusive
            onChange={handleToggleChange}
            orientation={orientation}
            color={color}
            size={size}
            disabled={disabled}
            sx={sx}
        >
            {presets.map((preset, key) => (
                <LeafToggleButton
                    key={key}
                    value={preset}
                    sx={{
                        textTransform: 'unset',
                        justifyContent:
                            orientation === 'vertical' ? 'start' : 'center'
                    }}
                >
                    <CoreTypography
                        customVariant={
                            size === 'large' ? 'buttonLarge' : 'buttonNormal'
                        }
                    >
                        {intl.formatMessage(preset.displayName)}
                    </CoreTypography>
                </LeafToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}
