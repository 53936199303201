import React, { useState } from 'react'
import { Checkbox, Typography } from '@mui/material'
import { useIntl, defineMessages } from 'react-intl'

import { CheckboxConsentProps } from '../'
import LeafPrivacyPolicyLink from '../../../elements/Links/LeafPolicyLinks/LeafPrivacyPolicyLink/'
import LeafToSLink from '../../../elements/Links/LeafPolicyLinks/LeafToSLink/'

const messages = defineMessages({
    checkboxLabelRequired: {
        defaultMessage: 'Accept Privacy Policy and Terms of Service',
        description:
            'Message shown when a user needs to select the Terms of Service'
    },
    checkboxText: {
        defaultMessage:
            'I agree to the <PrivacyPolicy>Privacy Policy</PrivacyPolicy> and <ToS>Terms of Service</ToS>',
        description:
            'Label for the privacy policy and Terms of Service checkbox'
    }
})

export type StandardConsentProps = {
    defaultValue?: boolean
    onAccepted: CheckboxConsentProps['onAccepted']
}

export const StandardConsent = ({
    defaultValue,
    onAccepted
}: StandardConsentProps) => {
    const intl = useIntl()
    const [accepted, setAccepted] = useState<boolean>(defaultValue)

    const handleCheckboxChange = ({
        target: { checked }
    }: React.ChangeEvent<HTMLInputElement>) => {
        onAccepted(checked)
        setAccepted(checked)
    }

    return (
        <>
            <Checkbox
                required
                id="thrive-consent-tos"
                value={accepted}
                checked={accepted}
                onChange={handleCheckboxChange}
                color="primary"
                sx={{
                    '& .MuiSvgIcon-root': { fontSize: 28 }
                }}
            />

            <Typography
                variant="body2"
                component="label"
                htmlFor="thrive-consent-tos"
            >
                {intl.formatMessage(messages.checkboxText, {
                    PrivacyPolicy: () => <LeafPrivacyPolicyLink />,
                    ToS: () => <LeafToSLink />
                })}
            </Typography>
        </>
    )
}
