import { ROUTE_TYPE } from '.'

import { InputRoutesConfigObject } from 'single-spa-layout/dist/types/isomorphic/constructRoutes'

enum APPS {
  DASHBOARD = '@thriveglobal/thrive-web-dashboard',
  TODAY = '@thriveglobal/thrive-web-today',
  EXTRAS = '@thriveglobal/thrive-web-extras',
  PULSE = '@thriveglobal/thrive-web-pulse',
  INSIGHTS = '@thriveglobal/thrive-web-pulse-insights',
  LEARN = '@thriveglobal/thrive-web-learn',
  COACHING = '@thriveglobal/thrive-web-coaching',
  CHALLENGES = '@thriveglobal/thrive-web-challenges',
  JOURNEYS = '@thriveglobal/thrive-web-journeys',
  RESET = '@thriveglobal/thrive-web-reset',
  SEARCH = '@thriveglobal/thrive-web-search',
  LOGIN = '@thriveglobal/thrive-web-sign-in',
  CALLS = '@thriveglobal/thrive-web-calls',
  ACHIEVEMENTS = '@thriveglobal/thrive-web-achievements',
  PROFILE = '@thriveglobal/thrive-web-profiles',
  MS_TEAMS_AUTH = '@thriveglobal/thrive-web-ms-teams-auth',
  AUTH_CORE = '@thriveglobal/thrive-web-auth-core',
  GUIDE = '@thriveglobal/thrive-web-guide',
  ONBOARDING = '@thriveglobal/thrive-web-onboarding',
  MS_TEAMS_APP = '@thriveglobal/thrive-web-ms-teams-app',
  CENTRALIZED_NOTIFICATIONS = '@thriveglobal/thrive-web-centralized-notifications',
}

export enum PATHS {
  BASE = '/',
  TODAY = 'today',
  PULSE = 'pulse',
  INSIGHTS = 'insights',
  LEARN = 'learn',
  LEARN_RESETS = 'learn/reset',
  LEARN_SEARCH = 'learn/search',
  COACHING = 'coaching',
  CHALLENGES = 'challenges',
  JOURNEYS = 'journeys',
  RESET = 'reset',
  SEARCH = 'search',
  LOGIN = 'login',
  CALLS = 'calls',
  ACHIEVEMENTS = 'achievements',
  PROFILE = 'profile',
  MS_TEAMS_AUTH = 'ms-teams/auth',
  FE_PLATFORM = 'fe-platform',
  GUIDE = 'guide',
  ONBOARDING = 'onboarding',
  MS_TEAMS_APP = 'teams',
  CENTRALIZED_NOTIFICATIONS = 'notifications',
}

export const getWebRoutes = async (config: InputRoutesConfigObject) => {
  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    default: true,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.EXTRAS },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.JOURNEYS,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.JOURNEYS },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.TODAY,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.TODAY },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.PULSE,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.PULSE },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.COACHING,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.COACHING },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.INSIGHTS,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.INSIGHTS },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.LEARN,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.LEARN },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.LEARN_RESETS,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.LEARN },
      { type: ROUTE_TYPE.APP, name: APPS.RESET },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.LEARN_SEARCH,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.LEARN },
      { type: ROUTE_TYPE.APP, name: APPS.SEARCH },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.CHALLENGES,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.CHALLENGES },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.RESET,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.RESET },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.SEARCH,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.SEARCH },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.LOGIN,
    routes: [{ type: ROUTE_TYPE.APP, name: APPS.LOGIN }],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.CALLS,
    routes: [{ type: ROUTE_TYPE.APP, name: APPS.CALLS }],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.ACHIEVEMENTS,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.ACHIEVEMENTS },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.PROFILE,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.PROFILE },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.MS_TEAMS_AUTH,
    routes: [{ type: ROUTE_TYPE.APP, name: APPS.MS_TEAMS_AUTH }],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.FE_PLATFORM,
    routes: [{ type: ROUTE_TYPE.APP, name: APPS.EXTRAS }],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.GUIDE,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.GUIDE },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.ONBOARDING,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.ONBOARDING },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.MS_TEAMS_APP,
    routes: [{ type: ROUTE_TYPE.APP, name: APPS.MS_TEAMS_APP }],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.CENTRALIZED_NOTIFICATIONS,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.CENTRALIZED_NOTIFICATIONS },
    ],
  })
}

export const getTroRoutes = async (config: InputRoutesConfigObject) => {
  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    default: true,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.EXTRAS },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.RESET,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.RESET },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.LOGIN,
    routes: [{ type: ROUTE_TYPE.APP, name: APPS.LOGIN }],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.PROFILE,
    routes: [
      { type: ROUTE_TYPE.APP, name: APPS.DASHBOARD },
      { type: ROUTE_TYPE.APP, name: APPS.PROFILE },
    ],
  })

  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: PATHS.MS_TEAMS_AUTH,
    routes: [{ type: ROUTE_TYPE.APP, name: APPS.MS_TEAMS_AUTH }],
  })
}
