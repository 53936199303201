export enum ROUTES {
    HOME = '/',
    COMPANY = '/company/:id',
    COMPANY_IDENTITY_SETTINGS = '/company/:id/identity-settings',
    COMPANY_FEATURE_FLAGS = '/company/:id/feature-flags',
    COMPANY_INTENTION_SETTINGS = '/company/:id/intention-settings',
    COMPANY_CHAT_FEATURE_FLAGS = '/company/:id/chat-feature-flags',

    COMPANY_THRIVE_GAME_MANAGEMENT = '/company/:id/thrive-game-management',
    COMPANY_THRIVE_GAME_MANAGEMENT_GAME = '/company/:id/thrive-game-management/:gameId',

    CREATE_COMPANY = '/create',
    // Temp for now
    LOGIN_CALLBACK = '/login/callback'
}
