import { Analytics } from '@segment/analytics-next'
import { getReduxStore } from './redux'

declare global {
  interface Window {
    analytics: Analytics
  }
}

const debounce = (func: Function, delay: number) => {
  let debounceId: ReturnType<typeof setTimeout>

  return function (...args: any[]) {
    clearTimeout(debounceId)
    debounceId = setTimeout(() => func.apply(null, args), delay)
  }
}

const getPlatform = (client: string) =>
  client === 'RN_ANDROID'
    ? 'hybrid-android'
    : client === 'RN_IOS'
    ? 'hybrid-ios'
    : window.location.hostname.includes('app.msteams')
    ? 'msteams'
    : 'web'

const pageEvent = async (newUrl: string, previousUrl: string) => {
  if (!navigator.webdriver) {
    const store = getReduxStore()
    const client = window.getThriveClient()
    if (window.analytics === undefined) {
      console.warn('Segment analytics is not initialized')
      return
    }

    window.analytics.page(undefined, new URL(newUrl).pathname, {
      client,
      app_source: getPlatform(client),
      previous_path: new URL(previousUrl)?.pathname ?? null,
      session_id:
        window.sessionStorage.getItem('THRIVE_SEGMENT_SESSION_ID') ?? null,
      thrive_locale: store?.settings?.locale ?? null,
      is_logged_in: store?.auth?.isLoggedIn ?? null,
      company_id: store?.user?.companyId ?? null,
      subscription_id: store?.auth?.subscriptionId ?? null,
      subscription_type: store?.auth?.subscriptionType ?? null,
      license: store?.auth?.license ?? null,
    })
  }
}

export const debouncedPageEvent = debounce(pageEvent, 300)
