import React, { useMemo } from 'react'
import { CopyIdButton } from '../../buttons'
import { LogoContainer } from '../../elements'
import {
    FormattedMessage,
    defineMessages,
    useIntl,
    MessageDescriptor
} from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'
import { Stack, Box, Link, Paper, Divider, Chip } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { CompanyWithIdpInfo } from '../../../types'

export interface CompanyListItemProps {
    company: CompanyWithIdpInfo
    showCopyConfirmation: (id: string) => void
}

const messages = defineMessages({
    credentialLabel: {
        defaultMessage: 'Web/Mobile (Credential)',
        description: 'Label for credential signin method chip'
    },
    supportTeamsLabel: {
        defaultMessage: 'Thrive for Support Teams',
        description: 'Label for Thrive for Support Teams signin method chip'
    },
    ssoLabel: {
        defaultMessage: 'Web/Mobile (SSO)',
        description: 'Label for SSO signin method chip'
    },
    slackLabel: {
        defaultMessage: 'Slack',
        description: 'Label for Slack signin method chip'
    },
    teamsLabel: {
        defaultMessage: 'Teams',
        description: 'Label for Teams signin method chip'
    },
    webexLabel: {
        defaultMessage: 'Webex',
        description: 'Label for Webex signin method chip'
    }
})

const idpTypeToMessageMap = {
    keycloak_direct_password: messages.credentialLabel,
    magiclink: messages.credentialLabel,
    genesys: messages.supportTeamsLabel,
    genesys_us_east_1: messages.supportTeamsLabel,
    genesys_us_west_2: messages.supportTeamsLabel,
    nice: messages.supportTeamsLabel,
    keycloak_sso: messages.ssoLabel,
    slack: messages.slackLabel,
    azuread: messages.teamsLabel,
    webex: messages.webexLabel
}

const getIdpTypeChips = (idpTypes: string[]) => {
    const chips: MessageDescriptor[] = []
    idpTypes.forEach((idpType) => {
        const label = idpTypeToMessageMap[idpType]
        if (label && !chips.includes(label)) {
            chips.push(label)
        }
    })
    return chips
}

export const CompanyListItem: React.FC<CompanyListItemProps> = ({
    company,
    showCopyConfirmation
}) => {
    const intl = useIntl()
    const theme = useTheme()
    const chips = useMemo(
        () => getIdpTypeChips(company.enabledIdpTypes),
        [company.enabledIdpTypes]
    )
    return (
        <>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'row'
                }}
                data-testid={`company-list-item-${company.name}`}
            >
                <LogoContainer imageUrl={company.imageUrl} alt={company.name} />
                <Stack
                    direction="column"
                    sx={{ width: '100%', ml: 2 }}
                    spacing={1}
                >
                    <Link
                        component={RouterLink}
                        to={'/company/' + company.id}
                        underline="none"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            <Stack spacing={1}>
                                <CoreTypography variant="overline">
                                    {company.status}
                                </CoreTypography>
                                <CoreTypography variant="h3">
                                    {company.name}
                                </CoreTypography>
                                <CoreTypography variant="overline">
                                    <FormattedMessage
                                        description="Label for company created date"
                                        defaultMessage={`Created: {date}`}
                                        values={{
                                            date: intl.formatDate(
                                                company.createdAt,
                                                {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric'
                                                }
                                            )
                                        }}
                                    />
                                </CoreTypography>
                            </Stack>
                            <Box sx={{ p: 1 }}>
                                <LeafIcon icon={'chevron-right'} />
                            </Box>
                        </Box>
                    </Link>
                    <Stack direction="column" spacing={1}>
                        <Divider sx={{ width: '90%' }} />
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                            <CoreTypography variant="overline">
                                <FormattedMessage
                                    description="Label for company id"
                                    defaultMessage={`Company ID: {id}`}
                                    values={{
                                        id: company.id
                                    }}
                                />
                            </CoreTypography>
                            <CopyIdButton
                                id={company.id}
                                showCopyConfirmation={showCopyConfirmation}
                            />
                        </Stack>
                        <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                            {chips &&
                                chips.map((chipText, index) => (
                                    <Chip
                                        sx={{
                                            height: '28px',
                                            fontSize: '1rem !important',
                                            fontFamily: theme.typography.h6,
                                            textTransform: 'uppercase',
                                            m: '4px !important'
                                        }}
                                        key={index}
                                        label={intl.formatMessage(chipText)}
                                        color="secondary"
                                    />
                                ))}
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </>
    )
}
