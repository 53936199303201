import { toDataURL } from 'qrcode'
import {
    BranchLink,
    captureException,
    useAppSelector,
    CoreState
} from '@thriveglobal/thrive-web-core'
import { useEffect, useState } from 'react'
import { type ApolloError } from '@apollo/client'
import {
    GetMagicLinkQuery,
    useGetMagicLinkLazyQuery
} from '../../graphql/generated/autogenerated'

export const useMagicLink = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error | ApolloError | null>(null)

    const { userId } = useAppSelector((state: CoreState) => state.user)

    const [magicLink, setMagicLink] = useState<BranchLink>({
        link: '',
        qrCode: ''
    })
    const [getLinks, { refetch: refetchQuery }] = useGetMagicLinkLazyQuery({
        fetchPolicy: 'cache-and-network',
        onCompleted: async (data: GetMagicLinkQuery) => {
            const link = data?.identity?.me?.magicLink
            const qrCode = link ? await toDataURL(link) : ''

            setMagicLink({
                link: link,
                qrCode: qrCode
            })
            setLoading(false)
        },
        onError: (err) => {
            setError(err)
            setLoading(false)
        }
    })
    const refetch = () => {
        setLoading(true)
        setError(null)
        refetchQuery()
    }
    useEffect(() => {
        // hook props can sometimes be undefined for a period of time
        // due to data fetching, mounting order, etc...
        const checkValuesAndSetError = () => {
            if (!userId) {
                //if the required values don't resolve within 5sec
                //show an error
                setLoading(false)
                const paramError = new Error(
                    'Missing required parameters for generating links'
                )
                setError(paramError)
                captureException(paramError, null, 'identity')
            }
        }
        const timerId = setTimeout(checkValuesAndSetError, 5000)
        return () => clearTimeout(timerId)
    }, [userId])

    useEffect(() => {
        if (userId) {
            getLinks()
        }
    }, [userId, getLinks])

    return {
        loading,
        error,
        magicLink,
        refetch
    }
}
