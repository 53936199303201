import {
    FormControl,
    FormControlProps,
    FormHelperText,
    Typography
} from '@mui/material'
import { useTheme } from '../../../../hooks'

interface LeafFormControlProps extends FormControlProps {
    label: string
    id: string
    helperText?: string
}

export const LeafFormControl: React.FC<LeafFormControlProps> = ({
    label = '',
    id = '',
    helperText = '',
    disabled = false,
    children,
    color = 'primary',
    ...props
}) => {
    const theme = useTheme()

    const labelColor = disabled
        ? theme.palette.text.disabled
        : props.error
          ? theme.palette.error.main
          : theme.palette[color].main

    return (
        <FormControl {...props}>
            <label
                htmlFor={id}
                style={{
                    marginBottom: 8,
                    color: labelColor
                }}
            >
                <Typography variant={'body2'}>{label}</Typography>
            </label>
            {children}
            <FormHelperText sx={{ marginLeft: 0 }}>{helperText}</FormHelperText>
        </FormControl>
    )
}

export default LeafFormControl
