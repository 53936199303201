export enum THEME {
    THRIVE = 'THRIVE',
    DARK = 'DARK',
    HIGH_CONTRAST = 'HIGH_CONTRAST',
    NATURE = 'NATURE',
    CAMPBWELL = 'CAMP_B-WELL',
    DEMO = 'DEMO'
}

export const THEMES = {
    THRIVE: THEME.THRIVE,
    HIGH_CONTRAST: THEME.HIGH_CONTRAST,
    DARK: THEME.DARK,
    DEMO: THEME.DEMO
}

export const MSTEAMS_THEMES = {
    default: THEME.THRIVE,
    dark: THEME.DARK,
    contrast: THEME.HIGH_CONTRAST
}

export const HEIGHTS = {
    LARGE_COMPONENT_HEIGHT: '58px',
    MEDIUM_COMPONENT_HEIGHT: '44px'
}

export const BORDER_RADIUS = {
    DEFAULT: '16px',
    BUTTON: '24px',
    CHIP: '8px'
} as const

export const Z_INDEX = {
    BELOW_NAV: 1099
}
