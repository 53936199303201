import { Analytics, AnalyticsSnippet } from '@segment/analytics-next'
import { addBreadcrumb, captureEvent, captureException } from '../../Sentry'
import { getPlatform } from '../../userAgent'
import { getBestFitLocale } from '../../Intl'
import store from '../../../store'
import { setSegmentInitialized } from '../../../store/slices/globalAppVariables'
import { getSegmentSession } from './session'

export const addSegmentMiddlewareProperty = (key, value): Analytics => {
    try {
        const mwfunc = ({ payload, next }) => {
            // We put custom properties in the properties object this is for the data team to pass these
            // along to bigQuery.
            const params = new URLSearchParams(window.location.search)
            if (params.has('utm_source')) {
                payload.obj.context.referrer = {
                    type: params.get('utm_source'),
                    link: window.location.href
                }
            }

            if (!payload.obj.properties) {
                payload.obj.properties = {}
            }

            payload.obj.properties[key] = value
            next(payload)
        }
        if (window.analytics) {
            window.analytics.addSourceMiddleware(mwfunc)
        } else {
            addBreadcrumb({
                message: `Segment middleware not initialized, could not set ${key} in middleware`
            })
        }
        return window.analytics
    } catch (e) {
        captureException(e, { message: 'segment middleware update error' })
    }
}

export const initializeSegment = async (): Promise<Analytics> => {
    //return window.analytics as AnalyticsSnippet
    // Generic Segment setup
    if (window.analytics) {
        store.dispatch(setSegmentInitialized(true))
        const state = store.getState()
        addSegmentMiddlewareProperty('app_source', getPlatform())
        addSegmentMiddlewareProperty('thrive_locale', getBestFitLocale())
        addSegmentMiddlewareProperty('client', window?.getThriveClient?.())
        addSegmentMiddlewareProperty('session_id', getSegmentSession())
        // On initial page load if we have the user in redux then we just identify
        if (state.user.userId && state.user.companyId) {
            await identifyUser()
        } else {
            // Otherwise, we subscribe to the store to identify if analytics has not been set and once we have a userId
            store.subscribe(async () => {
                if (
                    // Store user id and company id must be set
                    store.getState().user.userId &&
                    store.getState().user.companyId &&
                    // We must not have called identify before
                    window.analytics?.user?.()?.id?.() === null
                ) {
                    await identifyUser()
                }
            })
        }

        return window.analytics as AnalyticsSnippet
    }
}

const identifyUser = async () => {
    const state = store.getState()
    if (!state.user.userId) {
        captureEvent(
            'core - Identify with no user id',
            window.btoa(JSON.stringify(state))
        )
    }
    try {
        return await window.analytics.identify(state.user.userId, {
            company_id: state.user.companyId,
            user_id: state.user.userId,
            app_source: getPlatform(),
            core_commit_hash: process.env.GIT_REF,
            bacon: state.user.userId ? true : false,
            thrive_locale: getBestFitLocale(),
            client: window?.getThriveClient?.()
        })
    } catch (error) {
        captureException(error, { message: 'Segment failed to identify user' })
    }
}

export * from './session'
