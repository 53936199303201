import * as singleSpa from 'single-spa'
import { captureError, captureMessage } from '../utils/datadog'
import { PATHS } from './webRoutes'
import checkAuthBeforeRouting from './checkAuthBeforeRouting'
import maintenance from '../screens/maintenance.html'
import { isFeatureEnabled, FeatureFlagFeatures } from '../utils/configCat'
import { debouncedPageEvent } from '../utils/segment'

function isUsingOldSingleSpa(): boolean {
  try {
    return Boolean(
      (window as any)?.System.get(
        'https://unpkg.com/single-spa@5.9.4/lib/system/single-spa.min.js'
      )
    )
  } catch (error) {
    captureError(error as Error)
    return false
  }
}

function navigateOnNextCycle(route: string) {
  setTimeout(() => {
    singleSpa.navigateToUrl(route)
  }, 0)
}

function addMaintenanceMessage() {
  document.body.innerHTML = maintenance
  Array.from(document.body.getElementsByTagName('script')).map((el) =>
    eval(el.innerHTML)
  )
}

async function shouldCancelRouting(
  isThriveResetOnly: boolean,
  newUrl: string,
  oldUrl: string
) {
  try {
    const newURL = new URL(newUrl)
    const isBaseUrl = newURL.pathname === PATHS.BASE
    const isTodayRootUrl = newURL.pathname.replace(/\//g, '') === PATHS.TODAY
    const isJourneysRootUrl =
      newURL.pathname.replace(/\//g, '') === PATHS.JOURNEYS

    if (isBaseUrl) {
      // for now, only check whether the app is down
      // for maintenance when the user navigates to /
      const isAppDownForMaintenance = await isFeatureEnabled(
        FeatureFlagFeatures.isAppDownForMaintenance,
        false,
        true
      )

      if (isAppDownForMaintenance) {
        addMaintenanceMessage()
        return true
      }
    }

    // each license should do some redirecting; for a user on a standard
    // license in a post-thrive-3 world, we should redirect both PATHS.BASE
    // and PATHS.TODAY to PATHS.JOURNEYS. for a TRO license, redirect PATHS.BASE
    // and PATHS.JOURNEYS to PATHS.RESET
    if (isThriveResetOnly) {
      if (isBaseUrl || isTodayRootUrl || isJourneysRootUrl) {
        captureMessage(
          `Navigating Thrive Reset Only user from ${newURL.pathname} to ${PATHS.RESET}`
        )
        navigateOnNextCycle(`/${PATHS.RESET}`)
        return true
      }
    } else {
      if (isBaseUrl || isTodayRootUrl) {
        captureMessage(
          `Navigating user from ${newURL.pathname} to ${PATHS.JOURNEYS}`
        )
        navigateOnNextCycle(`/${PATHS.JOURNEYS}`)
        return true
      }
    }

    // check the user's authentication to make sure
    // they can navigate to the page they are trying
    // to load
    await checkAuthBeforeRouting({
      newRoute: newURL,
    })
    debouncedPageEvent(newUrl, oldUrl)
  } catch (err) {
    captureError(err as Error, {
      message: `Root: Before Routing Failed: ${err}`,
    })
    window.location.href = newUrl
  }
}

type BeforeRoutingProps = { isThriveResetOnly: boolean }
type BeforeRoutingAsyncProps = {
  detail: { newUrl: string; cancelNavigation: Function; oldUrl: string }
}
export const beforeRouting =
  ({ isThriveResetOnly }: BeforeRoutingProps) =>
  async ({
    detail: { newUrl, cancelNavigation, oldUrl },
  }: BeforeRoutingAsyncProps) => {
    if (isUsingOldSingleSpa()) {
      captureMessage(
        'User is using old version of single SPA with latest JS code.'
      )
      // Return to prevent looping error
      return
    }
    cancelNavigation(shouldCancelRouting(isThriveResetOnly, newUrl, oldUrl))
  }
