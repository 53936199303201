import { getReduxStore } from './redux'

declare global {
  interface Window {
    DD_RUM: any
    DD_LOGS: any
  }
}

function logError(errorMessage: string, error: Error, errorContext?: any) {
  const errorContextPayload = errorContext || {}
  window.DD_LOGS?.logger?.error(errorMessage, errorContextPayload, error)
  window.DD_RUM?.addError(errorMessage, errorContextPayload, error)
}

function logMessage(message: string) {
  window.DD_LOGS?.logger?.info(message)
  window.DD_RUM?.addAction(message)
}

const setUser = () => {
  const store = getReduxStore()

  if (window.DD_RUM?.getUser) {
    const ddUser = window.DD_RUM?.getUser()

    if (store?.user && ddUser?.userId !== store.user?.userId) {
      const user = {
        id: store.user?.userId ?? null,
        companyId: store.user?.companyId ?? null,
        thriveAuthType: store.auth?.authType ?? null,
        analyticsId: store.user?.analyticsId ?? null,
      }
      window.DD_RUM?.setUser(user)
      window?.DD_LOGS?.setUser(user)
    }
  }
}

export const captureError = (error: Error, errorContext?: any) => {
  setUser()
  const errorMessage = typeof error === 'string' ? error : error.message
  const reportedError = typeof error === 'string' ? new Error(error) : error

  try {
    logError(errorMessage, reportedError, errorContext)
  } catch (err) {
    console.error('DataDog Not Present in Application')
  }
}

export const captureMessage = (message: string) => {
  setUser()
  try {
    logMessage(message)
  } catch (err) {
    console.error('DataDog Not Present in Application')
  }
}
