import { captureError } from '../utils/datadog'

interface ICheckAuthBeforeRoutingInput {
  newRoute: URL
}
async function checkAuth({ newRoute }: ICheckAuthBeforeRoutingInput) {
  try {
    // If the new route being navigated to is in the login app, we don't want to refresh the token
    if (newRoute.pathname.includes('/login')) {
      return
    }
    const authCore = await System.import('@thriveglobal/thrive-web-auth-core')
    await authCore.maybeRefreshToken()
  } catch (error) {
    captureError(error as Error)
  }
}

export default checkAuth
