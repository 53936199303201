import React from 'react'

import { StandardConsent } from './StandardConsent'
import { APACConsent } from './APACConsent'

export type CheckboxConsentProps = {
    defaultValue?: boolean
    country: string
    onAccepted: (b: boolean) => any
}

const APAC_COUNTRIES = ['KR', 'VN']

export const CheckboxConsent: React.FC<CheckboxConsentProps> = ({
    country,
    onAccepted,
    ...props
}) =>
    APAC_COUNTRIES.includes(country.toUpperCase()) ? (
        <APACConsent onAccepted={onAccepted} {...props} />
    ) : (
        <StandardConsent onAccepted={onAccepted} {...props} />
    )
