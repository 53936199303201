import React, { useEffect, useState } from 'react'
import { ThriveFullscreenLoading } from '@thriveglobal/thrive-web-leafkit'
import {
    isRunningInWebview,
    postMessageToWebview,
    HybridEventType,
    captureException
} from '@thriveglobal/thrive-web-core'
import { LoginRedirect } from '../protectedRoute/LoginRedirect'
import { Avo, clearTracking } from '@thriveglobal/thrive-web-tracking'

export const LogoutCallback: React.FC = () => {
    const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false)
    useEffect(() => {
        try {
            if (isRunningInWebview()) {
                postMessageToWebview({ type: HybridEventType.LOGOUT })
            }
            Avo.signoutCompleted({
                activityType: 'user_signout',
                featureType: 'identity',
                userId_: null,
                isSystemEvent: null
            })
            //clear segment local storage and cookies
            clearTracking()
        } catch (error) {
            console.error(error)
            captureException(error as Error, {
                message: 'Failed in logout callback'
            })
        }
        setRedirectToLogin(true)
    }, [])

    if (!redirectToLogin) {
        return <ThriveFullscreenLoading />
    }
    return <LoginRedirect />
}
