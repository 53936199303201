import { gql, Apollo } from '@thriveglobal/thrive-web-core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    AllowedLocale: any
    BigDecimal: any
    Color: any
    ISODate: any
    Instant: any
    JSON: any
    Locale: any
    Long: any
    PartnerUserId: any
    TimeZone: any
    UUID: any
    Url: any
}

export type AcceptToSInput = {
    userTime: Scalars['Instant']
    userZoneId?: InputMaybe<Scalars['TimeZone']>
}

export type AccessTypeInput = {
    name: Scalars['String']
}

export type AccessTypePage = {
    __typename?: 'AccessTypePage'
    items: Array<Scalars['String']>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export enum AchievementFrequency {
    OnceAMonth = 'ONCE_A_MONTH',
    OneTime = 'ONE_TIME',
    Unlimited = 'UNLIMITED'
}

export type AchievementInfo = {
    __typename?: 'AchievementInfo'
    /** The action type of the achievement */
    actionType?: Maybe<Scalars['String']>
    /** The description of the achievement */
    description?: Maybe<Scalars['String']>
    /** The entity uuid of the related entity */
    entityId?: Maybe<Scalars['UUID']>
    /** The uuid of the achievement */
    id?: Maybe<Scalars['UUID']>
    /** The url of the image */
    image?: Maybe<Scalars['String']>
    /** The quantity of the achievement */
    quantity?: Maybe<Scalars['Int']>
    /** The title of the achievement */
    title?: Maybe<Scalars['String']>
    /** Tell us if the achievement is a one off */
    unique?: Maybe<Scalars['Boolean']>
    /** The achievement upc */
    upc?: Maybe<Scalars['String']>
}

export type AchievementLevel = {
    __typename?: 'AchievementLevel'
    /** the claim date */
    claimedAt?: Maybe<Scalars['Instant']>
    /** the completion date */
    completedAt?: Maybe<Scalars['Instant']>
    /** The level of the achievement */
    level?: Maybe<Scalars['Int']>
    /** The amount needed to get the achievement */
    total?: Maybe<Scalars['Int']>
}

export type AchievementProgress = {
    __typename?: 'AchievementProgress'
    /** The id of the achievement */
    achievementId?: Maybe<Scalars['UUID']>
    /** The levels of the achievement */
    levels: Array<AchievementLevel>
    /** The progress of the achievement */
    progress?: Maybe<Scalars['Int']>
    /** The user id of the achievementProgress */
    userId?: Maybe<Scalars['UUID']>
}

export type AchievementV2 = {
    __typename?: 'AchievementV2'
    /** The achievement action type */
    actionType?: Maybe<Scalars['String']>
    /** The description of the achievement */
    description?: Maybe<Scalars['String']>
    /** The uuid of the achievement */
    id?: Maybe<Scalars['UUID']>
    /** The url of the image */
    image?: Maybe<Scalars['String']>
    /** The maximum level achievable */
    maximumLevel: Scalars['Int']
    /** The reward amount the user will receive when claiming the achievement */
    rewardAmount?: Maybe<Scalars['Int']>
    /** The reward currency code the user will receive when claiming the achievement */
    rewardCurrencyCode?: Maybe<Scalars['String']>
    /** The title of the achievement */
    title?: Maybe<Scalars['String']>
    /** The achievement upc */
    upc?: Maybe<Scalars['String']>
}

export type AchievementV3Info = {
    __typename?: 'AchievementV3Info'
    /** The count of actions needed to complete the achievement */
    actionNumber: Scalars['Int']
    /** The action type of the achievement */
    actionType: Scalars['String']
    /** The description of the achievement */
    description: Scalars['String']
    /** The frequency of how often the achievement can be earned */
    frequency: Scalars['String']
    /** The uuid of the achievement */
    id: Scalars['UUID']
    /** The name of the achievement */
    name: Scalars['String']
    /** The reward amount the user will receive when claiming the achievement */
    rewardAmount: Scalars['Int']
    /** The code of the reward currency the user will receive when claiming the achievement */
    rewardCurrency: Scalars['String']
    /** The achievement upc */
    upc: Scalars['String']
}

export type AchievementV3Progress = {
    __typename?: 'AchievementV3Progress'
    /** The achievement info */
    achievementInfo: AchievementV3Info
    /** The current progress to the completion of the achievement */
    currentProgress: Scalars['Int']
    /** The last time the achievement was completed */
    lastCompletedAt?: Maybe<Scalars['Instant']>
    /** The last time the achievement was updated */
    lastUpdatedAt?: Maybe<Scalars['Instant']>
    /** The amount of times that the achievement has been achieved */
    total: Scalars['Int']
    /** The upc of the achievement */
    upc: Scalars['String']
}

export type AchievementsMutation = {
    __typename?: 'AchievementsMutation'
    autoClaimAchievementsAdmin: Scalars['Boolean']
    /** Claim achievement */
    claimAchievement: Array<AchievementProgress>
    createAchievement?: Maybe<AchievementInfo>
    createAchievementV3?: Maybe<AchievementV3Info>
    deleteAchievement: Scalars['String']
    deleteAchievementV3: Scalars['String']
    deleteAchievementsForAllUsers: Scalars['String']
    deleteAchievementsForUser: Scalars['String']
    deleteAllAchievementsForUsers: Scalars['String']
    /** Sets a user flag */
    setUserFlag?: Maybe<Scalars['String']>
    updateAchievement?: Maybe<AchievementInfo>
}

export type AchievementsMutationAutoClaimAchievementsAdminArgs = {
    limit: Scalars['Int']
    pageSize: Scalars['Int']
}

export type AchievementsMutationClaimAchievementArgs = {
    achievementId: Scalars['UUID']
    levels: Array<Scalars['Int']>
}

export type AchievementsMutationCreateAchievementArgs = {
    actionType: Scalars['String']
    description: Scalars['String']
    entityId?: InputMaybe<Scalars['UUID']>
    image: Scalars['String']
    title: Scalars['String']
    upc: Scalars['String']
}

export type AchievementsMutationCreateAchievementV3Args = {
    actionNumber: Scalars['Int']
    actionType: ActionType
    description: Scalars['String']
    frequency: AchievementFrequency
    name: Scalars['String']
    rewardAmount: Scalars['Int']
    rewardCurrency: Scalars['String']
    upc: Scalars['String']
}

export type AchievementsMutationDeleteAchievementArgs = {
    achievementId: Scalars['UUID']
}

export type AchievementsMutationDeleteAchievementV3Args = {
    achievementId: Scalars['UUID']
}

export type AchievementsMutationDeleteAchievementsForAllUsersArgs = {
    achievementIds: Array<Scalars['UUID']>
}

export type AchievementsMutationDeleteAchievementsForUserArgs = {
    achievementIds: Array<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type AchievementsMutationDeleteAllAchievementsForUsersArgs = {
    excluding: Scalars['Boolean']
    userIds: Array<Scalars['UUID']>
}

export type AchievementsMutationSetUserFlagArgs = {
    key: Scalars['String']
    value?: InputMaybe<Scalars['String']>
}

export type AchievementsMutationUpdateAchievementArgs = {
    achievementId: Scalars['UUID']
    actionType: Scalars['String']
    description: Scalars['String']
    entityId?: InputMaybe<Scalars['UUID']>
    image: Scalars['String']
    title: Scalars['String']
    upc: Scalars['String']
}

export type AchievementsQuery = {
    __typename?: 'AchievementsQuery'
    /** Get V3 achievements info */
    achievementsV3: Array<AchievementV3Info>
    /** Get achievements V3 progress related to the user */
    achievementsV3UserProgress: Array<AchievementV3Progress>
    /** Get achievement progress related to the user */
    getAchievementsUserProgress: Array<AchievementProgress>
    /** Get v2 achievements related to the user */
    getAchievementsV2: Array<AchievementV2>
    /** Returns the value for a user flag */
    getUserFlag?: Maybe<Scalars['String']>
    /** Returns all flags stored for a user */
    getUserFlags: Array<UserFlag>
}

export type AchievementsQueryGetUserFlagArgs = {
    key: Scalars['String']
}

export enum ActionType {
    CheckIn = 'CHECK_IN',
    ContentFinished = 'CONTENT_FINISHED',
    CourseCompleted = 'COURSE_COMPLETED',
    JourneyLevelCompleted = 'JOURNEY_LEVEL_COMPLETED',
    PersonalResetCreated = 'PERSONAL_RESET_CREATED',
    ProfileImageAdded = 'PROFILE_IMAGE_ADDED',
    PulseSurveyCompleted = 'PULSE_SURVEY_COMPLETED',
    ReferralCompleted = 'REFERRAL_COMPLETED',
    ResetWatched = 'RESET_WATCHED',
    SocialSharingCompleted = 'SOCIAL_SHARING_COMPLETED',
    WearableConnected = 'WEARABLE_CONNECTED',
    WorkDetailsCompleted = 'WORK_DETAILS_COMPLETED'
}

export type ActivityDailyStats = {
    __typename?: 'ActivityDailyStats'
    /** Calendar day */
    day: Scalars['Int']
    /** Calendar month */
    month: Scalars['Int']
    /** Activity steps/points */
    steps: Scalars['Int']
    /** Calendar year */
    year: Scalars['Int']
}

export type ActivityEntry = {
    __typename?: 'ActivityEntry'
    date?: Maybe<Scalars['ISODate']>
    dayOfYear?: Maybe<Scalars['Int']>
    durationMins?: Maybe<Scalars['Int']>
    label?: Maybe<Scalars['String']>
    steps: Scalars['Int']
    year?: Maybe<Scalars['Int']>
}

export type ActivityEntryDto = {
    __typename?: 'ActivityEntryDTO'
    date: Scalars['ISODate']
    durationMins: Scalars['Int']
    id: Scalars['UUID']
    label: Scalars['String']
    steps: Scalars['Int']
}

export type ActivityEntryInput = {
    date?: InputMaybe<Scalars['ISODate']>
    dayOfYear?: InputMaybe<Scalars['Int']>
    durationMins?: InputMaybe<Scalars['Int']>
    label?: InputMaybe<Scalars['String']>
    steps: Scalars['Int']
    year?: InputMaybe<Scalars['Int']>
}

export type ActivityFeatureCountQuery = {
    __typename?: 'ActivityFeatureCountQuery'
    /** Historical Challenges Count for a company */
    historicChallengesCount: HistoricChallengesCountStatsResponse
    /** Historical Microstep Checkin Count for a company */
    historicMicrostepCheckinCount: HistoricMicrostepCheckinCountResponse
}

export type ActivityFeatureCountQueryHistoricChallengesCountArgs = {
    end: MonthYearInput
    start: MonthYearInput
}

export type ActivityFeatureCountQueryHistoricMicrostepCheckinCountArgs = {
    end: MonthYearInput
    start: MonthYearInput
}

export type ActivityMutation = {
    __typename?: 'ActivityMutation'
    /** Delete activity entries by Id for a user */
    deleteManualActivityEntries: Scalars['Boolean']
    /** Save an activity entry for a user */
    saveActivityEntry: ActivityEntry
    /** Save an updated Step Count from Apple Health */
    saveAppleHealthStepsActivity: Array<AppleHealthSteps>
}

export type ActivityMutationDeleteManualActivityEntriesArgs = {
    date: Scalars['ISODate']
    entryIds: Array<Scalars['UUID']>
}

export type ActivityMutationSaveActivityEntryArgs = {
    activityEntry: ActivityEntryInput
}

export type ActivityMutationSaveAppleHealthStepsActivityArgs = {
    stepsRecords: Array<AppleHealthStepsInput>
}

export type ActivityQuery = {
    __typename?: 'ActivityQuery'
    /** Get a list of activity entries for date */
    getActivityEntriesForDate: Array<ActivityEntryDto>
    /** Get collection of manual activity entry options */
    getManualActivityOptions: Array<BiometricsManualActivityDto>
    /** Get a user's total step count for the given date */
    getStepCount: Scalars['Int']
}

export type ActivityQueryGetActivityEntriesForDateArgs = {
    date: Scalars['ISODate']
}

export type ActivityQueryGetStepCountArgs = {
    date: Scalars['ISODate']
}

export type ActivityStats = {
    __typename?: 'ActivityStats'
    /** Average individual activity steps/points for the whole challenge */
    averageIndividualSteps: Scalars['Int']
    /** Average team activity steps/points for the whole challenge */
    averageTeamSteps?: Maybe<Scalars['Int']>
    dailyIndividualStats: Array<ActivityDailyStats>
    dailyTeamStats: Array<ActivityDailyStats>
}

export type ActivitySummaryStats = {
    averageSteps: Scalars['Float']
}

export type ActivitySummaryStatsResponse = ActivitySummaryStats & {
    __typename?: 'ActivitySummaryStatsResponse'
    averageSteps: Scalars['Float']
}

export type AcuteCareBanner = {
    __typename?: 'AcuteCareBanner'
    description: Scalars['String']
    title: Scalars['String']
}

export type AdminChallengeSubscription = {
    __typename?: 'AdminChallengeSubscription'
    challengesId: Array<Scalars['UUID']>
    subscriptionId: Scalars['UUID']
}

export type AdminGoalMutation = {
    __typename?: 'AdminGoalMutation'
    /**
     * Fetches or creates a microstep plan for the user passing the goal id/journey id
     * @deprecated Use fetchPersonalizedPlan query
     */
    fetchOrCreateUserPlan: UserPlanResponse
    /** Remove step as done */
    removeStepAsDone?: Maybe<Scalars['ISODate']>
    /** Resets the intention */
    resetIntention?: Maybe<Intention>
    /** Sets an intention for the user */
    setIntention?: Maybe<Intention>
    /** Set step as done */
    setStepAsDone?: Maybe<Scalars['ISODate']>
    /** Sets the subscription tier for intention, 1 is the default tier */
    setSubscriptionTier: Scalars['Int']
}

export type AdminGoalMutationFetchOrCreateUserPlanArgs = {
    companyId: Scalars['UUID']
    getFullHabit?: InputMaybe<Scalars['Boolean']>
    goalId?: InputMaybe<Scalars['UUID']>
    journeyId?: InputMaybe<Scalars['UUID']>
    locale?: InputMaybe<Scalars['Locale']>
    userId: Scalars['UUID']
    zoneId?: InputMaybe<Scalars['String']>
}

export type AdminGoalMutationRemoveStepAsDoneArgs = {
    stepId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type AdminGoalMutationResetIntentionArgs = {
    userId: Scalars['UUID']
    zoneId?: InputMaybe<Scalars['String']>
}

export type AdminGoalMutationSetIntentionArgs = {
    companyId?: InputMaybe<Scalars['UUID']>
    intention: Scalars['String']
    journeyIds: Array<Scalars['UUID']>
    userId: Scalars['UUID']
    zoneId?: InputMaybe<Scalars['String']>
}

export type AdminGoalMutationSetStepAsDoneArgs = {
    checkinDate?: InputMaybe<Scalars['ISODate']>
    microstepId: Scalars['UUID']
    userId: Scalars['UUID']
    zoneId?: InputMaybe<Scalars['String']>
}

export type AdminGoalMutationSetSubscriptionTierArgs = {
    subscriptionId: Scalars['UUID']
    tier: Scalars['Int']
}

export type AdminGoalQuery = {
    __typename?: 'AdminGoalQuery'
    /** Returns the user's intention */
    fetchIntention?: Maybe<Intention>
    /** Returns an Intention by id */
    fetchIntentionById?: Maybe<Intention>
    /** Fetches the user plan, if the user don't have the intention flow it needs the journey id */
    fetchPersonalizedPlan: UserPlanResponse
    /** Returns recommended content for the user */
    fetchRecommendedContent: Array<RecommendedEntities>
    /** Returns the list of all user steps for the date sent */
    fetchStepsByDate: Array<Step>
    /** Returns the user subscription tier for intention, 1 if not found */
    fetchSubscriptionTier: Scalars['Int']
}

export type AdminGoalQueryFetchIntentionArgs = {
    userId: Scalars['UUID']
}

export type AdminGoalQueryFetchIntentionByIdArgs = {
    id: Scalars['UUID']
}

export type AdminGoalQueryFetchPersonalizedPlanArgs = {
    companyId: Scalars['UUID']
    journeyId?: InputMaybe<Scalars['UUID']>
    locale?: InputMaybe<Scalars['Locale']>
    userId: Scalars['UUID']
    zoneId?: InputMaybe<Scalars['String']>
}

export type AdminGoalQueryFetchRecommendedContentArgs = {
    companyId: Scalars['UUID']
    limit: Scalars['Int']
    locale: Scalars['Locale']
    recommendedTypes: Array<PersonalizedRecommendedContent>
    userId: Scalars['UUID']
    zoneId: Scalars['String']
}

export type AdminGoalQueryFetchStepsByDateArgs = {
    date: Scalars['ISODate']
    locale: Scalars['Locale']
    userId: Scalars['UUID']
}

export type AdminGoalQueryFetchSubscriptionTierArgs = {
    subscriptionId: Scalars['UUID']
}

export type AdminMutation = {
    __typename?: 'AdminMutation'
    /** Pull and save the latest Benchmark Ranges from BigQuery */
    refreshBenchmarkRanges: Array<OptimalRange>
}

export type AgentAnalyticsResponse = {
    __typename?: 'AgentAnalyticsResponse'
    agentId: Scalars['String']
    companyId: Scalars['String']
    data: Array<AgentMetricData>
    endDate: Scalars['Instant']
    startDate: Scalars['Instant']
}

export type AgentEngagement = {
    __typename?: 'AgentEngagement'
    agentId: Scalars['String']
    interactions: Scalars['Int']
    lastResetTimestamp?: Maybe<Scalars['Instant']>
    name: Scalars['String']
    numResetsReceived: Scalars['Int']
    resetCompletionRate: Scalars['Float']
}

export type AgentEngagementReport = {
    __typename?: 'AgentEngagementReport'
    agentCount: Scalars['Int']
    agentEngagementNumbers: Array<AgentEngagement>
}

export type AgentGroup = {
    __typename?: 'AgentGroup'
    id: Scalars['String']
    name?: Maybe<Scalars['String']>
}

export type AgentLastReset = {
    __typename?: 'AgentLastReset'
    agentId: Scalars['UUID']
    clientRowId: Scalars['UUID']
    id: Scalars['UUID']
    lastResetTime: Scalars['Instant']
    queryQueueId?: Maybe<Scalars['UUID']>
    trigger?: Maybe<Trigger>
}

export type AgentLastTrigger = {
    __typename?: 'AgentLastTrigger'
    triggerConfiguration?: Maybe<TriggerConfiguration>
    triggerReason?: Maybe<TriggerReason>
}

export type AgentMetricCoordinate = {
    __typename?: 'AgentMetricCoordinate'
    /** Trigger reason: duration in seconds or number of interactions */
    trigger: Scalars['Float']
    /** Time range */
    x: Scalars['String']
    /** Duration in seconds */
    y: Scalars['Float']
}

export type AgentMetricData = {
    __typename?: 'AgentMetricData'
    coordinates: Array<AgentMetricCoordinate>
    metric: AgentMetricType
}

export enum AgentMetricType {
    Break = 'BREAK',
    CallDurationReset = 'CALL_DURATION_RESET',
    ConsecutiveCallReset = 'CONSECUTIVE_CALL_RESET',
    CustomerInteraction = 'CUSTOMER_INTERACTION',
    ManualReset = 'MANUAL_RESET'
}

export type AgentQuery = {
    __typename?: 'AgentQuery'
    /** Terminates an agent's last reset interaction. */
    endReset: Scalars['Boolean']
    /** Get agent details */
    getAgent?: Maybe<PlatformAgent>
    /** Get the trigger configuration for the agent */
    getAgentLastTrigger?: Maybe<AgentLastTrigger>
    /** Get agents that belong to the requested group */
    getGroupAgents: Array<PlatformAgent>
    /** Get agent groups for a company by group type */
    getGroups: PlatformGroup
    /** Checks if the current user is a platform admin. */
    hasAdminAccess: Scalars['Boolean']
    /** Checks if the current user has view permission to Thrive admin page. */
    hasViewAccess: Scalars['Boolean']
    /** Send a Thrive Reset to the requested agent. Returns conversation id of the Reset interaction created. */
    sendReset?: Maybe<Scalars['String']>
    /** Request a reset to be sent to the authenticated user. */
    sendResetToSelf?: Maybe<Scalars['String']>
}

export type AgentQueryEndResetArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type AgentQueryGetAgentArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type AgentQueryGetAgentLastTriggerArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type AgentQueryGetGroupAgentsArgs = {
    companyId: Scalars['UUID']
    groupIdentifier: Scalars['String']
    groupType: GroupType
    platform: PlatformType
}

export type AgentQueryGetGroupsArgs = {
    companyId: Scalars['UUID']
    groupType: GroupType
    platform: PlatformType
}

export type AgentQueryHasAdminAccessArgs = {
    platform: PlatformType
}

export type AgentQueryHasViewAccessArgs = {
    platform: PlatformType
}

export type AgentQuerySendResetArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type AgentQuerySendResetToSelfArgs = {
    platform: PlatformType
}

export type AggregatePerformanceMetrics = {
    __typename?: 'AggregatePerformanceMetrics'
    callSkillName?: Maybe<Scalars['String']>
    interactionType: InteractionType
    metrics: Array<MetricData>
    queueId: Scalars['UUID']
    queueName?: Maybe<Scalars['String']>
}

export type AllAssessmentAttemptsError = {
    __typename?: 'AllAssessmentAttemptsError'
    errorCode: ErrorCode
}

export type AllAssessmentAttemptsResult =
    | AllAssessmentAttemptsError
    | AllAssessmentAttemptsSuccess

export type AllAssessmentAttemptsSuccess = {
    __typename?: 'AllAssessmentAttemptsSuccess'
    userAssessmentAttempts: Array<AssessmentAttemptSuccess>
}

export type AnalyticsResponse = {
    __typename?: 'AnalyticsResponse'
    data: Array<MetricData>
    endDate: Scalars['String']
    granularity: Granularity
    reportName: Scalars['String']
    startDate: Scalars['String']
}

export type AppUsageTrend = {
    __typename?: 'AppUsageTrend'
    endValue: Scalars['Long']
    percentDifference: Scalars['Float']
    startValue: Scalars['Long']
}

export type AppUsageTrends = {
    __typename?: 'AppUsageTrends'
    activeUsers: AppUsageTrend
    challengesStarted: AppUsageTrend
    checkins: AppUsageTrend
    cumlativeActiveUsers: AppUsageTrend
    learnModulesStarted: AppUsageTrend
    microstepsCheckedIn: AppUsageTrend
    resetsPlayed: AppUsageTrend
    uniqueChallengeUsers: AppUsageTrend
    uniqueCheckinUsers: AppUsageTrend
    uniqueLearnUsers: AppUsageTrend
    uniqueMicrostepUsers: AppUsageTrend
    uniqueResetUsers: AppUsageTrend
}

export type AppleHealthSleepBlocksInput = {
    appleHealthId: Scalars['UUID']
    endDate: Scalars['Instant']
    startDate: Scalars['Instant']
    thriveUserId: Scalars['UUID']
    value: Scalars['String']
}

export type AppleHealthSteps = {
    __typename?: 'AppleHealthSteps'
    dayOfYear: Scalars['Int']
    steps: Scalars['Int']
    thriveUserId: Scalars['UUID']
    year: Scalars['Int']
}

export type AppleHealthStepsInput = {
    dayOfYear: Scalars['Int']
    steps: Scalars['Int']
    thriveUserId: Scalars['UUID']
    year: Scalars['Int']
}

export type ArticleAuthor = {
    __typename?: 'ArticleAuthor'
    bio: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    photoUrl: Scalars['String']
    title: Scalars['String']
}

export type ArticleContentStatusInput = {
    /** article id */
    contentId: Scalars['String']
    /** article progress in seconds (for video and audio articles) */
    progressInSeconds?: InputMaybe<Scalars['Int']>
    progressType: LearnContentStatusProgressType
    subtype?: InputMaybe<LearnContentStatusSubtype>
}

export enum ArticleContentType {
    Article = 'Article',
    Audio = 'Audio',
    Text = 'Text',
    Video = 'Video'
}

export type ArticleContributorDto = {
    __typename?: 'ArticleContributorDTO'
    name: Scalars['String']
}

export type ArticleDto = {
    __typename?: 'ArticleDTO'
    authors?: Maybe<Array<ArticleContributorDto>>
    body?: Maybe<Scalars['String']>
    durationInSeconds: Scalars['Int']
    featuredImage?: Maybe<ArticleUploadFileDto>
    isRoleModel: Scalars['Boolean']
    locale?: Maybe<Scalars['String']>
    subtitle?: Maybe<Scalars['String']>
    title: Scalars['String']
    uuid?: Maybe<Scalars['String']>
}

export type ArticleDetail = {
    __typename?: 'ArticleDetail'
    article: ArticleV2
    articleInfo: ArticleV2Detail
    goalType: GoalType
}

export type ArticlePageV2 = {
    __typename?: 'ArticlePageV2'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ArticleV2>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type ArticleStats = {
    __typename?: 'ArticleStats'
    /** Articles list */
    articles: Array<ArticleStatsDetail>
    /** Number of articles read */
    total: Scalars['Int']
}

export type ArticleStatsDetail = {
    __typename?: 'ArticleStatsDetail'
    articleDetail: ArticleDetail
    completed: Scalars['Boolean']
}

export enum ArticleType {
    Article = 'Article',
    Recipe = 'Recipe',
    RoleModel = 'RoleModel',
    WatchMeThrive = 'WatchMeThrive'
}

export type ArticleUploadFileDto = {
    __typename?: 'ArticleUploadFileDTO'
    caption?: Maybe<Scalars['String']>
    height?: Maybe<Scalars['Int']>
    mime: Scalars['String']
    name: Scalars['String']
    previewUrl?: Maybe<Scalars['String']>
    size: Scalars['Float']
    url: Scalars['String']
    width?: Maybe<Scalars['Int']>
}

export type ArticleV2 = {
    __typename?: 'ArticleV2'
    articleContentType: ArticleContentType
    audioUrl?: Maybe<Scalars['String']>
    authors: Array<ArticleAuthor>
    body?: Maybe<Scalars['String']>
    bookmarked: Scalars['Boolean']
    collection?: Maybe<Scalars['String']>
    /** List of condition tags related to this article. */
    conditions: Array<Tag>
    contentStatus?: Maybe<LearnContentStatus>
    contentType: LearnContentStatusType
    createdAt: Scalars['Instant']
    durationSeconds: Scalars['Int']
    id: Scalars['ID']
    imageAlternativeText?: Maybe<Scalars['String']>
    imageUrl?: Maybe<Scalars['String']>
    /** @deprecated No longer supported, replace with contentType */
    isRoleModelStory: Scalars['Boolean']
    journeyIds: Array<Scalars['ID']>
    journeys: Array<Journey>
    language?: Maybe<Scalars['String']>
    likes: Likes
    relatedArticles: Array<ArticleV2>
    seoDescription?: Maybe<Scalars['String']>
    seoTitle?: Maybe<Scalars['String']>
    source?: Maybe<Scalars['String']>
    /** List of subscription ids for which this article is considered a spotlight article. */
    spotlightSubscriptionIds: Array<Scalars['UUID']>
    summary: Scalars['String']
    /** List of all tags related to this article. */
    tags: Array<Tag>
    title: Scalars['String']
    userJourneys: UserJourneys
}

export type ArticleV2Detail = {
    __typename?: 'ArticleV2Detail'
    articleContentType?: Maybe<Scalars['String']>
    durationSeconds?: Maybe<Scalars['Int']>
    id: Scalars['String']
    imageUrl?: Maybe<Scalars['String']>
    summary?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type AspectRatioUrls = {
    __typename?: 'AspectRatioUrls'
    landscape?: Maybe<Scalars['Url']>
    portrait?: Maybe<Scalars['Url']>
}

export type AssessmentAdminMutation = {
    __typename?: 'AssessmentAdminMutation'
    skipAssessment: AssessmentAttemptResult
    submitResponse: AssessmentAttemptResult
}

export type AssessmentAdminMutationSkipAssessmentArgs = {
    input: SkipAssessmentInput
    userId: Scalars['UUID']
}

export type AssessmentAdminMutationSubmitResponseArgs = {
    input: SubmitResponseInput
    userId: Scalars['UUID']
}

export type AssessmentAdminQuery = {
    __typename?: 'AssessmentAdminQuery'
    getInProgressOrCreateNewAttempt: AssessmentAttemptResult
    getRecommendedJourneys: RecommendedJourneysResult
    tryGetLatestAttempt: AssessmentAttemptResult
}

export type AssessmentAdminQueryGetInProgressOrCreateNewAttemptArgs = {
    input: GetAssessmentByNameAndVariantInput
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type AssessmentAdminQueryGetRecommendedJourneysArgs = {
    input: GetRecommendedJourneysByAssessmentInput
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type AssessmentAdminQueryTryGetLatestAttemptArgs = {
    input: GetAssessmentByNameAndVariantInput
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type AssessmentAttemptError = {
    __typename?: 'AssessmentAttemptError'
    errorCode: ErrorCode
}

export type AssessmentAttemptResult =
    | AssessmentAttemptError
    | AssessmentAttemptSuccess

export type AssessmentAttemptSuccess = {
    __typename?: 'AssessmentAttemptSuccess'
    allQuestions: Array<AssessmentItem>
    assessmentAttemptId: Scalars['UUID']
    assessmentId: Scalars['UUID']
    createdAt: Scalars['Instant']
    givenResponses: Array<GivenResponse>
    locale: Scalars['Locale']
    name: Scalars['String']
    status: AssessmentStatus
    superlative?: Maybe<SuperlativeWithScore>
    type: AssessmentType
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
    variant: Scalars['String']
}

export type AssessmentError = {
    __typename?: 'AssessmentError'
    errorCode: ErrorCode
}

export type AssessmentItem = {
    __typename?: 'AssessmentItem'
    id: Scalars['UUID']
    journey?: Maybe<AssessmentJourney>
    pillar: AssessmentPillar
    questionText: Scalars['String']
    responses: Array<ResponseOptionCms>
}

export type AssessmentJourney = {
    __typename?: 'AssessmentJourney'
    coreType: JourneyCoreType
    uuid: Scalars['UUID']
}

export type AssessmentMutation = {
    __typename?: 'AssessmentMutation'
    admin: AssessmentAdminMutation
    getOrCreateBaselineAssessmentAttempt: AssessmentAttemptResult
    getOrCreateNewProgressAssessmentAttempt: AssessmentAttemptResult
    skipAssessment: AssessmentAttemptResult
    submitResponse: AssessmentAttemptResult
}

export type AssessmentMutationSkipAssessmentArgs = {
    input: SkipAssessmentInput
}

export type AssessmentMutationSubmitResponseArgs = {
    input: SubmitResponseInput
}

export enum AssessmentPillar {
    Emotional = 'EMOTIONAL',
    MeaningAndPurpose = 'MEANING_AND_PURPOSE',
    Mental = 'MENTAL',
    Physical = 'PHYSICAL',
    Social = 'SOCIAL',
    Unknown = 'UNKNOWN'
}

export type AssessmentQuery = {
    __typename?: 'AssessmentQuery'
    admin: AssessmentAdminQuery
    getAllAssessmentAttempts: AllAssessmentAttemptsResult
    getAssessment: AssessmentResult
    getInProgressOrCreateNewAttempt: AssessmentAttemptResult
    getRecommendedJourneys: RecommendedJourneysResult
    tryGetLatestAttempt: AssessmentAttemptResult
}

export type AssessmentQueryGetAllAssessmentAttemptsArgs = {
    input: GetAssessmentByNameAndVariantInput
}

export type AssessmentQueryGetAssessmentArgs = {
    input: GetAssessmentByNameAndVariantInput
}

export type AssessmentQueryGetInProgressOrCreateNewAttemptArgs = {
    input: GetAssessmentByNameAndVariantInput
}

export type AssessmentQueryGetRecommendedJourneysArgs = {
    input: GetRecommendedJourneysByAssessmentInput
}

export type AssessmentQueryTryGetLatestAttemptArgs = {
    input: GetAssessmentByNameAndVariantInput
}

export type AssessmentResult = AssessmentError | AssessmentSuccess

export type AssessmentStats = {
    average: Scalars['Float']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
}

export enum AssessmentStatus {
    Completed = 'completed',
    Expired = 'expired',
    InProgress = 'in_progress',
    Skipped = 'skipped'
}

export type AssessmentSuccess = {
    __typename?: 'AssessmentSuccess'
    allQuestions: Array<AssessmentItem>
    assessmentId: Scalars['UUID']
    locale: Scalars['Locale']
    name: Scalars['String']
    variant: Scalars['String']
}

export enum AssessmentType {
    BaselineAssessment = 'BASELINE_ASSESSMENT',
    ProgressAssessment = 'PROGRESS_ASSESSMENT',
    UncategorizedAssessment = 'UNCATEGORIZED_ASSESSMENT'
}

export type AssetQuery = {
    __typename?: 'AssetQuery'
    /** Get an In App Message for a users subscription */
    getInAppMessage: Array<InAppMessageDto>
    /** Hello World Query */
    helloWorldQuery: Scalars['String']
}

export type Audio = {
    __typename?: 'Audio'
    author: Scalars['String']
    createdAt: Scalars['Instant']
    /** @deprecated this field is deprecated, replace with durationInSeconds */
    duration: Scalars['Int']
    durationInSeconds: Scalars['Int']
    id: Scalars['UUID']
    order?: Maybe<Scalars['Int']>
    provider?: Maybe<AudioProvider>
    thumbnailUrl?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt: Scalars['Instant']
    url: Scalars['String']
}

export type AudioBookChapter = {
    __typename?: 'AudioBookChapter'
    number: Scalars['Int']
    /** Collection of audiobook chapter parts (audio file urls) */
    parts: Array<AudioBookChapterPart>
    /** Audio book chapter progress for user, based on userId from context. Checks progress on audio book chapter parts. */
    progress?: Maybe<LearnContentProgress>
    title?: Maybe<Scalars['String']>
}

export type AudioBookChapterPart = {
    __typename?: 'AudioBookChapterPart'
    /** Url to the audio file */
    audioUrl: Scalars['String']
    /** UUID of parent book */
    bookId?: Maybe<Scalars['UUID']>
    /** Number of parent chapter */
    chapterNumber?: Maybe<Scalars['Int']>
    contentStatus?: Maybe<LearnContentStatus>
    contentType: LearnContentStatusType
    /** Duration in seconds of the audio file */
    durationSeconds: Scalars['Int']
    id: Scalars['UUID']
    /** Audiobook chapter part number */
    number: Scalars['Int']
}

/** Progress input for Podcast Episode */
export type AudioBookChapterPartContentStatusInput = {
    /** audiobook chapter part id */
    contentId: Scalars['String']
    /** progress in seconds */
    progressInSeconds: Scalars['Int']
    progressType: LearnContentStatusProgressType
    /** total duration of in seconds */
    totalInSeconds: Scalars['Int']
}

export type AudioBookMutation = {
    __typename?: 'AudioBookMutation'
    /** Mutation creating or updating audio book chapter part status, based on userId from context */
    updateAudioBookChapterPartStatus: LearnContentStatus
}

export type AudioBookMutationUpdateAudioBookChapterPartStatusArgs = {
    input: AudioBookChapterPartContentStatusInput
}

export type AudioChapterPartParent = {
    __typename?: 'AudioChapterPartParent'
    bookID: Scalars['UUID']
    chapterNumber: Scalars['Int']
    chapterPartNumber: Scalars['Int']
}

export enum AudioProvider {
    Thrive = 'THRIVE',
    Umg = 'UMG'
}

export type AudioQuery = {
    __typename?: 'AudioQuery'
    thrive: ThriveAudioQuery
}

export type AudioResetDetail = {
    __typename?: 'AudioResetDetail'
    description?: Maybe<Scalars['String']>
    id: Scalars['UUID']
    name?: Maybe<Scalars['String']>
    url?: Maybe<Scalars['String']>
}

export type AudioResetGoalDetail = {
    __typename?: 'AudioResetGoalDetail'
    audioReset: ThriveResetAudio
    audioResetInfo: AudioResetDetail
}

export type AudioResetStatsDetail = {
    __typename?: 'AudioResetStatsDetail'
    audioResetDetail: AudioResetGoalDetail
    completed: Scalars['Boolean']
}

export type BalanceInfo = {
    __typename?: 'BalanceInfo'
    /** The balance amount */
    amount: Scalars['Int']
    /** The currency code */
    currencyCode: Scalars['String']
}

export type BannerQuery = {
    __typename?: 'BannerQuery'
    thrive: ThriveBannerQuery
}

export type BehavioralProfileError = {
    __typename?: 'BehavioralProfileError'
    errorCode: BehavioralProfileErrorCode
}

export enum BehavioralProfileErrorCode {
    NoAssessmentsCompleted = 'NO_ASSESSMENTS_COMPLETED',
    UnknownError = 'UNKNOWN_ERROR'
}

export type BehavioralProfileQuery = {
    __typename?: 'BehavioralProfileQuery'
    getBehavioralProfile: BehavioralProfileResult
}

export type BehavioralProfileQueryGetBehavioralProfileArgs = {
    input: GetBehavioralProfileInput
}

export type BehavioralProfileResult =
    | BehavioralProfileError
    | BehavioralProfileSuccess

export type BehavioralProfileSuccess = {
    __typename?: 'BehavioralProfileSuccess'
    baselineAssessmentCount: Scalars['Int']
    coreHealthBehaviors: Array<CoreHealthBehaviorScore>
    lastUpdatedAt: Scalars['Instant']
    progressAssessmentCount: Scalars['Int']
}

export type BenefitsConnectDetails = {
    __typename?: 'BenefitsConnectDetails'
    benefitGroupsForLocaleAndSubscription: Array<MiniBenny>
    benefitGroupsForLocaleAndSubscriptionAndCountryCode: Array<MiniBenny>
    benefitsConnectForQuestion?: Maybe<MiniBenny>
    companyUUID: Scalars['UUID']
    countryCodeUsed: Scalars['String']
    dciResponse?: Maybe<SurveyResponse>
    subscriptionUUID: Scalars['UUID']
    triggeredBenefitsConnect?: Maybe<MiniBenny>
    userCountryCode?: Maybe<Scalars['String']>
    userUUID: Scalars['UUID']
}

export type BigRedButtonRespose = {
    __typename?: 'BigRedButtonRespose'
    affectedCompanyIds: Array<Maybe<Scalars['UUID']>>
    wasDestructive: Scalars['Boolean']
}

export type BiometricsManualActivityDto = {
    __typename?: 'BiometricsManualActivityDTO'
    activityDescription: Scalars['String']
    isCoreStep: Scalars['Boolean']
    stepsPerMin: Scalars['Int']
    uuid: Scalars['String']
}

export type BlurbV2 = {
    __typename?: 'BlurbV2'
    description: Scalars['String']
}

export type Book = {
    __typename?: 'Book'
    /** Collection of audiobook chapters */
    audioChapters: Array<AudioBookChapter>
    /** Collection of authors */
    authors: Array<ArticleAuthor>
    id: Scalars['ID']
    imageUrl: Scalars['String']
    /** Url to the pdf resource file of the book */
    resourceUrl?: Maybe<Scalars['String']>
    subtitle: Scalars['String']
    title: Scalars['String']
    uuid: Scalars['UUID']
}

export type BookMutation = {
    __typename?: 'BookMutation'
    audio: AudioBookMutation
}

export type BookPage = {
    __typename?: 'BookPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<Book>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type BookQuery = {
    __typename?: 'BookQuery'
    /** Get book */
    getBook?: Maybe<Book>
    /** Get books */
    getBooks: BookPage
}

export type BookQueryGetBookArgs = {
    bookId: Scalars['UUID']
}

export type BookQueryGetBooksArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type BookmarkMutation = {
    __typename?: 'BookmarkMutation'
    create: Scalars['Boolean']
    delete: Scalars['Boolean']
}

export type BookmarkMutationCreateArgs = {
    id: Scalars['UUID']
}

export type BookmarkMutationDeleteArgs = {
    id: Scalars['UUID']
}

export type BookmarkQuery = {
    __typename?: 'BookmarkQuery'
    bookmarked: BookmarkedContentPage
    isBookmarked: Scalars['Boolean']
}

export type BookmarkQueryBookmarkedArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type BookmarkQueryIsBookmarkedArgs = {
    id: Scalars['UUID']
}

export type BookmarkedContent = ThriveReset | ThriveResetAudio

export type BookmarkedContentPage = {
    __typename?: 'BookmarkedContentPage'
    items: Array<BookmarkedContent>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    total: Scalars['Int']
}

export type BotUserId = {
    __typename?: 'BotUserId'
    slackUserId: Scalars['String']
}

export type Brand = {
    __typename?: 'Brand'
    companyId: Scalars['UUID']
    createdAt: Scalars['Instant']
    imageUrl?: Maybe<Scalars['String']>
    isPublic: Scalars['Boolean']
    name: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type BrandInput = {
    imageUrl?: InputMaybe<Scalars['String']>
    isPublic?: InputMaybe<Scalars['Boolean']>
    name: Scalars['String']
}

export type BrandInputGraphQlInput = {
    companyId: Scalars['UUID']
    imageUrl?: InputMaybe<Scalars['String']>
    isPublic: Scalars['Boolean']
    name: Scalars['String']
}

export type BrandPage = {
    __typename?: 'BrandPage'
    items: Array<Brand>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type BulkDailyCheckInRequestInput = {
    /** The Daily Check-In request date */
    requestDate: Scalars['ISODate']
    /** The channel requesting the Daily Check-In */
    requestingChannel: Channel
    /** The user options for the requested Daily Check-Ins */
    userConfigs: Array<UserConfigInput>
}

export enum CacheTypes {
    Challenges = 'CHALLENGES',
    Identity = 'IDENTITY',
    Socialgroups = 'SOCIALGROUPS'
}

export type CalendarQuery = {
    __typename?: 'CalendarQuery'
    getResetEventUrl: Scalars['Url']
    services: Array<CalendarServiceFields>
}

export type CalendarQueryGetResetEventUrlArgs = {
    service: CalendarService
}

export enum CalendarService {
    Google = 'Google',
    Office365 = 'Office365',
    Outlook = 'Outlook'
}

export type CalendarServiceFields = {
    iconUrl: Scalars['Url']
    name: Scalars['String']
    resetEventUrl: Scalars['Url']
    type: CalendarService
}

export type CallQueue = {
    __typename?: 'CallQueue'
    division?: Maybe<Division>
    id: Scalars['UUID']
    memberCount?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
}

export type CallToAction = {
    __typename?: 'CallToAction'
    args: Array<Scalars['String']>
    title: Scalars['String']
    type: CallToActionType
}

export enum CallToActionType {
    CreatePersonalizedReset = 'CreatePersonalizedReset',
    PlayThriveReset = 'PlayThriveReset',
    Redirect = 'Redirect',
    ScheduleResetPlayback = 'ScheduleResetPlayback'
}

export type CallsCommand = {
    companyId: Scalars['UUID']
    date: Scalars['Instant']
    platformType: PlatformType
    type: CallsCommandType
    userId: Scalars['String']
}

export enum CallsCommandType {
    TriggerReset = 'TRIGGER_RESET'
}

export type CallsMutation = {
    __typename?: 'CallsMutation'
    company: CompanyMutation
    trigger: TriggerMutation
}

export type CallsQuery = {
    __typename?: 'CallsQuery'
    agent: AgentQuery
    company: CompanyQuery
    connect: ConnectQuery
    inject: InjectQuery
    reporting: ReportingQuery
    trigger: TriggerQuery
}

export type CentralizedNotificationsMutation = {
    __typename?: 'CentralizedNotificationsMutation'
    /** Mutation root for nudge mutations */
    nudge: CnsNudgeMutation
    /** Mutation to update the nudge status for the user and type; SOFT-DEPRECATED: use nudge->selfNudgeStatus */
    nudgeStatus: UserNudgeStatusUpsertResponse
    /** Mutation root for nudge triggers for the current user */
    selfTrigger: CnsSelfTriggerMutation
    /** Mutation root for nudge triggers */
    trigger: CnsTriggerMutation
}

export type CentralizedNotificationsMutationNudgeStatusArgs = {
    input: NudgeStatusUpdateInput
}

export type CentralizedNotificationsQuery = {
    __typename?: 'CentralizedNotificationsQuery'
    /** Query root for nudge queries */
    nudge: CnsNudgeQuery
    /** Get the status (enabled/disabled) of all nudges for the current user.  SOFT-DEPRECATED: use nudge->selfNudgeStatus instead. */
    nudgeStatus: UserNudgeStatusFetchResponse
    /** Get any pending nudges for the current user.  SOFT-DEPRECATED: use nudge->selfNudgeStatus instead. */
    pendingNudges: UserPendingNudgesFetchResponse
}

export type CentralizedNotificationsQueryPendingNudgesArgs = {
    input?: InputMaybe<PendingNudgesInput>
}

export type ChallengeCustomization = {
    __typename?: 'ChallengeCustomization'
    challengeId: Scalars['UUID']
    stepsAmount?: Maybe<Scalars['Int']>
    userId: Scalars['UUID']
    waterAmount?: Maybe<Scalars['Int']>
}

export type ChallengeCustomizationOperationResult =
    | ChallengeCustomizationResult
    | ErrorMessageCustomization

export type ChallengeCustomizationResult = {
    __typename?: 'ChallengeCustomizationResult'
    challengeCustomization: ChallengeCustomization
}

export type ChallengeGoal = {
    __typename?: 'ChallengeGoal'
    /** The id of the entity related with the alternative goal */
    altGoalEntityId?: Maybe<Scalars['UUID']>
    /** The title of the alternative goal entity */
    altGoalEntityTitle?: Maybe<Scalars['String']>
    /** Alternative goal type */
    altGoalType?: Maybe<Scalars['String']>
    /** The id of the challenge */
    challengeId: Scalars['UUID']
    createdAt?: Maybe<Scalars['Instant']>
    /** The day of the goal */
    day: Scalars['Int']
    /** The id of the challenge goal entity */
    goalEntityId?: Maybe<Scalars['UUID']>
    /** The title of the goal entity */
    goalEntityTitle?: Maybe<Scalars['String']>
    /** Number of steps for GoalType.STEPS */
    goalSteps?: Maybe<Scalars['Int']>
    /** The goal type */
    goalType?: Maybe<Scalars['String']>
    /** Number of water intake for GoalType.WATER */
    goalWater?: Maybe<Scalars['Int']>
    /** The id of this challenge goal */
    id: Scalars['UUID']
    /** Ordering of the goal */
    position?: Maybe<Scalars['Int']>
    updatedAt?: Maybe<Scalars['Instant']>
    /** The url in case of EXTERNAL_LINK goal type */
    url?: Maybe<Scalars['String']>
}

export type ChallengeGoalCreateInput = {
    /** The id of the entity related with the alternative goal */
    altGoalEntityId?: InputMaybe<Scalars['UUID']>
    /** Alternative goal type */
    altGoalType?: InputMaybe<Scalars['String']>
    /** The id of the challenge */
    challengeId: Scalars['UUID']
    /** Day this goal belongs during the challenge */
    day: Scalars['Int']
    /** The id of the entity related with the goal */
    goalEntityId?: InputMaybe<Scalars['UUID']>
    /** Number of steps for GoalType.STEPS */
    goalSteps?: InputMaybe<Scalars['Int']>
    /** Goal type */
    goalType: Scalars['String']
    /** Number of water intake for GoalType.WATER */
    goalWater?: InputMaybe<Scalars['Int']>
    /** Ordering during the day */
    position?: InputMaybe<Scalars['Int']>
    /** The url in case of EXTERNAL_LINK goal type */
    url?: InputMaybe<Scalars['String']>
}

export type ChallengeRecommendationAck = {
    __typename?: 'ChallengeRecommendationAck'
    /** The challenge id acknowledge */
    challengeId: Scalars['UUID']
    /** The createdAt of the acknowledge */
    createdAt: Scalars['Instant']
    /** The microstepId id acknowledge */
    microstepId: Scalars['UUID']
}

export type ChallengeStats = {
    __typename?: 'ChallengeStats'
    activityStats: ActivityStats
    articleStats: ArticleStats
    feelingQuestionStats: FeelingQuestionStats
    microstepStats: MicrostepStats
    resetStats: ResetStats
    waterStats: WaterStats
}

export type ChallengeSubscriptionsInfo = {
    __typename?: 'ChallengeSubscriptionsInfo'
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    companyName: Scalars['String']
    enabled: Scalars['Boolean']
    id: Scalars['UUID']
    subscriptionId?: Maybe<Scalars['UUID']>
}

export type ChallengeTeamDailyGoal = {
    __typename?: 'ChallengeTeamDailyGoal'
    /** The external entity detail */
    userDailyGoals: Array<ChallengeUserDailyGoal>
    /** The user's display name */
    userDisplayName: Scalars['String']
}

export type ChallengeTemplate = {
    __typename?: 'ChallengeTemplate'
    /** Indicates if this template allows customers to override step goals */
    allowCustomSteps: Scalars['Boolean']
    /** The background image */
    backgroundImage?: Maybe<Scalars['String']>
    /** The biotype of the challenge */
    biotype?: Maybe<TemplateBiotype>
    /** Category */
    category?: Maybe<UnifiedJourney>
    /** Description of the template */
    description?: Maybe<Scalars['String']>
    /** Identifies if challenge supports wearables functionality  */
    deviceEnabled: Scalars['Boolean']
    /** Duration of the challenge */
    duration: Scalars['Int']
    /** Goal of the challenge */
    goal?: Maybe<Scalars['String']>
    /** Header image */
    header?: Maybe<Scalars['String']>
    /** The id of this template */
    id: Scalars['UUID']
    /** Indicates is this a featured template */
    isFeatured: Scalars['Boolean']
    /** List of Journeys associated to this template */
    journeys?: Maybe<Array<TemplateJourney>>
    /** Name of the template */
    name: Scalars['String']
    /** Custom  order position set for the challenge */
    position: Scalars['Int']
    /** The description for promo 1 field */
    promoDescription1?: Maybe<Scalars['String']>
    /** The description for promo 2 field */
    promoDescription2?: Maybe<Scalars['String']>
    /** The description for promo 3 field */
    promoDescription3?: Maybe<Scalars['String']>
    /** The header for promo 1 field */
    promoHeader1?: Maybe<Scalars['String']>
    /** The header for promo 2 field */
    promoHeader2?: Maybe<Scalars['String']>
    /** The header for promo 3 field */
    promoHeader3?: Maybe<Scalars['String']>
    /** The image for promo 1 field */
    promoImage1?: Maybe<Scalars['String']>
    /** The image for promo 2 field */
    promoImage2?: Maybe<Scalars['String']>
    /** The image for promo 3 field */
    promoImage3?: Maybe<Scalars['String']>
    /** List of Quotes associated to this template */
    quotes?: Maybe<Array<TemplateQuote>>
    /** Indicates the scope of this template: COMPANY, GROUP... */
    templateScope: Scalars['String']
    /** The company challenge theme */
    theme?: Maybe<Scalars['String']>
    /** Thumbnail */
    thumbnail?: Maybe<Scalars['String']>
    /** The image for the community challenge trophy plant */
    trophyPlantImage?: Maybe<Scalars['String']>
}

export type ChallengeTemplateCreateInput = {
    /** The id of the challenge template */
    challengeTemplateId?: InputMaybe<Scalars['UUID']>
    /** The company id to which the challenge is assigned */
    companyId?: InputMaybe<Scalars['UUID']>
    /** Enabled flag for the challenge */
    enabled?: InputMaybe<Scalars['Boolean']>
    /** Indicates if the challenge is featured */
    isFeatured?: InputMaybe<Scalars['Boolean']>
    /** Indicates if team members are unlimited */
    isUnlimited?: InputMaybe<Scalars['Boolean']>
    /** Indicates the maximum number of members allowed in teams */
    maxTeamSize?: InputMaybe<Scalars['Int']>
    /** Optional prefix to be displayed before the challenge's name */
    namePrefix?: InputMaybe<Scalars['String']>
    /** The signup date of the challenge */
    signupDate?: InputMaybe<Scalars['ISODate']>
    /** The start date of the challenge */
    startDate?: InputMaybe<Scalars['ISODate']>
    /** The subscription id to which the challenge is assigned */
    subscriptionId?: InputMaybe<Scalars['UUID']>
    /** Trophy plant image url */
    trophyPlantImgUrl?: InputMaybe<Scalars['String']>
}

export type ChallengeTemplateGoal = {
    __typename?: 'ChallengeTemplateGoal'
    /** The id of the entity related with the alternative goal */
    altGoalEntityId?: Maybe<Scalars['UUID']>
    /** The title of the alternative goal entity */
    altGoalEntityTitle?: Maybe<Scalars['String']>
    /** Alternative goal type */
    altGoalType?: Maybe<Scalars['String']>
    /** The id of the challenge template */
    challengeTemplateId: Scalars['UUID']
    /** The day of the goal */
    day: Scalars['Int']
    /** The id of the challenge goal entity */
    goalEntityId?: Maybe<Scalars['UUID']>
    /** The title of the challenge goal entity */
    goalEntityTitle?: Maybe<Scalars['String']>
    /** Number of steps for GoalType.STEPS */
    goalSteps?: Maybe<Scalars['Int']>
    /** The goal type */
    goalType?: Maybe<Scalars['String']>
    /** Number of water intake for GoalType.WATER */
    goalWater?: Maybe<Scalars['Int']>
    /** Ordering of the goal */
    position?: Maybe<Scalars['Int']>
    /** The url in case of EXTERNAL_LINK goal type */
    url?: Maybe<Scalars['String']>
}

export type ChallengeTemplateInstance = {
    __typename?: 'ChallengeTemplateInstance'
    /** The challenge company relation */
    challengeCompanyRelation: UnifiedChallengeCompany
    /** The challenge goals */
    challengeGoals: Array<ChallengeGoal>
    /** The challenge */
    challengeInstance: UnifiedChallenge
    /** The challenge template */
    challengeTemplate?: Maybe<ChallengeTemplate>
}

export enum ChallengeTimePoint {
    End = 'END',
    Start = 'START'
}

export type ChallengeUserDailyGoal = {
    __typename?: 'ChallengeUserDailyGoal'
    /** The challenge goal info */
    challengeGoal: ChallengeGoal
    /** True if the user has completed this daily goal */
    completed: Scalars['Boolean']
    /** The external entity detail */
    goalEntityDetail: GoalEntityDetail
}

export enum Channel {
    MobileAndroid = 'MOBILE_ANDROID',
    MobileIos = 'MOBILE_IOS',
    MobileNotification = 'MOBILE_NOTIFICATION',
    MsTeams = 'MS_TEAMS',
    Slack = 'SLACK',
    Web = 'WEB',
    Webex = 'WEBEX'
}

export type CheckInResponseDetailsDto = {
    __typename?: 'CheckInResponseDetailsDTO'
    pulseUserId: Scalars['UUID']
    /** @deprecated Use selectedOption and responseChannel in root of object */
    response: DailyCheckInResponseDto
    responseChannel: Scalars['String']
    responseDate: Scalars['ISODate']
    selectedOption: OptionsDto
    survey: PulseSurveyQuestionDto
    thriveId?: Maybe<Scalars['UUID']>
}

export type CheckInResponseDetailsPage = {
    __typename?: 'CheckInResponseDetailsPage'
    content: Array<CheckInResponseDetailsDto>
    page: Scalars['Long']
    totalElements: Scalars['Long']
    totalPages: Scalars['Long']
}

export type CheckinCount = {
    __typename?: 'CheckinCount'
    count: Scalars['Int']
    date: Scalars['Instant']
}

export enum CmsContentType {
    CmsArticle = 'CMS_ARTICLE',
    CmsBook = 'CMS_BOOK',
    CmsChallenge = 'CMS_CHALLENGE',
    CmsCompanySubscription = 'CMS_COMPANY_SUBSCRIPTION',
    CmsCourse = 'CMS_COURSE',
    CmsCourseLesson = 'CMS_COURSE_LESSON',
    CmsCourseModule = 'CMS_COURSE_MODULE',
    CmsJourney = 'CMS_JOURNEY',
    CmsMicrostep = 'CMS_MICROSTEP',
    CmsPodcast = 'CMS_PODCAST',
    CmsPodcastEpisode = 'CMS_PODCAST_EPISODE',
    CmsPodcastSeason = 'CMS_PODCAST_SEASON',
    CmsReset = 'CMS_RESET',
    CmsResetAudio = 'CMS_RESET_AUDIO',
    CmsResetStockAudio = 'CMS_RESET_STOCK_AUDIO'
}

export type CnsAsyncTriggerEventLaunchFailed = {
    __typename?: 'CnsAsyncTriggerEventLaunchFailed'
    error: Scalars['String']
}

export type CnsAsyncTriggerEventLaunched = {
    __typename?: 'CnsAsyncTriggerEventLaunched'
    launched: Scalars['Boolean']
}

export type CnsAsyncTriggerEventLaunchedResponse =
    | CnsAsyncTriggerEventLaunchFailed
    | CnsAsyncTriggerEventLaunched

export type CnsBatchTriggerEventFailure = {
    __typename?: 'CnsBatchTriggerEventFailure'
    error: Scalars['String']
}

export type CnsBatchTriggerEventResponse =
    | CnsBatchTriggerEventFailure
    | CnsBatchTriggerEventSuccess

export type CnsBatchTriggerEventSuccess = {
    __typename?: 'CnsBatchTriggerEventSuccess'
    successes: Array<UserNudgesSentResult>
}

export type CnsLargeBatchTriggerEventAttempted = {
    __typename?: 'CnsLargeBatchTriggerEventAttempted'
    failuresCount: Scalars['Int']
    successfulUserIdsCount: Scalars['Int']
}

export type CnsLargeBatchTriggerEventResponse =
    | CnsLargeBatchTriggerEventAttempted
    | CnsLargeBatchTriggerEventTotalFailure

export type CnsLargeBatchTriggerEventTotalFailure = {
    __typename?: 'CnsLargeBatchTriggerEventTotalFailure'
    error: Scalars['String']
}

export type CnsNudgeMutation = {
    __typename?: 'CnsNudgeMutation'
    /** Mutation to update the nudge sentAt timestamp for matching nudges (identified by the user IDs, nudge type, send date, and send time).  If nudges do not exist for any users, they won't be returned in the response. */
    nudgeSentAtForUsers: UpdateNudgeSentAtResponse
    /** Mutation to update the nudge status for the user and type */
    nudgeStatusForUser: UserNudgeStatusUpsertResponse
    /** Mutation to update the nudge sentAt timestamp for the user's nudge (identified by the nudge type, send date, and send time).  If no nudge matches, a successful response with no nudges will be returned. */
    selfNudgeSentAt: UpdateNudgeSentAtResponse
    /** Mutation to update the nudge status for the user and type */
    selfNudgeStatus: UserNudgeStatusUpsertResponse
}

export type CnsNudgeMutationNudgeSentAtForUsersArgs = {
    input: NudgeSentAtUpdateInput
    userIds: Array<Scalars['UUID']>
}

export type CnsNudgeMutationNudgeStatusForUserArgs = {
    input: NudgeStatusUpdateInput
    userId: Scalars['UUID']
}

export type CnsNudgeMutationSelfNudgeSentAtArgs = {
    input: NudgeSentAtUpdateInput
}

export type CnsNudgeMutationSelfNudgeStatusArgs = {
    input: NudgeStatusUpdateInput
}

export type CnsNudgeQuery = {
    __typename?: 'CnsNudgeQuery'
    /** Get the status (enabled/disabled) of all nudges for the given user. */
    nudgeStatusForUser: UserNudgeStatusFetchResponse
    /** Get any pending nudges for the given user. */
    pendingNudgesForUser: UserPendingNudgesFetchResponse
    /** Get the status (enabled/disabled) of all nudges for the current user. */
    selfNudgeStatus: UserNudgeStatusFetchResponse
    /** Get any pending nudges for the current user. */
    selfPendingNudges: UserPendingNudgesFetchResponse
}

export type CnsNudgeQueryNudgeStatusForUserArgs = {
    userId: Scalars['UUID']
}

export type CnsNudgeQueryPendingNudgesForUserArgs = {
    input?: InputMaybe<PendingNudgesInput>
    userId: Scalars['UUID']
}

export type CnsNudgeQuerySelfPendingNudgesArgs = {
    input?: InputMaybe<PendingNudgesInput>
}

export type CnsSelfTriggerMutation = {
    __typename?: 'CnsSelfTriggerMutation'
    /** Triggers an immediate test nudge event for the user from the auth token (does not validate Thrive User ID) */
    selfTestEvent: CnsTriggerEventResponse
}

export type CnsSelfTriggerMutationSelfTestEventArgs = {
    input?: InputMaybe<TriggerSelfTestEventInput>
}

export type CnsTriggerEventFailure = {
    __typename?: 'CnsTriggerEventFailure'
    error: Scalars['String']
}

export type CnsTriggerEventResponse =
    | CnsTriggerEventFailure
    | CnsTriggerEventSuccess

export type CnsTriggerEventSuccess = {
    __typename?: 'CnsTriggerEventSuccess'
    result: UserNudgesSentResult
}

export type CnsTriggerMutation = {
    __typename?: 'CnsTriggerMutation'
    /** Triggers an immediate in-challenge goal reminder nudge event for the given users (does not validate Thrive User IDs).  Note that the event is dispatched immediately, but it is marked with the sendTime/sendDate provided, and the expiration is based on sendDate (and the timezone).  In the future, the dispatch will be based on the input sendTime/sendDate.  If a nudge has already been sent for a user at the given sendTime/sendDate, this will not dispatch a new nudge.  Only newly dispatched nudges will be returned by this mutation. */
    inChallengeGoalReminders: CnsBatchTriggerEventResponse
    /** Triggers an immediate test nudge event for the given user (does not validate Thrive User ID) */
    testEvent: CnsTriggerEventResponse
    /** Triggers an immediate test nudge event for the given users (does not validate Thrive User IDs) */
    testEvents: CnsBatchTriggerEventResponse
    /** Triggers an immediate web nudge announcement for all users at the given company.  Note that the event is dispatched immediately, and is marked with a sendTime/sendDate based on the current time at UTC.  This should be fine as this is a one-time nudge, web-only, and has a 90-day expiration.  Due to the number of users at companies, this only returns counts of successes/failures. */
    webNudgeAnnouncementsForCompany: CnsLargeBatchTriggerEventResponse
    /** Triggers an immediate web nudge announcement for all users at the given company.  Note that the event is dispatched immediately, and is marked with a sendTime/sendDate based on the current time at UTC.  This should be fine as this is a one-time nudge, web-only, and has a 90-day expiration.  This kicks off the launch asynchronously and only returns whether the kickoff worked (not the results, which may take a while). */
    webNudgeAnnouncementsForCompanyAsync: CnsAsyncTriggerEventLaunchedResponse
}

export type CnsTriggerMutationInChallengeGoalRemindersArgs = {
    input: TriggerInChallengeGoalRemindersInput
}

export type CnsTriggerMutationTestEventArgs = {
    input: TriggerTestEventInput
}

export type CnsTriggerMutationTestEventsArgs = {
    input: TriggerTestEventsInput
}

export type CnsTriggerMutationWebNudgeAnnouncementsForCompanyArgs = {
    input: TriggerWebNudgeAnnouncementsForCompanyInput
}

export type CnsTriggerMutationWebNudgeAnnouncementsForCompanyAsyncArgs = {
    input: TriggerWebNudgeAnnouncementsForCompanyInput
}

export type CoachGqlModel = {
    __typename?: 'CoachGqlModel'
    activeCoacheeCount: Scalars['Int']
    bio: Scalars['String']
    certifications: Array<Scalars['String']>
    city?: Maybe<Scalars['String']>
    coachingLanguages: Array<Scalars['String']>
    coachingTimezones: Array<Scalars['String']>
    country?: Maybe<Scalars['String']>
    created: Scalars['Instant']
    defaultLanguage: Scalars['String']
    email: Scalars['String']
    favouriteMicrostepDescription?: Maybe<Scalars['String']>
    favouriteMicrostepId?: Maybe<Scalars['UUID']>
    firstName: Scalars['String']
    imageUrl: Scalars['String']
    isActive: Scalars['Boolean']
    lastModified: Scalars['Instant']
    lastName: Scalars['String']
    referralSource?: Maybe<Scalars['String']>
    specialities: Array<Scalars['String']>
    stateOrRegion?: Maybe<Scalars['String']>
    thriveUserId: Scalars['UUID']
    timezone: Scalars['String']
    whatHelpsMeThrive?: Maybe<Scalars['String']>
}

export type CoachSummaryGqlModel = {
    __typename?: 'CoachSummaryGqlModel'
    bio: Scalars['String']
    coachingLanguages: Array<Scalars['String']>
    created: Scalars['Instant']
    firstName: Scalars['String']
    imageUrl: Scalars['String']
    isActive: Scalars['Boolean']
    lastModified: Scalars['Instant']
    lastName: Scalars['String']
    specialities: Array<Scalars['String']>
    thriveUserId: Scalars['UUID']
    timezone: Scalars['String']
}

export type CoachesQuery = {
    __typename?: 'CoachesQuery'
    /** Get all coaches */
    getAll: GetCoachesGqlResponse
    /** Get one coach by id */
    getById: GetCoachGqlResponse
    /** Get recommended coaches for a user */
    getRecommendedForUser: GetRecommendedCoachesGqlResponse
}

export type CoachesQueryGetAllArgs = {
    page?: InputMaybe<Scalars['Long']>
    pageSize?: InputMaybe<Scalars['Long']>
}

export type CoachesQueryGetByIdArgs = {
    thriveUserId: Scalars['UUID']
}

export type CoachingMutation = {
    __typename?: 'CoachingMutation'
    /** Dummy mutation */
    helloWorldMutation: Scalars['String']
}

export type CoachingQuery = {
    __typename?: 'CoachingQuery'
    /** Coach related queries */
    coaches: CoachesQuery
}

export type CommunityChallengeCompletedDay = {
    __typename?: 'CommunityChallengeCompletedDay'
    /** The challenge id */
    challengeId: Scalars['UUID']
    createdAt?: Maybe<Scalars['Instant']>
    /** The completed day */
    day: Scalars['Int']
    updatedAt?: Maybe<Scalars['Instant']>
    /** The user's id */
    userId: Scalars['UUID']
}

export type CommunityChallengeThreshold = {
    __typename?: 'CommunityChallengeThreshold'
    achieved: Scalars['Int']
    threshold: Scalars['Int']
    total: Scalars['Int']
}

export type CommunityGardenInfo = {
    __typename?: 'CommunityGardenInfo'
    /** Challenge day date */
    date: Scalars['Instant']
    /** Total number of plants for that day */
    plantsNumber: Scalars['Int']
    /** The 30*15 plants template to represent the garden */
    plantsTemplate: Array<PlantInfo>
}

export type CompaniesQuery = {
    __typename?: 'CompaniesQuery'
    /** Get company details from Pulse */
    info?: Maybe<CompanyInfoDto>
}

export type Company = {
    __typename?: 'Company'
    brands: Array<Brand>
    configuration: Scalars['String']
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    imageUrl?: Maybe<Scalars['String']>
    isPublic: Scalars['Boolean']
    name: Scalars['String']
    seatsAllowed: Scalars['Int']
    /** Social Groups for this company */
    socialGroups: Array<SocialGroup>
    status: CompanyStatus
    updatedAt: Scalars['Instant']
}

export type CompanyConfiguration = {
    __typename?: 'CompanyConfiguration'
    agentTriggerLimit?: Maybe<Scalars['Int']>
    companyId?: Maybe<Scalars['UUID']>
    companyName?: Maybe<Scalars['String']>
    createdAt: Scalars['Instant']
    digitalResetRoutingId?: Maybe<Scalars['String']>
    isActive: Scalars['Boolean']
    isListening: Scalars['Boolean']
    isSmart: Scalars['Boolean']
    oauthClientId: Scalars['String']
    /** @deprecated This field has been renamed to platformOrgId, replace with platformOrgId */
    organizationId?: Maybe<Scalars['String']>
    platform: PlatformType
    platformOrgId?: Maybe<Scalars['String']>
    queryAllQueues: Scalars['Boolean']
    region?: Maybe<Scalars['String']>
    resetBufferMinutes?: Maybe<Scalars['Int']>
    resetPriority?: Maybe<Scalars['Int']>
    resetRoutingId?: Maybe<Scalars['String']>
    resetScriptId?: Maybe<Scalars['String']>
    /** @deprecated This field has been renamed to companyId, replace with companyId */
    thriveCompanyId?: Maybe<Scalars['UUID']>
    triggerConfiguration: Array<TriggerConfiguration>
    updatedAt: Scalars['Instant']
    usesPilotPage: Scalars['Boolean']
    usesSSO: Scalars['Boolean']
    webResourceName?: Maybe<Scalars['String']>
    wrapUpCodeId?: Maybe<Scalars['String']>
}

export type CompanyConfigurationCreateInput = {
    acChromeSigningKey?: InputMaybe<Scalars['String']>
    agentTriggerLimit?: InputMaybe<Scalars['Int']>
    companyId: Scalars['UUID']
    digitalResetRoutingId?: InputMaybe<Scalars['String']>
    oauthClientId: Scalars['String']
    oauthClientSecret: Scalars['String']
    platform: PlatformType
    platformOrgId?: InputMaybe<Scalars['String']>
    region?: InputMaybe<Scalars['String']>
    resetBufferMinutes?: InputMaybe<Scalars['Int']>
    resetPriority?: InputMaybe<Scalars['Int']>
    resetRoutingId?: InputMaybe<Scalars['String']>
    resetScriptId?: InputMaybe<Scalars['String']>
    usesPilotPage?: InputMaybe<Scalars['Boolean']>
    usesSSO?: InputMaybe<Scalars['Boolean']>
    webResourceName?: InputMaybe<Scalars['String']>
    webhookSecret?: InputMaybe<Scalars['String']>
    wrapUpCodeId?: InputMaybe<Scalars['String']>
}

export type CompanyId = {
    __typename?: 'CompanyId'
    id: Scalars['UUID']
}

export type CompanyInfoDto = {
    __typename?: 'CompanyInfoDTO'
    age?: Maybe<Scalars['Int']>
    createdAt?: Maybe<Scalars['Instant']>
    id: Scalars['UUID']
    matured?: Maybe<Scalars['Boolean']>
    totalEmployees: Scalars['Int']
}

export type CompanyInputGraphQlInput = {
    brands?: InputMaybe<Array<BrandInput>>
    configuration?: InputMaybe<Scalars['String']>
    imageUrl?: InputMaybe<Scalars['String']>
    isPublic?: InputMaybe<Scalars['Boolean']>
    name: Scalars['String']
    seatsAllowed?: InputMaybe<Scalars['Int']>
    status?: InputMaybe<CompanyStatus>
}

export type CompanyMutation = {
    __typename?: 'CompanyMutation'
    /** Disables all relevant companies and attempts to stop further resets from occurring */
    bigRedButton: BigRedButtonRespose
    /** Adds company configuration details for an organization */
    create: CompanyConfiguration
    /** Deletes the configuration for a company */
    delete: Scalars['String']
    /** Modifies the configuration for an existing company */
    update: CompanyConfiguration
}

export type CompanyMutationBigRedButtonArgs = {
    companyIds?: InputMaybe<Array<Scalars['UUID']>>
    destructive: Scalars['Boolean']
    onlyActive?: InputMaybe<Scalars['Boolean']>
    platform: PlatformType
}

export type CompanyMutationCreateArgs = {
    companyConfiguration: CompanyConfigurationCreateInput
}

export type CompanyMutationDeleteArgs = {
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type CompanyMutationUpdateArgs = {
    agentTriggerLimit?: InputMaybe<Scalars['Int']>
    companyId: Scalars['UUID']
    digitalResetRoutingId?: InputMaybe<Scalars['String']>
    isActive?: InputMaybe<Scalars['Boolean']>
    platform: PlatformType
    platformOrgId?: InputMaybe<Scalars['String']>
    region?: InputMaybe<Scalars['String']>
    resetBufferMinutes?: InputMaybe<Scalars['Int']>
    resetPriority?: InputMaybe<Scalars['Int']>
    resetRoutingId?: InputMaybe<Scalars['String']>
    resetScriptId?: InputMaybe<Scalars['String']>
    usesPilotPage?: InputMaybe<Scalars['Boolean']>
    usesSSO?: InputMaybe<Scalars['Boolean']>
    webResourceName?: InputMaybe<Scalars['String']>
    webhookSecret?: InputMaybe<Scalars['String']>
    wrapUpCodeId?: InputMaybe<Scalars['String']>
}

export type CompanyPage = {
    __typename?: 'CompanyPage'
    items: Array<Company>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type CompanyQuery = {
    __typename?: 'CompanyQuery'
    /** Retrieves calls company configuration with filters. */
    getAllCompanyConfiguration: Array<CompanyConfiguration>
    /** Retrieves calls company configuration by Thrive company id. */
    getCompanyConfigurationByThriveCompanyId?: Maybe<CompanyConfiguration>
    /** Returns company information for an agent */
    getCompanyConfigurationForAgent?: Maybe<CompanyConfiguration>
    /** Retrieves the company configuration for the current user. */
    getCompanyConfigurationForUser: Array<CompanyConfiguration>
}

export type CompanyQueryGetAllCompanyConfigurationArgs = {
    companyId?: InputMaybe<Scalars['UUID']>
    isActive?: InputMaybe<Scalars['Boolean']>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    platformType?: InputMaybe<PlatformType>
}

export type CompanyQueryGetCompanyConfigurationByThriveCompanyIdArgs = {
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type CompanyQueryGetCompanyConfigurationForAgentArgs = {
    agentId: Scalars['String']
    routingId: Scalars['String']
    scriptId: Scalars['String']
}

export type CompanyQueryGetCompanyConfigurationForUserArgs = {
    isActive?: InputMaybe<Scalars['Boolean']>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    platformType?: InputMaybe<PlatformType>
}

export type CompanyResourceGroup = {
    __typename?: 'CompanyResourceGroup'
    alreadySeenToday: Scalars['Boolean']
    companyResources: Array<CompanyResourceV3>
    contentStatus?: Maybe<LearnContentStatus>
    createdAt: Scalars['Instant']
    groupType: Array<CompanyResourceGroupType>
    id: Scalars['ID']
    locale: Scalars['Locale']
    pulseDimensionTypes: Array<Scalars['String']>
    pulseSurveyIds: Array<Scalars['String']>
    subtitle: Scalars['String']
    title: Scalars['String']
    updatedAt: Scalars['Instant']
    /** Ignore this field, it's only been added so that it can be used for the resolver */
    userCountryCode: Scalars['String']
    uuid: Scalars['String']
}

export type CompanyResourceGroupPage = {
    __typename?: 'CompanyResourceGroupPage'
    items: Array<CompanyResourceGroup>
    page: Scalars['Int']
    pageSize: Scalars['Int']
}

export enum CompanyResourceGroupType {
    BenefitsConnect = 'BENEFITS_CONNECT',
    Section = 'SECTION'
}

export type CompanyResourceImage = {
    __typename?: 'CompanyResourceImage'
    alternativeText?: Maybe<Scalars['String']>
    caption?: Maybe<Scalars['String']>
    height?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
    url: Scalars['String']
    width?: Maybe<Scalars['Int']>
}

export type CompanyResourceRevealedInfo = {
    __typename?: 'CompanyResourceRevealedInfo'
    content?: Maybe<Scalars['String']>
    type?: Maybe<CompanyResourceRevealedInfoType>
}

export enum CompanyResourceRevealedInfoType {
    Call = 'CALL',
    Email = 'EMAIL'
}

export enum CompanyResourceType {
    Call = 'Call',
    Email = 'Email',
    Link = 'Link',
    Pdf = 'Pdf'
}

export type CompanyResourceV3 = {
    __typename?: 'CompanyResourceV3'
    alertLevelResource: Scalars['Boolean']
    appearOnSectionsOnly?: Maybe<Scalars['Boolean']>
    companyResourceTypeName: Scalars['String']
    id: Scalars['ID']
    image?: Maybe<CompanyResourceImage>
    link?: Maybe<Scalars['String']>
    publishedAt?: Maybe<Scalars['Instant']>
    revealedInfo?: Maybe<Array<CompanyResourceRevealedInfo>>
    subtitle: Scalars['String']
    title: Scalars['String']
    type: CompanyResourceType
}

export enum CompanyStatus {
    Active = 'active',
    Demo = 'demo',
    Inactive = 'inactive',
    Prospect = 'prospect',
    Test = 'test'
}

export type ConnectQuery = {
    __typename?: 'ConnectQuery'
    /** Mutation to post events from the Desktop app */
    desktop: Scalars['Boolean']
    poll: PollResponse
    /** Poll for events for a given user and company. It does not check jwt token. This is a temporary solution until Identity implements Webex integration. */
    pollWithUserIdAndCompanyId: PollResponse
    /**
     * Generates a dummy TriggerReset command to allow testing from the FE
     * @deprecated This is a temporary solution until we have MVP working end to end
     */
    sendTriggerResetCommand: Scalars['Boolean']
}

export type ConnectQueryDesktopArgs = {
    event: DesktopWebhookEventInput
}

export type ConnectQueryPollArgs = {
    platformType: PlatformType
    sessionId?: InputMaybe<Scalars['String']>
}

export type ConnectQueryPollWithUserIdAndCompanyIdArgs = {
    companyId: Scalars['UUID']
    platformType: PlatformType
    sessionId?: InputMaybe<Scalars['String']>
    userId: Scalars['String']
}

export type ConnectQuerySendTriggerResetCommandArgs = {
    companyId: Scalars['UUID']
    date?: InputMaybe<Scalars['Instant']>
    platformType: PlatformType
    userId: Scalars['String']
}

export enum ContentDifficulty {
    Difficult = 'DIFFICULT',
    Easy = 'EASY',
    Moderate = 'MODERATE'
}

export type ContentExperienceQuery = {
    __typename?: 'ContentExperienceQuery'
    /** Get acute care banner for the user's locale */
    getAcuteCareBanner?: Maybe<AcuteCareBanner>
    /** Benefits Connect (Acute Care) Resource */
    getAdminBenefitsConnectGroup?: Maybe<CompanyResourceGroup>
    /** Benefits Connect (Acute Care) Resource */
    getBenefitsConnectGroup?: Maybe<CompanyResourceGroup>
    /** Get journey progress for the user selected journey */
    getJourneyProgress?: Maybe<JourneyProgress>
}

export type ContentExperienceQueryGetAdminBenefitsConnectGroupArgs = {
    locale: Scalars['String']
    pulseDimensionType: Scalars['String']
    pulseSurveyId: Scalars['String']
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type ContentExperienceQueryGetBenefitsConnectGroupArgs = {
    pulseDimensionType: Scalars['String']
    pulseSurveyId: Scalars['String']
}

export type ContentExperienceQueryGetJourneyProgressArgs = {
    journeyUUID: Scalars['UUID']
}

export enum ContentFeedback {
    Negative = 'Negative',
    Positive = 'Positive'
}

export type ContentFilterInput = {
    /** Skip any validation checks and always return benefits connect content, when available. By default, false */
    alwaysReturnBenefitsConnect?: InputMaybe<Scalars['Boolean']>
    /** Only include content associated with these dimensions (values match pulse dimension type field in the CMS). By default, all are included. If this list is empty (not null), then only content with no pulse dimension will be included. If the pulse dimension relationship does not apply to a given content type, then this filter will be ignored. */
    dimensions?: InputMaybe<Array<Scalars['String']>>
    /** List of UUIDs for content to exclude. By default, none are excluded. Both includedContent and excludedContent cannot be used together. */
    excludedContent?: InputMaybe<Array<Scalars['String']>>
    /** List of UUIDs for content to include. By default, all are included. Both includedContent and excludedContent cannot be used together. */
    includedContent?: InputMaybe<Array<Scalars['String']>>
    /** Only include content associated with these journeys. By default, all are included. If this list is empty (not null), then only content with no journey will be included. If the journeys relationship does not apply to a given content type, then this filter will be ignored. */
    journeys?: InputMaybe<Array<Scalars['String']>>
    /** Only include articles associated with the journey from a user's most recent DCI or, if journey from user's most recent DCI is not available, use the user's currently selected journey. By default, this is true. When this is set to false, articles will not be limited to current journey. Keep in mind, other filters may influence included articles as well. */
    limitArticlesToCurrentJourney?: InputMaybe<Scalars['Boolean']>
    /** Only include course modules associated with the journey from a user's most recent DCI or, if journey from user's most recent DCI is not available, use the user's currently selected journey. By default, this is false. When this is set to false, course modules will not be limited to current journey. Keep in mind, other filters may influence included course modules as well. */
    limitCourseModulesToCurrentJourney?: InputMaybe<Scalars['Boolean']>
    /** Only include journey daily steps associated with the journey from a user's most recent DCI or, if journey from user's most recent DCI is not available, use the user's currently selected journey. By default, this is true. When this is set to false, journey daily steps will not be limited to current journey. Keep in mind, other filters may influence included daily journey steps as well. */
    limitJourneyDailyStepToCurrentJourney?: InputMaybe<Scalars['Boolean']>
    /** Only include microsteps associated with the journey from a user's most recent DCI or, if journey from user's most recent DCI is not available, use the user's currently selected journey. By default, this is true. When this is set to false, microsteps will not be limited to current journey. Keep in mind, other filters may influence included microsteps as well. */
    limitMicrostepsToCurrentJourney?: InputMaybe<Scalars['Boolean']>
    /** Only include podcast episodes associated with the journey from a user's most recent DCI or, if journey from user's most recent DCI is not available, use the user's currently selected journey. By default, this is true. When this is set to false, podcast episodes will not be limited to current journey. Keep in mind, other filters may influence included podcast episodes as well. */
    limitPodcastEpisodesToCurrentJourney?: InputMaybe<Scalars['Boolean']>
    /** Only include content associated with these personas. By default, all are included. If this list is empty (not null), then only content with no persona will be included. If the personas relationship does not apply to a given content type, then this filter will be ignored. */
    personas?: InputMaybe<Array<Scalars['String']>>
    /** Optional text that will be used to improve microstep, article, and reset recommendations. By default, null (searchString will not be used). */
    searchString?: InputMaybe<Scalars['String']>
    /** Only include content associated with these tags. By default, all are included. If this list is empty (not null), then only content with no tag will be included. If the tags relationship does not apply to a given content type, then this filter will be ignored. */
    tags?: InputMaybe<Array<Scalars['String']>>
    /** Skip any journey information related to the latest DCI and use the user's currently Intention, if available. By default, false. */
    useOnlyIntentionRelatedContent?: InputMaybe<Scalars['Boolean']>
}

export enum ContentImpact {
    High = 'HIGH',
    Low = 'LOW',
    Moderate = 'MODERATE'
}

export type ContentList = {
    __typename?: 'ContentList'
    contents: LearnContentItemPage
    id: Scalars['UUID']
    title: Scalars['String']
}

export type ContentListContentsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ContentListPage = {
    __typename?: 'ContentListPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ContentList>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type ContentListQuery = {
    __typename?: 'ContentListQuery'
    getList?: Maybe<ContentList>
    getLists: ContentListPage
}

export type ContentListQueryGetListArgs = {
    id: Scalars['UUID']
}

export type ContentListQueryGetListsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ContentMutation = {
    __typename?: 'ContentMutation'
    book: BookMutation
    journal: JournalMutation
    successStory: SuccessStoryMutation
}

export type ContentPack = {
    __typename?: 'ContentPack'
    content: Array<LearnContentItem>
    description: Scalars['String']
    title: Scalars['String']
}

export type ContentPackPage = {
    __typename?: 'ContentPackPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ContentPack>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type ContentQuery = {
    __typename?: 'ContentQuery'
    journal: JournalQuery
    list: ContentListQuery
    successStory: SuccessStoryQuery
    thrive: ThriveContentQuery
}

export enum ContentType {
    Audio = 'AUDIO',
    Text = 'TEXT',
    Video = 'VIDEO'
}

export type Contributor = {
    __typename?: 'Contributor'
    bio: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    photoUrl: Scalars['String']
    title: Scalars['String']
}

export type CoreHealthBehaviorScore = {
    __typename?: 'CoreHealthBehaviorScore'
    averageScore?: Maybe<Scalars['Float']>
    journeyCoreType: JourneyCoreType
}

export type CourseModuleParent = {
    __typename?: 'CourseModuleParent'
    courseID: Scalars['ID']
    lessonID: Scalars['ID']
}

export type CourseMutation = {
    /** Mutation creating or updating course resource status (for course or lesson), based on userId from context */
    markCourseResourceAsCompleted: LearnContentStatus
}

export type CourseMutationMarkCourseResourceAsCompletedArgs = {
    contentId: Scalars['String']
}

export type CoursePage = {
    __typename?: 'CoursePage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<CourseV2>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type CourseQuery = {
    /** Get course by id */
    getCourse?: Maybe<CourseV2>
    /** Get courses by companyId and subscriptionId from context */
    getCourses: CoursePage
}

export type CourseQueryGetCourseArgs = {
    courseId: Scalars['String']
}

export type CourseQueryGetCoursesArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    sort?: InputMaybe<Array<Scalars['String']>>
}

/** Supplementary Course Resource */
export type CourseResource = {
    __typename?: 'CourseResource'
    /** Course resource progress for user, based on userId from context. */
    contentStatus?: Maybe<LearnContentStatus>
    createdAt: Scalars['Instant']
    hasCompleted: Scalars['Boolean']
    /** CourseResource identifier, value of uuid from CMS */
    id: Scalars['ID']
    /** course resource type */
    resourceType: CourseResourceType
    /** link to the resource, if link contains '$companyId' tag it is replaced with user company id from context */
    source: Scalars['String']
    summary?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt: Scalars['Instant']
}

/** Supplementary Course Resource Type */
export enum CourseResourceType {
    Link = 'Link',
    Pdf = 'PDF',
    Survey = 'Survey'
}

export type CourseV2 = {
    __typename?: 'CourseV2'
    about: Scalars['String']
    blurbs: Array<BlurbV2>
    bookmarked: Scalars['Boolean']
    contentType: LearnContentStatusType
    experts: Array<Contributor>
    id: Scalars['ID']
    isCompleted: Scalars['Boolean']
    lessons: Array<LessonV2>
    /** Course progress for user, based on userId from context. Checks progress on lessons. */
    progress?: Maybe<LearnContentProgress>
    resources: Array<CourseResource>
    subTitle: Scalars['String']
    tags: Array<Tag>
    thumbnail?: Maybe<Scalars['String']>
    title: Scalars['String']
    uuid?: Maybe<Scalars['UUID']>
}

export type CurrencyInfo = {
    __typename?: 'CurrencyInfo'
    /** The three letter code that represents this currency */
    code: Scalars['String']
    /** The key that points to the translated property in the i18n file */
    name: Scalars['String']
}

export type DailyActivitySummaryStatsResponse = ActivitySummaryStats & {
    __typename?: 'DailyActivitySummaryStatsResponse'
    averageSteps: Scalars['Float']
    day: Scalars['Int']
    month: Scalars['Int']
    year: Scalars['Int']
}

export type DailyAssessmentStatsResponse = AssessmentStats & {
    __typename?: 'DailyAssessmentStatsResponse'
    average: Scalars['Float']
    day: Scalars['Int']
    month: Scalars['Int']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
    year: Scalars['Int']
}

export type DailyCheckInDto = {
    __typename?: 'DailyCheckInDTO'
    dispatchDate: Scalars['ISODate']
    id: Scalars['UUID']
    pulseUserId: Scalars['UUID']
    /** @deprecated Use response.recommendedContentDimension */
    recommendedContentDimension?: Maybe<Surveys>
    response?: Maybe<DailyCheckInResponseDto>
    status: Scalars['String']
    survey: PulseSurveyQuestionDto
    thriveId: Scalars['UUID']
}

export type DailyCheckInResponseDto = {
    __typename?: 'DailyCheckInResponseDTO'
    recommendAcuteCare: Scalars['Boolean']
    recommendedContentDimension: Surveys
    responseChannel: Scalars['String']
    selectedOption?: Maybe<OptionsDto>
}

export type DailyCheckInResponseInput = {
    /** The unique id of the Daily Check-In */
    id?: InputMaybe<Scalars['UUID']>
    /** When true (default), the markJourneyDailyStepAsCompleted mutation will be invoked in DCI flow */
    markJourneyDailyStepAsCompleted?: InputMaybe<Scalars['Boolean']>
    /** The channel in which the Daily Check-In response is coming from */
    respondingChannel: Channel
    /** The date of the survey that the Daily Check-In response is for */
    responseDate: Scalars['ISODate']
    /** The Daily Check-In response value */
    responseValue: Scalars['Int']
    /** The list of desired recommended content types to be returned on success. Defaults to all */
    supportedContentTypes?: InputMaybe<Array<SupportedRecommendedContentType>>
    /** The local date of the user at the time of the response */
    userCurrentDate: Scalars['ISODate']
}

export type DailyCheckInScoresQuery = {
    __typename?: 'DailyCheckInScoresQuery'
    /** Historic Daily Check In Scores for a company */
    historicDailyCheckInScores: HistoricDailyCheckInScoresResponse
    /** Recent Daily Check In Scores for a company */
    recentDailyCheckInScores?: Maybe<RecentDailyCheckInScoresResponse>
}

export type DailyCheckInScoresQueryHistoricDailyCheckInScoresArgs = {
    companyId: Scalars['UUID']
    dimension: Scalars['String']
    end: MonthYearInput
    start: MonthYearInput
}

export type DailyCheckInScoresQueryRecentDailyCheckInScoresArgs = {
    companyId: Scalars['UUID']
}

export type DailyCheckinDetail = {
    __typename?: 'DailyCheckinDetail'
    name?: Maybe<Scalars['String']>
}

export type DailyExperienceMutation = {
    __typename?: 'DailyExperienceMutation'
    goals: GoalMutation
}

export type DailyExperienceQuery = {
    __typename?: 'DailyExperienceQuery'
    /** Goals Query */
    goals: GoalQuery
}

export type DailySleepSummaryStatsResponse = SleepSummaryStats & {
    __typename?: 'DailySleepSummaryStatsResponse'
    averageBedTimeStart?: Maybe<Scalars['Long']>
    averageMinutesAsleep: Scalars['Float']
    day: Scalars['Int']
    month: Scalars['Int']
    year: Scalars['Int']
}

export enum DashboardViewType {
    Admin = 'ADMIN',
    Personal = 'PERSONAL'
}

/** Rachel made these names */
export type DeprovisionResponse = {
    __typename?: 'DeprovisionResponse'
    successfulUserIds?: Maybe<Array<Scalars['String']>>
    unsuccessfulUserIds?: Maybe<Array<Scalars['String']>>
}

export type DesktopWebhookEventInput = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    contactId: Scalars['String']
    date: Scalars['Instant']
    state: ResetState
}

export type DimensionTrendsDto = {
    __typename?: 'DimensionTrendsDTO'
    currentPeriodScore: Scalars['Int']
    difference: Scalars['Int']
    isZeroTrend: Scalars['Boolean']
    lastPeriodScore: Scalars['Int']
    optimalRange: OptimalRange
    type: Surveys
}

export enum Direction {
    Inbound = 'INBOUND',
    Na = 'NA',
    Outbound = 'OUTBOUND'
}

export type DislikeStepError = {
    __typename?: 'DislikeStepError'
    error: DislikeStepResponseError
}

export type DislikeStepResponse = DislikeStepError | DislikeStepResult

export enum DislikeStepResponseError {
    ErrorCreatingStep = 'ERROR_CREATING_STEP',
    ErrorQueryingMicrostep = 'ERROR_QUERYING_MICROSTEP',
    NonExistentStep = 'NON_EXISTENT_STEP',
    NonExistentToken = 'NON_EXISTENT_TOKEN',
    NoNewRecommendationFound = 'NO_NEW_RECOMMENDATION_FOUND'
}

export type DislikeStepResult = {
    __typename?: 'DislikeStepResult'
    newStep: Step
}

export type DisplayName = {
    __typename?: 'DisplayName'
    displayName?: Maybe<Scalars['String']>
    isPublic: Scalars['Boolean']
    userId?: Maybe<Scalars['UUID']>
}

export enum DisplayTarget {
    Chat = 'CHAT',
    Mobile = 'MOBILE',
    Web = 'WEB',
    /** In addition to showing up on the web, also send a web notification */
    WebNotify = 'WEB_NOTIFY'
}

export type Division = {
    __typename?: 'Division'
    id?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
}

export type EmailChangeInput = {
    newEmail: Scalars['String']
    oldEmail: Scalars['String']
}

export type EmailChangeOutput = {
    __typename?: 'EmailChangeOutput'
    newEmail: Scalars['String']
    oldEmail: Scalars['String']
}

export type EnablingChallengeStatus = {
    __typename?: 'EnablingChallengeStatus'
    challengesInCompany: Array<UnifiedChallengeCompany>
}

export type EngagementLevelsQuery = {
    __typename?: 'EngagementLevelsQuery'
    /** Historical Engagement Levels for a company */
    historicEngagementLevels: HistoricEngagementLevelResponse
    /** Recent Engagement Levels for a company */
    recentEngagementLevels: RecentEngagementLevelResponse
}

export type EngagementLevelsQueryHistoricEngagementLevelsArgs = {
    companyId: Scalars['UUID']
    end: MonthYearInput
    start: MonthYearInput
}

export type EngagementLevelsQueryRecentEngagementLevelsArgs = {
    companyId: Scalars['UUID']
}

export type EngagementReportResponse = {
    __typename?: 'EngagementReportResponse'
    challengesStarted: Scalars['Long']
    checkins: Scalars['Long']
    companyId: Scalars['UUID']
    lastUpdated: Scalars['ISODate']
    learnModulesStarted: Scalars['Long']
    microstepsCheckedin: Scalars['Long']
    period: ReportPeriod
    resetsPlayed: Scalars['Long']
    uniqueChallengeUsers: Scalars['Long']
    uniqueCheckinUsers: Scalars['Long']
    uniqueLearnUsers: Scalars['Long']
    uniqueMicrostepUsers: Scalars['Long']
    uniqueResetUsers: Scalars['Long']
}

export enum EntityType {
    Microstep = 'MICROSTEP',
    Reset = 'RESET'
}

export enum ErrorCode {
    AssessmentAttemptCompletedAlready = 'ASSESSMENT_ATTEMPT_COMPLETED_ALREADY',
    AssessmentAttemptNotCompleted = 'ASSESSMENT_ATTEMPT_NOT_COMPLETED',
    AssessmentAttemptNotFound = 'ASSESSMENT_ATTEMPT_NOT_FOUND',
    AssessmentBaselineCmsAssessmentNotFound = 'ASSESSMENT_BASELINE_CMS_ASSESSMENT_NOT_FOUND',
    AssessmentBaselineIneligible = 'ASSESSMENT_BASELINE_INELIGIBLE',
    AssessmentCmsAssessmentNotFound = 'ASSESSMENT_CMS_ASSESSMENT_NOT_FOUND',
    AssessmentIneligible = 'ASSESSMENT_INELIGIBLE',
    AssessmentNotAllowed = 'ASSESSMENT_NOT_ALLOWED',
    AssessmentNoJourney = 'ASSESSMENT_NO_JOURNEY',
    AssessmentUnexpectedError = 'ASSESSMENT_UNEXPECTED_ERROR'
}

export type ErrorMessage = {
    __typename?: 'ErrorMessage'
    message: Scalars['String']
}

export type ErrorMessageCustomization = {
    __typename?: 'ErrorMessageCustomization'
    message: Scalars['String']
}

export type ErrorMessageFeelingQuestion = {
    __typename?: 'ErrorMessageFeelingQuestion'
    message: Scalars['String']
}

export type ErrorMessageSleepHabits = {
    __typename?: 'ErrorMessageSleepHabits'
    message: Scalars['String']
}

export type ErrorMessageTemplate = {
    __typename?: 'ErrorMessageTemplate'
    message: Scalars['String']
}

export type ErrorMessageWater = {
    __typename?: 'ErrorMessageWater'
    message: Scalars['String']
}

export type Event = {
    __typename?: 'Event'
    rawJson: Scalars['JSON']
}

export type ExampleQuery = {
    __typename?: 'ExampleQuery'
    /** Very simple example query */
    exampleQuery: Scalars['String']
    /** Return list of microsteps for given list of UUIDs. For example only. */
    microsteps: Array<GraphQlMicrostep>
}

export type ExampleQueryMicrostepsArgs = {
    uuids: Array<Scalars['String']>
}

export type ExternalLinkDetail = {
    __typename?: 'ExternalLinkDetail'
    name?: Maybe<Scalars['String']>
}

export type ExternalPulseMutation = {
    __typename?: 'ExternalPulseMutation'
    /** Create a pulse survey response */
    saveSurveyAnswer: SaveSurveyAnswerResponse
}

export type ExternalPulseMutationSaveSurveyAnswerArgs = {
    requestDate: Scalars['ISODate']
    responseValue: Scalars['Int']
    supportedContentTypes?: InputMaybe<Array<SupportedRecommendedContentType>>
    thriveUserId?: InputMaybe<Scalars['UUID']>
}

export type ExternalPulseQuery = {
    __typename?: 'ExternalPulseQuery'
    /** Company Information */
    company?: Maybe<PulseCompanyDto>
    /** Retrieve actionable Pulse recommendations for a company based on employee responses for a specified set of dimensions */
    getRecommendedContentForCompany: Array<RecommendedContent>
    /** Retrieves a survey for a Thrive user */
    getSurveyForUser: PulseOnDemand
    /** Get All Survey Questions */
    getSurveys: Array<PulseSurveyQuestionDto>
}

export type ExternalPulseQueryCompanyArgs = {
    companyId: Scalars['UUID']
}

export type ExternalPulseQueryGetRecommendedContentForCompanyArgs = {
    companyId: Scalars['UUID']
    dimensions: Array<Surveys>
    from: Scalars['String']
    to: Scalars['String']
}

export type ExternalPulseQueryGetSurveyForUserArgs = {
    channel: Channel
    companyId?: InputMaybe<Scalars['UUID']>
    requestDate: Scalars['ISODate']
    thriveUserId?: InputMaybe<Scalars['UUID']>
}

export type ExternalPulseQueryGetSurveysArgs = {
    activeOnly?: InputMaybe<Scalars['Boolean']>
}

export enum Feedback {
    Dislike = 'Dislike',
    Like = 'Like'
}

export type FeedbackQuestionResponse = FeedbackQuestionYesNoResponse

export enum FeedbackQuestionType {
    YesNo = 'YesNo'
}

export type FeedbackQuestionYesNoResponse = {
    __typename?: 'FeedbackQuestionYesNoResponse'
    response: Scalars['Boolean']
}

export type FeedbackWeightsInput = {
    negative: Scalars['Int']
    neutral: Scalars['Int']
    positive: Scalars['Int']
}

export type FeelingAnswer = {
    __typename?: 'FeelingAnswer'
    /** Answer value */
    answer: Scalars['Int']
    /** Answer day */
    answerDate: Scalars['ISODate']
    /** Challenge day */
    challengeDay: Scalars['Int']
    /** Challenge id */
    challengeId: Scalars['UUID']
}

export type FeelingQuestionDailyStats = {
    __typename?: 'FeelingQuestionDailyStats'
    /** User answer */
    answer: Scalars['Int']
    /** Answer's challenge day */
    challengeDay: Scalars['Int']
    /** Answer's calendar day */
    day: Scalars['Int']
    /** Answer's calendar month */
    month: Scalars['Int']
    /** Answer's calendar year */
    year: Scalars['Int']
}

export type FeelingQuestionDetail = {
    __typename?: 'FeelingQuestionDetail'
    name?: Maybe<Scalars['String']>
}

export type FeelingQuestionOperationResult =
    | ErrorMessageFeelingQuestion
    | FeelingQuestionResult

export type FeelingQuestionResult = {
    __typename?: 'FeelingQuestionResult'
    feelingAnswer: FeelingAnswer
}

export type FeelingQuestionStats = {
    __typename?: 'FeelingQuestionStats'
    /** Average answer through the whole challenge */
    averageAnswer: Scalars['Int']
    dailyStats: Array<FeelingQuestionDailyStats>
}

export type Frame = {
    _: Scalars['Boolean']
}

export type FrameInputIdInput = {
    frameType: FrameInputType
    id: Scalars['UUID']
}

export enum FrameInputType {
    Image = 'image',
    PersonalizedQuote = 'personalizedQuote',
    Quote = 'quote'
}

export type GenesysAgentMutation = {
    __typename?: 'GenesysAgentMutation'
    /**
     * Disconnects agent from the Reset Callback
     * @deprecated Not supported
     */
    disconnectAgentFromResetCallBack: Scalars['String']
    /**
     * Removes agent from the queue after receiving a Reset
     * @deprecated Moved to calls -> company -> getCompanyConfigurationForAgent, replace with getCompanyConfigurationForAgent(routingId, scriptId, agentId)
     */
    updateAgentResetTime: AgentLastReset
}

export type GenesysAgentMutationDisconnectAgentFromResetCallBackArgs = {
    agentId: Scalars['UUID']
    conversationId: Scalars['UUID']
    region: GenesysRegion
    resetQueueId: Scalars['UUID']
    resetScriptId: Scalars['UUID']
}

export type GenesysAgentMutationUpdateAgentResetTimeArgs = {
    agentId: Scalars['UUID']
    region: GenesysRegion
    resetQueueId: Scalars['UUID']
    resetScriptId: Scalars['UUID']
}

export type GenesysAnalyticsQuery = {
    __typename?: 'GenesysAnalyticsQuery'
    /** Get average reset call duration for the selected client and timeframe */
    getAverageResetCallDuration: GenesysAnalyticsResponse
    /**
     * Get a list of queues for the selected client
     * @deprecated Moved to calls -> agent -> getGroups, replace with getGroups(companyId, platformType, "QUEUE")
     */
    getClientCallQueues: Array<CallQueue>
    /**
     * Get a list of call skills for the selected client
     * @deprecated Moved to calls -> agent -> getGroups, replace with getGroups(companyId, platformType, "SKILL")
     */
    getClientCallSkills: Array<Skill>
    /**
     * Get multiple metrics for all queues configured for the selected client and timeframe
     * @deprecated No longer supported
     */
    getPerformanceMetricsForConfiguredQueues: GetPerformanceMetricsForConfiguredQueuesResponse
    /**
     * Get performance metrics for the selected queues
     * @deprecated Removed from reporting page
     */
    getQueuePerformance?: Maybe<GenesysAnalyticsResponse>
    /**
     * Get performance metrics for the selected queue, comparing agents with and without a skill
     * @deprecated Removed from reporting page
     */
    getQueuePerformanceSegmentedBySkill?: Maybe<GenesysAnalyticsResponse>
    /**
     * Get answer rate for reset calls for the selected client and timeframe
     * @deprecated Moved to calls -> reporting -> getResetCallAnswerRate
     */
    getResetCallAnswerRate: GenesysAnalyticsResponse
    /**
     * Get average reset call completion rate for the selected client and timeframe
     * @deprecated Moved to calls -> reporting -> getResetCallCompletionRate
     */
    getResetCallCompletionRate: GenesysAnalyticsResponse
    /**
     * Get reset call count averages per agent for the selected client and timeframe
     * @deprecated Moved to calls -> reporting -> getResetCallCountAveragesPerAgent
     */
    getResetCallCountAveragesPerAgent: GenesysAnalyticsResponse
    /**
     * Get reset call counts for the selected client and timeframe
     * @deprecated Moved to calls -> reporting -> getResetCallCounts
     */
    getResetCallCounts: GenesysAnalyticsResponse
}

export type GenesysAnalyticsQueryGetAverageResetCallDurationArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsQueryGetClientCallQueuesArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysAnalyticsQueryGetClientCallSkillsArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysAnalyticsQueryGetPerformanceMetricsForConfiguredQueuesArgs =
    {
        request: GetPerformanceMetricsForConfiguredQueuesRequestInput
    }

export type GenesysAnalyticsQueryGetQueuePerformanceArgs = {
    request: GetQueuePerformanceRequestInput
}

export type GenesysAnalyticsQueryGetQueuePerformanceSegmentedBySkillArgs = {
    request: GetQueuePerformanceBySkillRequestInput
}

export type GenesysAnalyticsQueryGetResetCallAnswerRateArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsQueryGetResetCallCompletionRateArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsQueryGetResetCallCountAveragesPerAgentArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsQueryGetResetCallCountsArgs = {
    request: GenesysResetEngagementRequestInput
}

export type GenesysAnalyticsResponse = {
    __typename?: 'GenesysAnalyticsResponse'
    data: Array<MetricData>
    endDate: Scalars['String']
    granularity: ReportingGranularity
    reportName: Scalars['String']
    startDate: Scalars['String']
}

export type GenesysClient = {
    __typename?: 'GenesysClient'
    agentCallSkillId?: Maybe<Scalars['UUID']>
    agentTriggerLimit?: Maybe<Scalars['Int']>
    callDurationThresholdMinutes?: Maybe<Scalars['Int']>
    callThreshold?: Maybe<Scalars['Int']>
    clientId: Scalars['UUID']
    companyName?: Maybe<Scalars['String']>
    createdAt: Scalars['Instant']
    digitalResetRoutingId?: Maybe<Scalars['String']>
    id: Scalars['UUID']
    isActive: Scalars['Boolean']
    isListening: Scalars['Boolean']
    isProcessing: Scalars['Boolean']
    lastQueriedAt: Scalars['Instant']
    numberLicensedAgents?: Maybe<Scalars['Int']>
    organizationId?: Maybe<Scalars['UUID']>
    queryAllQueues: Scalars['Boolean']
    queryQueueIds?: Maybe<Array<Scalars['UUID']>>
    region: GenesysRegion
    resetBufferMinutes?: Maybe<Scalars['Int']>
    resetQueueId?: Maybe<Scalars['UUID']>
    scriptId?: Maybe<Scalars['UUID']>
    shiftHours?: Maybe<Scalars['Float']>
    sso: Scalars['Boolean']
    thriveCompanyId?: Maybe<Scalars['UUID']>
    updatedAt: Scalars['Instant']
    usesPilotPage: Scalars['Boolean']
    websocketChannelId?: Maybe<Scalars['String']>
    websocketConnectUri?: Maybe<Scalars['String']>
    wrapUpCodeId?: Maybe<Scalars['UUID']>
}

export type GenesysClientCreateInput = {
    agentCallSkillId?: InputMaybe<Scalars['UUID']>
    callDurationThresholdMinutes?: InputMaybe<Scalars['Int']>
    callThreshold?: InputMaybe<Scalars['Int']>
    clientId: Scalars['UUID']
    digitalResetRoutingId?: InputMaybe<Scalars['String']>
    numberLicensedAgents?: InputMaybe<Scalars['Int']>
    organizationId?: InputMaybe<Scalars['UUID']>
    queryAllQueues?: InputMaybe<Scalars['Boolean']>
    queryQueueIds?: InputMaybe<Array<Scalars['UUID']>>
    region: GenesysRegion
    resetQueueId: Scalars['UUID']
    scriptId?: InputMaybe<Scalars['UUID']>
    secret: Scalars['String']
    shiftHours?: InputMaybe<Scalars['Float']>
    sso?: InputMaybe<Scalars['Boolean']>
    wrapUpCodeId?: InputMaybe<Scalars['UUID']>
}

export type GenesysClientMutation = {
    __typename?: 'GenesysClientMutation'
    /**
     * Adds Genesys configuration details for an organization
     * @deprecated Moved to calls -> company mutation, replace with calls.company.create
     */
    createClient: GenesysClient
    /**
     * Adds Genesys configuration details for an organization with multiple queues
     * @deprecated Moved to calls -> trigger mutation, replace with calls.trigger.upsert
     */
    createClientWithMultipleQueues: GenesysClientWithMultipleQueues
    /**
     * Delete configuration details for a Genesys organization
     * @deprecated Moved to calls -> company mutation, replace with calls.company.delete
     */
    deleteClient: Scalars['String']
    /**
     * Delete queue configuration for a Genesys organization
     * @deprecated Moved to calls -> trigger mutation, replace with calls.trigger.delete
     */
    deleteClientQueueConfig: Scalars['String']
    /** Imports a script */
    importScript: Script
    /**
     * Update a configuration details for a Genesys organization
     * @deprecated Moved to calls -> company mutation, replace with calls.company.update
     */
    updateClient: GenesysClient
    /**
     * Update a configuration details for a Genesys organization for multiple queues
     * @deprecated Moved to calls -> trigger mutation, replace with calls.trigger.upsert
     */
    updateClientWithMultipleQueues: GenesysClientWithMultipleQueues
}

export type GenesysClientMutationCreateClientArgs = {
    client: GenesysClientCreateInput
}

export type GenesysClientMutationCreateClientWithMultipleQueuesArgs = {
    client: GenesysClientCreateInput
    queueConfigs: Array<GenesysClientQueueConfigInput>
}

export type GenesysClientMutationDeleteClientArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientMutationDeleteClientQueueConfigArgs = {
    clientId: Scalars['UUID']
    queryQueueId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientMutationImportScriptArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientMutationUpdateClientArgs = {
    client: GenesysClientUpdateInput
}

export type GenesysClientMutationUpdateClientWithMultipleQueuesArgs = {
    client: GenesysClientUpdateInput
    queueConfigs: Array<GenesysClientQueueConfigInput>
}

export type GenesysClientPage = {
    __typename?: 'GenesysClientPage'
    items: Array<GenesysClient>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type GenesysClientQuery = {
    __typename?: 'GenesysClientQuery'
    /**
     * Get all clients paginated
     * @deprecated Moved to calls -> company -> getAllCompanyConfiguration, replace with getAllCompanyConfiguration
     */
    getAllClients: GenesysClientPage
    /**
     * Get company information for all Genesys clients.
     * @deprecated Moved to calls -> company -> getAllCompanyConfiguration, replace with getAllCompanyConfiguration
     */
    getAllGenesysCompanyInformation?: Maybe<Array<GenesysCompanyInformation>>
    /**
     * Get clients by client id and region
     * @deprecated Moved to calls -> company -> getCompanyConfigurationByThriveCompanyId, replace with getCompanyConfigurationByThriveCompanyId(companyId, platformType)
     */
    getClient?: Maybe<GenesysClient>
    /**
     * Get client by id
     * @deprecated Moved to calls -> company -> getCompanyConfigurationByThriveCompanyId, replace with getCompanyConfigurationByThriveCompanyId(companyId, platformType)
     */
    getClientById?: Maybe<GenesysClientWithMultipleQueues>
    /**
     * Get clients and queues by client id and region
     * @deprecated Moved to calls -> company -> getCompanyConfigurationByThriveCompanyId, replace with getCompanyConfigurationByThriveCompanyId(companyId, platformType)
     */
    getClientWithMultipleQueues?: Maybe<GenesysClientWithMultipleQueues>
}

export type GenesysClientQueryGetAllClientsArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type GenesysClientQueryGetAllGenesysCompanyInformationArgs = {
    onlyActive: Scalars['Boolean']
}

export type GenesysClientQueryGetClientArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientQueryGetClientByIdArgs = {
    id: Scalars['UUID']
}

export type GenesysClientQueryGetClientWithMultipleQueuesArgs = {
    clientId: Scalars['UUID']
    region: GenesysRegion
}

export type GenesysClientQueueConfig = {
    __typename?: 'GenesysClientQueueConfig'
    agentCallSkillId?: Maybe<Scalars['UUID']>
    callDurationThresholdMinutes?: Maybe<Scalars['Int']>
    callDurationThresholdMinutesEnabled: Scalars['Boolean']
    callThreshold?: Maybe<Scalars['Int']>
    callThresholdEnabled: Scalars['Boolean']
    clientId?: Maybe<Scalars['UUID']>
    digitalOnly?: Maybe<Scalars['Boolean']>
    direction?: Maybe<Array<Direction>>
    locale?: Maybe<Scalars['String']>
    mediaTypes?: Maybe<MediaTypeConfig>
    queryQueueId: Scalars['UUID']
}

export type GenesysClientQueueConfigInput = {
    agentCallSkillId?: InputMaybe<Scalars['UUID']>
    callDurationThresholdMinutes?: InputMaybe<Scalars['Int']>
    callDurationThresholdMinutesEnabled: Scalars['Boolean']
    callThreshold?: InputMaybe<Scalars['Int']>
    callThresholdEnabled: Scalars['Boolean']
    clientId?: InputMaybe<Scalars['UUID']>
    digitalOnly?: InputMaybe<Scalars['Boolean']>
    direction?: InputMaybe<Array<Direction>>
    locale?: InputMaybe<Scalars['String']>
    mediaTypes?: InputMaybe<MediaTypeConfigInput>
    queryQueueId: Scalars['UUID']
}

export type GenesysClientUpdateInput = {
    agentCallSkillId?: InputMaybe<Scalars['UUID']>
    agentTriggerLimit?: InputMaybe<Scalars['Int']>
    callDurationThresholdMinutes?: InputMaybe<Scalars['Int']>
    callThreshold?: InputMaybe<Scalars['Int']>
    clientId: Scalars['UUID']
    digitalResetRoutingId?: InputMaybe<Scalars['String']>
    isActive?: InputMaybe<Scalars['Boolean']>
    isProcessing?: InputMaybe<Scalars['Boolean']>
    numberLicensedAgents?: InputMaybe<Scalars['Int']>
    organizationId?: InputMaybe<Scalars['UUID']>
    queryQueueIds?: InputMaybe<Array<Scalars['UUID']>>
    region: GenesysRegion
    resetBufferMinutes?: InputMaybe<Scalars['Int']>
    resetQueueId?: InputMaybe<Scalars['UUID']>
    scriptId?: InputMaybe<Scalars['UUID']>
    shiftHours?: InputMaybe<Scalars['Float']>
    wrapUpCodeId?: InputMaybe<Scalars['UUID']>
}

export type GenesysClientWithMultipleQueues = {
    __typename?: 'GenesysClientWithMultipleQueues'
    genesysClient: GenesysClient
    queueConfigs: Array<GenesysClientQueueConfig>
}

export type GenesysCompanyInformation = {
    __typename?: 'GenesysCompanyInformation'
    companyName?: Maybe<Scalars['String']>
    genesysOrganizationId?: Maybe<Scalars['UUID']>
    oauthClientId: Scalars['UUID']
    region: GenesysRegion
    thriveCompanyId?: Maybe<Scalars['UUID']>
}

export type GenesysIntegrationMutation = {
    __typename?: 'GenesysIntegrationMutation'
    /** Disables Genesys integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Genesys integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type GenesysIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type GenesysIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    genesysOrgId: Scalars['String']
    iss: Scalars['String']
}

export enum GenesysRegion {
    ApNortheast_1 = 'AP_NORTHEAST_1',
    ApNortheast_2 = 'AP_NORTHEAST_2',
    ApSoutheast_2 = 'AP_SOUTHEAST_2',
    ApSouth_1 = 'AP_SOUTH_1',
    CaCentral_1 = 'CA_CENTRAL_1',
    EuCentral_1 = 'EU_CENTRAL_1',
    EuWest_1 = 'EU_WEST_1',
    EuWest_2 = 'EU_WEST_2',
    UsEast_1 = 'US_EAST_1',
    UsEast_2 = 'US_EAST_2',
    UsWest_2 = 'US_WEST_2',
    Wiremock = 'WIREMOCK'
}

export type GenesysResetEngagementRequestInput = {
    clientId: Scalars['UUID']
    endDate: Scalars['String']
    granularity: ReportingGranularity
    region: GenesysRegion
    startDate: Scalars['String']
}

export type GenesysUsEast1IntegrationMutation = {
    __typename?: 'GenesysUsEast1IntegrationMutation'
    /** Disables Genesys US East 1 integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Genesys US East 1 integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type GenesysUsEast1IntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type GenesysUsEast1IntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    orgId: Scalars['String']
}

export type GenesysUsWest2IntegrationMutation = {
    __typename?: 'GenesysUsWest2IntegrationMutation'
    /** Disables Genesys US West 2 integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Genesys US West 2 integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type GenesysUsWest2IntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type GenesysUsWest2IntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    orgId: Scalars['String']
}

export type GetAssessmentByNameAndVariantInput = {
    name: Scalars['String']
    variant?: InputMaybe<Scalars['String']>
}

export type GetBehavioralProfileInput = {
    from: Scalars['String']
    to: Scalars['String']
}

export type GetCoachFailure = {
    __typename?: 'GetCoachFailure'
    error: Scalars['String']
}

export type GetCoachGqlResponse = GetCoachFailure | GetCoachSuccess

export type GetCoachSuccess = {
    __typename?: 'GetCoachSuccess'
    result?: Maybe<CoachGqlModel>
}

export type GetCoachesFailure = {
    __typename?: 'GetCoachesFailure'
    error: Scalars['String']
}

export type GetCoachesGqlResponse = GetCoachesFailure | GetCoachesSuccess

export type GetCoachesSuccess = {
    __typename?: 'GetCoachesSuccess'
    hasMore: Scalars['Boolean']
    page: Scalars['Int']
    pageCount: Scalars['Int']
    pageSize: Scalars['Int']
    result: Array<CoachSummaryGqlModel>
}

export type GetNotificationSettings = {
    __typename?: 'GetNotificationSettings'
    /** Returns the notification settings for MS Teams user */
    getNotificationSettings: NotificationSettingsOperationResult
}

export type GetNotificationSettingsGetNotificationSettingsArgs = {
    input: NotificationSettingsInput
}

export type GetPerformanceMetricsForConfiguredQueuesRequestInput = {
    clientId: Scalars['UUID']
    endDate: Scalars['String']
    granularity: ReportingGranularity
    metrics: Array<MetricsEnum>
    region: GenesysRegion
    startDate: Scalars['String']
}

export type GetPerformanceMetricsForConfiguredQueuesResponse = {
    __typename?: 'GetPerformanceMetricsForConfiguredQueuesResponse'
    queueData: Array<AggregatePerformanceMetrics>
}

export type GetQueuePerformanceBySkillRequestInput = {
    callSkillName: Scalars['String']
    clientId: Scalars['UUID']
    endDate: Scalars['String']
    granularity: ReportingGranularity
    interactionType: InteractionType
    metric: MetricsEnum
    queueId: Scalars['UUID']
    region: GenesysRegion
    startDate: Scalars['String']
}

export type GetQueuePerformanceRequestInput = {
    clientId: Scalars['UUID']
    endDate: Scalars['String']
    granularity: ReportingGranularity
    interactionType: InteractionType
    metric: MetricsEnum
    queueIds: Array<Scalars['UUID']>
    region: GenesysRegion
    startDate: Scalars['String']
}

export type GetRecommendedCoachesFailure = {
    __typename?: 'GetRecommendedCoachesFailure'
    error: Scalars['String']
}

export type GetRecommendedCoachesGqlResponse =
    | GetRecommendedCoachesFailure
    | GetRecommendedCoachesSuccess

export type GetRecommendedCoachesSuccess = {
    __typename?: 'GetRecommendedCoachesSuccess'
    result: Array<CoachSummaryGqlModel>
}

export type GetRecommendedJourneysByAssessmentInput = {
    assessmentAttemptId: Scalars['UUID']
}

export type GetTimezoneForUserInput = {
    users: Array<Scalars['UUID']>
}

export type GetTimezoneForUserResult = {
    __typename?: 'GetTimezoneForUserResult'
    usersByZone: Array<UsersByTimezone>
}

export type GetTimezoneForUsersQuery = {
    __typename?: 'GetTimezoneForUsersQuery'
    /** Retrieve the timezone and locale information for users */
    getTimezoneForUsers: GetTimezoneForUserResult
}

export type GetTimezoneForUsersQueryGetTimezoneForUsersArgs = {
    input: GetTimezoneForUserInput
}

export type GivenResponse = {
    __typename?: 'GivenResponse'
    assessmentItemId: Scalars['UUID']
    createdAt: Scalars['Instant']
    response?: Maybe<Response>
    updatedAt: Scalars['Instant']
    userTime?: Maybe<Scalars['String']>
}

export type GoalDto = {
    __typename?: 'GoalDTO'
    goal?: Maybe<Scalars['String']>
    id: Scalars['UUID']
    journeyIds: Array<Scalars['UUID']>
}

export type GoalEntityDetail =
    | ArticleDetail
    | AudioResetGoalDetail
    | DailyCheckinDetail
    | ExternalLinkDetail
    | FeelingQuestionDetail
    | MicrostepGoalDetail
    | ResetGoalDetail
    | StepsGoalDetail
    | WaterGoalDetail

export type GoalMutation = {
    __typename?: 'GoalMutation'
    /** Acknowledge the user's answer to change the intention */
    acknowledgeUserIntentionResponse: Scalars['Boolean']
    admin: AdminGoalMutation
    /** Dislike a step and return a new one */
    dislikeStep: DislikeStepResponse
    /**
     * Fetches or creates a microstep plan for the user passing the goal id/journey id
     * @deprecated Use fetchPersonalizedPlan query
     */
    fetchOrCreateUserPlan: UserPlanResponse
    /**
     * Remove step as done
     * @deprecated No need to use this mutation anymore because we switched to use events
     */
    removeStepAsDone?: Maybe<Scalars['ISODate']>
    /** Remove disliked content from the user's list. If contentIds is empty, all disliked content will be removed. */
    resetDislikedContent: Scalars['Int']
    /** Save the user's feedback loop answers */
    saveFeedbackLoopAnswers: SaveFeedbackResponse
    /**
     * Sets an intention for the user
     * @deprecated Use setIntentionV2
     */
    setIntention?: Maybe<Intention>
    /** Sets an intention for the user */
    setIntentionV2: SetIntentionResponse
    /**
     * Set step as done
     * @deprecated No need to use this mutation anymore because we switched to use events
     */
    setStepAsDone?: Maybe<Scalars['ISODate']>
}

export type GoalMutationAcknowledgeUserIntentionResponseArgs = {
    habitId: Scalars['UUID']
    intentionId: Scalars['UUID']
}

export type GoalMutationDislikeStepArgs = {
    stepId: Scalars['UUID']
}

export type GoalMutationFetchOrCreateUserPlanArgs = {
    getFullHabit?: InputMaybe<Scalars['Boolean']>
    goalId?: InputMaybe<Scalars['UUID']>
    journeyId?: InputMaybe<Scalars['UUID']>
}

export type GoalMutationRemoveStepAsDoneArgs = {
    stepId: Scalars['UUID']
}

export type GoalMutationResetDislikedContentArgs = {
    contentIds: Array<Scalars['UUID']>
}

export type GoalMutationSaveFeedbackLoopAnswersArgs = {
    feedbackQuestion: Scalars['String']
    feedbackScore: Scalars['Int']
}

export type GoalMutationSetIntentionArgs = {
    intention: Scalars['String']
    journeyIds: Array<Scalars['UUID']>
}

export type GoalMutationSetIntentionV2Args = {
    intention: Scalars['String']
    journeyIds: Array<Scalars['UUID']>
}

export type GoalMutationSetStepAsDoneArgs = {
    checkinDate?: InputMaybe<Scalars['ISODate']>
    microstepId: Scalars['UUID']
}

export type GoalPage = {
    __typename?: 'GoalPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<GoalDto>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type GoalQuery = {
    __typename?: 'GoalQuery'
    admin: AdminGoalQuery
    /** Returns true if user needs to be asked for feedback */
    displayFeedback: Scalars['Boolean']
    /** Returns paginated goals from the CMS */
    fetchCMSGoalsPaginated: GoalPage
    /** Returns the user's intention */
    fetchIntention?: Maybe<Intention>
    /** Fetches the user plan */
    fetchPersonalizedPlan: UserPlanResponse
    /** Returns recommended content for the user related to the intention */
    fetchRecommendedContent: Array<RecommendedEntities>
    /** Returns the list of all user steps for the date sect. Defaults to today */
    fetchStepsByDate: Array<Step>
    /** Returns the user subscription tier for intention, 1 is the default tier */
    fetchSubscriptionTier: Scalars['Int']
    /** Return a list of the user habits */
    fetchUserHabitHistory: Array<UserHabitHistory>
    /**
     * Given an intention, goes to ML to retrieve the list of related journeys
     * @deprecated Use mapIntentionToJourneysV2
     */
    mapIntentionToJourneys?: Maybe<IntentionToJourneysResponse>
    /** Given an intention, goes to ML to retrieve the list of related journeys */
    mapIntentionToJourneysV2: IntentionToJourneysResponseV2
}

export type GoalQueryFetchCmsGoalsPaginatedArgs = {
    journeyIds?: InputMaybe<Array<Scalars['UUID']>>
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type GoalQueryFetchPersonalizedPlanArgs = {
    journeyId?: InputMaybe<Scalars['UUID']>
}

export type GoalQueryFetchRecommendedContentArgs = {
    limit: Scalars['Int']
    recommendedTypes: Array<PersonalizedRecommendedContent>
}

export type GoalQueryFetchStepsByDateArgs = {
    date?: InputMaybe<Scalars['ISODate']>
}

export type GoalQueryMapIntentionToJourneysArgs = {
    intention: Scalars['String']
}

export type GoalQueryMapIntentionToJourneysV2Args = {
    intention: Scalars['String']
}

export enum GoalType {
    ArticleRead = 'ARTICLE_READ',
    ArticleWatched = 'ARTICLE_WATCHED',
    CheckIn = 'CHECK_IN',
    ExternalLink = 'EXTERNAL_LINK',
    FeelingQuestion = 'FEELING_QUESTION',
    PulseCheckIn = 'PULSE_CHECK_IN',
    RecipeRead = 'RECIPE_READ',
    RecipeWatched = 'RECIPE_WATCHED',
    ResetListened = 'RESET_LISTENED',
    ResetWatched = 'RESET_WATCHED',
    RoleModelRead = 'ROLE_MODEL_READ',
    RoleModelWatched = 'ROLE_MODEL_WATCHED',
    Steps = 'STEPS',
    Water = 'WATER'
}

export type Google = CalendarServiceFields & {
    __typename?: 'Google'
    iconUrl: Scalars['Url']
    name: Scalars['String']
    resetEventUrl: Scalars['Url']
    type: CalendarService
}

export enum Granularity {
    Day = 'DAY',
    Hour = 'HOUR',
    Week = 'WEEK'
}

export type GraphQlGuide = {
    __typename?: 'GraphQlGuide'
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    imgUrl: Scalars['String']
    pages: Array<GraphQlGuidePage>
    position: Scalars['Int']
    status?: Maybe<Scalars['String']>
    statusUpdatedAt?: Maybe<Scalars['Instant']>
    successMsg: Scalars['String']
    takeaways: Array<Scalars['String']>
    title: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type GraphQlGuidePage = {
    __typename?: 'GraphQlGuidePage'
    createdAt: Scalars['Instant']
    elements: Array<GraphQlGuidePageElement>
    id: Scalars['UUID']
    pageType: GraphQlGuidePageType
    title: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type GraphQlGuidePageElement = {
    __typename?: 'GraphQlGuidePageElement'
    align: GraphQlGuidePageElementAlign
    alt: Scalars['String']
    createdAt: Scalars['Instant']
    elementType: GraphQlGuidePageElementType
    id: Scalars['UUID']
    src: Scalars['String']
    text: Scalars['String']
    updatedAt: Scalars['Instant']
}

export enum GraphQlGuidePageElementAlign {
    Center = 'CENTER',
    End = 'END',
    Start = 'START'
}

export enum GraphQlGuidePageElementType {
    Break = 'BREAK',
    ButtonQuiz = 'BUTTON_QUIZ',
    ButtonQuizCorrect = 'BUTTON_QUIZ_CORRECT',
    Image = 'IMAGE',
    ImageCard = 'IMAGE_CARD',
    ImageProfile = 'IMAGE_PROFILE',
    Quotation = 'QUOTATION',
    TextBody1 = 'TEXT_BODY1',
    TextBullet = 'TEXT_BULLET',
    TextDisplay4 = 'TEXT_DISPLAY4',
    TextForm = 'TEXT_FORM',
    TextH1 = 'TEXT_H1',
    TextOverline = 'TEXT_OVERLINE',
    TextP14 = 'TEXT_P14',
    TextVideo = 'TEXT_VIDEO',
    Video = 'VIDEO'
}

export enum GraphQlGuidePageType {
    Quiz = 'QUIZ',
    Text = 'TEXT',
    Video = 'VIDEO'
}

export type GraphQlGuideUserProgress = {
    __typename?: 'GraphQlGuideUserProgress'
    createdAt: Scalars['String']
    guideId: Scalars['UUID']
    id: Scalars['UUID']
    status: GraphQlGuideUserProgressStatus
    updatedAt: Scalars['String']
    userId: Scalars['UUID']
}

export enum GraphQlGuideUserProgressStatus {
    Finished = 'FINISHED',
    Progressed = 'PROGRESSED',
    Viewed = 'VIEWED'
}

export type GraphQlMicrostep = {
    __typename?: 'GraphQlMicrostep'
    action: Scalars['String']
    body: Scalars['String']
    cadence: Scalars['String']
    checked?: Maybe<Scalars['Boolean']>
    committed?: Maybe<Scalars['Boolean']>
    company_id?: Maybe<Scalars['ID']>
    created_at: Scalars['String']
    duration: Scalars['Int']
    frequency: Scalars['String']
    gender: Scalars['String']
    id: Scalars['ID']
    journeyIds?: Maybe<Array<Scalars['ID']>>
    journeys: Array<Maybe<Journey>>
    liked?: Maybe<Scalars['Boolean']>
    reminder_enabled: Scalars['Boolean']
    tags: Array<Tag>
    time_of_day: Scalars['String']
    time_unit: Scalars['String']
    title: Scalars['String']
    totalCheckins: Scalars['Int']
    updated_at: Scalars['String']
    worker_type: Scalars['String']
}

export type GraphQlMicrostepMomentum = {
    __typename?: 'GraphQlMicrostepMomentum'
    checkins: Array<CheckinCount>
    total: Scalars['Int']
}

export type GraphQlSearchResult = {
    __typename?: 'GraphQlSearchResult'
    contentType: ContentType
    snippet: Scalars['String']
    title: Scalars['String']
    type: Type
    uuid: Scalars['UUID']
}

export type GroupChallengeTemplateCreateInput = {
    /** The id of the challenge template */
    challengeTemplateId?: InputMaybe<Scalars['UUID']>
    /** To indicate if the private challenge is GROUP or PERSONAL */
    groupChallengeType?: InputMaybe<GroupChallengeType>
    /** Indicates if team members are unlimited */
    isUnlimited?: InputMaybe<Scalars['Boolean']>
    /** Indicates the maximum number of members allowed in teams */
    maxTeamSize?: InputMaybe<Scalars['Int']>
    /** Optional prefix to be displayed before the challenge's name */
    namePrefix?: InputMaybe<Scalars['String']>
    /** The start date of the challenge */
    startDate?: InputMaybe<Scalars['ISODate']>
}

export enum GroupChallengeType {
    Group = 'GROUP',
    Personal = 'PERSONAL'
}

export enum GroupType {
    Group = 'GROUP',
    Queue = 'QUEUE',
    Skill = 'SKILL',
    Team = 'TEAM'
}

export type HapiActivitySummaryModel = {
    __typename?: 'HAPIActivitySummaryModel'
    calories: Scalars['Int']
    createdAt: Scalars['String']
    date: Scalars['String']
    distance: Scalars['Int']
    duration: Scalars['Int']
    humanId: Scalars['String']
    id: Scalars['String']
    light: Scalars['Int']
    moderate: Scalars['Int']
    sedentary: Scalars['Int']
    source: Scalars['String']
    steps: Scalars['Int']
    updatedAt: Scalars['String']
    vigorous: Scalars['Int']
}

export type Habit = {
    __typename?: 'Habit'
    createdAt: Scalars['Instant']
    durationInDays: Scalars['Int']
    id: Scalars['UUID']
    lastUpdatedBy: Scalars['UUID']
    replacedByHabit?: Maybe<Scalars['UUID']>
    steps: Array<Step>
    updatedAt: Scalars['Instant']
}

export type HapiAccessToken = {
    __typename?: 'HapiAccessToken'
    accessToken: Scalars['String']
    expiresIn: Scalars['Int']
    refreshToken: Scalars['String']
}

export type HapiIdToken = {
    __typename?: 'HapiIdToken'
    idRefreshToken: Scalars['String']
    idToken: Scalars['String']
    idTokenExpiresIn: Scalars['Int']
    tokenType: Scalars['String']
}

export type HapiSessionToken = {
    __typename?: 'HapiSessionToken'
    expiresIn: Scalars['Int']
    humanId: Scalars['String']
    sessionToken: Scalars['String']
}

export type HistoricAppUsageResponse = {
    __typename?: 'HistoricAppUsageResponse'
    data: Array<HistoricAppUsageStats>
    trends: AppUsageTrends
}

export type HistoricAppUsageStats = {
    __typename?: 'HistoricAppUsageStats'
    activeUsagePercentage: Scalars['Float']
    activeUsers: Scalars['Long']
    challengesStarted: Scalars['Long']
    checkins: Scalars['Long']
    cumlativeActiveUsers: Scalars['Long']
    learnModulesStarted: Scalars['Long']
    microstepsCheckedIn: Scalars['Long']
    period: MonthYear
    resetsPlayed: Scalars['Long']
    uniqueChallengeUsers: Scalars['Long']
    uniqueCheckinUsers: Scalars['Long']
    uniqueLearnUsers: Scalars['Long']
    uniqueMicrostepUsers: Scalars['Long']
    uniqueResetUsers: Scalars['Long']
}

export type HistoricChallengesCountStats = {
    __typename?: 'HistoricChallengesCountStats'
    challengesJoinedCount: Scalars['Int']
    period: MonthYear
}

export type HistoricChallengesCountStatsResponse = {
    __typename?: 'HistoricChallengesCountStatsResponse'
    data: Array<HistoricChallengesCountStats>
}

export type HistoricDailyCheckInScoresResponse = {
    __typename?: 'HistoricDailyCheckInScoresResponse'
    data: Array<HistoricDailyCheckInScoresStats>
}

export type HistoricDailyCheckInScoresStats = {
    __typename?: 'HistoricDailyCheckInScoresStats'
    averageScore: Scalars['Float']
    averageScoreBenchmarkScorePercentageDifference: Scalars['Float']
    averageScorePercentage: Scalars['Float']
    benchmarkScore: Scalars['Float']
    benchmarkScorePercentage: Scalars['Float']
    employeeCount: Scalars['Int']
    period: MonthYear
    responseCount: Scalars['Int']
}

export type HistoricEngagementLevelMonthlyStats = {
    __typename?: 'HistoricEngagementLevelMonthlyStats'
    benchmarkCount: Scalars['Int']
    benchmarkPercentage: Scalars['Float']
    employeeCount: Scalars['Int']
    percentage: Scalars['Float']
}

export type HistoricEngagementLevelResponse = {
    __typename?: 'HistoricEngagementLevelResponse'
    data: Array<HistoricEngagementLevelStats>
}

export type HistoricEngagementLevelStats = {
    __typename?: 'HistoricEngagementLevelStats'
    atRisk: HistoricEngagementLevelMonthlyStats
    highlyEngaged: HistoricEngagementLevelMonthlyStats
    moderatelyEngaged: HistoricEngagementLevelMonthlyStats
    period: MonthYear
}

export type HistoricMicrostepCheckinCountResponse = {
    __typename?: 'HistoricMicrostepCheckinCountResponse'
    data: Array<HistoricMicrostepCheckinCountStats>
}

export type HistoricMicrostepCheckinCountStats = {
    __typename?: 'HistoricMicrostepCheckinCountStats'
    microstepCheckinUsers: Scalars['Int']
    microstepCheckins: Scalars['Int']
    microstepId: Scalars['UUID']
    microstepName: Scalars['String']
    period: MonthYear
}

export type HumanApiMutation = {
    __typename?: 'HumanApiMutation'
    /** Manually sync all HAPI sources for a user */
    syncSources: Scalars['Boolean']
}

export type HumanApiQuery = {
    __typename?: 'HumanApiQuery'
    /** Get a list of HumanApi source information */
    sourceReference: Array<HumanApiSourceReferenceDto>
}

export type HumanApiSourceReferenceDto = {
    __typename?: 'HumanApiSourceReferenceDTO'
    humanApiSourceId: Scalars['String']
    sourceId: Scalars['UUID']
    sourceName: Scalars['String']
}

export type IdentityAccessTypeMutation = {
    __typename?: 'IdentityAccessTypeMutation'
    /** Create a new access type from the given input */
    create?: Maybe<Scalars['String']>
    /** Tries to delete existing access type by name */
    tryDelete: Scalars['Boolean']
}

export type IdentityAccessTypeMutationCreateArgs = {
    input: AccessTypeInput
}

export type IdentityAccessTypeMutationTryDeleteArgs = {
    name: Scalars['String']
}

export type IdentityAccessTypeQuery = {
    __typename?: 'IdentityAccessTypeQuery'
    /** Returns all access types with optional pagination */
    getAll: AccessTypePage
}

export type IdentityAccessTypeQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentityBrandMutation = {
    __typename?: 'IdentityBrandMutation'
    /** Create a new brand from the given input */
    create: Brand
    /** Tries to delete existing brand by name */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing brand by the provided input */
    tryUpdate?: Maybe<Brand>
}

export type IdentityBrandMutationCreateArgs = {
    input: BrandInputGraphQlInput
}

export type IdentityBrandMutationTryDeleteArgs = {
    name: Scalars['String']
}

export type IdentityBrandMutationTryUpdateArgs = {
    input: BrandInputGraphQlInput
    name: Scalars['String']
}

export type IdentityBrandQuery = {
    __typename?: 'IdentityBrandQuery'
    /** Returns all brands with optional pagination */
    getAll: BrandPage
    /** Tries to fetch existing brand by name */
    tryGet?: Maybe<Brand>
}

export type IdentityBrandQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    publicOnly?: InputMaybe<Scalars['Boolean']>
}

export type IdentityBrandQueryTryGetArgs = {
    name: Scalars['String']
}

export type IdentityCompanyMutation = {
    __typename?: 'IdentityCompanyMutation'
    /** Add new brands to the company, returning the company */
    addBrands?: Maybe<Company>
    /** Adds an email domain to owned email domains */
    addOwnedEmailDomain: OwnedEmailDomain
    /** Create a new company from the given input */
    create: Company
    /** Change company status. Enables IDP configs if new status is ACTIVE.  Disables IDP configs if new status is INACTIVE. Returns true if company was found and updated */
    tryChangeStatus: Scalars['Boolean']
    /** Tries to delete existing company by id */
    tryDelete: Scalars['Boolean']
    /** Deletes an email domain from owned email domains */
    tryDeleteOwnedEmailDomain: Scalars['Boolean']
    /** Tries to update existing company by id and provided input */
    tryUpdate?: Maybe<Company>
}

export type IdentityCompanyMutationAddBrandsArgs = {
    brands: Array<BrandInput>
    id: Scalars['UUID']
}

export type IdentityCompanyMutationAddOwnedEmailDomainArgs = {
    input: OwnedEmailDomainInput
}

export type IdentityCompanyMutationCreateArgs = {
    input: CompanyInputGraphQlInput
}

export type IdentityCompanyMutationTryChangeStatusArgs = {
    id: Scalars['UUID']
    status: CompanyStatus
}

export type IdentityCompanyMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentityCompanyMutationTryDeleteOwnedEmailDomainArgs = {
    emailDomain: Scalars['String']
}

export type IdentityCompanyMutationTryUpdateArgs = {
    id: Scalars['UUID']
    input: CompanyInputGraphQlInput
}

export type IdentityCompanyQuery = {
    __typename?: 'IdentityCompanyQuery'
    /** Returns all company with optional pagination */
    getAll: CompanyPage
    /** Returns all owned email domains for a company */
    getOwnedEmailDomains: Array<OwnedEmailDomain>
    /** Tries to fetch existing company by id */
    tryGet?: Maybe<Company>
}

export type IdentityCompanyQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    publicOnly?: InputMaybe<Scalars['Boolean']>
}

export type IdentityCompanyQueryGetOwnedEmailDomainsArgs = {
    companyId: Scalars['UUID']
}

export type IdentityCompanyQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentityIdpConfigMutation = {
    __typename?: 'IdentityIdpConfigMutation'
    /** Allow email domain for given IDP config */
    allowEmailDomain?: Maybe<IdpConfig>
    /** Change the idpCompanyId for a given company. Returns true if config exists and idpCompanyId updated */
    changeIdpCompanyId: Scalars['Boolean']
    /** Change whether idpType is used for friends and family signups only. Returns true if config does exist and was updated */
    changeIdpConfigFriendsAndFamilyOnlyStatus: Scalars['Boolean']
    /** Change priority for IDP config for given company. Returns true if config does exist and was updated */
    changePriority: Scalars['Boolean']
    /** Create a new IDP Config from the given input */
    create: IdpConfig
    /** Disable IDP config for given company. Returns true if config does exist and was disabled */
    disable: Scalars['Boolean']
    /** Disallow email domain for given IDP config */
    disallowEmailDomain?: Maybe<IdpConfig>
    /** Enables IDP config for given company. Returns true if config does exist and was enabled */
    enable: Scalars['Boolean']
    /** Set (replace) email domain allowlist for given IDP config */
    setEmailAllowlist?: Maybe<IdpConfig>
    /** Tries to delete existing IDP Config by id */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing IDP Config by id and provided input */
    tryUpdate?: Maybe<IdpConfig>
}

export type IdentityIdpConfigMutationAllowEmailDomainArgs = {
    emailDomain: Scalars['String']
    idpConfigId: Scalars['UUID']
}

export type IdentityIdpConfigMutationChangeIdpCompanyIdArgs = {
    companyId: Scalars['UUID']
    idpCompanyId: Scalars['String']
    idpType: IdpType
}

export type IdentityIdpConfigMutationChangeIdpConfigFriendsAndFamilyOnlyStatusArgs =
    {
        companyId: Scalars['UUID']
        friendsAndFamilyOnly: Scalars['Boolean']
        idpType: IdpType
    }

export type IdentityIdpConfigMutationChangePriorityArgs = {
    companyId: Scalars['UUID']
    idpType: IdpType
    priority?: InputMaybe<Scalars['Int']>
}

export type IdentityIdpConfigMutationCreateArgs = {
    input: IdpConfigInputGraphQlInput
}

export type IdentityIdpConfigMutationDisableArgs = {
    companyId: Scalars['UUID']
    idpType: IdpType
}

export type IdentityIdpConfigMutationDisallowEmailDomainArgs = {
    emailDomain: Scalars['String']
    idpConfigId: Scalars['UUID']
}

export type IdentityIdpConfigMutationEnableArgs = {
    companyId: Scalars['UUID']
    idpType: IdpType
}

export type IdentityIdpConfigMutationSetEmailAllowlistArgs = {
    idpConfigId: Scalars['UUID']
    input: IdpConfigSetEmailAllowlistInput
}

export type IdentityIdpConfigMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentityIdpConfigMutationTryUpdateArgs = {
    id: Scalars['UUID']
    input: IdpConfigInputGraphQlInput
}

export type IdentityIdpConfigQuery = {
    __typename?: 'IdentityIdpConfigQuery'
    /** Returns all IDP Configs with optional pagination */
    getAll: IdpConfigPage
    /** Tries to fetch existing IDP Config by id */
    tryGet?: Maybe<IdpConfig>
    /** Tries to find an existing IDP Config by IDP Type + IDP Company ID (external company ID, or signupCode for ZP/cognito) */
    tryGetByIdpCompanyId?: Maybe<IdpConfig>
}

export type IdentityIdpConfigQueryGetAllArgs = {
    filters?: InputMaybe<IdpConfigFiltersInput>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentityIdpConfigQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentityIdpConfigQueryTryGetByIdpCompanyIdArgs = {
    idpCompanyId: Scalars['String']
    idpType: IdpType
}

export type IdentityJwkCacheMutation = {
    __typename?: 'IdentityJwkCacheMutation'
    /** Purge all JWK cache entries */
    purge: Scalars['Boolean']
}

export type IdentityJwkCacheQuery = {
    __typename?: 'IdentityJwkCacheQuery'
    /** Get all current cache entries from JWK cache */
    entries: Array<JwkCacheEntry>
}

export type IdentityMutation = {
    __typename?: 'IdentityMutation'
    accessType: IdentityAccessTypeMutation
    brand: IdentityBrandMutation
    company: IdentityCompanyMutation
    idpConfig: IdentityIdpConfigMutation
    idpProfile: IdpProfileMutation
    integration: IntegrationMutation
    jwkCache: IdentityJwkCacheMutation
    resourceType: IdentityResourceTypeMutation
    scope: IdentityScopeMutation
    signupCode: IdentitySignupCodeMutation
    subscription: IdentitySubscriptionMutation
    user: IdentityUserMutation
    /** Used to update attributes attached to this user (e.g. timezone, locale,...). Not to be confused with with attributes coming from IDP information. */
    userAttributes: UserAttributeMutation
}

export type IdentityQuery = {
    __typename?: 'IdentityQuery'
    accessType: IdentityAccessTypeQuery
    brand: IdentityBrandQuery
    company: IdentityCompanyQuery
    idpConfig: IdentityIdpConfigQuery
    jwkCache: IdentityJwkCacheQuery
    /** Returns authenticated user */
    me: User
    partner: PartnerQuery
    /** Used to query for referral information */
    referrals: IdentityReferralsQuery
    resourceType: IdentityResourceTypeQuery
    scope: IdentityScopeQuery
    signupCode: IdentitySignupCodeQuery
    subscription: IdentitySubscriptionQuery
    user: IdentityUserQuery
    /** Used to query attributes attached to this user (e.g. timezone, locale,...). Not to be confused with with attributes coming from IDP information (see 'me' query). */
    userAttributes: UserAttributeQuery
    userGroups: IdentityUserGroupQuery
}

export type IdentityReferralsQuery = {
    __typename?: 'IdentityReferralsQuery'
    /** Retrieve subscriptions and signup codes for a users company */
    subscriptionGroupCodes: SubscriptionGroupCodesResponse
}

export type IdentityResourceTypeMutation = {
    __typename?: 'IdentityResourceTypeMutation'
    /** Create a new resource type from the given input */
    create?: Maybe<Scalars['String']>
    /** Tries to delete existing resource type by name */
    tryDelete: Scalars['Boolean']
}

export type IdentityResourceTypeMutationCreateArgs = {
    input: ResourceTypeInput
}

export type IdentityResourceTypeMutationTryDeleteArgs = {
    name: Scalars['String']
}

export type IdentityResourceTypeQuery = {
    __typename?: 'IdentityResourceTypeQuery'
    /** Returns all resource types with optional pagination */
    getAll: ResourceTypePage
}

export type IdentityResourceTypeQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentityScopeMutation = {
    __typename?: 'IdentityScopeMutation'
    /** Create a new scope from the given input */
    create: Scope
    /** Tries to delete existing scope by id */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing scope by id and provided input */
    tryUpdate?: Maybe<Scope>
}

export type IdentityScopeMutationCreateArgs = {
    input: ScopeInputGraphQlInput
}

export type IdentityScopeMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentityScopeMutationTryUpdateArgs = {
    id: Scalars['UUID']
    input: ScopeInputGraphQlInput
}

export type IdentityScopeQuery = {
    __typename?: 'IdentityScopeQuery'
    /** Returns all scopes with optional pagination and filters */
    getAll: ScopePage
    /** Tries to fetch existing scope by id */
    tryGet?: Maybe<Scope>
}

export type IdentityScopeQueryGetAllArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    subscriptionId?: InputMaybe<Scalars['UUID']>
}

export type IdentityScopeQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentitySignupCodeMutation = {
    __typename?: 'IdentitySignupCodeMutation'
    /** Create a new signup code from the given input */
    create: SignupCode
    /** Tries to delete existing signup code by name */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing signup code by name and provided input */
    tryUpdate?: Maybe<SignupCode>
}

export type IdentitySignupCodeMutationCreateArgs = {
    input: SignupCodeInput
}

export type IdentitySignupCodeMutationTryDeleteArgs = {
    signupCode: Scalars['String']
}

export type IdentitySignupCodeMutationTryUpdateArgs = {
    input: SignupCodeInput
    signupCode: Scalars['String']
}

export type IdentitySignupCodeQuery = {
    __typename?: 'IdentitySignupCodeQuery'
    /** Returns all signup codes with optional pagination */
    getAll: SignupCodePage
    /** Tries to fetch existing signup code by name */
    tryGet?: Maybe<SignupCode>
}

export type IdentitySignupCodeQueryGetAllArgs = {
    filters?: InputMaybe<SignupCodeFiltersInput>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentitySignupCodeQueryTryGetArgs = {
    signupCode: Scalars['String']
}

export type IdentitySubscriptionMutation = {
    __typename?: 'IdentitySubscriptionMutation'
    /** Create a new subscription from the given input */
    create: UserSubscription
    /** Sets the provided subscription as the default (will disable isDefault on all other subscriptions) */
    makeDefault: Scalars['UUID']
    /** Sets the isDefault flag if possible, returning the value of the flag (unchanged if it cannot be changed) */
    setDefault: Scalars['Boolean']
    /** Tries to delete existing subscription by id */
    tryDelete: Scalars['Boolean']
    /** Tries to update existing subscription by id and provided input */
    tryUpdate?: Maybe<UserSubscription>
}

export type IdentitySubscriptionMutationCreateArgs = {
    input: SubscriptionInputGraphQlInput
}

export type IdentitySubscriptionMutationMakeDefaultArgs = {
    id: Scalars['UUID']
}

export type IdentitySubscriptionMutationSetDefaultArgs = {
    id: Scalars['UUID']
    isDefault: Scalars['Boolean']
}

export type IdentitySubscriptionMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentitySubscriptionMutationTryUpdateArgs = {
    id: Scalars['UUID']
    input: SubscriptionInputGraphQlInput
}

export type IdentitySubscriptionQuery = {
    __typename?: 'IdentitySubscriptionQuery'
    /** Returns all subscriptions with optional pagination */
    getAll: SubscriptionPage
    /** Tries to fetch existing subscription by id */
    tryGet?: Maybe<UserSubscription>
}

export type IdentitySubscriptionQueryGetAllArgs = {
    filters?: InputMaybe<SubscriptionFiltersInput>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentitySubscriptionQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentityUserAttribute = {
    __typename?: 'IdentityUserAttribute'
    name: Scalars['String']
    userId: Scalars['UUID']
    value: Scalars['String']
}

export type IdentityUserGroupQuery = {
    __typename?: 'IdentityUserGroupQuery'
    /** Returns the list of user groups with associated auth roles which can be assigned to a user. */
    getUserGroups: Array<UserGroup>
}

export type IdentityUserMutation = {
    __typename?: 'IdentityUserMutation'
    /** Accept the Terms of Service for the authenticated user. Returns the date and time the user accepted the ToS. If the user has already accepted the ToS, returns the date and time the user accepted the ToS previously. */
    acceptToS: WhenToSAccepted
    /** Adds user to a Thrive group */
    addUserToGroup: Scalars['Boolean']
    /** Change an attribute of an existing user */
    changeAttributeOfAnExistingUser: Scalars['Boolean']
    /** Change the email address of an existing user */
    changeEmailAddressOfAnExistingUser: Scalars['Boolean']
    /** Change the email address of an existing user when a conflicting user exists */
    changeEmailAddressOfAnExistingUserWithConflict: Scalars['Boolean']
    /** Change the email address of many existing users and handle conflicts appropriately */
    changeEmailAddressOfExistingUsersWithConflict: Array<EmailChangeOutput>
    /** Change a subscription of an existing user */
    changeSubscriptionOfAnExistingUser: Scalars['Boolean']
    /** Create a new partner user for the given input */
    createPartnerUser: IdpProfile
    /** Delete a group of users. */
    deleteUsers: Scalars['Boolean']
    /** Deprovision a group of users */
    deprovisionUsers: DeprovisionResponse
    /** Get a magic link for a user */
    getMagicLink: PrivateMagicLinkResponse
    impersonate: TokenResponse
    /** Initiate the deletion process for the authenticated user's account, using the supplied email as a point of contact.  Email must match hash, or for users with no email hash, email must not match any other users.  Throws an error if email doesn't match or user cannot be found.  Returns 'true' if the delete initiation was successful, or 'false' if the initiation failed given a valid user. */
    initiateDeletion: Scalars['Boolean']
    /** Request one-time upload URL for Cloudflare Images */
    initiateDirectUpload?: Maybe<Scalars['String']>
    refreshToken: TokenResponse
    /** Remove user from a Thrive group */
    removeUserFromGroup: Scalars['Boolean']
    /** Send referral emails to a list of email addresses */
    sendReferralEmails: ReferralEmailResponse
    /** Tries to delete existing user by id */
    tryDelete: Scalars['Boolean']
    /** Update the verification status of an existing user */
    updateVerificationStatus?: Maybe<User>
    /** Get existing or create new user object within Identity Database based purely on email hash. Will throw an error if subscription associated with given ID couldn't be found. Will TRY to update subscription (depends on is-default flag) if subscription Id is present on fetching */
    upsert: User
    /** Write an event acknowledging that a user referred another user */
    writeReferral: Scalars['Boolean']
}

export type IdentityUserMutationAcceptToSArgs = {
    input?: InputMaybe<AcceptToSInput>
}

export type IdentityUserMutationAddUserToGroupArgs = {
    group: Scalars['UUID']
    userId: Scalars['UUID']
}

export type IdentityUserMutationChangeAttributeOfAnExistingUserArgs = {
    attribute: VisibleProfileAttribute
    userId: Scalars['UUID']
    value: Scalars['String']
}

export type IdentityUserMutationChangeEmailAddressOfAnExistingUserArgs = {
    newEmail: Scalars['String']
    oldEmail: Scalars['String']
}

export type IdentityUserMutationChangeEmailAddressOfAnExistingUserWithConflictArgs =
    {
        newEmail: Scalars['String']
        oldEmail: Scalars['String']
    }

export type IdentityUserMutationChangeEmailAddressOfExistingUsersWithConflictArgs =
    {
        emailChanges: Array<EmailChangeInput>
    }

export type IdentityUserMutationChangeSubscriptionOfAnExistingUserArgs = {
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type IdentityUserMutationCreatePartnerUserArgs = {
    input: PartnerUserInputGraphQlInput
}

export type IdentityUserMutationDeleteUsersArgs = {
    reason: Scalars['String']
    userList?: InputMaybe<Array<Scalars['String']>>
}

export type IdentityUserMutationDeprovisionUsersArgs = {
    attributeName?: InputMaybe<Scalars['String']>
    attributeValue?: InputMaybe<Scalars['String']>
    deprovisionReason: Scalars['String']
    userList?: InputMaybe<Array<Scalars['String']>>
}

export type IdentityUserMutationGetMagicLinkArgs = {
    input: PrivateMagicLinkRequestInput
}

export type IdentityUserMutationImpersonateArgs = {
    attributes?: InputMaybe<Array<UserAttributeInput>>
    companyName: Scalars['String']
    email?: InputMaybe<Scalars['String']>
    firstName?: InputMaybe<Scalars['String']>
    idpId: Scalars['String']
    idpType: IdpType
    ignoreKcUpdate?: InputMaybe<Scalars['Boolean']>
    issuer?: InputMaybe<Scalars['String']>
    lastName?: InputMaybe<Scalars['String']>
    realm?: InputMaybe<Realm>
    userId?: InputMaybe<Scalars['UUID']>
}

export type IdentityUserMutationInitiateDeletionArgs = {
    email: Scalars['String']
}

export type IdentityUserMutationRemoveUserFromGroupArgs = {
    group: Scalars['UUID']
    userId: Scalars['UUID']
}

export type IdentityUserMutationSendReferralEmailsArgs = {
    input: ReferralEmailRequestInput
}

export type IdentityUserMutationTryDeleteArgs = {
    id: Scalars['UUID']
}

export type IdentityUserMutationUpdateVerificationStatusArgs = {
    userId: Scalars['UUID']
    verificationStatus: VerificationStatus
}

export type IdentityUserMutationUpsertArgs = {
    input: UpsertUserInput
}

export type IdentityUserMutationWriteReferralArgs = {
    referringUserId: Scalars['UUID']
}

export type IdentityUserQuery = {
    __typename?: 'IdentityUserQuery'
    /** Tries to fetch existing user by id */
    tryGet?: Maybe<User>
    /** Tries to fetch existing user by email */
    tryGetByEmail?: Maybe<User>
    /** Tries to fetch existing user by idp id, idp type and company name in idp config */
    tryGetByIdpId?: Maybe<User>
    /** Tries to fetch existing user using IDP id and IDP type */
    tryGetByIdpIdAndIdpType?: Maybe<User>
    /** Fetches a user with their idp profiles by user id */
    tryGetUserWithIdpProfiles?: Maybe<User>
    /** Tries to fetch existing user with their idp profiles by email */
    tryGetUserWithIdpProfilesByEmail?: Maybe<User>
    usersForCompany: UserByCompanyPage
    /** Tries to get when Terms of Service was accepted for the current user. If the user has not accepted the Terms of Service, returns null. */
    whenToSAccepted?: Maybe<WhenToSAccepted>
    /** Returns the view we would have of an authenticated user */
    you: User
}

export type IdentityUserQueryTryGetArgs = {
    id: Scalars['UUID']
}

export type IdentityUserQueryTryGetByEmailArgs = {
    email: Scalars['String']
    realm?: InputMaybe<Realm>
}

export type IdentityUserQueryTryGetByIdpIdArgs = {
    idpCompanyName: Scalars['String']
    idpId: Scalars['String']
    idpType: IdpType
}

export type IdentityUserQueryTryGetByIdpIdAndIdpTypeArgs = {
    idpId: Scalars['String']
    idpType: IdpType
    lookupKeycloak: Scalars['Boolean']
}

export type IdentityUserQueryTryGetUserWithIdpProfilesArgs = {
    id: Scalars['UUID']
}

export type IdentityUserQueryTryGetUserWithIdpProfilesByEmailArgs = {
    email: Scalars['String']
    realm?: InputMaybe<Realm>
}

export type IdentityUserQueryUsersForCompanyArgs = {
    companyId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type IdentityUserQueryYouArgs = {
    idpId?: InputMaybe<Scalars['String']>
    idpType?: InputMaybe<IdpType>
    userId: Scalars['UUID']
}

export type IdpConfig = {
    __typename?: 'IdpConfig'
    companyId: Scalars['UUID']
    configuration: Scalars['String']
    emailAllowList: Array<Scalars['String']>
    enabled: Scalars['Boolean']
    id: Scalars['UUID']
    idpCompanyId?: Maybe<Scalars['String']>
    idpType: Scalars['String']
    loginMethod: Scalars['String']
}

export type IdpConfigFiltersInput = {
    companyId?: InputMaybe<Scalars['UUID']>
    idpTypes?: InputMaybe<Array<IdpType>>
}

export type IdpConfigInputGraphQlInput = {
    companyId: Scalars['UUID']
    configuration: Scalars['String']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    enabled?: InputMaybe<Scalars['Boolean']>
    idpCompanyId?: InputMaybe<Scalars['String']>
    idpType: IdpType
    loginMethod: LoginMethod
}

export type IdpConfigPage = {
    __typename?: 'IdpConfigPage'
    items: Array<IdpConfig>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type IdpConfigSetEmailAllowlistInput = {
    emailAllowlist: Array<Scalars['String']>
}

export type IdpIdEntry = {
    __typename?: 'IdpIdEntry'
    idpId?: Maybe<Scalars['String']>
    idpType: IdpType
    source?: Maybe<Scalars['String']>
}

export type IdpProfile = {
    __typename?: 'IdpProfile'
    idpId: Scalars['String']
    idpType: Scalars['String']
    userId: Scalars['UUID']
}

export type IdpProfileMutation = {
    __typename?: 'IdpProfileMutation'
    /** Links authenticated user with defined provider using token in body */
    link: IdpProfile
}

export type IdpProfileMutationLinkArgs = {
    token: Scalars['String']
    type: IdpType
}

export enum IdpType {
    Awscognito = 'awscognito',
    Azuread = 'azuread',
    Genesys = 'genesys',
    GenesysUsEast_1 = 'genesys_us_east_1',
    GenesysUsWest_2 = 'genesys_us_west_2',
    HumanApi = 'human_api',
    KeycloakDirectPassword = 'keycloak_direct_password',
    KeycloakDirectWebauthn = 'keycloak_direct_webauthn',
    KeycloakOktaSso = 'keycloak_okta_sso',
    KeycloakSso = 'keycloak_sso',
    KeycloakTokenExchange = 'keycloak_token_exchange',
    Magiclink = 'magiclink',
    Nice = 'nice',
    Okta = 'okta',
    OktaZp = 'okta_zp',
    Partner = 'partner',
    Slack = 'slack',
    Webex = 'webex',
    WebexTst = 'webex_tst',
    Zoom = 'zoom'
}

export type Image = Frame & {
    __typename?: 'Image'
    _: Scalars['Boolean']
    accentColor?: Maybe<Scalars['Color']>
    createdAt: Scalars['Instant']
    description?: Maybe<Scalars['String']>
    dominantColors: Array<Scalars['Color']>
    id: Scalars['UUID']
    order?: Maybe<Scalars['Int']>
    resets: Array<PersonalizedReset>
    tag?: Maybe<ImageTag>
    /** @deprecated this field is deprecated, replace with urls.xs */
    thumbnailUrl?: Maybe<Scalars['Url']>
    updatedAt: Scalars['Instant']
    /** @deprecated this field is deprecated, replace with urls.xl */
    url: Scalars['Url']
    urls: ImageUrls
    user?: Maybe<ResetApiUser>
}

export type ImageQuery = {
    __typename?: 'ImageQuery'
    personalized: PersonalizedImageQuery
    thrive: ThriveImageQuery
}

export enum ImageTag {
    Animal = 'Animal',
    Family = 'Family',
    Nature = 'Nature'
}

export type ImageUrls = {
    __typename?: 'ImageUrls'
    lg: Scalars['Url']
    md: Scalars['Url']
    sm: Scalars['Url']
    xl: Scalars['Url']
    xs: Scalars['Url']
}

export type ImagesMutation = {
    __typename?: 'ImagesMutation'
    personalized: PersonalizedImagesMutation
}

export type InAppMessageDto = {
    __typename?: 'InAppMessageDTO'
    body: Scalars['String']
    callToActionText?: Maybe<Scalars['String']>
    callToActionUrl?: Maybe<Scalars['String']>
    endDate?: Maybe<Scalars['ISODate']>
    excludedSubscriptions: Array<Scalars['UUID']>
    eyebrowText?: Maybe<Scalars['String']>
    header: Scalars['String']
    image?: Maybe<UploadFileDto>
    imageUrl?: Maybe<Scalars['String']>
    includedSubscriptions: Array<Scalars['UUID']>
    isUnrestricted?: Maybe<Scalars['Boolean']>
    locale?: Maybe<Scalars['String']>
    page: InAppMessagePageDto
    startDate?: Maybe<Scalars['ISODate']>
    type: InAppMessageTypeDto
    uuid: Scalars['String']
}

export enum InAppMessagePageDto {
    Challenges = 'CHALLENGES',
    Guide = 'GUIDE',
    Insights = 'INSIGHTS',
    Journeys = 'JOURNEYS',
    Learn = 'LEARN',
    Pulse = 'PULSE',
    Reset = 'RESET'
}

export enum InAppMessageTypeDto {
    Banner = 'BANNER',
    ImageBanner = 'IMAGE_BANNER',
    PersonalizedReset = 'PERSONALIZED_RESET',
    UnknownValue = 'UNKNOWN_VALUE'
}

export type InChallengeData = {
    __typename?: 'InChallengeData'
    challengeDay: Scalars['Int']
    challengeId: Scalars['UUID']
    challengeType: InChallengeType
}

export type InChallengeDataInput = {
    challengeDay: Scalars['Int']
    challengeId: Scalars['UUID']
    challengeType: InChallengeType
}

export type InChallengeGoalReminder = {
    __typename?: 'InChallengeGoalReminder'
    challenges: Array<InChallengeData>
}

export enum InChallengeType {
    Biotype = 'BIOTYPE',
    Company = 'COMPANY',
    Group = 'GROUP',
    Journey = 'JOURNEY',
    Personal = 'PERSONAL',
    Prize = 'PRIZE'
}

export type InjectQuery = {
    __typename?: 'InjectQuery'
    /** Mutation to generate a TMobile shaped contact event on Firebase DB (on default Thrive company with id: da4d10d3-6b4b-4ee6-8a9f-521805ad89f4). It does it async and completes in 10 seconds. */
    injectTMobileInboundContact: Scalars['Boolean']
    /** Mutation to generate a TMobile shaped READY_FOR_RESET event on Firebase DB (on default Thrive company with id: da4d10d3-6b4b-4ee6-8a9f-521805ad89f4). */
    injectTMobileWaitingForReset: Scalars['Boolean']
}

export type InjectQueryInjectTMobileInboundContactArgs = {
    agentId: Scalars['String']
    companyId?: InputMaybe<Scalars['UUID']>
}

export type InjectQueryInjectTMobileWaitingForResetArgs = {
    agentId: Scalars['String']
}

export type InputFeedbackInput = {
    answer: ContentFeedback
    question: Scalars['String']
}

export type InsightsMutation = {
    __typename?: 'InsightsMutation'
    /** Update the last time a user played the tutorial to now */
    setTutorialLastPlayedToNow?: Maybe<TutorialConfig>
}

export type InsightsQuery = {
    __typename?: 'InsightsQuery'
    /** Get the given user's changes in a dimension over a given date range */
    adminDimensionTrends?: Maybe<Array<DimensionTrendsDto>>
    /** Get assessment statistics for the given time range and interval */
    assessmentStats?: Maybe<Array<AssessmentStats>>
    /** Get total completed assessments over the given range for survey dimensions */
    completedAssessments: Scalars['Int']
    /** Get changes in a dimension over a given date range */
    dimensionTrends?: Maybe<Array<DimensionTrendsDto>>
    /** Get risk cohort statistics over the specified range */
    riskCohortStatistics: Array<RiskCohortStats>
    /** Get a users trend highlights */
    trendHighlights: TrendHighlights
    /** Get user checkin maturity status, determines whether the user can see their individual dashboard and - if they can't - how many responses are left until their account is considered mature */
    userCheckinMaturity?: Maybe<UserCheckinMaturity>
    /** Get a users tutorial config */
    userTutorialConfig?: Maybe<TutorialConfig>
}

export type InsightsQueryAdminDimensionTrendsArgs = {
    companyId: Scalars['UUID']
    dimensions: Array<Surveys>
    userId: Scalars['UUID']
}

export type InsightsQueryAssessmentStatsArgs = {
    dashboardViewType?: InputMaybe<DashboardViewType>
    dimensions: Array<Surveys>
    intervalType: ReportInterval
}

export type InsightsQueryCompletedAssessmentsArgs = {
    dashboardViewType?: InputMaybe<DashboardViewType>
    dimensions?: InputMaybe<Array<Surveys>>
}

export type InsightsQueryDimensionTrendsArgs = {
    dimensions: Array<Surveys>
}

export type InsightsQueryRiskCohortStatisticsArgs = {
    dimension: Surveys
}

export type InsightsQueryTrendHighlightsArgs = {
    dimensions: Array<Surveys>
}

export type IntakeResult = {
    __typename?: 'IntakeResult'
    waterIntake: WaterIntake
}

export type IntegrationMutation = {
    __typename?: 'IntegrationMutation'
    genesys: GenesysIntegrationMutation
    genesysUsEast1: GenesysUsEast1IntegrationMutation
    genesysUsWest2: GenesysUsWest2IntegrationMutation
    keycloakDirectPassword: KeycloakDirectPasswordIntegrationMutation
    keycloakOktaSso: KeycloakOktaSsoIntegrationMutation
    keycloakSso: KeycloakSsoIntegrationMutation
    magicLink: MagicLinkIntegrationMutation
    msTeams: MsTeamsIntegrationMutation
    nice: NiceIntegrationMutation
    oktaSso: OktaIntegrationMutation
    partner: PartnerIntegrationMutation
    slack: SlackIntegrationMutation
    webex: WebexIntegrationMutation
    webexTst: WebexTstIntegrationMutation
}

export type Intention = {
    __typename?: 'Intention'
    createdAt: Scalars['Instant']
    /** The id of this intention */
    id: Scalars['UUID']
    /** The intention string */
    intention?: Maybe<Scalars['String']>
    /** The journey id the intention is associated with */
    journeyIds?: Maybe<Array<Scalars['UUID']>>
    journeys: Array<Maybe<Journey>>
    updatedAt: Scalars['Instant']
    /** The id of the user */
    userId: Scalars['UUID']
}

export type IntentionSettingMutation = {
    __typename?: 'IntentionSettingMutation'
    /** Send a confirmation to a user's chat confirming intention selection */
    sendIntentionSelectedConfirmation: TeamsSendIntentionSelectedConfirmationResult
}

export type IntentionToJourneysResponse = {
    __typename?: 'IntentionToJourneysResponse'
    journeyIds?: Maybe<Array<Scalars['UUID']>>
    journeys: Array<Maybe<Journey>>
}

export type IntentionToJourneysResponseV2 =
    | IntentionToJourneysV2Error
    | IntentionToJourneysV2Result

export type IntentionToJourneysV2Error = {
    __typename?: 'IntentionToJourneysV2Error'
    inappropriateContent?: Maybe<IntentionValidation>
    type: IntentionToJourneysV2ErrorType
}

export enum IntentionToJourneysV2ErrorType {
    InappropriateContentError = 'INAPPROPRIATE_CONTENT_ERROR',
    IntentionLengthError = 'INTENTION_LENGTH_ERROR'
}

export type IntentionToJourneysV2Result = {
    __typename?: 'IntentionToJourneysV2Result'
    journeyIds?: Maybe<Array<Scalars['UUID']>>
    journeys: Array<Maybe<Journey>>
}

export type IntentionValidation = {
    __typename?: 'IntentionValidation'
    items: Array<IntentionValidationItem>
}

export enum IntentionValidationCategory {
    Hate = 'HATE',
    SelfHarm = 'SELF_HARM',
    Sexual = 'SEXUAL',
    Violence = 'VIOLENCE'
}

export type IntentionValidationItem = {
    __typename?: 'IntentionValidationItem'
    /** Category for which the validation has found a match */
    category: IntentionValidationCategory
    /** Severity of the match. Higher is more severe */
    severity: Scalars['Int']
}

export enum InteractionType {
    Chat = 'CHAT',
    Email = 'EMAIL',
    Message = 'MESSAGE',
    Voice = 'VOICE',
    WorkItem = 'WORK_ITEM'
}

export type InvalidAction = {
    __typename?: 'InvalidAction'
    message: Scalars['String']
}

export type InvalidField = {
    __typename?: 'InvalidField'
    field: InvalidFieldIdentifier
    message: Scalars['String']
}

export enum InvalidFieldIdentifier {
    Id = 'ID',
    Name = 'NAME'
}

export type InvalidInput = {
    __typename?: 'InvalidInput'
    invalidFields: Array<InvalidField>
}

export type JoinChallengeSucceeded = {
    __typename?: 'JoinChallengeSucceeded'
    challengeActivity: UnifiedUserChallengeActivity
}

export type JournalMutation = {
    __typename?: 'JournalMutation'
    saveMySurveyJournal?: Maybe<MySurveyJournal>
}

export type JournalMutationSaveMySurveyJournalArgs = {
    input: MySurveyJournalInput
}

export type JournalQuery = {
    __typename?: 'JournalQuery'
    getMySurveyJournal?: Maybe<MySurveyJournal>
    getMySurveyJournals: MySurveyJournalPage
}

export type JournalQueryGetMySurveyJournalArgs = {
    id: Scalars['UUID']
}

export type JournalQueryGetMySurveyJournalsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type Journey = {
    __typename?: 'Journey'
    colorPalette: JourneyColorPalette
    coreType?: Maybe<JourneyCoreType>
    createdAt: Scalars['Instant']
    description?: Maybe<Scalars['String']>
    emoji?: Maybe<Scalars['String']>
    enabled: Scalars['Boolean']
    hasMicrosteps: Scalars['Boolean']
    id: Scalars['ID']
    /** @deprecated Use imageUrl */
    image?: Maybe<Scalars['String']>
    imageUrl: Scalars['String']
    /** @deprecated Use getLatestUserProgress */
    isSelected: Scalars['Boolean']
    /** Total number of levels in the journey */
    journeyLevels?: Maybe<Scalars['Int']>
    /** @deprecated Use getLatestUserProgress */
    journeyProgress?: Maybe<JourneyProgress>
    journeyProgressForGivenUser?: Maybe<JourneyProgress>
    name: Scalars['String']
    preferredOrder?: Maybe<Scalars['Int']>
    shortName?: Maybe<Scalars['String']>
    thumbnailUrl: Scalars['String']
    topic?: Maybe<Scalars['String']>
    updatedAt: Scalars['Instant']
}

export type JourneyJourneyProgressForGivenUserArgs = {
    userId: Scalars['UUID']
}

export type JourneyColorPalette = {
    __typename?: 'JourneyColorPalette'
    contrastText: Scalars['Color']
    dark: Scalars['Color']
    light: Scalars['Color']
    main: Scalars['Color']
}

export enum JourneyCoreType {
    Connect = 'Connect',
    Focus = 'Focus',
    Food = 'Food',
    Money = 'Money',
    Move = 'Move',
    Recharge = 'Recharge',
    StressManagement = 'Stress_Management'
}

export type JourneyDailyStep = {
    __typename?: 'JourneyDailyStep'
    contentStatus?: Maybe<LearnContentStatus>
    contentType: LearnContentStatusType
    day: Scalars['Int']
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    id: Scalars['ID']
    iframeUrl: Scalars['String']
    imageUrl: Scalars['String']
    isCompleted: Scalars['Boolean']
    journey?: Maybe<Journey>
    level: Scalars['Int']
    microsteps: Array<GraphQlMicrostep>
    /** MicrostepsV2 filter by subscriptionId */
    microstepsV2: Array<MicrostepV2>
    /** calculated based on the 'order'; example 'Day 3' */
    name: Scalars['String']
    title: Scalars['String']
    videoUrl: Scalars['String']
}

export type JourneyDailyStepIframeUrlArgs = {
    userId: Scalars['UUID']
    utmSource: Scalars['String']
}

export type JourneyDailyStepContentStatusInput = {
    /** JourneyDailyStep id */
    contentId: Scalars['String']
    /** JourneyDailyStep progress in seconds */
    progressInSeconds?: InputMaybe<Scalars['Int']>
    progressType: LearnContentStatusProgressType
    totalInSeconds?: InputMaybe<Scalars['Int']>
}

export type JourneyDailyStepV2 = {
    __typename?: 'JourneyDailyStepV2'
    contentType: LearnContentStatusType
    day: Scalars['Int']
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    id: Scalars['UUID']
    iframeUrl: Scalars['String']
    imageUrl: Scalars['String']
    level: Scalars['Int']
    microsteps: Array<GraphQlMicrostep>
    /** calculated based on the 'order'; example 'Day 3' */
    name: Scalars['String']
    title: Scalars['String']
    videoUrl: Scalars['String']
}

export type JourneyDailyStepV2IframeUrlArgs = {
    userId: Scalars['UUID']
    utmSource: Scalars['String']
}

export type JourneyDailyStepVideoMetadata = {
    __typename?: 'JourneyDailyStepVideoMetadata'
    alternativeText?: Maybe<Scalars['String']>
    caption?: Maybe<Scalars['String']>
    day?: Maybe<Scalars['Int']>
    description?: Maybe<Scalars['String']>
    id?: Maybe<Scalars['Int']>
    journeyId?: Maybe<Scalars['Int']>
    journeyName?: Maybe<Scalars['String']>
    journeyUuid?: Maybe<Scalars['UUID']>
    level?: Maybe<Scalars['Int']>
    name: Scalars['String']
    thumbnailUrl?: Maybe<Scalars['String']>
    title: Scalars['String']
    url: Scalars['String']
    uuid?: Maybe<Scalars['UUID']>
}

export type JourneyLevel = {
    __typename?: 'JourneyLevel'
    /** collection of daily steps, usually 7 */
    dailySteps: Array<JourneyDailyStep>
    /** Returns JourneyLevelCompletionFeedback from CMS when level is completed, otherwise returns null.Feedback question response is populated if user submitted their response */
    feedback?: Maybe<JourneyLevelCompletionFeedback>
    journey?: Maybe<Journey>
    journeyLevelProgress: JourneyLevelProgress
    level: Scalars['Int']
    /** calculated based on the 'order'; example 'Level 2' */
    name: Scalars['String']
}

export type JourneyLevelCompletionFeedback = {
    __typename?: 'JourneyLevelCompletionFeedback'
    isSkipped?: Maybe<Scalars['Boolean']>
    isSubmitted?: Maybe<Scalars['Boolean']>
    questions: Array<JourneyLevelCompletionFeedbackQuestion>
    uuid: Scalars['UUID']
}

export type JourneyLevelCompletionFeedbackQuestion = {
    __typename?: 'JourneyLevelCompletionFeedbackQuestion'
    question: Scalars['String']
    /** @deprecated No longer supported */
    response?: Maybe<FeedbackQuestionResponse>
    type: FeedbackQuestionType
}

export type JourneyLevelProgress = {
    __typename?: 'JourneyLevelProgress'
    isCompleted: Scalars['Boolean']
    numberOfCompletedSteps: Scalars['Int']
    /** @deprecated Use numberOfCompletedSteps and totalNumberOfSteps instead */
    progressInPercent: Scalars['Int']
    totalNumberOfSteps: Scalars['Int']
}

export type JourneyLevelV2 = {
    __typename?: 'JourneyLevelV2'
    completedDailySteps: Array<JourneyDailyStepV2>
    /** collection of daily steps, usually 7 */
    dailySteps: Array<JourneyDailyStepV2>
    /** Returns JourneyLevelCompletionFeedback from CMS when level is completed, otherwise returns null.Feedback question response is populated if user submitted their response */
    feedback?: Maybe<JourneyLevelCompletionFeedback>
    journeyId: Scalars['UUID']
    journeyLevelProgress: JourneyLevelProgress
    level: Scalars['Int']
    /** calculated based on the 'order'; example 'Level 2' */
    name: Scalars['String']
}

export type JourneyMutation = {
    __typename?: 'JourneyMutation'
    /** Completes journey for the user */
    completeJourneyForUser?: Maybe<Array<LearnContentStatus>>
    /**
     * Restart Journey
     * @deprecated Use restartJourneyV2Admin instead
     */
    restartJourney: Scalars['String']
    /** Restart journey for the user */
    restartJourneyV2: JourneyRestartResponse
    /** Admin endpoint for restarting the journey for a user */
    restartJourneyV2Admin: JourneyRestartResponse
    /** Save JourneyLevelCompletionFeedback for a journey and level */
    saveJourneyLevelCompletionFeedback: Scalars['String']
    /** User mutation to select one of the Core Journeys, userId is taken from the jwt token */
    selectCoreJourney: Scalars['String']
    /** User mutation to select one of the Core Journeys, userId is passed as a parameter (for Chat) */
    selectCoreJourneyForGivenUser: Scalars['String']
    /** Skips JourneyLevelCompletionFeedback (saves user skip action) for a journey and level */
    skipJourneyLevelCompletionFeedback: Scalars['String']
    /** User mutation to unselect user's Core journey */
    unselectCoreJourneyForGivenUser: Scalars['String']
}

export type JourneyMutationCompleteJourneyForUserArgs = {
    companyId: Scalars['UUID']
    journeyId: Scalars['UUID']
    locale: Scalars['String']
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
    zoneId: Scalars['String']
}

export type JourneyMutationRestartJourneyArgs = {
    journeyId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type JourneyMutationRestartJourneyV2Args = {
    journeyId: Scalars['UUID']
}

export type JourneyMutationRestartJourneyV2AdminArgs = {
    journeyId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type JourneyMutationSaveJourneyLevelCompletionFeedbackArgs = {
    feedback?: InputMaybe<Array<InputFeedbackInput>>
    journeyId: Scalars['UUID']
    level: Scalars['Int']
}

export type JourneyMutationSelectCoreJourneyArgs = {
    journeyId: Scalars['UUID']
}

export type JourneyMutationSelectCoreJourneyForGivenUserArgs = {
    journeyId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type JourneyMutationSkipJourneyLevelCompletionFeedbackArgs = {
    journeyId: Scalars['UUID']
    level: Scalars['Int']
}

export type JourneyMutationUnselectCoreJourneyForGivenUserArgs = {
    userId: Scalars['UUID']
}

export type JourneyProgress = {
    __typename?: 'JourneyProgress'
    currentLevel?: Maybe<JourneyLevel>
    currentStep?: Maybe<JourneyDailyStep>
    dailySteps: Array<JourneyDailyStep>
    isCompleted: Scalars['Boolean']
    isTodaysProgress: Scalars['Boolean']
    journey?: Maybe<Journey>
    journeyLevel?: Maybe<JourneyLevel>
    stepCompletedToday?: Maybe<JourneyDailyStep>
}

export type JourneyProgressJourneyLevelArgs = {
    level: Scalars['Int']
}

export type JourneyProgressV2 = {
    __typename?: 'JourneyProgressV2'
    completedProgress: Array<UserJourneyProgress>
    currentLevel?: Maybe<JourneyLevelV2>
    currentStep?: Maybe<JourneyDailyStepV2>
    dailySteps?: Maybe<Array<JourneyDailyStepV2>>
    isCompleted: Scalars['Boolean']
    isTodaysProgress: Scalars['Boolean']
    journey?: Maybe<Journey>
    stepCompletedToday?: Maybe<UserJourneyProgress>
}

export type JourneyQuery = {
    __typename?: 'JourneyQuery'
    /** Get all Journey Daily Step */
    getAllJourneyDailySteps: Array<JourneyDailyStep>
    /** Core Journeys available to a company */
    getCoreJourneys: Array<Journey>
    /** Journey Daily Step by uuid */
    getJourneyDailyStep?: Maybe<JourneyDailyStep>
    /** Journey Daily Step videos */
    getJourneyDailyStepVideos: Array<JourneyDailyStepVideoMetadata>
    /** User completed journey progress V2 */
    getLatestUserProgressV2?: Maybe<UserJourneyProgressV2>
    /** Returns the list of uncompleted journeys */
    getUncompletedJourneys: Array<Scalars['UUID']>
    /** Returns the list of uncompleted journeys */
    getUncompletedJourneysAdmin: Array<Scalars['UUID']>
    /** Returns the list of uncompleted journeys with journey progress details */
    getUncompletedJourneysProgress: Array<UncompletedJourneyProgress>
    /** Individual Journey information */
    journey?: Maybe<Journey>
    /** Individual Journey information */
    journeys: Array<Journey>
    /** A users preferred journeys and the journeys available to them. */
    myJourneyPreferences: UserJourneys
    /** A users preferred journeys and the journeys available to them for a given user id. */
    myJourneyPreferencesForGivenUserId: UserJourneys
}

export type JourneyQueryGetCoreJourneysArgs = {
    adaptToIntentions?: InputMaybe<Scalars['Boolean']>
}

export type JourneyQueryGetJourneyDailyStepArgs = {
    contentId: Scalars['UUID']
}

export type JourneyQueryGetLatestUserProgressV2Args = {
    userId?: InputMaybe<Scalars['UUID']>
}

export type JourneyQueryGetUncompletedJourneysAdminArgs = {
    userId: Scalars['UUID']
}

export type JourneyQueryJourneyArgs = {
    id: Scalars['ID']
}

export type JourneyQueryJourneysArgs = {
    ids: Array<Scalars['ID']>
}

export type JourneyQueryMyJourneyPreferencesForGivenUserIdArgs = {
    userID: Scalars['UUID']
}

export type JourneyRestart = {
    __typename?: 'JourneyRestart'
    createdAt: Scalars['Instant']
    journeyId: Scalars['UUID']
    restartCount: Scalars['Int']
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type JourneyRestartError = {
    __typename?: 'JourneyRestartError'
    error: RestartError
}

export type JourneyRestartResponse = JourneyRestartError | JourneyRestartResult

export type JourneyRestartResult = {
    __typename?: 'JourneyRestartResult'
    journeyRestart: JourneyRestart
}

export type JwkCacheEntry = {
    __typename?: 'JwkCacheEntry'
    algoName?: Maybe<Scalars['String']>
    e?: Maybe<Scalars['String']>
    issuer: Scalars['String']
    jwkUrl: Scalars['Url']
    kid?: Maybe<Scalars['String']>
    n?: Maybe<Scalars['String']>
}

export type KeycloakDirectPasswordIntegrationMutation = {
    __typename?: 'KeycloakDirectPasswordIntegrationMutation'
    /** Disables Keycloak Direct Password integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Keycloak Direct Password integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything. */
    enable: Scalars['Boolean']
}

export type KeycloakDirectPasswordIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type KeycloakDirectPasswordIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
}

export type KeycloakOktaSsoIntegrationMutation = {
    __typename?: 'KeycloakOktaSsoIntegrationMutation'
    /** Disables Keycloak Okta SSO integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Keycloak Okta SSO integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything. IdpHint is a unique identifier of SSO connection in the Keycloak */
    enable: Scalars['Boolean']
}

export type KeycloakOktaSsoIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type KeycloakOktaSsoIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    idpHint: Scalars['String']
}

export type KeycloakSsoIntegrationMutation = {
    __typename?: 'KeycloakSsoIntegrationMutation'
    /** Disables Keycloak SSO integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Keycloak SSO integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything. IdpHint is a unique identifier of SSO connection in the Keycloak */
    enable: Scalars['Boolean']
}

export type KeycloakSsoIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type KeycloakSsoIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    idpHint: Scalars['String']
}

export type Leaderboard = {
    __typename?: 'Leaderboard'
    rankedList: Array<ScoreRanked>
    /** The requester entity ranked position */
    requesterRank: Scalars['Int']
    totalEntries: Scalars['Int']
}

export type LearnContentBookmark = {
    __typename?: 'LearnContentBookmark'
    bookmarked: Scalars['Boolean']
    contentId: Scalars['String']
    contentSubtype?: Maybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    createdAt: Scalars['Instant']
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type LearnContentByTopicOutput = {
    __typename?: 'LearnContentByTopicOutput'
    itemPage: LearnContentItemPage
    topic: Topic
}

export type LearnContentFeedback = {
    __typename?: 'LearnContentFeedback'
    contentId: Scalars['String']
    contentSubtype?: Maybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    feedbackAnswer: ContentFeedback
    feedbackQuestion: Scalars['String']
    openAnswer?: Maybe<Scalars['String']>
    openQuestion?: Maybe<Scalars['String']>
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type LearnContentItem = {
    __typename?: 'LearnContentItem'
    contentStatus?: Maybe<LearnContentStatus>
    contentSubtype: LearnContentStatusSubtype
    contentType: LearnContentStatusType
    durationInSeconds?: Maybe<Scalars['Int']>
    iconName?: Maybe<Scalars['String']>
    id: Scalars['ID']
    imageUrl: Scalars['String']
    parent?: Maybe<LearnContentParent>
    title: Scalars['String']
}

export type LearnContentItemPage = {
    __typename?: 'LearnContentItemPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<LearnContentItem>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type LearnContentLikes = {
    __typename?: 'LearnContentLikes'
    contentId: Scalars['String']
    contentSubtype?: Maybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    createdAt: Scalars['Instant']
    likes: Scalars['Int']
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type LearnContentParent =
    | AudioChapterPartParent
    | CourseModuleParent
    | PodcastEpisodeParent

/** Represents progress of a content */
export type LearnContentProgress = {
    __typename?: 'LearnContentProgress'
    /** Calculated, returns true if content is completed or if both numberOfCompletedItems and totalNumberOfItems is 0 */
    isCompleted: Scalars['Boolean']
    numberOfCompletedItems: Scalars['Int']
    /** Calculated, returns percentage of completion */
    progressInPercent: Scalars['Int']
    totalNumberOfItems: Scalars['Int']
}

export type LearnContentStatus = {
    __typename?: 'LearnContentStatus'
    contentId: Scalars['String']
    createdAt: Scalars['Instant']
    hasCompleted: Scalars['Boolean']
    inProgress: Scalars['Boolean']
    key: LearnContentStatusKey
    progressInPercent: Scalars['Int']
    progressInSeconds?: Maybe<Scalars['Int']>
    progressInSecondsOrDefault: Scalars['Int']
    progressType: LearnContentStatusProgressType
    subscriptionId?: Maybe<Scalars['UUID']>
    subtype?: Maybe<LearnContentStatusSubtype>
    totalInSeconds?: Maybe<Scalars['Int']>
    totalInSecondsOrDefault: Scalars['Int']
    type: LearnContentStatusType
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type LearnContentStatusKey = {
    __typename?: 'LearnContentStatusKey'
    contentId: Scalars['String']
    type: LearnContentStatusType
    userId: Scalars['UUID']
}

export enum LearnContentStatusProgressType {
    Finished = 'Finished',
    InProgress = 'InProgress',
    Opened = 'Opened'
}

export enum LearnContentStatusSubtype {
    Audio = 'Audio',
    Text = 'Text',
    Video = 'Video'
}

export enum LearnContentStatusType {
    AcuteCareResource = 'AcuteCareResource',
    Article = 'Article',
    AudioBookChapterPart = 'AudioBookChapterPart',
    Course = 'Course',
    CourseResource = 'CourseResource',
    JourneyDailyStep = 'JourneyDailyStep',
    Lesson = 'Lesson',
    Microstep = 'Microstep',
    Module = 'Module',
    Podcast = 'Podcast',
    PodcastEpisode = 'PodcastEpisode',
    Recipe = 'Recipe',
    Reset = 'Reset',
    RoleModel = 'RoleModel',
    WatchMeThrive = 'WatchMeThrive',
    Webinar = 'Webinar'
}

export type LearnFeaturedContent = {
    __typename?: 'LearnFeaturedContent'
    featuredArticle?: Maybe<ArticleV2>
    featuredCourse?: Maybe<CourseV2>
    featuredPodcastEpisode?: Maybe<PodcastEpisode>
    id: Scalars['ID']
    isUnrestricted: Scalars['Boolean']
    trendingArticle?: Maybe<ArticleV2>
    trendingCourse?: Maybe<CourseV2>
    trendingPodcastEpisode?: Maybe<PodcastEpisode>
}

export type LearnMutationV2 = CourseMutation &
    ModuleMutation & {
        __typename?: 'LearnMutationV2'
        /** Mutation to create a bookmark, based on userId from context */
        createBookmark: LearnContentBookmark
        /** Mutation to delete a bookmark, based on userId from context */
        deleteBookmark?: Maybe<LearnContentBookmark>
        /** Mutation creating or updating journey daily step status, for a given user. Emits event 'JourneyLevelCompleted'. */
        markAcuteCareResourceAsSeen: LearnContentStatus
        /** Mutation creating or updating course resource status (for course or lesson), based on userId from context */
        markCourseResourceAsCompleted: LearnContentStatus
        /** Mutation creating or updating journey daily step status, based on userId from context. Emits event 'JourneyLevelCompleted'. */
        markJourneyDailyStepAsCompleted: LearnContentStatus
        /** Mutation creating or updating journey daily step status, for a given user. Emits event 'JourneyLevelCompleted'. */
        markJourneyDailyStepAsCompletedForGivenUser: LearnContentStatus
        /** Mutation to register a user to a webinar using webinarId and userId from context */
        registerForWebinar: WebinarRegistrationResponse
        /** Save feedback of any type of content from questions at the end, one positive/negative feedback plus a free text field question */
        saveLearnContentFeedback: LearnContentFeedback
        /** Mutation setting the current journey daily step for a given user. Emits event 'JourneyLevelCompleted' for all steps completed. */
        setCurrentJourneyDailyStepForGivenUser?: Maybe<LearnContentStatus>
        /** Mutation for setting a user's onboarding status. */
        setUserOnboarding?: Maybe<UserOnboarding>
        /** Mutation for setting a user's onboarding assessment. */
        setUserOnboardingAssessment?: Maybe<UserOnboardingAssessment>
        /** Mutation to unmark a daily step for a given user. */
        unmarkJourneyDailyStepAsCompletedForGivenUser?: Maybe<LearnContentStatus>
        /** Mutation creating or updating article status, based on userId from context */
        updateArticleStatus: LearnContentStatus
        /** Mutation creating or updating content feedback, based on userId from context */
        updateContentLikes: LearnContentLikes
        /** Mutation creating or updating JourneyDailyStep status, based on userId from context */
        updateJourneyDailyStepStatus: LearnContentStatus
        /** Mutation creating or updating module status, based on userId from context */
        updateModuleStatus: LearnContentStatus
        /** Mutation creating or updating podcast status, based on userId from context */
        updatePodcastStatus: LearnContentStatus
        /** Mutation to set the attendance of a user to a webinar session id, based on userId from context */
        updateWebinarSessionStatus: Scalars['Boolean']
        /** Mutation creating or updating video status, based on userId from context */
        updateWebinarVideoStatus: LearnContentStatus
    }

export type LearnMutationV2CreateBookmarkArgs = {
    contentId: Scalars['String']
    contentSubtype?: InputMaybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
}

export type LearnMutationV2DeleteBookmarkArgs = {
    contentId: Scalars['String']
    contentSubtype?: InputMaybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
}

export type LearnMutationV2MarkAcuteCareResourceAsSeenArgs = {
    contentId: Scalars['String']
}

export type LearnMutationV2MarkCourseResourceAsCompletedArgs = {
    contentId: Scalars['String']
}

export type LearnMutationV2MarkJourneyDailyStepAsCompletedArgs = {
    contentId: Scalars['String']
}

export type LearnMutationV2MarkJourneyDailyStepAsCompletedForGivenUserArgs = {
    contentId: Scalars['String']
    userId: Scalars['UUID']
}

export type LearnMutationV2RegisterForWebinarArgs = {
    webinarId: Scalars['String']
}

export type LearnMutationV2SaveLearnContentFeedbackArgs = {
    contentId: Scalars['String']
    contentSubtype?: InputMaybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    feedbackAnswer: ContentFeedback
    feedbackQuestion: Scalars['String']
    openAnswer?: InputMaybe<Scalars['String']>
    openQuestion?: InputMaybe<Scalars['String']>
}

export type LearnMutationV2SetCurrentJourneyDailyStepForGivenUserArgs = {
    dailyStepId: Scalars['UUID']
    journeyId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type LearnMutationV2SetUserOnboardingArgs = {
    status: OnboardingAssessmentStatus
    version?: InputMaybe<Scalars['Float']>
}

export type LearnMutationV2SetUserOnboardingAssessmentArgs = {
    assessmentChoices: Scalars['String']
    onboardingId: Scalars['UUID']
}

export type LearnMutationV2UnmarkJourneyDailyStepAsCompletedForGivenUserArgs = {
    contentId: Scalars['String']
    userId: Scalars['UUID']
}

export type LearnMutationV2UpdateArticleStatusArgs = {
    input: ArticleContentStatusInput
}

export type LearnMutationV2UpdateContentLikesArgs = {
    input: LikesInput
}

export type LearnMutationV2UpdateJourneyDailyStepStatusArgs = {
    input: JourneyDailyStepContentStatusInput
}

export type LearnMutationV2UpdateModuleStatusArgs = {
    input: ModuleContentStatusInput
}

export type LearnMutationV2UpdatePodcastStatusArgs = {
    input: PodcastContentStatusInput
}

export type LearnMutationV2UpdateWebinarSessionStatusArgs = {
    sessionId: Scalars['String']
}

export type LearnMutationV2UpdateWebinarVideoStatusArgs = {
    input: WebinarVideoContentStatusInput
}

export type LearnQueryV2 = CourseQuery & {
    __typename?: 'LearnQueryV2'
    /** Get articleV2 by id from CMS cache */
    getArticleV2?: Maybe<ArticleV2>
    /** Get articleV2 by id and subscriptionId from CMS cache for default locale (used by Admin Tool) */
    getArticleV2BySubscription?: Maybe<ArticleV2>
    /** Get articles, returns articles, recipes or role models for a given subscription from CMS */
    getArticlesV2: ArticlePageV2
    /**
     * Get bookmarked items for a user
     * @deprecated No longer supported, replace with /content/thrive/getBookmarkedContent
     */
    getBookmarkedItems: LearnContentItemPage
    /** Get company resource sections by subscription Id */
    getCompanyResourceSectionsV2: CompanyResourceGroupPage
    /** Get course by id */
    getCourse?: Maybe<CourseV2>
    /** Get courses by companyId and subscriptionId from context */
    getCourses: CoursePage
    /** Get featured content from CMS based on subscriptionId */
    getFeaturedContent?: Maybe<LearnFeaturedContent>
    /** Get Latest User Onboarding */
    getLatestUserOnboarding?: Maybe<UserOnboarding>
    /**
     * Get Learn Content by Topic. A topic can have Journey UUID or a Pulse Dimension Type.
     * @deprecated No longer supported, replace with /content/thrive/getContentByTopic
     */
    getLearnContentByTopic: LearnContentByTopicOutput
    /** Get podcasts for Overview page */
    getOverviewPodcasts: PodcastPage
    /** Get podcast by id */
    getPodcast?: Maybe<Podcast>
    /** Get Podcast of the Day for a specific user and date */
    getPodcastOfTheDay?: Maybe<Podcast>
    /** Get podcasts */
    getPodcasts: PodcastPage
    /** Get Pulse Dimensions */
    getPulseDimensions: Array<PulseDimension>
    /** Get recently played Podcast episodes, returns episodes or empty if there are no played episodes */
    getRecentlyPlayedPodcastEpisodes: PodcastEpisodePage
    /** Get Latest User Onboarding assessment */
    getUserOnboardingAssessment?: Maybe<UserOnboardingAssessment>
    /** Get User Onboarding History */
    getUserOnboardingHistory: Array<UserOnboarding>
    /** Get webinar by id */
    getWebinar?: Maybe<Webinar>
    /** Get webinars registered to a user id */
    getWebinars: WebinarPage
    /**
     * Get recommended content by pulse dimensions
     * @deprecated Migrate to Recommendation API, https://thrive-global.atlassian.net/browse/PC-915
     */
    recommendByPulseDimension: RecommendedContentByPulseDimension
}

export type LearnQueryV2GetArticleV2Args = {
    id: Scalars['String']
    localeStr?: InputMaybe<Scalars['String']>
}

export type LearnQueryV2GetArticleV2BySubscriptionArgs = {
    id: Scalars['String']
    subscriptionId: Scalars['UUID']
}

export type LearnQueryV2GetArticlesV2Args = {
    articleTypes?: InputMaybe<Array<ArticleType>>
    contentTypes?: InputMaybe<Array<ArticleContentType>>
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetBookmarkedItemsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetCompanyResourceSectionsV2Args = {
    includeEnglish?: InputMaybe<Scalars['Boolean']>
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetCourseArgs = {
    courseId: Scalars['String']
}

export type LearnQueryV2GetCoursesArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    sort?: InputMaybe<Array<Scalars['String']>>
}

export type LearnQueryV2GetLatestUserOnboardingArgs = {
    version?: InputMaybe<Scalars['Float']>
}

export type LearnQueryV2GetLearnContentByTopicArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    topic: TopicInput
}

export type LearnQueryV2GetOverviewPodcastsArgs = {
    numberOfPodcasts?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetPodcastArgs = {
    podcastId: Scalars['String']
}

export type LearnQueryV2GetPodcastOfTheDayArgs = {
    timezone?: InputMaybe<Scalars['String']>
}

export type LearnQueryV2GetPodcastsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetRecentlyPlayedPodcastEpisodesArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2GetUserOnboardingHistoryArgs = {
    version?: InputMaybe<Scalars['Float']>
}

export type LearnQueryV2GetWebinarArgs = {
    webinarId: Scalars['String']
}

export type LearnQueryV2GetWebinarsArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type LearnQueryV2RecommendByPulseDimensionArgs = {
    limit?: InputMaybe<Scalars['Int']>
    pulseDimensions: Array<Scalars['String']>
    requesterApp?: InputMaybe<Scalars['String']>
}

export type LearnQuote = {
    __typename?: 'LearnQuote'
    author: Scalars['String']
    backgroundColor: Scalars['String']
    backgroundImageUrl: Scalars['String']
    body: Scalars['String']
    date: Scalars['ISODate']
    id: Scalars['ID']
    imageWithTextUrl: Scalars['String']
    textColor: Scalars['String']
    uuid: Scalars['UUID']
}

export type LessonFeedback = {
    __typename?: 'LessonFeedback'
    feedbackQuestion: Scalars['String']
    openQuestion: Scalars['String']
}

export type LessonV2 = {
    __typename?: 'LessonV2'
    courseID?: Maybe<Scalars['String']>
    feedback?: Maybe<LessonFeedback>
    id: Scalars['ID']
    moduleIds: Array<Scalars['ID']>
    modules: Array<ModuleV2>
    order: Scalars['Int']
    /** Course Lesson progress for user, based on userId from context. Checks progress on modules and lesson resources. */
    progress?: Maybe<LearnContentProgress>
    resources: Array<CourseResource>
    title: Scalars['String']
}

export enum LicenseType {
    /** @deprecated Old name */
    Lite = 'lite',
    Standard = 'standard',
    /** @deprecated Another old name */
    ThriveResetForTeams = 'thriveResetForTeams',
    ThriveResetOnly = 'thriveResetOnly'
}

export type LikeMutation = {
    __typename?: 'LikeMutation'
    create: Scalars['Boolean']
    delete: Scalars['Boolean']
}

export type LikeMutationCreateArgs = {
    id: Scalars['UUID']
}

export type LikeMutationDeleteArgs = {
    id: Scalars['UUID']
}

export type LikeQuery = {
    __typename?: 'LikeQuery'
    isLiked: Scalars['Boolean']
}

export type LikeQueryIsLikedArgs = {
    id: Scalars['UUID']
}

export type Likes = {
    __typename?: 'Likes'
    isLiked: Scalars['Boolean']
    numberOfLikes: Scalars['Int']
    totalNumberOfLikes: Scalars['Int']
}

export type LikesInput = {
    contentId: Scalars['String']
    contentSubtype?: InputMaybe<LearnContentStatusSubtype>
    contentType: LearnContentStatusType
    likes: Scalars['Int']
}

export type LinkedAccountDto = {
    __typename?: 'LinkedAccountDTO'
    /** The type of linked account */
    channel: Channel
    /** When this account was created in Pulse */
    createdAt: Scalars['Instant']
    /** If that user has an admin account on the tenant workspace */
    isAdmin: Scalars['Boolean']
    /** If that user is marked as deleted on the account */
    isDeleted: Scalars['Boolean']
    /** If that user is subscribed to Pulse on the account */
    subscribed: Scalars['Boolean']
    /** The tenant/team id for the account */
    tenantId: Scalars['String']
    /** The user's timezone for the account */
    timezone: Scalars['String']
    /** When this account was last updated in Pulse */
    updatedAt: Scalars['Instant']
    /** The user id for the account */
    userId: Scalars['String']
}

export type LocalizedThriveResetTag = {
    __typename?: 'LocalizedThriveResetTag'
    localized: Scalars['String']
    value: ThriveResetTag
}

export enum LoginMethod {
    Credentials = 'credentials',
    Sso = 'sso'
}

export type MsTeamsSubscribeUserMutation = {
    __typename?: 'MSTeamsSubscribeUserMutation'
    /** Subscribe user who onboarded through Home Tab */
    subscribeUser: TeamsSubscribeUserResult
}

export type MagicLinkIntegrationMutation = {
    __typename?: 'MagicLinkIntegrationMutation'
    /** Disables MagicLink integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new MagicLink integration to given company. If config already exists, it will be enabled without changing anything else. */
    enable: Scalars['Boolean']
}

export type MagicLinkIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type MagicLinkIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
}

export enum MeasureUnit {
    Ml = 'ML',
    Oz = 'OZ'
}

export enum MediaType {
    Chat = 'CHAT',
    Email = 'EMAIL',
    Message = 'MESSAGE',
    None = 'NONE',
    Voice = 'VOICE',
    WorkItem = 'WORK_ITEM'
}

export type MediaTypeConfig = {
    __typename?: 'MediaTypeConfig'
    chat: Scalars['Boolean']
    message?: Maybe<Scalars['Boolean']>
    voice: Scalars['Boolean']
}

export type MediaTypeConfigInput = {
    chat: Scalars['Boolean']
    message?: InputMaybe<Scalars['Boolean']>
    voice: Scalars['Boolean']
}

export type MessageParameters = {
    __typename?: 'MessageParameters'
    body: Scalars['String']
    ctaLabel?: Maybe<Scalars['String']>
    ctaUrl?: Maybe<Scalars['String']>
    header?: Maybe<Scalars['String']>
    imageUrl?: Maybe<Scalars['String']>
}

export type MessageParametersInput = {
    body: Scalars['String']
    ctaLabel?: InputMaybe<Scalars['String']>
    ctaUrl?: InputMaybe<Scalars['String']>
    header?: InputMaybe<Scalars['String']>
    imageUrl?: InputMaybe<Scalars['String']>
}

export type MetricData = {
    __typename?: 'MetricData'
    coordinates: Array<ReportingCoordinates>
    description?: Maybe<Scalars['String']>
    metric: Scalars['String']
}

export enum MetricsEnum {
    Nblindtransferred = 'NBLINDTRANSFERRED',
    Ncobrowsesessions = 'NCOBROWSESESSIONS',
    Nconnected = 'NCONNECTED',
    Nconsult = 'NCONSULT',
    Nconsulttransferred = 'NCONSULTTRANSFERRED',
    Nerror = 'NERROR',
    Noffered = 'NOFFERED',
    Noutbound = 'NOUTBOUND',
    Noutboundabandoned = 'NOUTBOUNDABANDONED',
    Noutboundattempted = 'NOUTBOUNDATTEMPTED',
    Noutboundconnected = 'NOUTBOUNDCONNECTED',
    Noversla = 'NOVERSLA',
    Nstatetransitionerror = 'NSTATETRANSITIONERROR',
    Ntransferred = 'NTRANSFERRED',
    Oexternalmediacount = 'OEXTERNALMEDIACOUNT',
    Omediacount = 'OMEDIACOUNT',
    Omessageturn = 'OMESSAGETURN',
    Oservicelevel = 'OSERVICELEVEL',
    Oservicetarget = 'OSERVICETARGET',
    Tabandon = 'TABANDON',
    Tacd = 'TACD',
    Tacw = 'TACW',
    Tagentresponsetime = 'TAGENTRESPONSETIME',
    Talert = 'TALERT',
    Tanswered = 'TANSWERED',
    Tbarging = 'TBARGING',
    Tcallback = 'TCALLBACK',
    Tcallbackcomplete = 'TCALLBACKCOMPLETE',
    Tcoaching = 'TCOACHING',
    Tcoachingcomplete = 'TCOACHINGCOMPLETE',
    Tconnected = 'TCONNECTED',
    Tcontacting = 'TCONTACTING',
    Tdialing = 'TDIALING',
    Tfirstconnect = 'TFIRSTCONNECT',
    Tfirstdial = 'TFIRSTDIAL',
    Tflowout = 'TFLOWOUT',
    Thandle = 'THANDLE',
    Theld = 'THELD',
    Theldcomplete = 'THELDCOMPLETE',
    Tivr = 'TIVR',
    Tmonitoring = 'TMONITORING',
    Tmonitoringcomplete = 'TMONITORINGCOMPLETE',
    Tnotresponding = 'TNOTRESPONDING',
    Tshortabandon = 'TSHORTABANDON',
    Ttalk = 'TTALK',
    Ttalkcomplete = 'TTALKCOMPLETE',
    Tuserresponsetime = 'TUSERRESPONSETIME',
    Tvoicemail = 'TVOICEMAIL',
    Twait = 'TWAIT'
}

export type MicrostepAndRemovalReasonInput = {
    microstepId: Scalars['ID']
    removalReason?: InputMaybe<MicrostepRemovalReason>
}

export type MicrostepCheckin = {
    __typename?: 'MicrostepCheckin'
    checkInDate: Scalars['ISODate']
    dateTime: Scalars['String']
    microstepId: Scalars['ID']
    status: Scalars['String']
    userId: Scalars['ID']
}

export type MicrostepCheckinDateList = {
    __typename?: 'MicrostepCheckinDateList'
    checkinDates: Array<Scalars['ISODate']>
    microstepId: Scalars['ID']
}

export type MicrostepCheckinProgressDate = {
    __typename?: 'MicrostepCheckinProgressDate'
    date: Scalars['String']
    microstepsCheckedIn: Array<Scalars['ID']>
    microstepsCheckedInCount: Scalars['Int']
    microstepsCheckedInInfo: Array<GraphQlMicrostep>
    microstepsCommittedCount: Scalars['Int']
    microstepsCommittedIds: Array<Scalars['ID']>
    microstepsCommittedInfo: Array<GraphQlMicrostep>
}

export type MicrostepDetail = {
    __typename?: 'MicrostepDetail'
    id: Scalars['UUID']
    title?: Maybe<Scalars['String']>
}

export type MicrostepGoalDetail = {
    __typename?: 'MicrostepGoalDetail'
    microstep: GraphQlMicrostep
    microstepInfo: MicrostepDetail
}

export enum MicrostepMomentumError {
    DateRangeNotValid = 'DATE_RANGE_NOT_VALID'
}

export type MicrostepMomentumErrorResponse = {
    __typename?: 'MicrostepMomentumErrorResponse'
    error: MicrostepMomentumError
}

export type MicrostepMomentumResult =
    | GraphQlMicrostepMomentum
    | MicrostepMomentumErrorResponse

export type MicrostepPage = {
    __typename?: 'MicrostepPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<GraphQlMicrostep>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
    totalItems: Scalars['Int']
}

export enum MicrostepRemovalReason {
    Accomplished = 'ACCOMPLISHED',
    NotInterested = 'NOT_INTERESTED'
}

export type MicrostepStats = {
    __typename?: 'MicrostepStats'
    /** Microsteps list */
    microsteps: Array<MicrostepStatsDetail>
    /** Number of microsteps checked */
    total: Scalars['Int']
}

export type MicrostepStatsDetail = {
    __typename?: 'MicrostepStatsDetail'
    completed: Scalars['Boolean']
    microstepDetail: MicrostepGoalDetail
}

export type MicrostepSubscription = {
    __typename?: 'MicrostepSubscription'
    microstepId: Scalars['ID']
    status: Scalars['String']
    userId: Scalars['ID']
}

export type MicrostepUserProgress = {
    __typename?: 'MicrostepUserProgress'
    checkedInCount: Scalars['Int']
    microstepId: Scalars['ID']
}

export type MicrostepUserStatus = {
    __typename?: 'MicrostepUserStatus'
    checkedIn: Scalars['Boolean']
    committed: Scalars['Boolean']
    microstep: GraphQlMicrostep
}

export type MicrostepUserTotal = {
    __typename?: 'MicrostepUserTotal'
    checkedInCount: Scalars['Int']
    userId: Scalars['ID']
}

export type MicrostepV2 = {
    __typename?: 'MicrostepV2'
    action: Scalars['String']
    body: Scalars['String']
    createdAt?: Maybe<Scalars['Instant']>
    deprecateAt?: Maybe<Scalars['ISODate']>
    duration: Scalars['Int']
    id: Scalars['UUID']
    journeyIds: Array<Scalars['UUID']>
    journeys: Array<Maybe<Journey>>
    timeUnit: Scalars['String']
    title: Scalars['String']
    updatedAt?: Maybe<Scalars['Instant']>
}

export type MiniBenny = {
    __typename?: 'MiniBenny'
    companyResourceCount: Scalars['Int']
    companyResourceGroupUUID: Scalars['UUID']
    name?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
}

export type ModuleContentStatusInput = {
    /** module id */
    contentId: Scalars['String']
    /** module progress in seconds */
    progressInSeconds: Scalars['Int']
    progressType: LearnContentStatusProgressType
    /** total duration of module in seconds */
    totalInSeconds: Scalars['Int']
}

export type ModuleMutation = {
    /** Mutation creating or updating module status, based on userId from context */
    updateModuleStatus: LearnContentStatus
}

export type ModuleMutationUpdateModuleStatusArgs = {
    input: ModuleContentStatusInput
}

export type ModuleV2 = {
    __typename?: 'ModuleV2'
    bookmarked: Scalars['Boolean']
    byline: Scalars['String']
    contentStatus?: Maybe<LearnContentStatus>
    courseID?: Maybe<Scalars['String']>
    /** Display name, directly from CMS */
    displayName: Scalars['String']
    hlsVideoLink?: Maybe<Scalars['String']>
    id: Scalars['ID']
    /** Label calculated from CMS 'displayName' minus ': title' */
    label: Scalars['String']
    lessonID?: Maybe<Scalars['String']>
    likes: Likes
    /** Order, order of the component in CMS */
    order: Scalars['Int']
    thumbnail: Scalars['String']
    title: Scalars['String']
    type: Scalars['String']
    videoLink: Scalars['String']
}

export type MonthYear = {
    __typename?: 'MonthYear'
    month: Scalars['Int']
    year: Scalars['Int']
}

export type MonthYearInput = {
    month: Scalars['Int']
    year: Scalars['Int']
}

export type MonthlyActivitySummaryStatsResponse = ActivitySummaryStats & {
    __typename?: 'MonthlyActivitySummaryStatsResponse'
    averageSteps: Scalars['Float']
    month: Scalars['Int']
    year: Scalars['Int']
}

export type MonthlyAssessmentStatsResponse = AssessmentStats & {
    __typename?: 'MonthlyAssessmentStatsResponse'
    average: Scalars['Float']
    month: Scalars['Int']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
    year: Scalars['Int']
}

export type MonthlySleepSummaryStatsResponse = SleepSummaryStats & {
    __typename?: 'MonthlySleepSummaryStatsResponse'
    averageBedTimeStart?: Maybe<Scalars['Long']>
    averageMinutesAsleep: Scalars['Float']
    month: Scalars['Int']
    year: Scalars['Int']
}

export type MsTeamsIntegrationMutation = {
    __typename?: 'MsTeamsIntegrationMutation'
    /** Disables Microsoft Teams integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Microsoft Teams integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type MsTeamsIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type MsTeamsIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    tenantId: Scalars['UUID']
}

export type Mutation = {
    __typename?: 'Mutation'
    /** Achievements Mutation */
    achievements: AchievementsMutation
    assessment: AssessmentMutation
    /** Thrive for Support Teams Mutations */
    calls: CallsMutation
    /** Centralized Notifications Root Mutation */
    centralizedNotifications: CentralizedNotificationsMutation
    /** Root function for Coaching mutations */
    coaching: CoachingMutation
    /** Content Root Mutation */
    content: ContentMutation
    /** Daily Experience Root Mutation */
    dailyExperience: DailyExperienceMutation
    /**
     * Genesys Agent Mutations
     * @deprecated Deprecated
     */
    genesysAgents: GenesysAgentMutation
    /** Genesys Customer Onboarding */
    genesysClients: GenesysClientMutation
    /** @deprecated Just placeholder for testing, replace with pulse */
    hello: Scalars['String']
    /** Hello World Mutation */
    helloWorldMutation: Scalars['String']
    /** Hello World Mutation */
    helloWorlddMutation: Scalars['String']
    identity: IdentityMutation
    /** Journeys Root Mutation */
    journeys: JourneyMutation
    /** Learn V2 Root Mutation */
    learnV2: LearnMutationV2
    notification: NotificationMutation
    /** Onboarding Root Mutation */
    onboarding: OnboardingMutation
    /** Update Thrive Pulse data */
    pulse: ThrivePulseMutation
    /** Pulse Reporting Root Mutation */
    pulseReporting: PulseReportingMutation
    /**
     * External Pulse Root Mutation
     * @deprecated Still work but latest APIs are now in pulse, replace with pulse
     */
    pulseV2: ExternalPulseMutation
    recommendation: RecommendationMutation
    /** Capture a NPSResults for a User */
    recordNPSResult: UserNpsResult
    reset: ResetMutation
    /** Reset Root Mutation */
    resetV2: ResetMutationV2
    /** Retail Mutation */
    retail: RetailMutation
    search: SearchMutation
    /** Slack Root Mutation */
    slack: SlackMutation
    /** Social Groups Root Mutation */
    socialGroups: SocialGroupMutation
    /** Teams Root Mutation */
    teams: TeamsMutation
    /** Test Mutation */
    testMutation: Scalars['String']
    today: TodayMutation
    /** Unified Challenges Root Mutation */
    unifiedChallenges: UnifiedChallengesMutation
    userHistory: UserHistoryMutation
    /** Wearables Platform Root Mutation */
    wearables: WearablesPlatformMutation
}

export type MutationRecordNpsResultArgs = {
    comment?: InputMaybe<Scalars['String']>
    completionDate: Scalars['Instant']
    locationPath: Scalars['String']
    npsScore?: InputMaybe<Scalars['Float']>
    platform: Scalars['String']
    sessionId?: InputMaybe<Scalars['UUID']>
    userId: Scalars['UUID']
    userLanguage: Scalars['String']
}

export type MySuccessStory = {
    __typename?: 'MySuccessStory'
    /** Unique id of the content related to the story */
    contentId?: Maybe<Scalars['String']>
    /** Content type of the content related to the story */
    contentType?: Maybe<LearnContentStatusType>
    createdAt: Scalars['Instant']
    iconName: Scalars['String']
    id: Scalars['UUID']
    /** Url of an image for my success story */
    imageUrl?: Maybe<Scalars['String']>
    /** Defaults to private story, not shared with Thrive nor employer */
    isPrivate: Scalars['Boolean']
    /** Text of my success story */
    story: Scalars['String']
    /** Title of My Success Story */
    title: Scalars['String']
    type: SuccessStoryFeedbackType
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type MySuccessStoryInput = {
    /** Unique id of the content related to the story */
    contentId?: InputMaybe<Scalars['String']>
    /** Content type of the content related to the story */
    contentType?: InputMaybe<LearnContentStatusType>
    /** Url of an image for my success story */
    imageUrl?: InputMaybe<Scalars['String']>
    /** Defaults to private story, not shared with Thrive nor employer */
    isPrivate: Scalars['Boolean']
    /** Text of my success story */
    story: Scalars['String']
    /** Title of My Success Story */
    title: Scalars['String']
    /** Type of Success Story feedback */
    type: SuccessStoryFeedbackType
}

export type MySuccessStoryPage = {
    __typename?: 'MySuccessStoryPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<MySuccessStory>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

/** User journal related to Pulse survey question (Pulse Check In) */
export type MySurveyJournal = {
    __typename?: 'MySurveyJournal'
    /** Content of my journal */
    content: Scalars['String']
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    surveyId: Scalars['UUID']
}

export type MySurveyJournalInput = {
    /** Content of my journal */
    content: Scalars['String']
    surveyId: Scalars['UUID']
}

export type MySurveyJournalPage = {
    __typename?: 'MySurveyJournalPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<MySurveyJournal>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type NiceIntegrationMutation = {
    __typename?: 'NiceIntegrationMutation'
    /** Disables NICE integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Nice integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type NiceIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type NiceIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    tenantId: Scalars['String']
}

export type NoMetadata = {
    __typename?: 'NoMetadata'
    thisFieldIsNotUsedItIsJustHereToMakeGqlHappy: Scalars['Boolean']
}

export type NotificationMutation = {
    __typename?: 'NotificationMutation'
    /** Adds a deviceId (OneSignal id) to a user */
    addDevice: Scalars['Boolean']
    /** Sends a notification with a custom title and message to a list of user_ids with company_ids. Notifications won't be send to user_ids not registered in the third party message providerMax 2000 users per call */
    sendCustomNotificationV2: Scalars['Boolean']
    /** Sets the notificationType activated/deactivated for the logged in user */
    setNotificationStatus: Scalars['Boolean']
}

export type NotificationMutationAddDeviceArgs = {
    deviceId: Scalars['String']
}

export type NotificationMutationSendCustomNotificationV2Args = {
    message: Scalars['String']
    notificationType: NotificationType
    title: Scalars['String']
    users: Array<UserInfoInput>
}

export type NotificationMutationSetNotificationStatusArgs = {
    active: Scalars['Boolean']
    notificationType: NotificationType
}

export type NotificationQuery = {
    __typename?: 'NotificationQuery'
    /** Gets the playerId (OneSignal id) for the logged in user */
    getDevices: Array<Scalars['String']>
    /** Gets true if the notification type is active for the logged in user */
    getNotificationStatus: Scalars['Boolean']
}

export type NotificationQueryGetNotificationStatusArgs = {
    notificationType: NotificationType
}

export type NotificationSettingsInput = {
    teamsUserId: Scalars['UUID']
}

export type NotificationSettingsOperationResult = NotificationSettingsResult

export type NotificationSettingsResult = {
    __typename?: 'NotificationSettingsResult'
    error?: Maybe<Scalars['String']>
    pulseSettings?: Maybe<PulseSettings>
}

export enum NotificationType {
    CompanyChallengeReminder = 'COMPANY_CHALLENGE_REMINDER',
    PulseReminder = 'PULSE_REMINDER'
}

export type NotifyChallengeUserResult = {
    __typename?: 'NotifyChallengeUserResult'
    error?: Maybe<Scalars['String']>
    numberOfUsersNotified: Scalars['Int']
}

export type NotifyMutation = {
    __typename?: 'NotifyMutation'
    /** Send a reminder to all users in a challenge to complete the daily goals for the challenge */
    notifyDailyGoalsChallengeUsers: NotifyChallengeUserResult
}

export type NotifyMutationNotifyDailyGoalsChallengeUsersArgs = {
    input: ThriveChallengeDailyGoalNotificationInput
}

export type NudgeMetadata =
    | InChallengeGoalReminder
    | NoMetadata
    | SmartNudgeReset
    | TestNudge

export type NudgeRecommendedReset = {
    __typename?: 'NudgeRecommendedReset'
    context: TimedRecommendedResetContext
    thriveReset: ThriveReset
}

export type NudgeSentAtUpdateInput = {
    nudgeType: NudgeType
    sendDate: Scalars['ISODate']
    /** ISO-8601 time string */
    sendTime: Scalars['String']
    sentAt: Scalars['Instant']
}

export type NudgeStatusUpdateInput = {
    enabled: Scalars['Boolean']
    type: NudgeType
}

export enum NudgeType {
    InChallengeGoalReminder = 'IN_CHALLENGE_GOAL_REMINDER',
    SmartNudgeMsTeamsMvp = 'SMART_NUDGE_MS_TEAMS_MVP',
    TestNudge = 'TEST_NUDGE',
    WebNudgesLaunchAnnouncement = 'WEB_NUDGES_LAUNCH_ANNOUNCEMENT'
}

export type Office365 = CalendarServiceFields & {
    __typename?: 'Office365'
    iconUrl: Scalars['Url']
    name: Scalars['String']
    resetEventUrl: Scalars['Url']
    type: CalendarService
}

export type OktaIntegrationMutation = {
    __typename?: 'OktaIntegrationMutation'
    /** Disables Okta SSO integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Okta SSO integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type OktaIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type OktaIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    emailAllowList?: InputMaybe<Array<Scalars['String']>>
    idpId: Scalars['String']
}

export enum OnboardingAssessmentStatus {
    Completed = 'COMPLETED',
    InProgress = 'IN_PROGRESS'
}

/** The onboarding information for the user */
export type OnboardingInfo = {
    __typename?: 'OnboardingInfo'
    cmsId?: Maybe<Scalars['UUID']>
    /** The pages for onboarding  */
    pages: Array<OnboardingPage>
    /** The users onboarding progress */
    progress: OnboardingProgress
    /** The type of onboarding */
    type: OnboardingType
}

export type OnboardingMutation = {
    __typename?: 'OnboardingMutation'
    /** Resets the onboarding progress for the specified user */
    resetOnboardingProgress: Scalars['Boolean']
    /** Sets the onboarding progress for the user */
    setOnboardingProgress: OnboardingProgress
}

export type OnboardingMutationResetOnboardingProgressArgs = {
    userId: Scalars['UUID']
}

export type OnboardingMutationSetOnboardingProgressArgs = {
    cmsId?: InputMaybe<Scalars['UUID']>
    status: UserOnboardingStatus
}

export type OnboardingPage = {
    __typename?: 'OnboardingPage'
    body: Scalars['String']
    header: Scalars['String']
    image?: Maybe<OnboardingPageImage>
}

export type OnboardingPageImage = {
    __typename?: 'OnboardingPageImage'
    alternativeText?: Maybe<Scalars['String']>
    caption?: Maybe<Scalars['String']>
    height?: Maybe<Scalars['Int']>
    url: Scalars['String']
    width?: Maybe<Scalars['Int']>
}

export type OnboardingProgress = {
    __typename?: 'OnboardingProgress'
    /** The CMS ID for the onboarding template */
    cmsId?: Maybe<Scalars['UUID']>
    createdAt?: Maybe<Scalars['Instant']>
    /** The status of the onboarding process */
    status: UserOnboardingStatus
    updatedAt?: Maybe<Scalars['Instant']>
    userId?: Maybe<Scalars['UUID']>
}

export type OnboardingQuery = {
    __typename?: 'OnboardingQuery'
    /** Gets onboarding information for the user */
    getOnboardingInfo: OnboardingInfo
    /** Gets onboarding progress for the user */
    getOnboardingProgress: OnboardingProgress
}

export enum OnboardingType {
    Coaching = 'COACHING',
    General = 'GENERAL',
    UnknownValue = 'UNKNOWN_VALUE'
}

export type OptimalRange = {
    __typename?: 'OptimalRange'
    dimension: Surveys
    high: Scalars['Int']
    low: Scalars['Int']
}

export type OptionsDto = {
    __typename?: 'OptionsDTO'
    text: Scalars['String']
    value: Scalars['Int']
}

export enum OtmTemplateName {
    Basic = 'BASIC'
}

export type OuraMutation = {
    __typename?: 'OuraMutation'
    /** Disconnect Oura account from Thrive */
    disconnect: Scalars['Boolean']
}

export type OuraQuery = {
    __typename?: 'OuraQuery'
    /** Get an authorisation url to begin the Oura OAuth flow */
    oauthAuthorisationUrl?: Maybe<Scalars['String']>
}

export type OuraQueryOauthAuthorisationUrlArgs = {
    redirectPath?: InputMaybe<Scalars['String']>
}

export type Outlook = CalendarServiceFields & {
    __typename?: 'Outlook'
    iconUrl: Scalars['Url']
    name: Scalars['String']
    resetEventUrl: Scalars['Url']
    type: CalendarService
}

export type OwnedEmailDomain = {
    __typename?: 'OwnedEmailDomain'
    company: Company
    createdAt: Scalars['Instant']
    emailDomain: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type OwnedEmailDomainInput = {
    companyId: Scalars['UUID']
    emailDomain: Scalars['String']
}

export type PageInfo = {
    __typename?: 'PageInfo'
    endCursor?: Maybe<Scalars['String']>
    hasNextPage: Scalars['Boolean']
    hasPreviousPage: Scalars['Boolean']
    startCursor?: Maybe<Scalars['String']>
}

export enum PageSortOrder {
    Asc = 'ASC',
    Desc = 'DESC'
}

export type PaginationInput = {
    page?: InputMaybe<Scalars['Long']>
    pageSize?: InputMaybe<Scalars['Int']>
    sortOrder?: InputMaybe<PageSortOrder>
}

export enum Partner {
    Azure = 'azure',
    Coda = 'coda',
    Cognito = 'cognito',
    Hermes = 'hermes',
    Okta = 'okta',
    Thriveglobal = 'thriveglobal',
    Thriveglobalidentity = 'thriveglobalidentity',
    /** @deprecated Use thriveglobalidentity, replace with thriveglobalidentity */
    Thriveglobalkeycloak = 'thriveglobalkeycloak',
    Thriveglobalweb = 'thriveglobalweb',
    Webex = 'webex',
    Westvirginiaunivercity = 'westvirginiaunivercity',
    Zoom = 'zoom'
}

export type PartnerEnableResponse = {
    __typename?: 'PartnerEnableResponse'
    enabled: Scalars['Boolean']
    token?: Maybe<Scalars['String']>
}

export type PartnerIntegrationMutation = {
    __typename?: 'PartnerIntegrationMutation'
    /** Disables Partner integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new partner integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. If enabled or created, a response will contain a token for the partner. */
    enable: PartnerEnableResponse
}

export type PartnerIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type PartnerIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
}

export type PartnerQuery = {
    __typename?: 'PartnerQuery'
    sign: TokenResponse
}

export type PartnerQuerySignArgs = {
    companyName: Scalars['String']
}

export type PartnerUserInputGraphQlInput = {
    companyId?: InputMaybe<Scalars['UUID']>
    scope: Scalars['String']
    userId: Scalars['String']
}

/** Input for pendingNudges query */
export type PendingNudgesInput = {
    /** Whether to filter by expired notifications. Defaults to null, in which case both expired and unexpired notifications will be returned if they fall within the other filters. */
    isExpired?: InputMaybe<Scalars['Boolean']>
    /**
     * Whether to filter by sentAt timestamp.
     * When true, only sent notifications will be returned.
     * When false, only unsent notifications will be returned.
     * Defaults to null, in which case both sent and unsent notifications will be returned.
     */
    isSent?: InputMaybe<Scalars['Boolean']>
    /** The platform categories to filter by. By default, all platforms will be returned. */
    platformCategories?: InputMaybe<Array<PlatformCategory>>
    /** The ISO-8601 duration to filter by. Defaults to 90 days */
    within?: InputMaybe<Scalars['String']>
}

export type PersonalPlantEntityInfo = {
    __typename?: 'PersonalPlantEntityInfo'
    createdAt: Scalars['Instant']
    /** The entity id */
    entityId: Scalars['UUID']
    /** The entity type */
    entityType: EntityType
    /** The date the entity was added */
    eventDate: Scalars['ISODate']
    /** The plant id */
    plantId: Scalars['UUID']
    updatedAt: Scalars['Instant']
}

export type PersonalizedImageConnection = {
    __typename?: 'PersonalizedImageConnection'
    edges: Array<PersonalizedImageEdge>
    /** @deprecated this field is deprecated, replace with nodes */
    items: Array<Image>
    /** @deprecated this field is deprecated, replace with pageInfo */
    itemsPerPage: Scalars['Int']
    nodes: Array<Image>
    /** @deprecated this field is deprecated, replace with pageInfo */
    page: Scalars['Int']
    pageInfo: PageInfo
    /** @deprecated this field is deprecated, replace with pageInfo */
    total: Scalars['Int']
}

export type PersonalizedImageEdge = {
    __typename?: 'PersonalizedImageEdge'
    cursor: Scalars['String']
    node: Image
}

export type PersonalizedImageQuery = {
    __typename?: 'PersonalizedImageQuery'
    get: PersonalizedImageConnection
}

export type PersonalizedImageQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type PersonalizedImagesMutation = {
    __typename?: 'PersonalizedImagesMutation'
    delete: Scalars['Boolean']
}

export type PersonalizedImagesMutationDeleteArgs = {
    imageId: Scalars['UUID']
}

export type PersonalizedQuote = Frame & {
    __typename?: 'PersonalizedQuote'
    _: Scalars['Boolean']
    author: Scalars['String']
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    text: Scalars['String']
    updatedAt: Scalars['Instant']
    user: ResetApiUser
}

export type PersonalizedQuoteConnection = {
    __typename?: 'PersonalizedQuoteConnection'
    edges: Array<PersonalizedQuoteEdge>
    nodes: Array<PersonalizedQuote>
    pageInfo: PageInfo
}

export type PersonalizedQuoteEdge = {
    __typename?: 'PersonalizedQuoteEdge'
    cursor: Scalars['String']
    node: PersonalizedQuote
}

export type PersonalizedQuoteInput = {
    author: Scalars['String']
    backgroundColor?: InputMaybe<Scalars['Color']>
    text: Scalars['String']
    textColor?: InputMaybe<Scalars['Color']>
}

export type PersonalizedQuoteQuery = {
    __typename?: 'PersonalizedQuoteQuery'
    get: PersonalizedQuoteConnection
}

export type PersonalizedQuoteQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type PersonalizedQuotesMutation = {
    __typename?: 'PersonalizedQuotesMutation'
    create: PersonalizedQuote
    delete: Scalars['Boolean']
    update?: Maybe<PersonalizedQuote>
}

export type PersonalizedQuotesMutationCreateArgs = {
    quote: PersonalizedQuoteInput
}

export type PersonalizedQuotesMutationDeleteArgs = {
    quoteId: Scalars['UUID']
}

export type PersonalizedQuotesMutationUpdateArgs = {
    quote: PersonalizedQuoteInput
    quoteId: Scalars['UUID']
}

export enum PersonalizedRecommendedContent {
    Article = 'ARTICLE',
    JourneyDailyStep = 'JOURNEY_DAILY_STEP',
    Microstep = 'MICROSTEP'
}

export type PersonalizedReset = Reset & {
    __typename?: 'PersonalizedReset'
    _: Scalars['Boolean']
    audio: Audio
    audioEndTime?: Maybe<Scalars['Int']>
    audioProvider?: Maybe<AudioProvider>
    audioStartTime?: Maybe<Scalars['Int']>
    createdAt: Scalars['Instant']
    durationInSeconds: Scalars['Int']
    frames: Array<Frame>
    id: Scalars['UUID']
    name: Scalars['String']
    shared: Array<SharedPersonalizedReset>
    updatedAt: Scalars['Instant']
    user: ResetApiUser
}

export type PersonalizedResetActionsMutation = {
    __typename?: 'PersonalizedResetActionsMutation'
    watched: Scalars['Boolean']
}

export type PersonalizedResetActionsMutationWatchedArgs = {
    resetId: Scalars['UUID']
}

export type PersonalizedResetInput = {
    audioEndTime?: InputMaybe<Scalars['Int']>
    audioId: Scalars['UUID']
    audioProvider?: InputMaybe<AudioProvider>
    audioStartTime?: InputMaybe<Scalars['Int']>
    frames: Array<FrameInputIdInput>
    name: Scalars['String']
}

export type PersonalizedResetPage = {
    __typename?: 'PersonalizedResetPage'
    items: Array<PersonalizedReset>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type PersonalizedResetQuery = {
    __typename?: 'PersonalizedResetQuery'
    get: PersonalizedResetPage
    getByIds: Array<PersonalizedReset>
}

export type PersonalizedResetQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type PersonalizedResetQueryGetByIdsArgs = {
    ids: Array<Scalars['UUID']>
}

export type PersonalizedResetsMutation = {
    __typename?: 'PersonalizedResetsMutation'
    create: PersonalizedReset
    delete: Scalars['Boolean']
    event: PersonalizedResetActionsMutation
    share?: Maybe<SharedPersonalizedReset>
    unshare: Scalars['Boolean']
    update?: Maybe<PersonalizedReset>
}

export type PersonalizedResetsMutationCreateArgs = {
    reset: PersonalizedResetInput
}

export type PersonalizedResetsMutationDeleteArgs = {
    resetId: Scalars['UUID']
}

export type PersonalizedResetsMutationShareArgs = {
    resetId: Scalars['UUID']
}

export type PersonalizedResetsMutationUnshareArgs = {
    sharedId: Scalars['UUID']
}

export type PersonalizedResetsMutationUpdateArgs = {
    reset: PersonalizedResetInput
    resetId: Scalars['UUID']
}

export type PlanEntity = {
    __typename?: 'PlanEntity'
    createdAt: Scalars['Instant']
    currentHabit: Habit
    id: Scalars['UUID']
    intention: Intention
    startDate: Scalars['ISODate']
    updatedAt: Scalars['Instant']
}

export enum PlanResponseType {
    CurrentPlan = 'CURRENT_PLAN',
    NewHabit = 'NEW_HABIT',
    NewPlan = 'NEW_PLAN'
}

export enum PlantActionError {
    ErrorProcessingPlantAction = 'ERROR_PROCESSING_PLANT_ACTION',
    PlantAlreadyPlanted = 'PLANT_ALREADY_PLANTED',
    PlantGrowthNotComplete = 'PLANT_GROWTH_NOT_COMPLETE',
    PlantNotFoundForUser = 'PLANT_NOT_FOUND_FOR_USER'
}

export type PlantActionErrorResult = {
    __typename?: 'PlantActionErrorResult'
    error: PlantActionError
}

export type PlantActionResponse = PlantActionErrorResult | PlantActionResult

export type PlantActionResult = {
    __typename?: 'PlantActionResult'
    userGardenId?: Maybe<Scalars['UUID']>
    userPlants?: Maybe<Array<UserPlantInfo>>
}

export type PlantInfo = {
    __typename?: 'PlantInfo'
    /** Plant color for layer 1 */
    colorLayer1?: Maybe<Scalars['String']>
    /** Plant color for layer 2 */
    colorLayer2?: Maybe<Scalars['String']>
    /** Plant color for layer 3 */
    colorLayer3?: Maybe<Scalars['String']>
    /** Plant color for layer 4 */
    colorLayer4?: Maybe<Scalars['String']>
    /** The product upc associated to the plant */
    productUpc: Scalars['String']
}

export type PlantsLeaderboard = {
    __typename?: 'PlantsLeaderboard'
    rankedList: Array<PlantsRanked>
    /** The requester entity ranked position */
    requesterRank: Scalars['Int']
    totalEntries: Scalars['Int']
}

export type PlantsRanked = {
    __typename?: 'PlantsRanked'
    /** The entity display name */
    displayName: Scalars['String']
    /** The entity id */
    entityId: Scalars['UUID']
    /** The rank position on the leaderboard */
    rank: Scalars['Int']
    /** Total plants number */
    totalPlants: Scalars['Int']
}

export type PlatformAgent = {
    __typename?: 'PlatformAgent'
    email?: Maybe<Scalars['String']>
    firstName?: Maybe<Scalars['String']>
    id: Scalars['String']
    isActive: Scalars['Boolean']
    lastName?: Maybe<Scalars['String']>
    lastResetTimestamp?: Maybe<Scalars['Instant']>
    name?: Maybe<Scalars['String']>
}

export enum PlatformCategory {
    Chat = 'CHAT',
    Mobile = 'MOBILE',
    Web = 'WEB'
}

export type PlatformGroup = {
    __typename?: 'PlatformGroup'
    groupType: GroupType
    groups: Array<AgentGroup>
}

export enum PlatformType {
    AmazonConnect = 'AMAZON_CONNECT',
    Desktop = 'DESKTOP',
    Genesys = 'GENESYS',
    Nice = 'NICE',
    Salesforce = 'SALESFORCE',
    Webex = 'WEBEX'
}

export type Podcast = {
    __typename?: 'Podcast'
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    numberOfEpisodes: Scalars['Int']
    numberOfSeasons: Scalars['Int']
    /** @deprecated Not supported, replace with LearnContentProgress */
    podcastProgress: PodcastProgress
    /** Podcast progress for user, based on userId from context. Checks progress on seasons. */
    progress?: Maybe<LearnContentProgress>
    seasons: Array<PodcastSeason>
    summary: Scalars['String']
    tags: Array<Tag>
    title: Scalars['String']
}

/** Progress input for Podcast Episode */
export type PodcastContentStatusInput = {
    /** podcast episode id */
    contentId: Scalars['String']
    /** podcast episode progress in seconds */
    progressInSeconds: Scalars['Int']
    progressType: LearnContentStatusProgressType
    /** total duration of podcast episode in seconds */
    totalInSeconds: Scalars['Int']
}

export type PodcastEpisode = {
    __typename?: 'PodcastEpisode'
    audioUrl?: Maybe<Scalars['String']>
    bookmarked: Scalars['Boolean']
    contentStatus?: Maybe<LearnContentStatus>
    contentType: LearnContentStatusType
    guests: Array<Contributor>
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    likes: Likes
    number: Scalars['Int']
    podcast?: Maybe<Podcast>
    podcastEpisodeProgress?: Maybe<PodcastEpisodeProgress>
    podcastId?: Maybe<Scalars['ID']>
    season?: Maybe<PodcastSeason>
    seasonId?: Maybe<Scalars['ID']>
    summary: Scalars['String']
    tags: Array<Tag>
    title: Scalars['String']
    transcriptUrl?: Maybe<Scalars['String']>
}

export type PodcastEpisodePage = {
    __typename?: 'PodcastEpisodePage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<PodcastEpisode>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type PodcastEpisodeParent = {
    __typename?: 'PodcastEpisodeParent'
    podcastID: Scalars['ID']
    seasonID: Scalars['ID']
}

export type PodcastEpisodeProgress = {
    __typename?: 'PodcastEpisodeProgress'
    durationInSeconds: Scalars['Int']
    hasCompleted: Scalars['Boolean']
    inProgress: Scalars['Boolean']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    updatedAt: Scalars['Instant']
}

export type PodcastPage = {
    __typename?: 'PodcastPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<Podcast>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type PodcastProgress = {
    __typename?: 'PodcastProgress'
    durationInSeconds: Scalars['Int']
    inProgress: Scalars['Boolean']
    isPreviouslyCompleted: Scalars['Boolean']
    isPristine: Scalars['Boolean']
    numberOfEpisodesInProgress: Scalars['Int']
    numberOfEpisodesPreviouslyCompleted: Scalars['Int']
    numberOfPristineEpisodes: Scalars['Int']
    numberOfPristineSeasons: Scalars['Int']
    numberOfSeasonsInProgress: Scalars['Int']
    numberOfSeasonsPreviouslyCompleted: Scalars['Int']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    updatedAt: Scalars['Instant']
}

export type PodcastSeason = {
    __typename?: 'PodcastSeason'
    episodes: Array<PodcastEpisode>
    id: Scalars['ID']
    number: Scalars['Int']
    numberOfEpisodes: Scalars['Int']
    podcast?: Maybe<Podcast>
    /** @deprecated Not supported, replace with LearnContentProgress */
    podcastSeasonProgress?: Maybe<PodcastSeasonProgress>
    /** Podcast season progress for user, based on userId from context. Checks progress on podcast episodes. */
    progress?: Maybe<LearnContentProgress>
    summary: Scalars['String']
    title: Scalars['String']
}

export type PodcastSeasonProgress = {
    __typename?: 'PodcastSeasonProgress'
    durationInSeconds: Scalars['Int']
    inProgress: Scalars['Boolean']
    isPristine: Scalars['Boolean']
    numberOfEpisodesInProgress: Scalars['Int']
    numberOfEpisodesPreviouslyCompleted: Scalars['Int']
    numberOfPristineEpisodes: Scalars['Int']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    statusList: Array<Maybe<LearnContentStatus>>
    updatedAt: Scalars['Instant']
}

export type PollResponse = {
    __typename?: 'PollResponse'
    items: Array<CallsCommand>
    sessionId: Scalars['String']
}

export type Post = {
    __typename?: 'Post'
    /** The id of this challenge */
    challengeId?: Maybe<Scalars['UUID']>
    createdAt?: Maybe<Scalars['Instant']>
    createdBy?: Maybe<Scalars['UUID']>
    createdBySocialGroup?: Maybe<Scalars['String']>
    /** The id of the entity that originated the post */
    entityId?: Maybe<Scalars['String']>
    /** The name of the entity that originated the post */
    entityName?: Maybe<Scalars['String']>
    /** The thumbnail of the entity that originated the post */
    entityThumbnail?: Maybe<Scalars['String']>
    /** The post of the entity that originated the post */
    entityType?: Maybe<Scalars['String']>
    /** The id of this post */
    id: Scalars['UUID']
    lastUpdatedBy?: Maybe<Scalars['UUID']>
    postType: PostType
    profilePhotoUrl?: Maybe<Scalars['String']>
    reactions?: Maybe<PostReactions>
    /** The id of this social group */
    socialGroupId: Scalars['UUID']
    updatedAt?: Maybe<Scalars['Instant']>
    userDisplayName?: Maybe<Scalars['String']>
    userId?: Maybe<Scalars['UUID']>
}

export type PostCreateInput = {
    /** Challenge Id of the post */
    challengeId?: InputMaybe<Scalars['UUID']>
    /** The date when this post was created */
    createdAt?: InputMaybe<Scalars['String']>
    createdBySocialGroupId?: InputMaybe<Scalars['UUID']>
    /** Entity id related to the post */
    entityId?: InputMaybe<Scalars['String']>
    entityName?: InputMaybe<Scalars['String']>
    /** Entity type related to the post */
    entityType?: InputMaybe<Scalars['String']>
    /** The type of this post */
    postType: Scalars['String']
    /** The social group Id */
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type PostOperationSucceeded = {
    __typename?: 'PostOperationSucceeded'
    /** The ID of the post for which the operation succeeded */
    postId: Scalars['UUID']
}

export type PostReaction = {
    __typename?: 'PostReaction'
    /** The id of this reaction */
    id: Scalars['UUID']
    /** The id of this post */
    postId: Scalars['UUID']
    /** The type of this reaction */
    postReactionTypeId: Scalars['String']
    /** The profile pic for the user who created the reaction */
    profilePhotoUrl?: Maybe<Scalars['String']>
    /** The user display name who created the reaction */
    userDisplayName?: Maybe<Scalars['String']>
    /** The id of the user who created the reaction */
    userId: Scalars['UUID']
}

export type PostReactionCreationSucceeded = {
    __typename?: 'PostReactionCreationSucceeded'
    /** The post Item ID for  which the operation succeeded */
    postReactionId: Scalars['UUID']
}

export type PostReactionInput = {
    /** The id of this post */
    postId: Scalars['UUID']
    /** The type of this reaction */
    postTypeId: Scalars['String']
}

export type PostReactions = {
    __typename?: 'PostReactions'
    celebrates?: Maybe<Array<PostReaction>>
    likes?: Maybe<Array<PostReaction>>
    loves?: Maybe<Array<PostReaction>>
}

export type PostType = {
    __typename?: 'PostType'
    language: Scalars['String']
    message: Scalars['String']
    postTypeId: Scalars['String']
    region: Scalars['String']
}

export type PrivateMagicLinkRequestInput = {
    clientId: Scalars['String']
    expiresInSeconds?: InputMaybe<Scalars['Int']>
    force?: InputMaybe<Scalars['Boolean']>
    relay?: InputMaybe<Scalars['String']>
    userId: Scalars['String']
}

export type PrivateMagicLinkResponse = {
    __typename?: 'PrivateMagicLinkResponse'
    link?: Maybe<Scalars['String']>
    userId?: Maybe<Scalars['String']>
}

export type ProductInfo = {
    __typename?: 'ProductInfo'
    /** The product cost */
    cost?: Maybe<Scalars['Int']>
    createdAt?: Maybe<Scalars['Instant']>
    /** The currency code */
    currencyCode?: Maybe<Scalars['String']>
    /** The description of the product */
    description?: Maybe<Scalars['String']>
    /** For plants, number of steps to fully grow */
    plantWateringSteps?: Maybe<Scalars['Int']>
    /** Deprecated - Use productTypeEnum */
    productType?: Maybe<Scalars['String']>
    /** The product type as an Enum */
    productTypeEnum?: Maybe<ProductType>
    /** The title of the product */
    title?: Maybe<Scalars['String']>
    /** The product id */
    upc?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['Instant']>
}

export enum ProductType {
    CommunityPlant = 'COMMUNITY_PLANT',
    Object = 'OBJECT',
    Plant = 'PLANT',
    TrophyPlant = 'TROPHY_PLANT'
}

export type PulseCompanyDto = {
    __typename?: 'PulseCompanyDTO'
    age?: Maybe<Scalars['Int']>
    /** @deprecated No longer supported. Will be removed in a future release */
    cadenceOption: Scalars['String']
    createdAt?: Maybe<Scalars['Instant']>
    id: Scalars['UUID']
    matured?: Maybe<Scalars['Boolean']>
    totalEmployees: Scalars['Int']
}

export type PulseDimension = {
    __typename?: 'PulseDimension'
    name: Scalars['String']
    type: Scalars['String']
}

export type PulseDispatchEventResponse = {
    __typename?: 'PulseDispatchEventResponse'
    channel: Channel
    cleared: Scalars['Boolean']
    eligibility: SurveyEligibility
    expiresAt: Scalars['Instant']
    id: Scalars['UUID']
    pulseUserId: Scalars['UUID']
    responded: Scalars['Boolean']
    responseValue?: Maybe<Scalars['Int']>
    survey?: Maybe<PulseSurveyQuestionDto>
    surveyIssueDate: Scalars['ISODate']
}

export type PulseOnDemand =
    | PulseDispatchEventResponse
    | PulseOnDemandErrorResponse

export type PulseOnDemandErrorResponse = {
    __typename?: 'PulseOnDemandErrorResponse'
    message: Scalars['String']
}

export type PulseReportingAdminMutation = {
    __typename?: 'PulseReportingAdminMutation'
    /** Manually Trigger Refresh of Engagement Reports */
    refreshEngagementMetrics: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Historic App Usage */
    refreshHistoricAppUsage: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Challenges Count */
    refreshHistoricChallengesCounts: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Historic Daily Check In Benchmarks */
    refreshHistoricDailyCheckInBenchmarks: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Historic Daily Check In Scores */
    refreshHistoricDailyCheckInScores: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Historic Engagement Levels */
    refreshHistoricEngagementLevels: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Microstep Checkin Count */
    refreshHistoricMicrostepCheckinCounts: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Recent Daily Check In Benchmarks */
    refreshRecentDailyCheckInBenchmarks: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Recent Daily Check In Scores */
    refreshRecentDailyCheckInScores: RefreshEngagementReportResponse
    /** Manually Trigger Refresh of Recent Engagement Levels */
    refreshRecentEngagementLevels: RefreshEngagementReportResponse
}

export type PulseReportingEngagementReportQuery = {
    __typename?: 'PulseReportingEngagementReportQuery'
    /** Engagement Reports */
    engagementReport?: Maybe<EngagementReportResponse>
}

export type PulseReportingEngagementReportQueryEngagementReportArgs = {
    companyId: Scalars['UUID']
    period: ReportPeriod
}

export type PulseReportingMutation = {
    __typename?: 'PulseReportingMutation'
    /** Manually Trigger Reports */
    admin: PulseReportingAdminMutation
}

export type PulseReportingQuery = {
    __typename?: 'PulseReportingQuery'
    activityFeatureCount: ActivityFeatureCountQuery
    dailyCheckInScores: DailyCheckInScoresQuery
    /** Engagement metrics for a company */
    engagement: PulseReportingEngagementReportQuery
    engagementLevels: EngagementLevelsQuery
    usage: UsageQuery
}

export type PulseSettings = {
    __typename?: 'PulseSettings'
    /** Pulse schedule */
    pulseSchedule: Array<Scalars['String']>
    /** Pulse time */
    pulseTime: Scalars['String']
}

export type PulseSettingsInput = {
    pulseSchedule: Array<Scalars['String']>
    pulseTime: Scalars['String']
    teamsUserId: Scalars['UUID']
}

export type PulseSettingsMutation = {
    __typename?: 'PulseSettingsMutation'
    /** Save Pulse settings for a user */
    savePulseSettings: PulseSettingsResult
}

export type PulseSettingsMutationSavePulseSettingsArgs = {
    input: PulseSettingsInput
}

export type PulseSettingsResult = {
    __typename?: 'PulseSettingsResult'
    error?: Maybe<Scalars['String']>
    updated: Scalars['Boolean']
}

export type PulseStreakDto = {
    __typename?: 'PulseStreakDTO'
    current: Scalars['Int']
    max: Scalars['Int']
}

export type PulseSurveyQuestionDto = {
    __typename?: 'PulseSurveyQuestionDTO'
    displayName: Scalars['String']
    id: Scalars['UUID']
    journeyId?: Maybe<Scalars['UUID']>
    label: Scalars['String']
    locale: Scalars['String']
    name: Scalars['String']
    question: QuestionDto
}

export enum PurchaseError {
    CantPurchaseCommunityPlants = 'CANT_PURCHASE_COMMUNITY_PLANTS',
    CantPurchaseNewPlants = 'CANT_PURCHASE_NEW_PLANTS',
    CantPurchaseProduct = 'CANT_PURCHASE_PRODUCT',
    DailyLimitReached = 'DAILY_LIMIT_REACHED',
    ErrorProcessingPurchase = 'ERROR_PROCESSING_PURCHASE',
    InsufficientFoundsForThatCurrency = 'INSUFFICIENT_FOUNDS_FOR_THAT_CURRENCY',
    ProductDoesntExist = 'PRODUCT_DOESNT_EXIST'
}

export type PurchaseErrorResult = {
    __typename?: 'PurchaseErrorResult'
    error: PurchaseError
}

export type PurchaseResponse = PurchaseErrorResult | PurchaseResult

export type PurchaseResult = {
    __typename?: 'PurchaseResult'
    userBalance?: Maybe<Array<BalanceInfo>>
    userInventory: Array<UserInventoryInfo>
}

export type Query = {
    __typename?: 'Query'
    /** Achievements Query */
    achievements: AchievementsQuery
    assessment: AssessmentQuery
    behavioralProfile: BehavioralProfileQuery
    /** Thrive for Support Teams Queries */
    calls: CallsQuery
    /** Centralized Notifications Root Query */
    centralizedNotifications: CentralizedNotificationsQuery
    /** Root function for Coaching queries */
    coaching: CoachingQuery
    content: ContentQuery
    contentExperience: ContentExperienceQuery
    /** Daily Experience Root Query */
    dailyExperience: DailyExperienceQuery
    example: ExampleQuery
    /** Genesys Analytics Queries */
    genesysAnalytics: GenesysAnalyticsQuery
    /**
     * Genesys Client Queries
     * @deprecated Moved to calls queries
     */
    genesysClients: GenesysClientQuery
    /** Returns all public companies with optional pagination */
    getCompanies: CompanyPage
    /** Get a list of NPSResults for a User */
    getNPSResult: Array<UserNpsResult>
    /** @deprecated Just placeholder for testing, replace with pulse */
    hello: Scalars['String']
    /** Hello World Query */
    helloWorldQuery: Scalars['String']
    identity: IdentityQuery
    inAppMessage: AssetQuery
    /** Journeys Root Query */
    journeys: JourneyQuery
    /** Learn Root Query - Version 2 */
    learnV2: LearnQueryV2
    notification: NotificationQuery
    /** Onboarding Root Query */
    onboarding: OnboardingQuery
    /** Access to Thrive Pulse data */
    pulse: ThrivePulseQuery
    /** Pulse Reporting Root Query */
    pulseReporting: PulseReportingQuery
    /**
     * External Pulse Root Query
     * @deprecated Still work but latest APIs are now in pulse, replace with pulse
     */
    pulseV2: ExternalPulseQuery
    recommendation: RecommendationQuery
    reset: ResetQuery
    /**
     * Reset Root Query
     * @deprecated Use reset under content.thrive
     */
    resetV2: ResetQueryV2
    /** Retail Query */
    retail: RetailQuery
    search: SearchQuery
    /** Slack Root Query */
    slack: SlackQuery
    /** Social Groups Root Query */
    socialGroups: SocialGroupQuery
    /** Teams Root Query */
    teams: TeamsQuery
    /** Test Query */
    testQuery: Scalars['String']
    /** Thrive score */
    thriveScore: ThriveScoreQuery
    today: TodayQuery
    /** Unified Challenges Root Query */
    unifiedChallenges: UnifiedChallengesQuery
    userHistory: UserHistoryQuery
    /** Wearables Platform Root Query */
    wearables: WearablesPlatformQuery
}

export type QueryGetCompaniesArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type QueryGetNpsResultArgs = {
    userId: Scalars['UUID']
}

export type QueryPulseV2Args = {
    companyId?: InputMaybe<Scalars['UUID']>
}

export type QuestionDto = {
    __typename?: 'QuestionDTO'
    options: Array<OptionsDto>
    text: Scalars['String']
}

export type QuoteQuery = {
    __typename?: 'QuoteQuery'
    personalized: PersonalizedQuoteQuery
    thrive: ThriveQuoteQuery
}

export type QuotesMutation = {
    __typename?: 'QuotesMutation'
    personalized: PersonalizedQuotesMutation
}

export enum Realm {
    ThriveGlobal = 'ThriveGlobal',
    Master = 'master'
}

export type RecentDailyCheckInScores = {
    __typename?: 'RecentDailyCheckInScores'
    aboveBenchmark: Scalars['Boolean']
    averageScore: Scalars['Float']
    averageScorePercentage: Scalars['Float']
    benchmarkScore: Scalars['Float']
    benchmarkScorePercentage: Scalars['Float']
}

export type RecentDailyCheckInScoresResponse = {
    __typename?: 'RecentDailyCheckInScoresResponse'
    belonging?: Maybe<RecentDailyCheckInScores>
    effectiveness?: Maybe<RecentDailyCheckInScores>
    employeeCount: Scalars['Int']
    energy?: Maybe<RecentDailyCheckInScores>
    enthusiasm?: Maybe<RecentDailyCheckInScores>
    productivity?: Maybe<RecentDailyCheckInScores>
    responseCount: Scalars['Int']
    retention?: Maybe<RecentDailyCheckInScores>
}

export type RecentEngagementLevelResponse = {
    __typename?: 'RecentEngagementLevelResponse'
    atRisk: RecentEngagementLevelStats
    employeeCount: Scalars['Int']
    highlyEngaged: RecentEngagementLevelStats
    moderatelyEngaged: RecentEngagementLevelStats
}

export type RecentEngagementLevelStats = {
    __typename?: 'RecentEngagementLevelStats'
    changeInPercentage: Scalars['Float']
    employeeCount: Scalars['Int']
    percentage: Scalars['Float']
}

export type RecommendationFilterInput = {
    /** Content filters to apply to the recommendations. */
    contentFilter?: InputMaybe<ContentFilterInput>
    /** Include these types in the recommendations. By default, all supported types are included. */
    recommendationTypes?: InputMaybe<Array<RecommendationType>>
    /** The requested number of recommendations to return for each content type. Not guaranteed to return this amount. Must be greater than 0 if set. Defaults to null, meaning that recommendations are not guaranteed to be returned for each type. */
    requestedRecommendationsPerType?: InputMaybe<Scalars['Int']>
    /** The max number of recommendations to return in total. Not guaranteed to return this amount. Must be greater than 0 if set. Defaults to null */
    requestedRecommendationsTotal?: InputMaybe<Scalars['Int']>
}

/** Recommendation input for include/exclude list */
export type RecommendationInput = {
    /** content UUID */
    contentId: Scalars['UUID']
    /** content type */
    contentType: LearnContentStatusType
}

export type RecommendationMutation = {
    __typename?: 'RecommendationMutation'
    /** Record a user's interaction with a piece of content. Past user interactions will affect future recommendations. */
    recordUserInteraction: Scalars['Boolean']
}

export type RecommendationMutationRecordUserInteractionArgs = {
    contentId: Scalars['UUID']
    contentType: UserRecommendationsContentType
    interactionType: UserRecommendationsInteractionType
    userId: Scalars['UUID']
}

export type RecommendationQuery = {
    __typename?: 'RecommendationQuery'
    /**
     * Hello World query for testing
     * @deprecated No longer supported.
     */
    helloRecommendationTestQuery: Scalars['String']
    /** Get recommendations for a user. This requires a service token with 'admin:recommendations' scope. */
    recommendationsForUser: Array<Maybe<RecommendedResourceV3>>
    /**
     * Get troubleshooting data for benefits connect
     * @deprecated Come talk to Content Platform team in #ask-content-platform
     */
    troubleshootBenefitsConnectData: BenefitsConnectDetails
    /** Get recommendations for a user. This requires a user token. */
    userRecommendations: Array<Maybe<RecommendedResourceV3>>
}

export type RecommendationQueryRecommendationsForUserArgs = {
    filter?: InputMaybe<RecommendationFilterInput>
    input?: InputMaybe<UserRecommendationInput>
    numberOfRecommendations?: InputMaybe<Scalars['Int']>
    userId: Scalars['UUID']
}

export type RecommendationQueryTroubleshootBenefitsConnectDataArgs = {
    locale: Scalars['String']
    userId: Scalars['UUID']
}

export type RecommendationQueryUserRecommendationsArgs = {
    filter?: InputMaybe<RecommendationFilterInput>
    input?: InputMaybe<UserRecommendationInput>
    numberOfRecommendations?: InputMaybe<Scalars['Int']>
}

export enum RecommendationType {
    Article = 'ARTICLE',
    BenefitsConnect = 'BENEFITS_CONNECT',
    CompanyResource = 'COMPANY_RESOURCE',
    CourseModule = 'COURSE_MODULE',
    JourneyDailyStep = 'JOURNEY_DAILY_STEP',
    Microstep = 'MICROSTEP',
    PodcastEpisode = 'PODCAST_EPISODE',
    Recipe = 'RECIPE',
    Reset = 'RESET',
    RoleModel = 'ROLE_MODEL'
}

export type RecommendationsQuery = {
    __typename?: 'RecommendationsQuery'
    /** Retrieve actionable Pulse recommendations for a company based on employee responses for a specified set of dimensions */
    byRangeAndDimensions: Array<RecommendedContent>
}

export type RecommendationsQueryByRangeAndDimensionsArgs = {
    dimensions: Array<Surveys>
    from: Scalars['String']
    to: Scalars['String']
}

export type RecommendedContent = {
    __typename?: 'RecommendedContent'
    URL: Scalars['String']
    contentType: SupportedRecommendedContentType
    description: Scalars['String']
    difficulty: ContentDifficulty
    dimension: Surveys
    impact: ContentImpact
    reason: Scalars['String']
    riskProfile: RiskProfile
}

export type RecommendedContentByPulseDimension = {
    __typename?: 'RecommendedContentByPulseDimension'
    articles: Array<Maybe<ArticleV2>>
    courseModules: Array<ModuleV2>
    microsteps: Array<GraphQlMicrostep>
    quotes: Array<Maybe<LearnQuote>>
    resets: Array<ThriveReset>
    roleModels: Array<Maybe<ArticleV2>>
}

export type RecommendedContentDto = {
    __typename?: 'RecommendedContentDTO'
    article?: Maybe<ArticleDto>
    contentType?: Maybe<SupportedRecommendedContentType>
    reset?: Maybe<ResetDto>
}

export type RecommendedEntities =
    | ArticleV2
    | GraphQlMicrostep
    | JourneyDailyStep

export type RecommendedJourneysError = {
    __typename?: 'RecommendedJourneysError'
    errorCode: ErrorCode
}

export type RecommendedJourneysInconclusive = {
    __typename?: 'RecommendedJourneysInconclusive'
    inconclusive: Scalars['Boolean']
}

export type RecommendedJourneysResult =
    | RecommendedJourneysError
    | RecommendedJourneysInconclusive
    | RecommendedJourneysSuccess

export type RecommendedJourneysSuccess = {
    __typename?: 'RecommendedJourneysSuccess'
    inconclusive: Scalars['Boolean']
    recommendedJourneys: Array<JourneyCoreType>
}

export enum RecommendedPlantsError {
    DailyLimitReached = 'DAILY_LIMIT_REACHED'
}

export type RecommendedPlantsErrorResult = {
    __typename?: 'RecommendedPlantsErrorResult'
    error: RecommendedPlantsError
}

export type RecommendedPlantsResponse =
    | RecommendedPlantsErrorResult
    | RecommendedPlantsResult

export type RecommendedPlantsResult = {
    __typename?: 'RecommendedPlantsResult'
    recommendedPlants: Array<ProductInfo>
}

export type RecommendedResetsGraphQlInput = {
    count: Scalars['Int']
    filterByTimeOfDay: Scalars['Boolean']
    filterByUserIntention: Scalars['Boolean']
}

export type RecommendedResourceV3 =
    | ArticleV2
    | CompanyResourceGroup
    | GraphQlMicrostep
    | JourneyDailyStep
    | ModuleV2
    | PodcastEpisode
    | ThriveReset

export type ReferralEmailRequestInput = {
    allowExistingUsers?: InputMaybe<Scalars['Boolean']>
    clientId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    coworkerReferralLink: Scalars['String']
    emailAddresses: Array<Scalars['String']>
    friendsAndFamilyReferralLink?: InputMaybe<Scalars['String']>
    referralType?: InputMaybe<Scalars['String']>
}

export type ReferralEmailResponse = {
    __typename?: 'ReferralEmailResponse'
    alreadyUsers: Array<Scalars['String']>
    failedToSendEmailTo: Array<Scalars['String']>
}

export type RefreshEngagementReportResponse = {
    __typename?: 'RefreshEngagementReportResponse'
    recordsUpdated: Scalars['Int']
}

export type RemoveChallengeResult = RemoveErrorMessage | RemoveSuccess

export type RemoveErrorMessage = {
    __typename?: 'RemoveErrorMessage'
    message: Scalars['String']
}

export type RemoveSuccess = {
    __typename?: 'RemoveSuccess'
    message: Scalars['String']
}

export enum ReportInterval {
    Daily = 'DAILY',
    Monthly = 'MONTHLY',
    Ungrouped = 'UNGROUPED',
    Weekly = 'WEEKLY'
}

export enum ReportPeriod {
    OneHundredEighty = 'ONE_HUNDRED_EIGHTY',
    Sixty = 'SIXTY',
    Thirty = 'THIRTY'
}

export type ReportingCoordinates = {
    __typename?: 'ReportingCoordinates'
    x: Scalars['String']
    y: Scalars['Float']
}

export enum ReportingGranularity {
    Day = 'DAY',
    Hour = 'HOUR',
    Week = 'WEEK'
}

export type ReportingQuery = {
    __typename?: 'ReportingQuery'
    /** Returns agent analytics */
    getAgentAnalytics: AgentAnalyticsResponse
    /** Returns a high-level overview of each agent's engagement with Thrive Resets */
    getAgentEngagement?: Maybe<AgentEngagementReport>
    /** Returns the percentage of agents that have received a Thrive Reset */
    getAgentReach?: Maybe<SingleMetricResult>
    /** Returns agent reset completion rate */
    getAgentResetCompletionRate: SingleMetricResult
    /** Returns resets accepted */
    getAgentResetsAccepted: SingleMetricResult
    /** Returns resets received */
    getAgentResetsReceived: SingleMetricResult
    /** Returns agent stat for total interactions */
    getAgentTotalInteractions: SingleMetricResult
    /** Get average reset call duration for the selected client and timeframe */
    getAverageResetCallDuration: AnalyticsResponse
    /** Get answer rate for reset calls for the selected client and timeframe */
    getResetCallAnswerRate: AnalyticsResponse
    /** Get average reset call completion rate for the selected client and timeframe */
    getResetCallCompletionRate: AnalyticsResponse
    /** Get reset call count averages per agent for the selected client and timeframe */
    getResetCallCountAveragesPerAgent: AnalyticsResponse
    /** Get reset call counts for the selected client and timeframe */
    getResetCallCounts: AnalyticsResponse
    /** Get the percentage of Thrive Resets that were viewed for longer than 50 seconds */
    getResetCompletionRate?: Maybe<SingleMetricResult>
    /** Returns the number of interactions handled by Thrive agents */
    getTotalInteractions?: Maybe<SingleMetricResult>
}

export type ReportingQueryGetAgentAnalyticsArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentEngagementArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentReachArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentResetCompletionRateArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentResetsAcceptedArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentResetsReceivedArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAgentTotalInteractionsArgs = {
    agentId: Scalars['String']
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetAverageResetCallDurationArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    granularity: Granularity
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetResetCallAnswerRateArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    granularity: Granularity
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetResetCallCompletionRateArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    granularity: Granularity
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetResetCallCountAveragesPerAgentArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    granularity: Granularity
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetResetCallCountsArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    granularity: Granularity
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetResetCompletionRateArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type ReportingQueryGetTotalInteractionsArgs = {
    agentGroupId?: InputMaybe<Scalars['String']>
    companyId: Scalars['UUID']
    endDate: Scalars['Instant']
    groupType?: InputMaybe<GroupType>
    platform: PlatformType
    startDate: Scalars['Instant']
}

export type Reset = {
    _: Scalars['Boolean']
}

export type ResetApiUser = {
    __typename?: 'ResetApiUser'
    companyId?: Maybe<CompanyId>
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    partner: Partner
    partnerUserId: Scalars['PartnerUserId']
    subscriptionId?: Maybe<SubscriptionId>
    updatedAt: Scalars['Instant']
}

export type ResetAudioMutationV2 = WithBookmarkMutation &
    WithLikeMutation & {
        __typename?: 'ResetAudioMutationV2'
        bookmark: BookmarkMutation
        like: LikeMutation
    }

export type ResetAudioQueryV2 = WithBookmarkQuery &
    WithLikeQuery & {
        __typename?: 'ResetAudioQueryV2'
        bookmark: BookmarkQuery
        /** Get reset audio */
        getAudios: ThriveResetAudioPageV2
        like: LikeQuery
    }

export type ResetAudioQueryV2GetAudiosArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ResetDto = {
    __typename?: 'ResetDTO'
    closedCaption?: Maybe<Scalars['String']>
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    iframeUrlLandscape?: Maybe<Scalars['String']>
    iframeUrlPortrait?: Maybe<Scalars['String']>
    manifestUrlLandscape?: Maybe<Scalars['String']>
    manifestUrlPortrait?: Maybe<Scalars['String']>
    name: Scalars['String']
    thumbnailUrlLandscape?: Maybe<Scalars['String']>
    thumbnailUrlPortrait?: Maybe<Scalars['String']>
    uuid: Scalars['String']
}

export type ResetDetail = {
    __typename?: 'ResetDetail'
    description?: Maybe<Scalars['String']>
    id: Scalars['UUID']
    landscape?: Maybe<ResetLandscape>
    name?: Maybe<Scalars['String']>
}

export type ResetGoalDetail = {
    __typename?: 'ResetGoalDetail'
    reset: ThriveReset
    resetInfo: ResetDetail
}

export type ResetLandscape = {
    __typename?: 'ResetLandscape'
    iframe?: Maybe<Scalars['String']>
    thumbnail?: Maybe<Scalars['String']>
}

export type ResetMessage = {
    __typename?: 'ResetMessage'
    postResetMessage: Scalars['String']
    preResetBody: Scalars['String']
    preResetHeader: Scalars['String']
    uuid: Scalars['UUID']
}

export type ResetMessageQueryV2 = {
    __typename?: 'ResetMessageQueryV2'
    random?: Maybe<ResetMessage>
}

export type ResetMutation = {
    __typename?: 'ResetMutation'
    image: ImagesMutation
    personalized: PersonalizedResetsMutation
    quote: QuotesMutation
    thrive: ThriveResetsMutation
    thriveAudio: ThriveResetAudioMutation
}

export type ResetMutationV2 = {
    __typename?: 'ResetMutationV2'
    audio: ResetAudioMutationV2
    video: ResetVideoMutationV2
}

export type ResetNudgeInput = {
    /** ISO-8601 time string.  Should be the time the nudge should send, in the users' timezone. */
    sendTime: Scalars['String']
    /** UUID of the user these nudge is for */
    thriveUserId: Scalars['UUID']
}

export type ResetNudgeResponse = {
    __typename?: 'ResetNudgeResponse'
    personalizedReset?: Maybe<PersonalizedReset>
    recommendedReset: NudgeRecommendedReset
}

export type ResetQuery = {
    __typename?: 'ResetQuery'
    admin: ThriveResetAdminQuery
    audio: AudioQuery
    banner: BannerQuery
    calendar: CalendarQuery
    image: ImageQuery
    personalized: PersonalizedResetQuery
    quote: QuoteQuery
    thrive: ThriveResetQuery
    thriveAudio: ThriveResetAudioQuery
}

export type ResetQueryV2 = {
    __typename?: 'ResetQueryV2'
    audio: ResetAudioQueryV2
    message: ResetMessageQueryV2
    video: ResetVideoQueryV2
}

export enum ResetRecommendationTimeOfDay {
    Afternoon = 'Afternoon',
    Evening = 'Evening',
    Morning = 'Morning'
}

export enum ResetState {
    Complete = 'COMPLETE',
    OfferAccepted = 'OFFER_ACCEPTED',
    OfferDeclined = 'OFFER_DECLINED',
    ResetOffered = 'RESET_OFFERED',
    Timeout = 'TIMEOUT'
}

export type ResetStats = {
    __typename?: 'ResetStats'
    /** Audio Resets list */
    audioResets: Array<AudioResetStatsDetail>
    /** Number of minutes watched */
    minutes: Scalars['Int']
    /** Resets list */
    resets: Array<ResetStatsDetail>
    /** Number of resets watched */
    total: Scalars['Int']
}

export type ResetStatsDetail = {
    __typename?: 'ResetStatsDetail'
    completed: Scalars['Boolean']
    resetDetail: ResetGoalDetail
}

export type ResetVideoMutationV2 = WithBookmarkMutation &
    WithLikeMutation & {
        __typename?: 'ResetVideoMutationV2'
        bookmark: BookmarkMutation
        like: LikeMutation
    }

export type ResetVideoQueryV2 = WithBookmarkQuery &
    WithLikeQuery & {
        __typename?: 'ResetVideoQueryV2'
        bookmark: BookmarkQuery
        /** Get reset videos */
        getVideos: ThriveResetPageV2
        /** Get reset videos (admin-scoped) */
        getVideosAdmin: ThriveResetPageV2
        like: LikeQuery
    }

export type ResetVideoQueryV2GetVideosArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ResetVideoQueryV2GetVideosAdminArgs = {
    locale: Scalars['String']
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    subscriptionId?: InputMaybe<Scalars['UUID']>
}

export type ResourceTypeInput = {
    name: Scalars['String']
}

export type ResourceTypePage = {
    __typename?: 'ResourceTypePage'
    items: Array<Scalars['String']>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type Response = {
    __typename?: 'Response'
    normalizedValue: Scalars['BigDecimal']
    text: Scalars['String']
    value: Scalars['Int']
}

export type ResponseInput = {
    normalizedValue: Scalars['BigDecimal']
    text: Scalars['String']
    value: Scalars['Int']
}

export type ResponseOptionCms = {
    __typename?: 'ResponseOptionCms'
    displayOrder?: Maybe<Scalars['Int']>
    normalizedValue: Scalars['BigDecimal']
    text: Scalars['String']
    value: Scalars['Int']
}

export enum RestartError {
    ErrorRestartingJourney = 'ERROR_RESTARTING_JOURNEY',
    JourneyDoesntExist = 'JOURNEY_DOESNT_EXIST'
}

export type RetailMutation = {
    __typename?: 'RetailMutation'
    /** Create new currency */
    createCurrency?: Maybe<CurrencyInfo>
    /** Create new product */
    createProduct?: Maybe<ProductInfo>
    /** Delete a currency */
    deleteCurrency: Scalars['String']
    /** Delete product */
    deleteProduct: Scalars['String']
    grantCommunityPlant?: Maybe<UserPlant>
    /** Grants the onboarding plant to the user */
    grantOnboardingPlant?: Maybe<UserPlantInfo>
    /**
     * Grants a personal plant for a user. Available UPC:
     *         plant.beginner
     *         plant.intermediate
     *         plant.advanced
     *         plant.beginner.rare
     *         plant.intermediate.rare
     *         plant.advanced.rare
     *         plant.resiliflora.rare
     *         plant.nutrivora
     */
    grantPersonalPlant?: Maybe<UserPlant>
    grantTokens: Scalars['String']
    /** plant in personal garden */
    plantPlant: PlantActionResponse
    /** purchase product */
    purchaseProduct: PurchaseResponse
    refillWaterCan: Array<UserPlantInfo>
    /** add water to plant */
    waterPlant: WaterPlantResponse
}

export type RetailMutationCreateCurrencyArgs = {
    code: Scalars['String']
    name: Scalars['String']
}

export type RetailMutationCreateProductArgs = {
    cost: Scalars['Int']
    currencyCode: Scalars['String']
    description: Scalars['String']
    plantWateringSteps?: InputMaybe<Scalars['Int']>
    productType: Scalars['String']
    title: Scalars['String']
    upc: Scalars['String']
}

export type RetailMutationDeleteCurrencyArgs = {
    code: Scalars['String']
}

export type RetailMutationDeleteProductArgs = {
    upc: Scalars['String']
}

export type RetailMutationGrantCommunityPlantArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type RetailMutationGrantPersonalPlantArgs = {
    plantUpc: Scalars['String']
    userId: Scalars['UUID']
}

export type RetailMutationGrantTokensArgs = {
    amount: Scalars['Int']
    userId: Scalars['UUID']
}

export type RetailMutationPlantPlantArgs = {
    plantId: Scalars['UUID']
}

export type RetailMutationPurchaseProductArgs = {
    upc: Scalars['String']
}

export type RetailMutationRefillWaterCanArgs = {
    userId: Scalars['UUID']
}

export type RetailMutationWaterPlantArgs = {
    plantId: Scalars['UUID']
}

export type RetailQuery = {
    __typename?: 'RetailQuery'
    /** Get user's balance */
    getBalance: Array<BalanceInfo>
    /** Get currencies defined in the system */
    getCurrencies: Array<CurrencyInfo>
    /** Get grouped community challenge plants for whole challenge */
    getGroupedCommunityChallengePlants: Array<CommunityGardenInfo>
    /** Get user's inventory */
    getInventory: Array<UserInventoryInfo>
    /** Get user's objects */
    getObjects: Array<UserObjectInfo>
    /** Get entities (microsteps/resets) used to grow a plant */
    getPersonalPlantEntities: Array<PersonalPlantEntityInfo>
    /** Get user's plants */
    getPlants: Array<UserPlantInfo>
    /** Get products defined in the system */
    getProducts: Array<ProductInfo>
    /** Get next plants recommended for the user */
    getRecommendedPlants: RecommendedPlantsResponse
    /** Returns the user's plants rank if participating solo on a given challenge */
    getSoloPlantsRank: PlantsRanked
    /** Get team's community challenge plants */
    getTeamCommunityChallengePlants: Array<UserPlantInfo>
    /** Returns the social group plants rank on a given challenge using totals */
    getTeamPlantsRank: PlantsRanked
    /** Returns the social group plants rank on a given challenge using averages */
    getTeamPlantsRankByAverage: PlantsRanked
    /** Returns the team players plants leaderboard for a challenge using totals */
    getTeamsPlantsLeaderboard: PlantsLeaderboard
    /** Returns the team players plants leaderboard for a challenge using averages */
    getTeamsPlantsLeaderboardByAverage: PlantsLeaderboard
    /** Get user's transaction history */
    getTransactionHistory: Array<TransactionHistoryInfo>
    /** Get user's community challenge plants */
    getUserCommunityChallengePlants: Array<UserPlantInfo>
    /** Get user percentile based on total plants earned during the challenge */
    getUserPlantsPercentile: Scalars['Float']
    /** Returns the solo players plants leaderboard for a challenge */
    getUsersPlantsLeaderboard: PlantsLeaderboard
}

export type RetailQueryGetGroupedCommunityChallengePlantsArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type RetailQueryGetPersonalPlantEntitiesArgs = {
    plantId: Scalars['UUID']
}

export type RetailQueryGetSoloPlantsRankArgs = {
    challengeId: Scalars['UUID']
}

export type RetailQueryGetTeamCommunityChallengePlantsArgs = {
    challengeId: Scalars['UUID']
    socialGroupId: Scalars['UUID']
}

export type RetailQueryGetTeamPlantsRankArgs = {
    challengeId: Scalars['UUID']
    socialGroupId: Scalars['UUID']
}

export type RetailQueryGetTeamPlantsRankByAverageArgs = {
    challengeId: Scalars['UUID']
    socialGroupId: Scalars['UUID']
}

export type RetailQueryGetTeamsPlantsLeaderboardArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type RetailQueryGetTeamsPlantsLeaderboardByAverageArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type RetailQueryGetUserCommunityChallengePlantsArgs = {
    challengeId: Scalars['UUID']
}

export type RetailQueryGetUserPlantsPercentileArgs = {
    challengeId: Scalars['UUID']
}

export type RetailQueryGetUsersPlantsLeaderboardArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
}

export enum RiskCohort {
    HighRisk = 'HighRisk',
    MediumRisk = 'MediumRisk',
    Thriving = 'Thriving'
}

export type RiskCohortStats = {
    __typename?: 'RiskCohortStats'
    absoluteSize: Scalars['Int']
    changeSinceLastPeriod: Scalars['Float']
    relativeSizePercentage: Scalars['Float']
    riskCohort: RiskCohort
    totalEmployees: Scalars['Int']
}

export enum RiskProfile {
    HighRisk = 'HIGH_RISK',
    ModerateRisk = 'MODERATE_RISK',
    Thriving = 'THRIVING'
}

export type SaveFeedbackError = {
    __typename?: 'SaveFeedbackError'
    error: SaveFeedbackResponseError
}

export type SaveFeedbackResponse = SaveFeedbackError | SaveFeedbackResult

export enum SaveFeedbackResponseError {
    ErrorSavingAnswer = 'ERROR_SAVING_ANSWER',
    NonExistentUserPlan = 'NON_EXISTENT_USER_PLAN'
}

export type SaveFeedbackResult = {
    __typename?: 'SaveFeedbackResult'
    newFeedbackScore: Scalars['Int']
}

export type SaveSurveyAnswerResponse = {
    __typename?: 'SaveSurveyAnswerResponse'
    currentStreak: Scalars['Int']
    isResponseSaved: Scalars['Boolean']
    maxStreak: Scalars['Int']
    recommendedContent?: Maybe<SimpleRecommendedContent>
}

export type SavedCheckInDto = {
    __typename?: 'SavedCheckInDTO'
    recommendedContent: RecommendedContentDto
    /** @deprecated Use savedCheckIn.response.recommendedContentDimension */
    recommendedContentDimension: Surveys
    savedCheckIn: DailyCheckInDto
    streak: PulseStreakDto
}

export type ScheduleOtm = {
    __typename?: 'ScheduleOtm'
    codaId?: Maybe<Scalars['Int']>
    companyName?: Maybe<Scalars['String']>
    createdAt: Scalars['Instant']
    csvBlobDownloadUrl?: Maybe<Scalars['String']>
    csvBlobPath: Scalars['String']
    csvBlobUploadUrl: Scalars['String']
    failReason?: Maybe<Scalars['String']>
    id: Scalars['UUID']
    messageParameters: MessageParameters
    messageTime: Scalars['Instant']
    schedulerAdminId: Scalars['UUID']
    status: ScheduleOtmStatus
    templateName: OtmTemplateName
    thriveCompanyId?: Maybe<Scalars['UUID']>
    updatedAt: Scalars['Instant']
}

export type ScheduleOtmInput = {
    codaId?: InputMaybe<Scalars['Int']>
    messageParameters: MessageParametersInput
    messageTime: Scalars['Instant']
    thriveCompanyId: Scalars['UUID']
}

export type ScheduleOtmPage = {
    __typename?: 'ScheduleOtmPage'
    items: Array<ScheduleOtm>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export enum ScheduleOtmStatus {
    CsvPending = 'CSV_PENDING',
    Scheduled = 'SCHEDULED',
    SchedulingFailed = 'SCHEDULING_FAILED'
}

export type Scope = {
    __typename?: 'Scope'
    accessType: Scalars['String']
    entitlement?: Maybe<Scalars['String']>
    id: Scalars['UUID']
    resourceType: Scalars['String']
    subscriptionId: Scalars['UUID']
}

export type ScopeInputGraphQlInput = {
    scope: Scalars['String']
    subscriptionId: Scalars['UUID']
}

export type ScopePage = {
    __typename?: 'ScopePage'
    items: Array<Scope>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type ScoreRanked = {
    __typename?: 'ScoreRanked'
    /** The entity id */
    entityId?: Maybe<Scalars['UUID']>
    /** The rank position on the leaderboard */
    rank: Scalars['Int']
    /** The requester entity display name */
    requesterDisplayName: Scalars['String']
    /** Total steps number */
    totalSteps: Scalars['Int']
}

export type Script = {
    __typename?: 'Script'
    id: Scalars['UUID']
    name: Scalars['String']
}

export type SearchMutation = {
    __typename?: 'SearchMutation'
    /** Update the Algolia Search Index */
    populateAlgolia: Scalars['Int']
}

export type SearchMutationPopulateAlgoliaArgs = {
    contentType?: InputMaybe<CmsContentType>
}

export type SearchQuery = {
    __typename?: 'SearchQuery'
    /** Get a Read-Only key for accessing Algolia which enforces filter requirements */
    getAlgoliaKey: Scalars['String']
    /** Get the total number of Challenges from the CMS this service sees. */
    getCmsChallengesCount: Scalars['Int']
    /** Search for given Type using the input search test */
    search: Array<GraphQlSearchResult>
    /** Search for all types of content */
    searchAll: Array<GraphQlSearchResult>
}

export type SearchQuerySearchArgs = {
    searchText: Scalars['String']
    types: Array<Type>
}

export type SearchQuerySearchAllArgs = {
    searchText: Scalars['String']
}

export type SetGroupChallengeTypeResult = SetTypeErrorMessage | SetTypeSuccess

export type SetIntentionError = {
    __typename?: 'SetIntentionError'
    inappropriateContent?: Maybe<IntentionValidation>
    type: SetIntentionResponseErrorType
}

export type SetIntentionResponse = SetIntentionError | SetIntentionResult

export enum SetIntentionResponseErrorType {
    EmptyJourneysError = 'EMPTY_JOURNEYS_ERROR',
    InappropriateContentError = 'INAPPROPRIATE_CONTENT_ERROR',
    IntentionLengthError = 'INTENTION_LENGTH_ERROR'
}

export type SetIntentionResult = {
    __typename?: 'SetIntentionResult'
    intention: Intention
}

export type SetTypeErrorMessage = {
    __typename?: 'SetTypeErrorMessage'
    message: Scalars['String']
}

export type SetTypeSuccess = {
    __typename?: 'SetTypeSuccess'
    message: Scalars['String']
}

export type SharedPersonalizedReset = {
    __typename?: 'SharedPersonalizedReset'
    id: Scalars['UUID']
    personalizedResetId: Scalars['UUID']
}

export type SignupCode = {
    __typename?: 'SignupCode'
    code: Scalars['String']
    subscriptionId: Scalars['UUID']
    validFrom: Scalars['Instant']
    validUntil: Scalars['Instant']
}

export type SignupCodeFiltersInput = {
    subscriptionId?: InputMaybe<Scalars['UUID']>
}

export type SignupCodeInput = {
    code: Scalars['String']
    subscriptionId: Scalars['UUID']
    validFrom: Scalars['Instant']
    validUntil: Scalars['Instant']
}

export type SignupCodePage = {
    __typename?: 'SignupCodePage'
    items: Array<SignupCode>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type SimpleRecommendedContent = {
    __typename?: 'SimpleRecommendedContent'
    contentType: SupportedRecommendedContentType
    domainId: Scalars['String']
    gqlQueryString: Scalars['String']
    title: Scalars['String']
}

export type SingleMetricResult = {
    __typename?: 'SingleMetricResult'
    count?: Maybe<Scalars['Int']>
    countDescription?: Maybe<Scalars['String']>
    percentChange?: Maybe<Scalars['Float']>
    result: Scalars['Float']
}

export type Skill = {
    __typename?: 'Skill'
    id: Scalars['String']
    name: Scalars['String']
}

export type SkipAssessmentInput = {
    assessmentAttemptId: Scalars['UUID']
    userTime?: InputMaybe<Scalars['Instant']>
}

export type SlackIntegrationMutation = {
    __typename?: 'SlackIntegrationMutation'
    /** Disables Slack integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Slack integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type SlackIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type SlackIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    workspaceId: Scalars['String']
}

export type SlackMutation = {
    __typename?: 'SlackMutation'
    notify: NotifyMutation
}

export type SlackQuery = {
    __typename?: 'SlackQuery'
    firstQuery: Scalars['String']
    team: SlackTeamQuery
}

export type SlackQueryTeamArgs = {
    teamId: Scalars['String']
}

export type SlackTeamBotInfo = {
    __typename?: 'SlackTeamBotInfo'
    appId?: Maybe<Scalars['String']>
    botId?: Maybe<Scalars['String']>
    botUserId?: Maybe<BotUserId>
    companyId: Scalars['UUID']
    enterpriseId?: Maybe<Scalars['String']>
    enterpriseName?: Maybe<Scalars['String']>
    enterpriseUrl?: Maybe<Scalars['String']>
    installedAt?: Maybe<Scalars['Instant']>
    isEnterpriseInstall?: Maybe<Scalars['Boolean']>
    teamId?: Maybe<Scalars['String']>
    teamName?: Maybe<Scalars['String']>
}

export type SlackTeamBotTokens = {
    __typename?: 'SlackTeamBotTokens'
    botAccessToken?: Maybe<Scalars['String']>
    botRefreshToken?: Maybe<Scalars['String']>
    botScope?: Maybe<Scalars['String']>
    botTokenExpiresAt?: Maybe<Scalars['Instant']>
    companyId?: Maybe<Scalars['UUID']>
    scope?: Maybe<Scalars['String']>
    teamId?: Maybe<Scalars['String']>
    tokenType?: Maybe<Scalars['String']>
}

export type SlackTeamProfileField = {
    __typename?: 'SlackTeamProfileField'
    fieldName?: Maybe<Scalars['String']>
    id?: Maybe<Scalars['String']>
    isHidden?: Maybe<Scalars['Boolean']>
    label?: Maybe<Scalars['String']>
    ordering?: Maybe<Scalars['Int']>
    possibleValues?: Maybe<Array<Scalars['String']>>
    type?: Maybe<Scalars['String']>
}

export type SlackTeamProfileFields = {
    __typename?: 'SlackTeamProfileFields'
    fieldsBySection: Array<SlackTeamProfileSection>
}

export type SlackTeamProfileSection = {
    __typename?: 'SlackTeamProfileSection'
    fields: Array<SlackTeamProfileField>
    id?: Maybe<Scalars['String']>
    isHidden?: Maybe<Scalars['Boolean']>
    label?: Maybe<Scalars['String']>
    order?: Maybe<Scalars['Int']>
}

export type SlackTeamQuery = {
    __typename?: 'SlackTeamQuery'
    botInfo?: Maybe<SlackTeamBotInfo>
    botTokens?: Maybe<SlackTeamBotTokens>
    profileFields?: Maybe<SlackTeamProfileFields>
    teamId: Scalars['String']
}

export type SleepHabits = {
    __typename?: 'SleepHabits'
    /** Challenge id */
    challengeId: Scalars['UUID']
    /** Habits at challenge end */
    endAnswer?: Maybe<Scalars['Int']>
    /** Habits at challenge start */
    startAnswer?: Maybe<Scalars['Int']>
}

export type SleepHabitsOperationResult =
    | ErrorMessageSleepHabits
    | SleepHabitsResult

export type SleepHabitsResult = {
    __typename?: 'SleepHabitsResult'
    sleepHabits: SleepHabits
}

export type SleepMutation = {
    __typename?: 'SleepMutation'
    /** Save an updated Sleep Count from Apple Health */
    saveAppleHealthSleepBlocks: Scalars['Boolean']
}

export type SleepMutationSaveAppleHealthSleepBlocksArgs = {
    sleepRecords: Array<AppleHealthSleepBlocksInput>
}

export type SleepSummaryStats = {
    /** Average time to bed since midnight(UTC) in seconds */
    averageBedTimeStart?: Maybe<Scalars['Long']>
    /** Average minutes asleep */
    averageMinutesAsleep: Scalars['Float']
}

export type SleepSummaryStatsResponse = SleepSummaryStats & {
    __typename?: 'SleepSummaryStatsResponse'
    averageBedTimeStart?: Maybe<Scalars['Long']>
    averageMinutesAsleep: Scalars['Float']
}

export type SmartNudgeReset = {
    __typename?: 'SmartNudgeReset'
    resetId?: Maybe<Scalars['UUID']>
}

export type SmartNudgesResetInput = {
    resetId: Scalars['UUID']
    thriveUserId: Scalars['UUID']
}

export type SmartNudgesResetMutation = {
    __typename?: 'SmartNudgesResetMutation'
    /** Send a Smart Nudges message to users */
    sendSmartNudgesResetMessage: SmartNudgesResetResult
}

export type SmartNudgesResetMutationSendSmartNudgesResetMessageArgs = {
    input: SmartNudgesResetInput
}

export type SmartNudgesResetResult = {
    __typename?: 'SmartNudgesResetResult'
    error?: Maybe<Scalars['String']>
    messageSent: Scalars['Boolean']
}

export type SocialGroup = {
    __typename?: 'SocialGroup'
    /** the challengeId of the group */
    challengeId?: Maybe<Scalars['UUID']>
    /** When this social group was created */
    createdAt: Scalars['Instant']
    /** User id of who created this social group */
    createdBy: Scalars['String']
    /** The id of this social group */
    id: Scalars['UUID']
    /** if the group is the main community challenge group */
    isMainCommunitySocialGroup?: Maybe<Scalars['Boolean']>
    /** Indicates if the social group is public or private */
    isPublic: Scalars['Boolean']
    /** if the group is a community challenge solo group */
    isSoloChallenge?: Maybe<Scalars['Boolean']>
    /** the expiration date for the social group */
    isSubscriptionGroup: Scalars['Boolean']
    /** the join date of the user */
    joinDate?: Maybe<Scalars['Instant']>
    /** User id of who last updated this social group */
    lastUpdatedBy?: Maybe<Scalars['String']>
    /** Max members allowed in the group (null is unlimited) */
    maxSize?: Maybe<Scalars['Int']>
    /** the total member count for the group */
    memberCount?: Maybe<Scalars['Int']>
    /**
     * Current members of this social group
     * @deprecated this dataloader is deprecated, use `members` with pagination, replace with paginatedMembers
     */
    members: Array<SocialGroupMember>
    /** The name of this social group */
    name: Scalars['String']
    /** Current members of this social group, paginated */
    paginatedMembers: Array<SocialGroupMember>
    /** the stats for the social group */
    socialGroupStats?: Maybe<SocialGroupStats>
    /** The subscription id */
    subscriptionId?: Maybe<Scalars['UUID']>
    /** When this social group was last updated */
    updatedAt?: Maybe<Scalars['Instant']>
    /** the expiration date for the social group */
    validTo?: Maybe<Scalars['Instant']>
}

export type SocialGroupPaginatedMembersArgs = {
    limit: Scalars['Int']
    offset: Scalars['Int']
}

export type SocialGroupCreateInput = {
    /** The challenge id */
    challengeId?: InputMaybe<Scalars['UUID']>
    /** Indicates if the social group is public or private */
    isPublic?: InputMaybe<Scalars['Boolean']>
    /** Indicates if members are unlimited */
    isUnlimited?: InputMaybe<Scalars['Boolean']>
    /** Indicates the maximum number of members allowed in the social group */
    maxSize?: InputMaybe<Scalars['Int']>
    /** The name for the new social group */
    name: Scalars['String']
    /** The expiration date for the social group */
    validTo?: InputMaybe<Scalars['String']>
}

export type SocialGroupEditInput = {
    /** Indicates if the social group is public or private */
    isPublic?: InputMaybe<Scalars['Boolean']>
    /** The updated name for this social group */
    name: Scalars['String']
    /** The ID of the social group to edit */
    socialGroupId: Scalars['UUID']
}

export type SocialGroupMember = {
    __typename?: 'SocialGroupMember'
    createdAt: Scalars['Instant']
    /** The user's display name */
    displayName: Scalars['String']
    updatedAt: Scalars['Instant']
    /** The user object for this member */
    user: User
}

export type SocialGroupMutation = {
    __typename?: 'SocialGroupMutation'
    createMultiplePostInAllUserGroups: Array<SocialGroupsResult>
    /** @deprecated this mutation is deprecated, replace with createMultiplePostInAllUserGroups */
    createPostInAllUserGroups?: Maybe<Array<SocialGroupsResult>>
    createPostInGroup?: Maybe<SocialGroupsResult>
    createSocialGroup?: Maybe<SocialGroupsResult>
    deleteSocialGroup?: Maybe<SocialGroupsResult>
    editDisplayName: SocialGroupsResult
    editIsPublic: SocialGroupsResult
    editSocialGroup?: Maybe<SocialGroupsResult>
    joinSocialGroup: SocialGroupsResult
    joinSocialGroupAdmin: SocialGroupsResult
    joinSocialGroups: Array<SocialGroupsResult>
    joinSoloSocialGroup?: Maybe<SocialGroupsResult>
    joinSoloSocialGroupAdmin?: Maybe<SocialGroupsResult>
    leaveSocialGroup: SocialGroupsResult
    leaveSocialGroupAdmin: SocialGroupsResult
    leaveSoloSocialGroupAdmin?: Maybe<SocialGroupsResult>
    reactToPost: SocialGroupsResult
    undoSocialGroupPostReaction: SocialGroupsResult
    updateDisplayName: SocialGroupsResult
    validateDisplayName: SocialGroupsResult
}

export type SocialGroupMutationCreateMultiplePostInAllUserGroupsArgs = {
    postCreateInputs: Array<PostCreateInput>
}

export type SocialGroupMutationCreatePostInAllUserGroupsArgs = {
    postCreateInput: PostCreateInput
}

export type SocialGroupMutationCreatePostInGroupArgs = {
    postCreateInput: PostCreateInput
}

export type SocialGroupMutationCreateSocialGroupArgs = {
    newSocialGroup: SocialGroupCreateInput
}

export type SocialGroupMutationDeleteSocialGroupArgs = {
    socialGroupID: Scalars['UUID']
}

export type SocialGroupMutationEditDisplayNameArgs = {
    displayName: Scalars['String']
}

export type SocialGroupMutationEditIsPublicArgs = {
    isPublic: Scalars['Boolean']
}

export type SocialGroupMutationEditSocialGroupArgs = {
    updatedSocialGroup: SocialGroupEditInput
}

export type SocialGroupMutationJoinSocialGroupArgs = {
    publishPost?: InputMaybe<Scalars['Boolean']>
    socialGroupID: Scalars['UUID']
}

export type SocialGroupMutationJoinSocialGroupAdminArgs = {
    socialGroupID: Scalars['UUID']
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type SocialGroupMutationJoinSocialGroupsArgs = {
    socialGroupIDs: Array<Scalars['UUID']>
}

export type SocialGroupMutationJoinSoloSocialGroupArgs = {
    challengeId: Scalars['UUID']
    validTo?: InputMaybe<Scalars['String']>
}

export type SocialGroupMutationJoinSoloSocialGroupAdminArgs = {
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    subscriptionId: Scalars['UUID']
    userId: Scalars['UUID']
    validTo?: InputMaybe<Scalars['String']>
}

export type SocialGroupMutationLeaveSocialGroupArgs = {
    socialGroupID: Scalars['UUID']
}

export type SocialGroupMutationLeaveSocialGroupAdminArgs = {
    socialGroupID: Scalars['UUID']
    userId: Scalars['UUID']
}

export type SocialGroupMutationLeaveSoloSocialGroupAdminArgs = {
    challengeId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type SocialGroupMutationReactToPostArgs = {
    postReactionInput: PostReactionInput
}

export type SocialGroupMutationUndoSocialGroupPostReactionArgs = {
    reactionId: Scalars['UUID']
}

export type SocialGroupMutationUpdateDisplayNameArgs = {
    displayName: Scalars['String']
}

export type SocialGroupMutationValidateDisplayNameArgs = {
    displayName: Scalars['String']
}

export type SocialGroupOperationSucceeded = {
    __typename?: 'SocialGroupOperationSucceeded'
    /** The ID of the social group for which the operation succeeded */
    socialGroupId: Scalars['UUID']
}

export type SocialGroupQuery = {
    __typename?: 'SocialGroupQuery'
    /** Get all subscription social groups */
    allSocialGroupsForCompany: Array<SocialGroup>
    /** Get cache stats */
    cacheStats: Scalars['String']
    /** Get the posts of a given challengeId */
    communitySocialGroupActivityFeed: Array<Post>
    /**
     * Get community social groups
     * @deprecated Use companyChallengeSocialGroups instead, replace with companyChallengeSocialGroups
     */
    communitySocialGroups: Array<SocialGroup>
    /** Get all company challenge social groups */
    companyChallengeSocialGroups: Array<SocialGroup>
    /** Get the posts of the user's subscriptionId */
    companySocialGroupActivityFeed: Array<Post>
    /** Get the display name of the user */
    displayName: DisplayName
    /** Get all display names for the given user ids */
    getDisplayNames: Array<DisplayName>
    /** Get profanity words */
    profanities: Array<Scalars['String']>
    /** Get social group by ID */
    socialGroup?: Maybe<SocialGroupsResult>
    /** Get the posts of a given group id and optionally for a given challenge id */
    socialGroupActivityFeed: Array<Post>
    /** Get all social groups for subscription except challenge groups */
    socialGroupsForCompany: Array<SocialGroup>
    /** Get all social groups for subscription */
    socialGroupsForCompanyV2: SocialGroupResponse
    /** Get all social groups for user */
    socialGroupsForUser: Array<SocialGroup>
    /** Get all social groups for user */
    socialGroupsForUserV2: SocialGroupResponse
}

export type SocialGroupQueryCacheStatsArgs = {
    cacheType: CacheTypes
}

export type SocialGroupQueryCommunitySocialGroupActivityFeedArgs = {
    challengeId: Scalars['UUID']
    limit: Scalars['Int']
    offset: Scalars['Int']
}

export type SocialGroupQueryCommunitySocialGroupsArgs = {
    challengeId: Scalars['UUID']
}

export type SocialGroupQueryCompanyChallengeSocialGroupsArgs = {
    challengeId: Scalars['UUID']
    excludeFullGroups?: InputMaybe<Scalars['Boolean']>
}

export type SocialGroupQueryCompanySocialGroupActivityFeedArgs = {
    limit: Scalars['Int']
    offset: Scalars['Int']
}

export type SocialGroupQueryGetDisplayNamesArgs = {
    userIds: Array<Scalars['UUID']>
}

export type SocialGroupQueryProfanitiesArgs = {
    language: Scalars['String']
}

export type SocialGroupQuerySocialGroupArgs = {
    id: Scalars['UUID']
}

export type SocialGroupQuerySocialGroupActivityFeedArgs = {
    challengeId?: InputMaybe<Scalars['UUID']>
    limit: Scalars['Int']
    offset: Scalars['Int']
    socialGroupId: Scalars['UUID']
}

export type SocialGroupQuerySocialGroupsForUserArgs = {
    challengeId?: InputMaybe<Scalars['UUID']>
}

export type SocialGroupResponse = {
    __typename?: 'SocialGroupResponse'
    /** the social groups for the company or the user */
    socialGroups: Array<SocialGroup>
    /** total number of groups in company */
    totalGroupsForCompany: Scalars['Int']
    /** total social groups the user has joined */
    totalGroupsUserJoined: Scalars['Int']
}

export type SocialGroupStats = {
    __typename?: 'SocialGroupStats'
    /** total number of posts in the social group */
    totalPostCount: Scalars['Int']
    /** total number celebrations given in the social group */
    totalReactionCount: Scalars['Int']
}

export type SocialGroupUndoPostReactionSucceed = {
    __typename?: 'SocialGroupUndoPostReactionSucceed'
    /** The ID of the reaction for which the operation succeeded */
    reactionId: Scalars['UUID']
}

export type SocialGroupsResult =
    | InvalidAction
    | InvalidInput
    | PostOperationSucceeded
    | PostReactionCreationSucceeded
    | SocialGroup
    | SocialGroupOperationSucceeded
    | SocialGroupUndoPostReactionSucceed
    | UpdateDisplayNameOperationSucceeded
    | UpdateIsPublicOperationSucceeded
    | ValidateDisplayNameOperationSucceeded

export enum SortOrder {
    CheckedDateAsc = 'CHECKED_DATE_ASC',
    CheckedDateDesc = 'CHECKED_DATE_DESC',
    CheckedNumberAsc = 'CHECKED_NUMBER_ASC',
    CheckedNumberDesc = 'CHECKED_NUMBER_DESC',
    CommittedAsc = 'COMMITTED_ASC',
    CommittedDesc = 'COMMITTED_DESC',
    PopularityAsc = 'POPULARITY_ASC',
    PopularityDesc = 'POPULARITY_DESC'
}

export type Step = {
    __typename?: 'Step'
    checked?: Maybe<Scalars['Boolean']>
    createdAt: Scalars['Instant']
    dataType: StepType
    date?: Maybe<Scalars['ISODate']>
    frequency?: Maybe<StepFrequency>
    id: Scalars['UUID']
    order?: Maybe<Scalars['Int']>
    position?: Maybe<Scalars['Int']>
    stepContent: StepContent
    updatedAt: Scalars['Instant']
}

export type StepContent = GraphQlMicrostep

export enum StepFrequency {
    Daily = 'DAILY',
    Once = 'ONCE',
    Situational = 'SITUATIONAL',
    Weekly = 'WEEKLY'
}

export enum StepType {
    Microstep = 'MICROSTEP'
}

export type StepsGoalDetail = {
    __typename?: 'StepsGoalDetail'
    steps: Scalars['Int']
}

export type StepsInfo = {
    __typename?: 'StepsInfo'
    /** User's today steps */
    todaySteps: Scalars['Int']
    /** User's total steps */
    totalSteps: Scalars['Int']
}

export type Story = {
    __typename?: 'Story'
    createdAt?: Maybe<Scalars['Instant']>
    /** The id of this story */
    id: Scalars['UUID']
    /** The story */
    story?: Maybe<Scalars['String']>
    /** The id of story type */
    storyTypeId: Scalars['UUID']
    updatedAt?: Maybe<Scalars['Instant']>
    /** The id of the user challenge activity */
    userChallengeActivityId: Scalars['UUID']
    /** The visiblity of the story */
    visibility?: Maybe<Visibility>
}

export type StoryCreateInput = {
    /** The story to create */
    message: Scalars['String']
    /** The story type ID of the story to create */
    storyTypeId: Scalars['UUID']
    /** The user-challenge ID of the participation */
    userChallengeActivityId: Scalars['UUID']
    /** The visibility of the story */
    visibility: Visibility
}

export type StoryOperationSucceeded = {
    __typename?: 'StoryOperationSucceeded'
    /** The ID of the story for which the operation succeeded */
    storyId: Scalars['UUID']
}

export type StoryQuestionCreateInput = {
    id?: InputMaybe<Scalars['UUID']>
    kind?: InputMaybe<Scalars['String']>
    language: Scalars['String']
    questions: Scalars['String']
    region: Scalars['String']
}

export type StoryType = {
    __typename?: 'StoryType'
    createdAt?: Maybe<Scalars['Instant']>
    /** The id of this story type */
    id: Scalars['UUID']
    kind?: Maybe<Scalars['String']>
    language: Scalars['String']
    questions?: Maybe<Scalars['String']>
    region: Scalars['String']
    subTitle?: Maybe<Scalars['String']>
    termsAndConditionsUrl?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['Instant']>
}

export type StoryTypeAdmin = {
    __typename?: 'StoryTypeAdmin'
    createdAt?: Maybe<Scalars['Instant']>
    /** The id of this story type */
    id: Scalars['UUID']
    /** The kind of this story type */
    kind?: Maybe<Scalars['String']>
    /** The name of this story type */
    name?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['Instant']>
}

export type SubmitResponseInput = {
    assessmentAttemptId: Scalars['UUID']
    assessmentItemId: Scalars['UUID']
    questionText: Scalars['String']
    response?: InputMaybe<ResponseInput>
    userTime?: InputMaybe<UserTimezoneInput>
}

export type SubscriptionCompanyInput = {
    /** The company id */
    companyId: Scalars['UUID']
    /** The subscription id */
    subscriptionId: Scalars['UUID']
}

export type SubscriptionFiltersInput = {
    companyId?: InputMaybe<Scalars['UUID']>
}

export type SubscriptionGroupCodes = {
    __typename?: 'SubscriptionGroupCodes'
    groupCodes: Array<Scalars['String']>
    subscription: UserSubscription
}

export type SubscriptionGroupCodesResponse = {
    __typename?: 'SubscriptionGroupCodesResponse'
    items: Array<SubscriptionGroupCodes>
}

export type SubscriptionId = {
    __typename?: 'SubscriptionId'
    id: Scalars['UUID']
}

export type SubscriptionInputGraphQlInput = {
    companyId: Scalars['UUID']
    enabled?: InputMaybe<Scalars['Boolean']>
    expiryDate?: InputMaybe<Scalars['Instant']>
    internalDescription?: InputMaybe<Scalars['String']>
    isDefault: Scalars['Boolean']
    license?: InputMaybe<LicenseType>
    seatsAllowed?: InputMaybe<Scalars['Int']>
    startDate?: InputMaybe<Scalars['Instant']>
    subscriptionType?: InputMaybe<SubscriptionType>
}

export type SubscriptionPage = {
    __typename?: 'SubscriptionPage'
    items: Array<UserSubscription>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export enum SubscriptionType {
    Employee = 'employee',
    FriendsAndFamily = 'friendsAndFamily',
    Other = 'other'
}

export type SuccessStoryFeedback = {
    __typename?: 'SuccessStoryFeedback'
    featuredTextFull: Scalars['String']
    featuredTextShort: Scalars['String']
    featuredTitle: Scalars['String']
    hint: Scalars['String']
    id: Scalars['UUID']
    photoUploadText: Scalars['String']
    savedFooter: Scalars['String']
    savedText: Scalars['String']
    savedTitle: Scalars['String']
    submittedFooter: Scalars['String']
    submittedText: Scalars['String']
    submittedTitle: Scalars['String']
    subtitle: Scalars['String']
    suggestions: Array<Scalars['String']>
    suggestionsTitle: Scalars['String']
    title: Scalars['String']
    type: SuccessStoryFeedbackType
}

export enum SuccessStoryFeedbackType {
    CourseLesson = 'CourseLesson',
    JourneyLevel = 'JourneyLevel',
    RoleModel = 'RoleModel'
}

export type SuccessStoryMutation = {
    __typename?: 'SuccessStoryMutation'
    saveMySuccessStory?: Maybe<MySuccessStory>
}

export type SuccessStoryMutationSaveMySuccessStoryArgs = {
    input: MySuccessStoryInput
}

export type SuccessStoryQuery = {
    __typename?: 'SuccessStoryQuery'
    /** Get My Success Stories, based on userId from context */
    getMySuccessStories: MySuccessStoryPage
    /** Get My Success Story, based on userId from context */
    getMySuccessStory?: Maybe<MySuccessStory>
    /** Get Success Story Feedback by type */
    getSuccessStoryFeedback?: Maybe<SuccessStoryFeedback>
}

export type SuccessStoryQueryGetMySuccessStoriesArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type SuccessStoryQueryGetMySuccessStoryArgs = {
    id: Scalars['UUID']
}

export type SuccessStoryQueryGetSuccessStoryFeedbackArgs = {
    type: SuccessStoryFeedbackType
}

export type SuperlativeWithScore = {
    __typename?: 'SuperlativeWithScore'
    description: Scalars['String']
    imageUrl: Scalars['String']
    journey?: Maybe<AssessmentJourney>
    locale: Scalars['String']
    lowScoreDescription: Scalars['String']
    lowScoreTitle: Scalars['String']
    score: Scalars['Float']
    title: Scalars['String']
    uuid: Scalars['UUID']
}

export enum SupportedRecommendedContentType {
    Article = 'ARTICLE',
    Course = 'COURSE',
    None = 'NONE',
    Qotd = 'QOTD',
    Reset = 'RESET'
}

export enum SurveyEligibility {
    AlreadyResponded = 'AlreadyResponded',
    AlreadySent = 'AlreadySent',
    Eligible = 'Eligible',
    NotSubscribed = 'NotSubscribed'
}

export type SurveyResponse = {
    __typename?: 'SurveyResponse'
    selectedResponseOption: Scalars['Int']
    surveyQuestionDimension: Scalars['String']
    surveyQuestionJourneyUUID?: Maybe<Scalars['String']>
    surveyQuestionUUID: Scalars['String']
}

export type SurveyResponseInput = {
    selectedResponseOption: Scalars['Int']
    surveyQuestionDimension: Scalars['String']
    surveyQuestionJourneyUUID?: InputMaybe<Scalars['String']>
    surveyQuestionUUID: Scalars['String']
}

export enum Surveys {
    Allyship = 'ALLYSHIP',
    Efficacy = 'EFFICACY',
    EnergyLevels = 'ENERGY_LEVELS',
    Enthusiasm = 'ENTHUSIASM',
    Productivity = 'PRODUCTIVITY',
    Retention = 'RETENTION'
}

export type SurveysMutation = {
    __typename?: 'SurveysMutation'
    /** Create any possible make-up Daily Check-Ins for a user for the past week */
    createMakeUpCheckIns?: Maybe<Array<DailyCheckInDto>>
    /** Get a user's Daily Check-In question */
    requestCheckIn?: Maybe<DailyCheckInDto>
    /** Bulk request Daily Check-Ins for users */
    requestCheckIns?: Maybe<Array<DailyCheckInDto>>
    /** Save a Daily Check-In response */
    saveCheckInResponse?: Maybe<SavedCheckInDto>
}

export type SurveysMutationCreateMakeUpCheckInsArgs = {
    channel: Channel
}

export type SurveysMutationRequestCheckInArgs = {
    channel: Channel
    requestDate: Scalars['ISODate']
}

export type SurveysMutationRequestCheckInsArgs = {
    request: BulkDailyCheckInRequestInput
}

export type SurveysMutationSaveCheckInResponseArgs = {
    responseInputData: DailyCheckInResponseInput
}

export type SurveysQuery = {
    __typename?: 'SurveysQuery'
    /**
     * Retrieve the response of a user
     * @deprecated Use dailyCheckInResponse2 or dailyCheckInResponse2Admin instead, replace with dailyCheckInResponse2
     */
    dailyCheckInResponse: CheckInResponseDetailsPage
    /** Retrieve your survey responses */
    dailyCheckInResponse2: CheckInResponseDetailsPage
    /** Retrieve the survey responses of a user */
    dailyCheckInResponse2Admin: CheckInResponseDetailsPage
    /** Get all survey questions */
    getAll: Array<PulseSurveyQuestionDto>
    /** Get survey questions by ID */
    getById?: Maybe<PulseSurveyQuestionDto>
    /** Get a paginated list of Daily Check-In responses */
    responses?: Maybe<CheckInResponseDetailsPage>
}

export type SurveysQueryDailyCheckInResponseArgs = {
    companyId: Scalars['UUID']
    fromDate: Scalars['ISODate']
    locale: Scalars['Locale']
    page: Scalars['Long']
    pageSize: Scalars['Int']
    thriveToken: Scalars['String']
    thriveUserId: Scalars['UUID']
    toDate: Scalars['ISODate']
}

export type SurveysQueryDailyCheckInResponse2Args = {
    fromDate: Scalars['ISODate']
    locale: Scalars['Locale']
    page: Scalars['Long']
    pageSize: Scalars['Int']
    toDate: Scalars['ISODate']
}

export type SurveysQueryDailyCheckInResponse2AdminArgs = {
    companyId: Scalars['UUID']
    fromDate: Scalars['ISODate']
    locale: Scalars['Locale']
    page: Scalars['Long']
    pageSize: Scalars['Int']
    toDate: Scalars['ISODate']
    userId: Scalars['UUID']
}

export type SurveysQueryGetAllArgs = {
    activeOnly?: InputMaybe<Scalars['Boolean']>
}

export type SurveysQueryGetByIdArgs = {
    activeOnly?: InputMaybe<Scalars['Boolean']>
    surveyId: Scalars['UUID']
}

export type SurveysQueryResponsesArgs = {
    from?: InputMaybe<Scalars['ISODate']>
    pagination?: InputMaybe<PaginationInput>
    surveyDimension?: InputMaybe<Surveys>
    to?: InputMaybe<Scalars['ISODate']>
}

export type Tag = {
    __typename?: 'Tag'
    id: Scalars['ID']
    name: Scalars['String']
    uuid?: Maybe<Scalars['UUID']>
}

export type TeamsMutation = {
    __typename?: 'TeamsMutation'
    notify: TeamsNotifyMutation
    otm: TeamsOtmMutation
    resetTokenForUser: TeamsResetTokenForUserMutation
    savePulseSettings: PulseSettingsMutation
    sendIntentionSelectedConfirmationMessage: IntentionSettingMutation
    sendSmartNudgesReset: SmartNudgesResetMutation
    subscribeUser: MsTeamsSubscribeUserMutation
}

export type TeamsNotifyChallengeUserResult = {
    __typename?: 'TeamsNotifyChallengeUserResult'
    error?: Maybe<Scalars['String']>
    numberOfUsersNotified: Scalars['Int']
}

export type TeamsNotifyMutation = {
    __typename?: 'TeamsNotifyMutation'
    /** Enqueue baseline assessment nudge to a user. We will send a nudge at 10 am the day after their intention set time. Best effort is made to not send two of these messages to the user but the caller should make sure not to call the endpoint more than once per user. */
    enqueueBaselineAssessmentNudge: TeamsNudgeBaselineAssessmentResult
    /** Enqueue daily challenge message to user */
    enqueueDailyChallengeMessage: TeamsSendDailyChallengeMessageResult
    /** Send a reminder to complete the daily goals for a challenge */
    notifyDailyGoalsChallengeUsers: TeamsNotifyChallengeUserResult
}

export type TeamsNotifyMutationEnqueueBaselineAssessmentNudgeArgs = {
    intentionSetTime: Scalars['Instant']
    thriveUserId: Scalars['UUID']
}

export type TeamsNotifyMutationEnqueueDailyChallengeMessageArgs = {
    input: TeamsThriveChallengeMessageInput
}

export type TeamsNotifyMutationNotifyDailyGoalsChallengeUsersArgs = {
    input: TeamsThriveChallengeDailyGoalNotificationInput
}

export type TeamsNudgeBaselineAssessmentResult = {
    __typename?: 'TeamsNudgeBaselineAssessmentResult'
    error?: Maybe<Scalars['String']>
    notified: Scalars['Boolean']
}

export type TeamsOtmMutation = {
    __typename?: 'TeamsOtmMutation'
    /** Schedule OTM */
    scheduleOtm: TeamsScheduleOtmResult
}

export type TeamsOtmMutationScheduleOtmArgs = {
    input: ScheduleOtmInput
}

export type TeamsOtmQuery = {
    __typename?: 'TeamsOtmQuery'
    /** Get individual scheduled one time message */
    getScheduledOneTimeMessage?: Maybe<ScheduleOtm>
    /** Get scheduled one time messages */
    getScheduledOneTimeMessages: ScheduleOtmPage
}

export type TeamsOtmQueryGetScheduledOneTimeMessageArgs = {
    id: Scalars['UUID']
}

export type TeamsOtmQueryGetScheduledOneTimeMessagesArgs = {
    limit: Scalars['Int']
    page: Scalars['Int']
    thriveCompanyId?: InputMaybe<Scalars['UUID']>
}

export type TeamsQuery = {
    __typename?: 'TeamsQuery'
    getNotificationSettings: GetNotificationSettings
    getTimezoneForUsers: GetTimezoneForUsersQuery
    otm: TeamsOtmQuery
}

export type TeamsResetTokenForUserInput = {
    userId: Scalars['UUID']
}

export type TeamsResetTokenForUserMutation = {
    __typename?: 'TeamsResetTokenForUserMutation'
    /** Force refresh the thrive token for a user */
    resetTokenForUser: TeamsResetTokenForUserResult
}

export type TeamsResetTokenForUserMutationResetTokenForUserArgs = {
    input: TeamsResetTokenForUserInput
}

export type TeamsResetTokenForUserResult = {
    __typename?: 'TeamsResetTokenForUserResult'
    error?: Maybe<Scalars['String']>
    updated: Scalars['Boolean']
}

export type TeamsScheduleOtmResult = {
    __typename?: 'TeamsScheduleOtmResult'
    error?: Maybe<Scalars['String']>
    scheduled: Scalars['Boolean']
    uploadUrl?: Maybe<Scalars['String']>
}

export type TeamsSendDailyChallengeMessageResult = {
    __typename?: 'TeamsSendDailyChallengeMessageResult'
    error?: Maybe<Scalars['String']>
    notificationSent: Scalars['Boolean']
}

export type TeamsSendIntentionSelectedConfirmationResult = {
    __typename?: 'TeamsSendIntentionSelectedConfirmationResult'
    error?: Maybe<Scalars['String']>
    notificationSent: Scalars['Boolean']
}

export type TeamsSubscribeUserResult = {
    __typename?: 'TeamsSubscribeUserResult'
    error?: Maybe<Scalars['String']>
    subscribed: Scalars['Boolean']
}

export type TeamsThriveChallengeDailyGoalNotificationInput = {
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    users: Array<Scalars['UUID']>
}

export type TeamsThriveChallengeMessageInput = {
    challengeIds: Array<Scalars['UUID']>
    thriveUserId: Scalars['UUID']
}

export type TemplateBiotype = {
    __typename?: 'TemplateBiotype'
    name?: Maybe<Scalars['String']>
}

export type TemplateJourney = {
    __typename?: 'TemplateJourney'
    id: Scalars['UUID']
    name: Scalars['String']
    shortName: Scalars['String']
}

export type TemplateOperationResult =
    | ChallengeTemplateInstance
    | ErrorMessageTemplate
    | UpdateResult

export type TemplateQuote = {
    __typename?: 'TemplateQuote'
    author: Scalars['String']
    backgroundColor?: Maybe<Scalars['String']>
    backgroundImageUrl?: Maybe<Scalars['String']>
    body: Scalars['String']
    day: Scalars['Int']
    id: Scalars['UUID']
    imageWithTextUrl?: Maybe<Scalars['String']>
}

export type TestNudge = {
    __typename?: 'TestNudge'
    testCtaUrl?: Maybe<Scalars['String']>
    testValue?: Maybe<Scalars['String']>
}

export type ThriveAudioConnection = {
    __typename?: 'ThriveAudioConnection'
    edges: Array<ThriveAudioEdge>
    /** @deprecated this field is deprecated, replace with nodes */
    items: Array<Audio>
    /** @deprecated this field is deprecated, replace with pageInfo */
    itemsPerPage: Scalars['Int']
    nodes: Array<Audio>
    /** @deprecated this field is deprecated, replace with pageInfo */
    page: Scalars['Int']
    pageInfo: PageInfo
    /** @deprecated this field is deprecated, replace with pageInfo */
    total: Scalars['Int']
}

export type ThriveAudioEdge = {
    __typename?: 'ThriveAudioEdge'
    cursor: Scalars['String']
    node: Audio
}

export type ThriveAudioQuery = {
    __typename?: 'ThriveAudioQuery'
    get: ThriveAudioConnection
    getByGenres: ThriveAudioConnection
    getByIds: Array<Audio>
}

export type ThriveAudioQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    page?: InputMaybe<Scalars['Int']>
    provider?: InputMaybe<Scalars['String']>
}

export type ThriveAudioQueryGetByGenresArgs = {
    genres: Array<Scalars['String']>
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    page?: InputMaybe<Scalars['Int']>
    provider?: InputMaybe<Scalars['String']>
}

export type ThriveAudioQueryGetByIdsArgs = {
    ids: Array<Scalars['String']>
    locale?: InputMaybe<Scalars['Locale']>
}

export type ThriveBanner = {
    __typename?: 'ThriveBanner'
    callToAction?: Maybe<CallToAction>
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    eyebrow: Scalars['String']
    imageDescription: Scalars['String']
    imageUrl: Scalars['Url']
    landscape: ThriveResetUrls
    portrait: ThriveResetUrls
    themes: Array<ThriveResetTheme>
    title: Scalars['String']
}

export type ThriveBannerConnection = {
    __typename?: 'ThriveBannerConnection'
    edges: Array<ThriveBannerEdge>
    /** @deprecated this field is deprecated, replace with nodes */
    items: Array<ThriveBanner>
    /** @deprecated this field is deprecated, replace with pageInfo */
    itemsPerPage: Scalars['Int']
    nodes: Array<ThriveBanner>
    /** @deprecated this field is deprecated, replace with pageInfo */
    page: Scalars['Int']
    pageInfo: PageInfo
    /** @deprecated this field is deprecated, replace with pageInfo */
    total: Scalars['Int']
}

export type ThriveBannerEdge = {
    __typename?: 'ThriveBannerEdge'
    cursor: Scalars['String']
    node: ThriveBanner
}

export type ThriveBannerQuery = {
    __typename?: 'ThriveBannerQuery'
    get: ThriveBannerConnection
}

export type ThriveBannerQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    page?: InputMaybe<Scalars['Int']>
}

export type ThriveChallengeDailyGoalNotificationInput = {
    challengeDay: Scalars['Int']
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    users: Array<Scalars['UUID']>
}

export type ThriveContentQuery = {
    __typename?: 'ThriveContentQuery'
    book: BookQuery
    contentPacks: ContentPackPage
    /** Get bookmarked content for a user */
    getBookmarkedContent: LearnContentItemPage
    /** Get content by Topic. A topic can have Journey UUID or a Pulse Dimension Type. */
    getContentByTopic: LearnContentByTopicOutput
    /** Get content in progress for a user, sorted by latest updatedAt descending. */
    getContentInProgress: LearnContentItemPage
    /** Get finished content for a user, sorted by latest updatedAt descending. */
    getFinishedContent: LearnContentItemPage
    /** Get machine-learning recommended content. */
    getMachineLearningRecommendedContent: Array<LearnContentItem>
    /** Get content by Topic. A topic can have Journey UUID or a Pulse Dimension Type. */
    getMostViewedContent: Array<LearnContentItem>
    reset: ResetQueryV2
}

export type ThriveContentQueryContentPacksArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ThriveContentQueryGetBookmarkedContentArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ThriveContentQueryGetContentByTopicArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
    topic: TopicInput
}

export type ThriveContentQueryGetContentInProgressArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ThriveContentQueryGetFinishedContentArgs = {
    page?: InputMaybe<Scalars['Int']>
    pageSize?: InputMaybe<Scalars['Int']>
}

export type ThriveContentQueryGetMachineLearningRecommendedContentArgs = {
    contentTypes?: InputMaybe<Array<Scalars['String']>>
    excludeList?: InputMaybe<Array<RecommendationInput>>
    includeList?: InputMaybe<Array<RecommendationInput>>
    limit?: InputMaybe<Scalars['Int']>
    refresh?: InputMaybe<Scalars['Boolean']>
    searchStr?: InputMaybe<Scalars['String']>
}

export type ThriveContentQueryGetMostViewedContentArgs = {
    limit?: InputMaybe<Scalars['Int']>
}

export type ThriveHapiResponse =
    | HapiAccessToken
    | HapiIdToken
    | HapiSessionToken

export type ThriveImagePage = {
    __typename?: 'ThriveImagePage'
    items: Array<Image>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type ThriveImageQuery = {
    __typename?: 'ThriveImageQuery'
    get: ThriveImagePage
}

export type ThriveImageQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    page?: InputMaybe<Scalars['Int']>
}

export type ThrivePulseMutation = {
    __typename?: 'ThrivePulseMutation'
    /** Admin Controls */
    admin: AdminMutation
    /** Modify Insights settings in Pulse */
    insights: InsightsMutation
    /** Modify survey data in Pulse */
    surveys: SurveysMutation
}

export type ThrivePulseQuery = {
    __typename?: 'ThrivePulseQuery'
    /** Get company details from Pulse */
    companies: CompaniesQuery
    /** Get insights/reporting data from Pulse */
    insights: InsightsQuery
    /** Get recommendations from Pulse */
    recommendations: RecommendationsQuery
    /** Get surveys from Pulse */
    surveys: SurveysQuery
    /**
     * Get user data from Pulse
     * @deprecated Don't use Pulse user information. Go to Identity
     */
    users: UsersQuery
}

export type ThrivePulseQueryInsightsArgs = {
    from: Scalars['String']
    to: Scalars['String']
}

export type ThriveQuote = Frame & {
    __typename?: 'ThriveQuote'
    _: Scalars['Boolean']
    author: Scalars['String']
    backgroundColor?: Maybe<Scalars['Color']>
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    order?: Maybe<Scalars['Int']>
    text: Scalars['String']
    textColor?: Maybe<Scalars['Color']>
    updatedAt: Scalars['Instant']
    /** @deprecated ThriveQuote will never be assigned to a user */
    user?: Maybe<ResetApiUser>
}

export type ThriveQuoteConnection = {
    __typename?: 'ThriveQuoteConnection'
    edges: Array<ThriveQuoteEdge>
    /** @deprecated this field is deprecated, replace with nodes */
    items: Array<ThriveQuote>
    /** @deprecated this field is deprecated, replace with pageInfo */
    itemsPerPage: Scalars['Int']
    nodes: Array<ThriveQuote>
    /** @deprecated this field is deprecated, replace with pageInfo */
    page: Scalars['Int']
    pageInfo: PageInfo
    /** @deprecated this field is deprecated, replace with pageInfo */
    total: Scalars['Int']
}

export type ThriveQuoteEdge = {
    __typename?: 'ThriveQuoteEdge'
    cursor: Scalars['String']
    node: ThriveQuote
}

export type ThriveQuoteQuery = {
    __typename?: 'ThriveQuoteQuery'
    get: ThriveQuoteConnection
}

export type ThriveQuoteQueryGetArgs = {
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
}

export type ThriveReset = Reset & {
    __typename?: 'ThriveReset'
    _: Scalars['Boolean']
    appUrl: Scalars['Url']
    bookmarked: Scalars['Boolean']
    cmsTags: Array<Tag>
    createdAt: Scalars['Instant']
    description?: Maybe<Scalars['String']>
    durationInSeconds: Scalars['Int']
    id: Scalars['UUID']
    iframeUrls: AspectRatioUrls
    keywords: Array<ThriveResetKeyword>
    landscape: ThriveResetUrls
    latestFeedback?: Maybe<Feedback>
    likes: Likes
    locale: Scalars['Locale']
    /** @deprecated this field is deprecated, replace with themes.localized */
    localizedTags: Array<LocalizedThriveResetTag>
    name?: Maybe<Scalars['String']>
    partner?: Maybe<Partner>
    portrait: ThriveResetUrls
    resetType: ThriveResetType
    /** @deprecated this field is deprecated, replace with themes */
    tags: Array<ThriveResetTag>
    themes: Array<ThriveResetTheme>
    /** @deprecated this field is deprecated, replace with portrait.thumbnail OR landscape.thumbnail */
    thumbnail?: Maybe<Scalars['Url']>
    updatedAt: Scalars['Instant']
    /** @deprecated this field is deprecated, replace with portrait.url OR landscape.url */
    url?: Maybe<Scalars['Url']>
    watched: Scalars['Boolean']
}

export type ThriveResetAppUrlArgs = {
    utmSource: Scalars['String']
}

export type ThriveResetIframeUrlsArgs = {
    utmSource: Scalars['String']
}

export type ThriveResetLocalizedTagsArgs = {
    locale: Scalars['Locale']
}

export type ThriveResetActionsMutation = {
    __typename?: 'ThriveResetActionsMutation'
    watched: Scalars['Boolean']
}

export type ThriveResetActionsMutationWatchedArgs = {
    resetId: Scalars['UUID']
}

export type ThriveResetAdminQuery = {
    __typename?: 'ThriveResetAdminQuery'
    /** Get a reset to be used as a nudge for a user. A thriveReset is always returned, a personalizedReset is returned on a configured frequency.This does not send the nudge to the user, that is responsibility of the client. This requires a service token with 'admin:reset' scope. */
    nudge: ResetNudgeResponse
}

export type ThriveResetAdminQueryNudgeArgs = {
    input: ResetNudgeInput
}

export type ThriveResetAudio = Reset & {
    __typename?: 'ThriveResetAudio'
    _: Scalars['Boolean']
    bookmarked: Scalars['Boolean']
    createdAt: Scalars['Instant']
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    id: Scalars['UUID']
    images: ImageUrls
    latestFeedback?: Maybe<Feedback>
    likes: Likes
    mp3Url: Scalars['Url']
    name: Scalars['String']
    provider?: Maybe<AudioProvider>
    themes: Array<ThriveResetAudioTheme>
    updatedAt: Scalars['Instant']
    watched: Scalars['Boolean']
}

export type ThriveResetAudioEventMutation = {
    __typename?: 'ThriveResetAudioEventMutation'
    watched: Scalars['Boolean']
}

export type ThriveResetAudioEventMutationWatchedArgs = {
    thriveResetAudioId: Scalars['UUID']
}

export type ThriveResetAudioFeedbackMutation = {
    __typename?: 'ThriveResetAudioFeedbackMutation'
    create: Scalars['Boolean']
}

export type ThriveResetAudioFeedbackMutationCreateArgs = {
    feedback: Feedback
    id: Scalars['UUID']
}

export type ThriveResetAudioFilterInput = {
    theme?: InputMaybe<ThriveResetAudioFilterThemeInput>
}

export type ThriveResetAudioFilterThemeInput = {
    in?: InputMaybe<Array<Scalars['String']>>
}

export type ThriveResetAudioMutation = {
    __typename?: 'ThriveResetAudioMutation'
    event: ThriveResetAudioEventMutation
    feedback: ThriveResetAudioFeedbackMutation
}

export type ThriveResetAudioPage = {
    __typename?: 'ThriveResetAudioPage'
    items: Array<ThriveResetAudio>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type ThriveResetAudioPageV2 = {
    __typename?: 'ThriveResetAudioPageV2'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ThriveResetAudioV2>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type ThriveResetAudioQuery = {
    __typename?: 'ThriveResetAudioQuery'
    feedback: ThriveResetFeedbackQuery
    get: ThriveResetAudioPage
    getByIds: Array<ThriveResetAudio>
    getRandomBasedOnUserHistory: ThriveResetAudio
    themes: Array<ThriveResetAudioTheme>
}

export type ThriveResetAudioQueryGetArgs = {
    filter?: InputMaybe<ThriveResetAudioFilterInput>
    limit?: InputMaybe<Scalars['Int']>
    page?: InputMaybe<Scalars['Int']>
    random?: InputMaybe<Scalars['Boolean']>
}

export type ThriveResetAudioQueryGetByIdsArgs = {
    ids: Array<Scalars['UUID']>
}

export type ThriveResetAudioTheme = {
    __typename?: 'ThriveResetAudioTheme'
    localized: Scalars['String']
    theme: Scalars['String']
}

export type ThriveResetAudioV2 = {
    __typename?: 'ThriveResetAudioV2'
    durationInSeconds: Scalars['Int']
    images?: Maybe<ImageUrls>
    isArchived: Scalars['Boolean']
    name: Scalars['String']
    uuid: Scalars['UUID']
}

export type ThriveResetFeedbackMutation = {
    __typename?: 'ThriveResetFeedbackMutation'
    create: Scalars['Boolean']
}

export type ThriveResetFeedbackMutationCreateArgs = {
    feedback: Feedback
    id: Scalars['UUID']
}

export type ThriveResetFeedbackQuery = {
    __typename?: 'ThriveResetFeedbackQuery'
    getLatest?: Maybe<Feedback>
}

export type ThriveResetFeedbackQueryGetLatestArgs = {
    id: Scalars['UUID']
}

export type ThriveResetFilterInput = {
    closedCaption?: InputMaybe<ThriveResetFilterStringInput>
    description?: InputMaybe<ThriveResetFilterStringInput>
    keyword?: InputMaybe<ThriveResetFilterKeywordInput>
    name?: InputMaybe<ThriveResetFilterStringInput>
    platform?: InputMaybe<ThriveResetFilterPlatformInput>
    theme?: InputMaybe<ThriveResetFilterThemeInput>
}

export type ThriveResetFilterKeywordInput = {
    in?: InputMaybe<Array<Scalars['String']>>
    notIn?: InputMaybe<Array<Scalars['String']>>
}

export type ThriveResetFilterPlatformInput = {
    in?: InputMaybe<Array<ThriveResetPlatform>>
}

export type ThriveResetFilterStringInput = {
    contains?: InputMaybe<Scalars['String']>
}

export type ThriveResetFilterThemeInput = {
    in?: InputMaybe<Array<Scalars['String']>>
}

export type ThriveResetKeyword = {
    __typename?: 'ThriveResetKeyword'
    isAvailableInHelpMeChoose: Scalars['Boolean']
    keyword: Scalars['String']
    localized: Scalars['String']
}

export type ThriveResetKeywordFilterInput = {
    isAvailableInHelpMeChoose?: InputMaybe<Scalars['Boolean']>
    platform?: InputMaybe<ThriveResetKeywordFilterPlatformInput>
}

export type ThriveResetKeywordFilterPlatformInput = {
    in?: InputMaybe<Array<ThriveResetPlatform>>
}

export type ThriveResetPage = {
    __typename?: 'ThriveResetPage'
    items: Array<ThriveReset>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type ThriveResetPageV2 = {
    __typename?: 'ThriveResetPageV2'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<ThriveResetV2>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export enum ThriveResetPlatform {
    App = 'App',
    Calls = 'Calls'
}

export type ThriveResetQuery = {
    __typename?: 'ThriveResetQuery'
    /** @deprecated Use featured query */
    daily?: Maybe<ThriveReset>
    featured?: Maybe<ThriveReset>
    feedback: ThriveResetFeedbackQuery
    get: ThriveResetPage
    getByIds: Array<ThriveReset>
    getMostRecentResetUserHistory: Array<ThriveResetUserHistory>
    getRandomResetBasedOnUserFeedback: ThriveReset
    getRandomResetBasedOnUserHistory: ThriveReset
    keywords: Array<ThriveResetKeyword>
    random?: Maybe<ThriveReset>
    recommended: Array<ThriveReset>
    /** @deprecated this field is deprecated, replace with themes */
    tags: Array<LocalizedThriveResetTag>
    themes: Array<ThriveResetTheme>
    watched: ThriveResetWatchQuery
}

export type ThriveResetQueryGetArgs = {
    filter?: InputMaybe<ThriveResetFilterInput>
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    page?: InputMaybe<Scalars['Int']>
    random?: InputMaybe<Scalars['Boolean']>
    resetType?: InputMaybe<ThriveResetType>
    thriveResetTag?: InputMaybe<ThriveResetTag>
}

export type ThriveResetQueryGetByIdsArgs = {
    ids: Array<Scalars['UUID']>
    locale?: InputMaybe<Scalars['Locale']>
}

export type ThriveResetQueryGetMostRecentResetUserHistoryArgs = {
    userId: Scalars['UUID']
}

export type ThriveResetQueryGetRandomResetBasedOnUserFeedbackArgs = {
    feedbackWeights?: InputMaybe<FeedbackWeightsInput>
    filter?: InputMaybe<ThriveResetFilterInput>
}

export type ThriveResetQueryGetRandomResetBasedOnUserHistoryArgs = {
    filter?: InputMaybe<ThriveResetFilterInput>
}

export type ThriveResetQueryKeywordsArgs = {
    filter?: InputMaybe<ThriveResetKeywordFilterInput>
}

export type ThriveResetQueryRecommendedArgs = {
    input: RecommendedResetsGraphQlInput
}

export type ThriveResetQueryTagsArgs = {
    locale?: InputMaybe<Scalars['Locale']>
}

export type ThriveResetQueryThemesArgs = {
    filter?: InputMaybe<ThriveResetThemeFilterInput>
}

export enum ThriveResetTag {
    Animals = 'Animals',
    Breathing = 'Breathing',
    EscapeAndExplore = 'EscapeAndExplore',
    Gratitude = 'Gratitude',
    Meditation = 'Meditation',
    Miir = 'Miir',
    Purpose = 'Purpose',
    RestAndReflect = 'RestAndReflect',
    Seasons = 'Seasons',
    Sollos = 'Sollos',
    Sports = 'Sports',
    StressRelief = 'StressRelief',
    Stretching = 'Stretching',
    Thrive = 'Thrive',
    Wonder = 'Wonder'
}

export type ThriveResetTheme = {
    __typename?: 'ThriveResetTheme'
    localized: Scalars['String']
    theme: Scalars['String']
    toLocalizedThriveResetTag?: Maybe<LocalizedThriveResetTag>
    toThriveResetTag?: Maybe<ThriveResetTag>
}

export type ThriveResetThemeFilterInput = {
    platform?: InputMaybe<ThriveResetThemeFilterPlatformInput>
}

export type ThriveResetThemeFilterPlatformInput = {
    in?: InputMaybe<Array<ThriveResetPlatform>>
}

export enum ThriveResetType {
    PepTalk = 'PEP_TALK',
    Standard = 'STANDARD'
}

export type ThriveResetUrls = {
    __typename?: 'ThriveResetUrls'
    iframe?: Maybe<Scalars['Url']>
    thumbnail?: Maybe<Scalars['Url']>
    thumbnails?: Maybe<ImageUrls>
}

export type ThriveResetUrlsV2 = {
    __typename?: 'ThriveResetUrlsV2'
    iframe?: Maybe<Scalars['Url']>
    thumbnails?: Maybe<ImageUrls>
}

export type ThriveResetUserHistory = {
    __typename?: 'ThriveResetUserHistory'
    country?: Maybe<Scalars['String']>
    language?: Maybe<Scalars['String']>
    resetId: Scalars['UUID']
    userId: Scalars['UUID']
    watchedAt: Scalars['Instant']
}

export type ThriveResetV2 = {
    __typename?: 'ThriveResetV2'
    description: Scalars['String']
    durationInSeconds: Scalars['Int']
    excludedSubscriptionIds: Array<Scalars['UUID']>
    includedSubscriptionIds: Array<Scalars['UUID']>
    isUnrestricted: Scalars['Boolean']
    landscape: ThriveResetUrlsV2
    name: Scalars['String']
    portrait: ThriveResetUrlsV2
    uuid: Scalars['UUID']
}

export type ThriveResetWatchQuery = {
    __typename?: 'ThriveResetWatchQuery'
    company: Scalars['Int']
    user: Scalars['Int']
}

export type ThriveResetWatchQueryCompanyArgs = {
    end: Scalars['Instant']
    id: Scalars['UUID']
    start: Scalars['Instant']
}

export type ThriveResetWatchQueryUserArgs = {
    end: Scalars['Instant']
    id: Scalars['UUID']
    start: Scalars['Instant']
}

export type ThriveResetsMutation = {
    __typename?: 'ThriveResetsMutation'
    event: ThriveResetActionsMutation
    feedback: ThriveResetFeedbackMutation
}

export type ThriveScore = {
    __typename?: 'ThriveScore'
    endDate: Scalars['ISODate']
    score: Scalars['Int']
    startDate: Scalars['ISODate']
    surveysAnsweredDayOf: Scalars['Int']
    surveysAnsweredRetroactive: Scalars['Int']
}

export type ThriveScoreQuery = {
    __typename?: 'ThriveScoreQuery'
    /** Get thrive score */
    thriveScore: Array<ThriveScore>
}

export type ThriveScoreQueryThriveScoreArgs = {
    endDate: Scalars['ISODate']
}

export type TimedRecommendedResetContext = {
    __typename?: 'TimedRecommendedResetContext'
    intentionId?: Maybe<Scalars['UUID']>
    timeOfDay: ResetRecommendationTimeOfDay
}

export type TodayMutation = {
    __typename?: 'TodayMutation'
    /** Adds check-ins for the given date and microstep ids. Returns a list of affected results, or an empty list if the checkins already exist */
    checkinToMicrostepsV2: Array<MicrostepCheckin>
    checkinToMicrostepsV2Admin: Array<MicrostepCheckin>
    commitToMicrosteps: Array<MicrostepSubscription>
    /** Remove the like of a microstep */
    removeLikedMicrostep: Scalars['Int']
    /** @deprecated Only used in Today web */
    setGuideUserProgress: GraphQlGuideUserProgress
    /** Like a microstep */
    setLikedMicrostep?: Maybe<Scalars['UUID']>
    /** Removes check-ins for the given date and microstep ids. Returns a list of the removed microstep ids */
    unCheckinToMicrostepsV2: Array<Scalars['ID']>
    unCommitToMicrostep: Array<MicrostepSubscription>
}

export type TodayMutationCheckinToMicrostepsV2Args = {
    date?: InputMaybe<Scalars['ISODate']>
    microstepIds: Array<Scalars['ID']>
}

export type TodayMutationCheckinToMicrostepsV2AdminArgs = {
    checkinDate: Scalars['ISODate']
    companyId: Scalars['UUID']
    microstepIds: Array<Scalars['ID']>
    userId: Scalars['UUID']
}

export type TodayMutationCommitToMicrostepsArgs = {
    microstepIds: Array<Scalars['ID']>
}

export type TodayMutationRemoveLikedMicrostepArgs = {
    microstepId: Scalars['UUID']
}

export type TodayMutationSetGuideUserProgressArgs = {
    guideId: Scalars['UUID']
    status: Scalars['String']
}

export type TodayMutationSetLikedMicrostepArgs = {
    microstepId: Scalars['UUID']
}

export type TodayMutationUnCheckinToMicrostepsV2Args = {
    date?: InputMaybe<Scalars['ISODate']>
    microstepIds: Array<Scalars['ID']>
}

export type TodayMutationUnCommitToMicrostepArgs = {
    microstepAndRemovalReason: Array<MicrostepAndRemovalReasonInput>
}

export type TodayQuery = {
    __typename?: 'TodayQuery'
    /** Returns all microsteps for the user's locale (no pagination) */
    allMicrosteps: Array<GraphQlMicrostep>
    /** Returns committed microsteps for the user */
    getActiveMicrostepsV2: Array<MicrostepUserStatus>
    /** @deprecated Only used in Today web */
    getAllGuides: Array<GraphQlGuide>
    /** Returns checked-in and committed microsteps for the user, daily, between two dates */
    getAllMicrostepCheckinProgressV2: Array<MicrostepCheckinProgressDate>
    /** For each microstep passed, returns the dates the user checked in */
    getMicrostepCheckinDates: Array<MicrostepCheckinDateList>
    getMicrostepCheckinDatesAdmin: Array<MicrostepCheckinDateList>
    /**
     * Returns number of check-ins the user did for a given microstep between two dates
     * @deprecated Only used in Today web
     */
    getMicrostepCheckinProgressV2: MicrostepUserProgress
    getMicrostepUserCheckinTotalsAdmin: Array<MicrostepUserTotal>
    /**
     * Returns a list of microsteps with their commit & checkin status
     * @deprecated Only used in Today web
     */
    getMicrostepsUserStatusForJourneys: Array<MicrostepUserStatus>
    /** Returns a list of microsteps for the given uuids */
    getMicrostepsV2: Array<GraphQlMicrostep>
    /** Returns a list of microsteps with pagination */
    getPaginatedMicrosteps: MicrostepPage
    /** Returns a list of microsteps user status for the given uuids */
    getUserMicrostepStatus: Array<MicrostepUserStatus>
    /**
     * Returns an array of date and microstep check-in count pairings, with a cumulative total. if `timeDimension` parameter is not passed: If the date range us up to 3 months, return each day in the response
     *
     * If the date range is between 3 months and 2 years, bundle the check-ins by week
     *
     * If the date range is longer than 2 years, bundle the check-ins by month.
     * @deprecated Only used in Today web
     */
    microstepMomentum: MicrostepMomentumResult
    /** Returns random microsteps for current time of day */
    timeOfDayMicrosteps: Array<GraphQlMicrostep>
}

export type TodayQueryAllMicrostepsArgs = {
    removeDeprecated?: InputMaybe<Scalars['Boolean']>
}

export type TodayQueryGetActiveMicrostepsV2Args = {
    activeDate?: InputMaybe<Scalars['ISODate']>
}

export type TodayQueryGetAllMicrostepCheckinProgressV2Args = {
    from: Scalars['ISODate']
    fullMicrosteps?: InputMaybe<Scalars['Boolean']>
    to: Scalars['ISODate']
}

export type TodayQueryGetMicrostepCheckinDatesArgs = {
    microstepIds: Array<Scalars['UUID']>
}

export type TodayQueryGetMicrostepCheckinDatesAdminArgs = {
    microstepIds: Array<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type TodayQueryGetMicrostepCheckinProgressV2Args = {
    from: Scalars['ISODate']
    microstepId: Scalars['ID']
    to: Scalars['ISODate']
}

export type TodayQueryGetMicrostepUserCheckinTotalsAdminArgs = {
    microstepIds: Array<Scalars['UUID']>
    userIds: Array<Scalars['UUID']>
}

export type TodayQueryGetMicrostepsUserStatusForJourneysArgs = {
    checkinDate?: InputMaybe<Scalars['ISODate']>
    journeyIds: Array<Scalars['UUID']>
}

export type TodayQueryGetMicrostepsV2Args = {
    ids: Array<Scalars['ID']>
}

export type TodayQueryGetPaginatedMicrostepsArgs = {
    frequency?: InputMaybe<Scalars['String']>
    journeyIds?: InputMaybe<Array<Scalars['UUID']>>
    onlyCheckedIn?: InputMaybe<Scalars['Boolean']>
    onlySubscribed?: InputMaybe<Scalars['Boolean']>
    page: Scalars['Int']
    pageSize: Scalars['Int']
    removeDeprecated?: InputMaybe<Scalars['Boolean']>
    searchText?: InputMaybe<Scalars['String']>
    sortOrder?: InputMaybe<SortOrder>
}

export type TodayQueryGetUserMicrostepStatusArgs = {
    microstepIds: Array<Scalars['UUID']>
}

export type TodayQueryMicrostepMomentumArgs = {
    from?: InputMaybe<Scalars['ISODate']>
    timeDimension?: InputMaybe<Scalars['String']>
    to?: InputMaybe<Scalars['ISODate']>
}

export type TodayQueryTimeOfDayMicrostepsArgs = {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['Locale']>
    zoneId?: InputMaybe<Scalars['String']>
}

export type TokenResponse = {
    __typename?: 'TokenResponse'
    token: Scalars['String']
}

export type Topic = {
    __typename?: 'Topic'
    journey?: Maybe<Journey>
    journeyUUID?: Maybe<Scalars['UUID']>
    pulseDimension?: Maybe<PulseDimension>
    pulseDimensionType?: Maybe<Scalars['String']>
}

export type TopicInput = {
    journeyUUID?: InputMaybe<Scalars['UUID']>
    pulseDimensionType?: InputMaybe<Scalars['String']>
}

export type TransactionHistoryInfo = {
    __typename?: 'TransactionHistoryInfo'
    /** The transaction's amount */
    amount: Scalars['Int']
    createdAt: Scalars['Instant']
    /** The currency code */
    currencyCode: Scalars['String']
    /** In case of DEBIT, the product upc associated to it */
    productUpc?: Maybe<Scalars['String']>
    /** The transaction type. Can be CREDIT or DEBIT */
    transactionType: Scalars['String']
    updatedAt: Scalars['Instant']
    /** The user id */
    userId: Scalars['UUID']
}

export type TrendHighlights = {
    __typename?: 'TrendHighlights'
    greatestOpportunity?: Maybe<DimensionTrendsDto>
    greatestStrength?: Maybe<DimensionTrendsDto>
    mostConsistent?: Maybe<DimensionTrendsDto>
    mostImproved?: Maybe<DimensionTrendsDto>
}

export enum Trigger {
    Break = 'BREAK',
    ConsecutiveCalls = 'CONSECUTIVE_CALLS',
    Duration = 'DURATION',
    None = 'NONE'
}

export type TriggerConfiguration = {
    __typename?: 'TriggerConfiguration'
    agentGroupId: Scalars['String']
    callCountLimit?: Maybe<Scalars['Int']>
    callCountTriggerEnabled: Scalars['Boolean']
    callDurationLimit?: Maybe<Scalars['Int']>
    callDurationTriggerEnabled: Scalars['Boolean']
    createdAt: Scalars['Instant']
    digitalOnly?: Maybe<Scalars['Boolean']>
    direction: Array<Direction>
    locale?: Maybe<Scalars['String']>
    mediaTypes: Array<MediaType>
    smart: Scalars['Boolean']
    updatedAt: Scalars['Instant']
}

export type TriggerInChallengeGoalRemindersInput = {
    /** If providing more than five challenges in this metadata, it is strongly recommended to lower the user batch size to prevent issues. */
    challenges: Array<InChallengeDataInput>
    sendDate: Scalars['ISODate']
    /** ISO-8601 time string.  Should be the time the nudge should send, in the users' timezone. */
    sendTime: Scalars['String']
    /** Cannot exceed 300 IDs in a batch.  NOTE: if providing more than five challenges in the metadata, it is strongly recommended to use a smaller batch size. */
    thriveUserIds: Array<Scalars['UUID']>
    /** The users' timezone.  A valid IANA timezone that parses as a ZoneId */
    timezone: Scalars['String']
}

export type TriggerMutation = {
    __typename?: 'TriggerMutation'
    /** Inserts or updates a trigger configuration for a company */
    delete?: Maybe<CompanyConfiguration>
    /** Inserts or updates a trigger configuration for a company */
    upsert: CompanyConfiguration
}

export type TriggerMutationDeleteArgs = {
    agentGroupId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type TriggerMutationUpsertArgs = {
    agentGroupId: Scalars['String']
    callCountLimit?: InputMaybe<Scalars['Int']>
    callCountTriggerEnabled?: InputMaybe<Scalars['Boolean']>
    callDurationLimit?: InputMaybe<Scalars['Int']>
    callDurationTriggerEnabled?: InputMaybe<Scalars['Boolean']>
    companyId: Scalars['UUID']
    digitalOnly?: InputMaybe<Scalars['Boolean']>
    direction?: InputMaybe<Array<Direction>>
    locale?: InputMaybe<Scalars['String']>
    mediaTypes?: InputMaybe<Array<MediaType>>
    platform: PlatformType
}

export type TriggerQuery = {
    __typename?: 'TriggerQuery'
    /** Returns the trigger configuration for the given group id */
    get?: Maybe<TriggerConfiguration>
    /** Returns all trigger configuration for the given company */
    getAll: Array<Maybe<TriggerConfiguration>>
}

export type TriggerQueryGetArgs = {
    agentGroupId: Scalars['String']
    companyId: Scalars['UUID']
    platform: PlatformType
}

export type TriggerQueryGetAllArgs = {
    companyId: Scalars['UUID']
    platform: PlatformType
}

export enum TriggerReason {
    ConsecutiveCalls = 'CONSECUTIVE_CALLS',
    Duration = 'DURATION',
    Manual = 'MANUAL'
}

export type TriggerReset = CallsCommand & {
    __typename?: 'TriggerReset'
    companyId: Scalars['UUID']
    date: Scalars['Instant']
    platformType: PlatformType
    type: CallsCommandType
    userId: Scalars['String']
}

export type TriggerSelfTestEventInput = {
    testCtaUrl?: InputMaybe<Scalars['String']>
    testValue?: InputMaybe<Scalars['String']>
}

export type TriggerTestEventInput = {
    testCtaUrl?: InputMaybe<Scalars['String']>
    testValue?: InputMaybe<Scalars['String']>
    thriveUserId: Scalars['UUID']
}

export type TriggerTestEventsInput = {
    testCtaUrl?: InputMaybe<Scalars['String']>
    testValue?: InputMaybe<Scalars['String']>
    /** Cannot exceed 300 IDs in a batch. */
    thriveUserIds: Array<Scalars['UUID']>
}

export type TriggerWebNudgeAnnouncementsForCompanyInput = {
    companyId: Scalars['UUID']
}

export type TutorialConfig = {
    __typename?: 'TutorialConfig'
    hasPlayedTutorial: Scalars['Boolean']
    lastPlayedAt?: Maybe<Scalars['Instant']>
    pulseUserId: Scalars['UUID']
}

export enum Type {
    Article = 'ARTICLE',
    Book = 'BOOK',
    Challenge = 'CHALLENGE',
    Course = 'COURSE',
    CourseLesson = 'COURSE_LESSON',
    CourseModule = 'COURSE_MODULE',
    Journey = 'JOURNEY',
    Microstep = 'MICROSTEP',
    Podcast = 'PODCAST',
    PodcastEpisode = 'PODCAST_EPISODE',
    PodcastSeason = 'PODCAST_SEASON',
    Recipe = 'RECIPE',
    Reset = 'RESET',
    ResetStockAudio = 'RESET_STOCK_AUDIO',
    RoleModel = 'ROLE_MODEL',
    WatchMeThrive = 'WATCH_ME_THRIVE'
}

export type UncompletedJourneyProgress = {
    __typename?: 'UncompletedJourneyProgress'
    completedProgress: Array<UserJourneyProgress>
    currentLevel?: Maybe<JourneyLevelV2>
    currentStep?: Maybe<JourneyDailyStepV2>
    dailySteps?: Maybe<Array<JourneyDailyStepV2>>
    isCompleted: Scalars['Boolean']
    journey?: Maybe<Journey>
    stepCompletedToday?: Maybe<UserJourneyProgress>
}

export type UngroupedAssessmentStatsResponse = AssessmentStats & {
    __typename?: 'UngroupedAssessmentStatsResponse'
    average: Scalars['Float']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
}

export type UnifiedBiotype = {
    __typename?: 'UnifiedBiotype'
    id?: Maybe<Scalars['UUID']>
    name?: Maybe<Scalars['String']>
}

export type UnifiedChallenge = {
    __typename?: 'UnifiedChallenge'
    /** Indicates if this template allows customers to override step goals */
    allowCustomSteps: Scalars['Boolean']
    /** The background image */
    backgroundImage?: Maybe<Scalars['String']>
    /** List of better choices of the challenge */
    betterChoices: Array<Scalars['UUID']>
    /** Biotype */
    biotype?: Maybe<UnifiedBiotype>
    /** Deprecated - use journeys list field */
    category?: Maybe<UnifiedJourney>
    /** The template id in case this challenge was created from a template */
    challengeTemplateId?: Maybe<Scalars['UUID']>
    /** Type of challenge (JOURNEY, BIOTYPE, PRIZE, COMPANY) */
    challenge_type: Scalars['String']
    /** The signup day (with no time) if the challenge is a company challenge */
    companyChallengeSignupDay?: Maybe<Scalars['ISODate']>
    /** The start date if the challenge is a company challenge */
    companyChallengeStartDate?: Maybe<Scalars['Instant']>
    /** The start day (with no time) if the challenge is a company challenge */
    companyChallengeStartDay?: Maybe<Scalars['ISODate']>
    /** The instance creator's userId in case this challenges was created from a template */
    createdBy?: Maybe<Scalars['UUID']>
    /** The day of company challenge it's negative if the has not started yet */
    dayOfChallenge?: Maybe<Scalars['Int']>
    /** Description of the challenge */
    description?: Maybe<Scalars['String']>
    /** Identifies if challenge supports wearables functionality  */
    device_enabled: Scalars['Boolean']
    /** Duration of the challenge */
    duration: Scalars['Int']
    /** If the challenge is visible to all users */
    enabled?: Maybe<Scalars['Boolean']>
    /** Goal of the challenge */
    goal?: Maybe<Scalars['String']>
    /** Group challenge type */
    groupChallengeType?: Maybe<GroupChallengeType>
    /** Header image */
    header?: Maybe<Scalars['String']>
    /** The id of this challenge */
    id: Scalars['UUID']
    /** Determines if the challenge is featured (Appears at the top) */
    isFeatured?: Maybe<Scalars['Boolean']>
    /** List of Journeys */
    journeys?: Maybe<Array<UnifiedJourney>>
    /** Indicates the max team size for the challenge */
    maxTeamSize?: Maybe<Scalars['Int']>
    /** List of microstep ids of the challenge */
    microsteps: Array<UnifiedMicrostep>
    /** Name of the challenge */
    name: Scalars['String']
    /** Custom  order position set for the challenge */
    position: Scalars['Int']
    /** The description for promo 1 field */
    promoDescription1?: Maybe<Scalars['String']>
    /** The description for promo 2 field */
    promoDescription2?: Maybe<Scalars['String']>
    /** The description for promo 3 field */
    promoDescription3?: Maybe<Scalars['String']>
    /** The header for promo 1 field */
    promoHeader1?: Maybe<Scalars['String']>
    /** The header for promo 2 field */
    promoHeader2?: Maybe<Scalars['String']>
    /** The header for promo 3 field */
    promoHeader3?: Maybe<Scalars['String']>
    /** The image for promo 1 field */
    promoImage1?: Maybe<Scalars['String']>
    /** The image for promo 2 field */
    promoImage2?: Maybe<Scalars['String']>
    /** The image for promo 3 field */
    promoImage3?: Maybe<Scalars['String']>
    /** List of Quotes */
    quotes?: Maybe<Array<TemplateQuote>>
    /** Indicates if the challenge is read only */
    readOnly: Scalars['Boolean']
    /** List of resets ids of the challenge */
    resetIds: Array<Scalars['UUID']>
    /** The company challenge theme */
    theme?: Maybe<Scalars['String']>
    /** Thumbnail */
    thumbnail?: Maybe<Scalars['String']>
    /** Total number of participants in this challenge for the same company */
    totalParticipants: Scalars['Int']
    /** The image for the community challenge trophy plant */
    trophyPlantImage?: Maybe<Scalars['String']>
    /** Type of challenge (JOURNEY, BIOTYPE) */
    type?: Maybe<Scalars['String']>
}

export type UnifiedChallengeActivity = {
    __typename?: 'UnifiedChallengeActivity'
    completedOn?: Maybe<Scalars['Instant']>
    expiredOn?: Maybe<Scalars['Instant']>
    id: Scalars['UUID']
    join: Scalars['Instant']
    leave?: Maybe<Scalars['Instant']>
    microstepActivities?: Maybe<Array<UnifiedMicrostepActivities>>
    socialGroup?: Maybe<Scalars['UUID']>
    streak?: Maybe<UnifiedStreak>
}

export type UnifiedChallengeAdmin = {
    __typename?: 'UnifiedChallengeAdmin'
    /** Type of challenge (JOURNEY, BIOTYPE, PRIZE, COMPANY) */
    challengeType: Scalars['String']
    createdAt?: Maybe<Scalars['Instant']>
    /** Description of the challenge */
    description?: Maybe<Scalars['String']>
    /** Is enabled */
    enabled: Scalars['Boolean']
    /** The id of this challenge */
    id: Scalars['UUID']
    /** Is default challenge */
    isDefault: Scalars['Boolean']
    /** Name of the challenge */
    name: Scalars['String']
    updatedAt?: Maybe<Scalars['Instant']>
}

export type UnifiedChallengeCompany = {
    __typename?: 'UnifiedChallengeCompany'
    /** The id of the challenge */
    challenge_id: Scalars['UUID']
    /** The id of the company */
    company_id: Scalars['String']
    /** enabled flag */
    enabled: Scalars['Boolean']
    /** The id of this relation */
    id: Scalars['UUID']
    /** The id of the subscription */
    subscription_id?: Maybe<Scalars['UUID']>
}

export type UnifiedChallengeCompanyStoryType = {
    __typename?: 'UnifiedChallengeCompanyStoryType'
    /** The id of the challenge company */
    challengeCompanyId: Scalars['UUID']
    /** The id of this relation */
    id: Scalars['UUID']
    /** The id of the story type */
    storyTypeId: Scalars['UUID']
}

export type UnifiedChallengeParticipation = {
    __typename?: 'UnifiedChallengeParticipation'
    challenge: UnifiedChallenge
    participation: Array<UnifiedChallengeActivity>
}

export type UnifiedChallengesMutation = {
    __typename?: 'UnifiedChallengesMutation'
    /** Acknowledge the user decision to not show challenge recommendations again */
    ackRecommendation?: Maybe<ChallengeRecommendationAck>
    addChallengeGoals: Array<ChallengeGoal>
    addChallengesToSubscription: UnifiedResult
    addChallengesToSubscriptions: UnifiedResult
    addStorySubmissionToCompany: Array<UnifiedChallengeCompanyStoryType>
    addWaterIntake: WaterOperationResult
    /** Stores the answer for a user's feeling-question */
    answerFeelingQuestion: FeelingQuestionOperationResult
    batchCompleteCommunityChallengeDay: Array<ChallengeUserDailyGoal>
    /** Changes a user participating in a community challenge to another socialGroup (or null for solo) */
    changeCommunityChallengeTeam: UnifiedResult
    /** Changes a user participating in a community challenge to another socialGroup (or null for solo) */
    changeCommunityChallengeTeamAdmin: UnifiedResult
    completeChallenge?: Maybe<UnifiedUserChallengeActivity>
    completeChallengeAdmin?: Maybe<UnifiedUserChallengeActivity>
    completeCommunityChallengeDailyGoal?: Maybe<ChallengeGoal>
    completeCommunityChallengeDay: Array<ChallengeUserDailyGoal>
    createChallenge?: Maybe<Scalars['UUID']>
    /** Creates a new challenge from a template */
    createChallengeFromTemplate: TemplateOperationResult
    /** Creates a new group challenge from a template */
    createGroupChallengeFromTemplate: TemplateOperationResult
    createStoryQuestions?: Maybe<Scalars['String']>
    createStoryQuestionsI18N?: Maybe<Scalars['String']>
    deleteStory: StoryOperationSucceeded
    editStory?: Maybe<Story>
    joinChallenge: UnifiedResult
    joinChallengeAdmin: UnifiedResult
    joinCommunityChallenge: UnifiedResult
    leaveChallenge?: Maybe<UnifiedUserChallengeActivity>
    leaveChallengeAdmin?: Maybe<UnifiedUserChallengeActivity>
    removeChallenge: Scalars['String']
    removeChallengeGoals: Scalars['String']
    removeChallengesFromCompany: Scalars['String']
    removeGroupChallenge: RemoveChallengeResult
    removeUserFromChallenge: UnifiedResult
    setChallengeCustomization: ChallengeCustomizationOperationResult
    setChallengeDefaultStatus: Scalars['Boolean']
    setChallengesStatusForSubscription: UnifiedResult
    /** Sets the company challenge notification status for a user */
    setCompanyNotificationStatus?: Maybe<Scalars['Boolean']>
    /** For group challenges, updates the type */
    setGroupChallengeType: SetGroupChallengeTypeResult
    /** Sets the company challenge notification status for a user */
    setPrivateNotificationStatus?: Maybe<Scalars['Boolean']>
    /** Stores the answer for a user's sleep habits */
    setSleepHabits: SleepHabitsOperationResult
    /** Marks if a user has watched the tutorial */
    setTutorialWatchedStatus: Scalars['Boolean']
    /** Sets a user flag */
    setUserFlag?: Maybe<Scalars['String']>
    submitStory?: Maybe<Story>
    updateChallengeGoal: ChallengeGoal
    /** Updates an instance of a challenge */
    updateChallengeInstance: TemplateOperationResult
    updateChallengesDailyGoalContent: Array<ChallengeGoal>
    /** Modify the join date for a user */
    updateJoinDate: UnifiedResult
    updateMeasureUnitSettings?: Maybe<UserInfo>
    updateStorySubmissionToCompany: Array<UnifiedChallengeCompanyStoryType>
    /** Sets the weekend notification status for a user */
    updateWeekendNotificationSettings?: Maybe<UserInfo>
}

export type UnifiedChallengesMutationAckRecommendationArgs = {
    challengeId: Scalars['UUID']
    microstepId: Scalars['UUID']
}

export type UnifiedChallengesMutationAddChallengeGoalsArgs = {
    newChallengeGoalsInput: Array<ChallengeGoalCreateInput>
}

export type UnifiedChallengesMutationAddChallengesToSubscriptionArgs = {
    challengesToAdd?: InputMaybe<Array<Scalars['UUID']>>
    companyId: Scalars['UUID']
    storyTypeId?: InputMaybe<Scalars['UUID']>
    subscriptionId: Scalars['UUID']
}

export type UnifiedChallengesMutationAddChallengesToSubscriptionsArgs = {
    challengesToAdd: Array<Scalars['UUID']>
    companiesSubscriptionsToAdd?: InputMaybe<Array<SubscriptionCompanyInput>>
    companiesSubscriptionsToExclude?: InputMaybe<
        Array<SubscriptionCompanyInput>
    >
    storyTypeId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesMutationAddStorySubmissionToCompanyArgs = {
    challengeCompanyIds: Array<Scalars['UUID']>
    storyTypeId: Scalars['UUID']
}

export type UnifiedChallengesMutationAddWaterIntakeArgs = {
    amount: Scalars['Int']
    challengeId: Scalars['UUID']
    date?: InputMaybe<Scalars['ISODate']>
}

export type UnifiedChallengesMutationAnswerFeelingQuestionArgs = {
    answer: Scalars['Int']
    challengeId: Scalars['UUID']
    date?: InputMaybe<Scalars['ISODate']>
}

export type UnifiedChallengesMutationBatchCompleteCommunityChallengeDayArgs = {
    challengeId: Scalars['UUID']
    day: Scalars['Int']
}

export type UnifiedChallengesMutationChangeCommunityChallengeTeamArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesMutationChangeCommunityChallengeTeamAdminArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationCompleteChallengeArgs = {
    completeDate?: InputMaybe<Scalars['ISODate']>
    userChallengeActivityId: Scalars['UUID']
}

export type UnifiedChallengesMutationCompleteChallengeAdminArgs = {
    companyId: Scalars['UUID']
    completeDate?: InputMaybe<Scalars['ISODate']>
    subscriptionId: Scalars['UUID']
    userChallengeActivityId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationCompleteCommunityChallengeDailyGoalArgs = {
    challengeGoalId: Scalars['UUID']
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesMutationCompleteCommunityChallengeDayArgs = {
    challengeId: Scalars['UUID']
    day: Scalars['Int']
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationCreateChallengeArgs = {
    newChallengeId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesMutationCreateChallengeFromTemplateArgs = {
    challengeTemplateCreateInput: ChallengeTemplateCreateInput
}

export type UnifiedChallengesMutationCreateGroupChallengeFromTemplateArgs = {
    input: GroupChallengeTemplateCreateInput
}

export type UnifiedChallengesMutationCreateStoryQuestionsArgs = {
    id?: InputMaybe<Scalars['UUID']>
    storyCreateInput: StoryQuestionCreateInput
}

export type UnifiedChallengesMutationCreateStoryQuestionsI18NArgs = {
    storyCreateInput: StoryQuestionCreateInput
}

export type UnifiedChallengesMutationDeleteStoryArgs = {
    storyID: Scalars['UUID']
}

export type UnifiedChallengesMutationEditStoryArgs = {
    updatedStory: StoryCreateInput
}

export type UnifiedChallengesMutationJoinChallengeArgs = {
    challengeId: Scalars['UUID']
    joinDate?: InputMaybe<Scalars['ISODate']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesMutationJoinChallengeAdminArgs = {
    challengeId: Scalars['UUID']
    companyId: Scalars['UUID']
    joinDate?: InputMaybe<Scalars['ISODate']>
    locale?: InputMaybe<Scalars['Locale']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
    subscriptionId: Scalars['UUID']
    thriveTimeZone?: InputMaybe<Scalars['String']>
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationJoinCommunityChallengeArgs = {
    challengeId: Scalars['UUID']
    joinDate?: InputMaybe<Scalars['ISODate']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
    socialGroupName?: InputMaybe<Scalars['String']>
    socialGroupPublic?: InputMaybe<Scalars['Boolean']>
}

export type UnifiedChallengesMutationLeaveChallengeArgs = {
    leaveDate?: InputMaybe<Scalars['ISODate']>
    userChallengeActivityId: Scalars['UUID']
}

export type UnifiedChallengesMutationLeaveChallengeAdminArgs = {
    leaveDate?: InputMaybe<Scalars['ISODate']>
    userChallengeActivityId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationRemoveChallengeArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesMutationRemoveChallengeGoalsArgs = {
    challengeGoalIds: Array<Scalars['UUID']>
}

export type UnifiedChallengesMutationRemoveChallengesFromCompanyArgs = {
    challengeCompanyIds: Array<Scalars['UUID']>
}

export type UnifiedChallengesMutationRemoveGroupChallengeArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesMutationRemoveUserFromChallengeArgs = {
    challengeId: Scalars['UUID']
    userIdToRemove: Scalars['UUID']
}

export type UnifiedChallengesMutationSetChallengeCustomizationArgs = {
    challengeId: Scalars['UUID']
    stepsAmount?: InputMaybe<Scalars['Int']>
    waterAmount?: InputMaybe<Scalars['Int']>
}

export type UnifiedChallengesMutationSetChallengeDefaultStatusArgs = {
    challengeId: Scalars['UUID']
    isDefault: Scalars['Boolean']
}

export type UnifiedChallengesMutationSetChallengesStatusForSubscriptionArgs = {
    challenges: Array<Scalars['UUID']>
    status: Scalars['Boolean']
    subscriptionId: Scalars['UUID']
}

export type UnifiedChallengesMutationSetCompanyNotificationStatusArgs = {
    status: Scalars['Boolean']
}

export type UnifiedChallengesMutationSetGroupChallengeTypeArgs = {
    challengeId: Scalars['UUID']
    groupChallengeType: GroupChallengeType
}

export type UnifiedChallengesMutationSetPrivateNotificationStatusArgs = {
    status: Scalars['Boolean']
}

export type UnifiedChallengesMutationSetSleepHabitsArgs = {
    answer: Scalars['Int']
    challengeId: Scalars['UUID']
    challengeTimePoint: ChallengeTimePoint
}

export type UnifiedChallengesMutationSetTutorialWatchedStatusArgs = {
    status: Scalars['Boolean']
}

export type UnifiedChallengesMutationSetUserFlagArgs = {
    key: Scalars['String']
    value?: InputMaybe<Scalars['String']>
}

export type UnifiedChallengesMutationSubmitStoryArgs = {
    newStory: StoryCreateInput
}

export type UnifiedChallengesMutationUpdateChallengeGoalArgs = {
    challengeGoalId: Scalars['UUID']
    challengeGoalInput: ChallengeGoalCreateInput
}

export type UnifiedChallengesMutationUpdateChallengeInstanceArgs = {
    challengeId: Scalars['UUID']
    challengeTemplateCreateInput: ChallengeTemplateCreateInput
}

export type UnifiedChallengesMutationUpdateChallengesDailyGoalContentArgs = {
    updateChallengesDailyGoalContentInput: UpdateChallengesDailyGoalContentInput
}

export type UnifiedChallengesMutationUpdateJoinDateArgs = {
    joinDate: Scalars['String']
    modifyExpirationDate: Scalars['Boolean']
    userId: Scalars['UUID']
}

export type UnifiedChallengesMutationUpdateMeasureUnitSettingsArgs = {
    measureUnit: MeasureUnit
}

export type UnifiedChallengesMutationUpdateStorySubmissionToCompanyArgs = {
    challengeCompanyIds: Array<Scalars['UUID']>
    storyTypeId: Scalars['UUID']
}

export type UnifiedChallengesMutationUpdateWeekendNotificationSettingsArgs = {
    weekendNotificationsActivated: Scalars['Boolean']
}

export type UnifiedChallengesQuery = {
    __typename?: 'UnifiedChallengesQuery'
    /** Get all challenges provisioned */
    allChallenges: Array<UnifiedChallengeAdmin>
    /** Get available interactions for a challenge */
    availableInteractions: Array<StoryType>
    /** Get available interactions for a challenge template */
    availableTemplateInteractions: Array<StoryType>
    /** Get a list of subscriptions information for a given challenge */
    challengeSubscriptionsInfo: Array<ChallengeSubscriptionsInfo>
    /** Get challenge template goals */
    challengeTemplateGoals: Array<ChallengeTemplateGoal>
    challengeTemplatesAdmin: Array<ChallengeTemplate>
    /** Get challenges by subscriptionId */
    challengesBySubscription: Array<AdminChallengeSubscription>
    /** Get community challenge goals */
    communityChallengeGoals: Array<ChallengeGoal>
    /** Get community challenge goals */
    communityChallengeGoalsAdmin: Array<ChallengeGoal>
    /** Get team members daily goals and progress for a challenge */
    communityChallengeTeamDailyGoals: Array<ChallengeTeamDailyGoal>
    /** Get community challenge template goals */
    communityChallengeTemplateGoals: Array<ChallengeTemplateGoal>
    /** Get total number of completed daily goals for a user */
    communityChallengeTotalDailyGoalsCompleted: Scalars['Int']
    /** Get user daily goals and progress for a challenge. If not challengeDay is passed it will return today's goals by default */
    communityChallengeUserDailyGoals: Array<ChallengeUserDailyGoal>
    /** Returns the user daily and total steps for a community challenge */
    communityChallengeUserSteps: StepsInfo
    communityUserChallenges: Array<UnifiedChallengeParticipation>
    getChallengeCustomization: ChallengeCustomizationOperationResult
    /** Returns challenge stats for a user */
    getChallengeStats: ChallengeStats
    /** Get community challenge total steps */
    getChallengeTotalSteps: Scalars['Int']
    /** Get community challenge total water */
    getChallengeTotalWater: Scalars['Int']
    /** Get a list of completed days of a community challenge by the user */
    getCommunityChallengeCompletedDays: Array<CommunityChallengeCompletedDay>
    /** Get community challenge threshold */
    getCommunityChallengeThreshold?: Maybe<CommunityChallengeThreshold>
    /** Gets the company challenge notification status for a user */
    getCompanyNotificationStatus: Scalars['Boolean']
    /** Returns the answer for the feeling-question */
    getFeelingAnswer: FeelingQuestionOperationResult
    /** Get the water measure units defined for this user */
    getMeasureUnitSettings?: Maybe<MeasureUnit>
    /** Gets the private challenge notification status for a user */
    getPrivateNotificationStatus: Scalars['Boolean']
    /** Returns recommended challenges based on the user's intention */
    getRecommendedChallenges: Array<ChallengeTemplate>
    /** Returns the answer for the feeling-question */
    getSleepHabits: SleepHabitsOperationResult
    /** Returns the user's rank if participating solo on a given challenge */
    getSoloPlayerRank: ScoreRanked
    /** Get stories related to a challenge submitted by a user */
    getStories: Array<Story>
    /** Returns the user's team rank on a given challenge using totals */
    getTeamRank: ScoreRanked
    /** Returns the user's team rank on a given challenge using averages */
    getTeamRankByAverage: ScoreRanked
    /** Get team's total water for a challenge */
    getTeamTotalWater: Scalars['Int']
    /** Returns the team players leaderboard for a challenge using totals */
    getTeamsLeaderboard: Leaderboard
    /** Returns the team players leaderboard for a challenge using averages */
    getTeamsLeaderboardByAverage: Leaderboard
    /** Returns a boolean indicating if a user has watched the tutorial */
    getTutorialWatchedStatus: Scalars['Boolean']
    /** Returns the value for a user flag */
    getUserFlag?: Maybe<Scalars['String']>
    /** Returns all flags stored for a user */
    getUserFlags: Array<UserFlag>
    /** Get user's total water for a challenge */
    getUserTotalWater: Scalars['Int']
    /** Returns the solo players leaderboard for a challenge */
    getUsersLeaderboard: Leaderboard
    getWaterIntake: WaterOperationResult
    /** Gets the weekend notification status for a user */
    getWeekendNotificationStatus?: Maybe<Scalars['Boolean']>
    groupChallengeTemplate?: Maybe<ChallengeTemplate>
    groupChallengeTemplates: Array<ChallengeTemplate>
    /** Get story types based on enabled status */
    storyTypes: Array<StoryTypeAdmin>
    /** Get challenge */
    unifiedChallenge?: Maybe<UnifiedChallenge>
    /** Retrieves a Company or Group challenge by id */
    unifiedChallengeAdmin?: Maybe<UnifiedChallengeAdmin>
    /** Get challenges */
    unifiedChallenges: Array<UnifiedChallenge>
    unifiedChallengesAdmin: Array<UnifiedChallenge>
    /** Retrieves a list of Company or Group challenges by id */
    unifiedChallengesByIdAdmin: Array<UnifiedChallenge>
    /** Get challenges by subscriptionId */
    unifiedChallengesBySubscriptionId: Array<UnifiedChallenge>
    unifiedUserChallenges: Array<UnifiedChallengeParticipation>
    unifiedUserChallengesAdmin: Array<UnifiedChallengeParticipation>
    usersParticipatingInChallenge: Array<Scalars['UUID']>
    usersTotalCheckins: Array<UserTotalCheckins>
}

export type UnifiedChallengesQueryAllChallengesArgs = {
    subscriptionIds?: InputMaybe<Array<Scalars['UUID']>>
}

export type UnifiedChallengesQueryAvailableInteractionsArgs = {
    challengeId: Scalars['UUID']
    locale?: InputMaybe<Scalars['Locale']>
}

export type UnifiedChallengesQueryAvailableTemplateInteractionsArgs = {
    locale?: InputMaybe<Scalars['Locale']>
    templateId: Scalars['UUID']
}

export type UnifiedChallengesQueryChallengeSubscriptionsInfoArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryChallengeTemplateGoalsArgs = {
    challengeTemplateId: Scalars['UUID']
}

export type UnifiedChallengesQueryChallengeTemplatesAdminArgs = {
    excludeReadOnly?: InputMaybe<Scalars['Boolean']>
    locale: Scalars['Locale']
}

export type UnifiedChallengesQueryChallengesBySubscriptionArgs = {
    subscriptionIds: Array<Scalars['UUID']>
}

export type UnifiedChallengesQueryCommunityChallengeGoalsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeGoalsAdminArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeTeamDailyGoalsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeTemplateGoalsArgs = {
    challengeTemplateId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeTotalDailyGoalsCompletedArgs =
    {
        challengeId: Scalars['UUID']
    }

export type UnifiedChallengesQueryCommunityChallengeUserDailyGoalsArgs = {
    challengeDay?: InputMaybe<Scalars['Int']>
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryCommunityChallengeUserStepsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetChallengeCustomizationArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetChallengeStatsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetChallengeTotalStepsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetChallengeTotalWaterArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetCommunityChallengeCompletedDaysArgs = {
    challengeId: Scalars['UUID']
    userId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetCommunityChallengeThresholdArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetFeelingAnswerArgs = {
    challengeId: Scalars['UUID']
    date?: InputMaybe<Scalars['ISODate']>
}

export type UnifiedChallengesQueryGetRecommendedChallengesArgs = {
    numberOfRecommendations: Scalars['Int']
}

export type UnifiedChallengesQueryGetSleepHabitsArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetSoloPlayerRankArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetStoriesArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetTeamRankArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesQueryGetTeamRankByAverageArgs = {
    challengeId: Scalars['UUID']
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesQueryGetTeamTotalWaterArgs = {
    challengeId: Scalars['UUID']
    socialGroupId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetTeamsLeaderboardArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesQueryGetTeamsLeaderboardByAverageArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    socialGroupId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesQueryGetUserFlagArgs = {
    key: Scalars['String']
}

export type UnifiedChallengesQueryGetUserTotalWaterArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryGetUsersLeaderboardArgs = {
    challengeId: Scalars['UUID']
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
}

export type UnifiedChallengesQueryGetWaterIntakeArgs = {
    challengeId: Scalars['UUID']
    date?: InputMaybe<Scalars['ISODate']>
}

export type UnifiedChallengesQueryGroupChallengeTemplateArgs = {
    templateId: Scalars['UUID']
}

export type UnifiedChallengesQueryGroupChallengeTemplatesArgs = {
    excludeReadOnly?: InputMaybe<Scalars['Boolean']>
}

export type UnifiedChallengesQueryStoryTypesArgs = {
    status: Scalars['Boolean']
}

export type UnifiedChallengesQueryUnifiedChallengeArgs = {
    challengeId: Scalars['UUID']
    locale?: InputMaybe<Scalars['Locale']>
    useCms?: InputMaybe<Scalars['Boolean']>
}

export type UnifiedChallengesQueryUnifiedChallengeAdminArgs = {
    challengeId: Scalars['UUID']
}

export type UnifiedChallengesQueryUnifiedChallengesArgs = {
    locale?: InputMaybe<Scalars['Locale']>
    useCms?: InputMaybe<Scalars['Boolean']>
}

export type UnifiedChallengesQueryUnifiedChallengesAdminArgs = {
    locale: Scalars['Locale']
    subscriptionId: Scalars['UUID']
    zoneId?: InputMaybe<Scalars['String']>
}

export type UnifiedChallengesQueryUnifiedChallengesByIdAdminArgs = {
    challengeIds: Array<Scalars['UUID']>
    locale?: InputMaybe<Scalars['Locale']>
    zoneId?: InputMaybe<Scalars['String']>
}

export type UnifiedChallengesQueryUnifiedChallengesBySubscriptionIdArgs = {
    locale?: InputMaybe<Scalars['Locale']>
    subscriptionId: Scalars['UUID']
}

export type UnifiedChallengesQueryUnifiedUserChallengesArgs = {
    inProgress?: InputMaybe<Scalars['Boolean']>
    locale?: InputMaybe<Scalars['Locale']>
    useCms?: InputMaybe<Scalars['Boolean']>
    userChallengeActivityId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedChallengesQueryUnifiedUserChallengesAdminArgs = {
    companyId?: InputMaybe<Scalars['UUID']>
    inProgress?: InputMaybe<Scalars['Boolean']>
    locale?: InputMaybe<Scalars['Locale']>
    subscriptionId?: InputMaybe<Scalars['UUID']>
    userChallengeActivityId?: InputMaybe<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type UnifiedChallengesQueryUsersParticipatingInChallengeArgs = {
    challengeId: Scalars['UUID']
    companyId?: InputMaybe<Scalars['UUID']>
    subscriptionId?: InputMaybe<Scalars['UUID']>
    withCheckins: Scalars['Boolean']
}

export type UnifiedChallengesQueryUsersTotalCheckinsArgs = {
    challengeId: Scalars['UUID']
    companyId?: InputMaybe<Scalars['UUID']>
    subscriptionId?: InputMaybe<Scalars['UUID']>
}

export type UnifiedJourney = {
    __typename?: 'UnifiedJourney'
    id: Scalars['UUID']
    name?: Maybe<Scalars['String']>
    shortName?: Maybe<Scalars['String']>
}

export type UnifiedMicrostep = {
    __typename?: 'UnifiedMicrostep'
    action?: Maybe<Scalars['String']>
    body?: Maybe<Scalars['String']>
    createdAt?: Maybe<Scalars['Instant']>
    deprecatedAt?: Maybe<Scalars['Instant']>
    duration?: Maybe<Scalars['Int']>
    id: Scalars['UUID']
    journeys?: Maybe<Array<UnifiedJourney>>
    reminderEnabled?: Maybe<Scalars['Boolean']>
    timeUnit?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
    updatedAt?: Maybe<Scalars['Instant']>
}

export type UnifiedMicrostepActivities = {
    __typename?: 'UnifiedMicrostepActivities'
    checkIns: Array<Scalars['Instant']>
    microstep: UnifiedMicrostep
}

export type UnifiedResult =
    | EnablingChallengeStatus
    | ErrorMessage
    | JoinChallengeSucceeded
    | UpdateUserChallengeSucceeded

export type UnifiedStreak = {
    __typename?: 'UnifiedStreak'
    currentStreak: Scalars['Int']
    id: Scalars['UUID']
    maxStreak: Scalars['Int']
    updatedAt: Scalars['Instant']
    userChallengeActivityId: Scalars['UUID']
}

export type UnifiedUserChallengeActivity = {
    __typename?: 'UnifiedUserChallengeActivity'
    /** The id of this user Challenge Activity */
    challengeActivityId: Scalars['UUID']
    /** The id of the challenge  */
    challengeId: Scalars['UUID']
    /** The completion date for the challenge */
    completedOn?: Maybe<Scalars['Instant']>
    /** The completion date for the challenge */
    completedOnLocalDate?: Maybe<Scalars['ISODate']>
    createdAt: Scalars['Instant']
    /** The expiration date for the challenge */
    expiredOn?: Maybe<Scalars['Instant']>
    /** The expiration date for the challenge */
    expiredOnLocalDate?: Maybe<Scalars['ISODate']>
    /** The creation date for the relation */
    joinDate?: Maybe<Scalars['Instant']>
    /** The creation date for the relation */
    joinLocalDate?: Maybe<Scalars['ISODate']>
    /** The date when the user left a challenge */
    leaveDate?: Maybe<Scalars['Instant']>
    /** The date when the user left a challenge */
    leaveLocalDate?: Maybe<Scalars['ISODate']>
    /** The social group id of the challenge  */
    socialGroupId?: Maybe<Scalars['UUID']>
    updatedAt?: Maybe<Scalars['Instant']>
    /** The id of the user  */
    userId: Scalars['String']
}

export type UpdateChallengesDailyGoalContentInput = {
    /** The id of the entity related with the alternative goal */
    altGoalEntityId?: InputMaybe<Scalars['UUID']>
    /** Alternative goal type */
    altGoalType?: InputMaybe<GoalType>
    /** Day this goal belongs during the challenge */
    challengeDay: Scalars['Int']
    /** List of challengeIds to update. If empty, all challenges will be updated */
    challengeIds: Array<Scalars['UUID']>
    /** Ordering during the day */
    challengePosition: Scalars['Int']
    /** The id of the entity related with the goal */
    goalEntityId?: InputMaybe<Scalars['UUID']>
    /** Goal type */
    goalType?: InputMaybe<GoalType>
    /** The id of the challenge template */
    templateId: Scalars['UUID']
}

export type UpdateDisplayNameOperationSucceeded = {
    __typename?: 'UpdateDisplayNameOperationSucceeded'
    /** The display name of the user */
    displayName: Scalars['String']
    /** The ID of the post for which the operation succeeded */
    userId: Scalars['String']
}

export type UpdateIsPublicOperationSucceeded = {
    __typename?: 'UpdateIsPublicOperationSucceeded'
    /** The isPublic status of the user */
    isPublic: Scalars['Boolean']
    /** The ID of the user for which the operation succeeded */
    userId: Scalars['String']
}

export type UpdateNudgeSentAtFailure = {
    __typename?: 'UpdateNudgeSentAtFailure'
    error: Scalars['String']
}

export type UpdateNudgeSentAtResponse =
    | UpdateNudgeSentAtFailure
    | UpdateNudgeSentAtSuccess

export type UpdateNudgeSentAtSuccess = {
    __typename?: 'UpdateNudgeSentAtSuccess'
    result: Array<UserNudgesSentResult>
}

export type UpdateResult = {
    __typename?: 'UpdateResult'
    updatedChallenge: UnifiedChallenge
}

export type UpdateUserChallengeSucceeded = {
    __typename?: 'UpdateUserChallengeSucceeded'
    challengeActivity: UnifiedUserChallengeActivity
}

export type UploadFileDto = {
    __typename?: 'UploadFileDTO'
    alternativeText?: Maybe<Scalars['String']>
    caption?: Maybe<Scalars['String']>
    previewUrl?: Maybe<Scalars['String']>
    url: Scalars['String']
}

export type UpsertUserInput = {
    email: Scalars['String']
    realm?: InputMaybe<Realm>
    subscriptionId: Scalars['UUID']
}

export type UsageQuery = {
    __typename?: 'UsageQuery'
    /** Historical App Usage for a company */
    historicAppUsage: HistoricAppUsageResponse
}

export type UsageQueryHistoricAppUsageArgs = {
    companyId: Scalars['UUID']
    end: MonthYearInput
    start: MonthYearInput
}

export type User = {
    __typename?: 'User'
    attributes: Array<UserAttribute>
    company?: Maybe<Company>
    countryCode?: Maybe<Scalars['String']>
    email?: Maybe<Scalars['String']>
    firstName?: Maybe<Scalars['String']>
    groups: Array<Scalars['String']>
    /** The ID of the user */
    id: Scalars['String']
    idpIds: Array<IdpIdEntry>
    lastName?: Maybe<Scalars['String']>
    magicLink?: Maybe<Scalars['String']>
    realm?: Maybe<Realm>
    roles: Array<Scalars['String']>
    /** Social Groups for this user */
    socialGroups?: Maybe<Array<SocialGroup>>
    subscription?: Maybe<UserSubscription>
    verified?: Maybe<Scalars['Boolean']>
}

export type UserAttribute = {
    __typename?: 'UserAttribute'
    key: Scalars['String']
    value?: Maybe<Scalars['String']>
}

export type UserAttributeInput = {
    key: Scalars['String']
    value?: InputMaybe<Scalars['String']>
}

export type UserAttributeMutation = {
    __typename?: 'UserAttributeMutation'
    /** Update attributes for authenticated user */
    save: Array<IdentityUserAttribute>
    /**
     * Update locale and timezone for authenticated user
     * @deprecated Use more generic save instead
     */
    update: Array<IdentityUserAttribute>
}

export type UserAttributeMutationSaveArgs = {
    attributes: Array<UserAttributeInput>
}

export type UserAttributeMutationUpdateArgs = {
    locale: Scalars['AllowedLocale']
    timeZone: Scalars['TimeZone']
}

export type UserAttributeQuery = {
    __typename?: 'UserAttributeQuery'
    /** Get user attributes for the authenticated user (does not include attributes from the IDP) */
    get: Array<IdentityUserAttribute>
    /** Get user attributes for the given user (does not include attributes from the IDP) */
    getForUserId: Array<IdentityUserAttribute>
}

export type UserAttributeQueryGetForUserIdArgs = {
    userId: Scalars['UUID']
}

export type UserByCompany = {
    __typename?: 'UserByCompany'
    companyId: Scalars['UUID']
    id: Scalars['UUID']
    subscriptionId: Scalars['UUID']
}

export type UserByCompanyPage = {
    __typename?: 'UserByCompanyPage'
    items: Array<UserByCompany>
    itemsPerPage: Scalars['Int']
    page: Scalars['Int']
    total: Scalars['Int']
}

export type UserCheckinMaturity = {
    __typename?: 'UserCheckinMaturity'
    checkInsUntilMature: Scalars['Int']
    isAccountMature: Scalars['Boolean']
}

export type UserConfigInput = {
    /** The user's company id */
    companyId: Scalars['UUID']
    /** The locale in which the Daily Check-In should be translated. Defaults to en-US */
    locale?: InputMaybe<Scalars['Locale']>
    /** The user's thrive id */
    thriveId: Scalars['UUID']
}

export type UserDto = {
    __typename?: 'UserDTO'
    /** Any linked accounts the user has connected with Pulse (i.e. Slack/Teams) */
    accounts: Array<LinkedAccountDto>
    /** The company this user is assigned to */
    companyId: Scalars['UUID']
    /** The company name, as per the user's Thrive profile */
    companyName?: Maybe<Scalars['String']>
    /** When the user was created in Pulse */
    createdAt: Scalars['Instant']
    /** The user's first name, as per their Thrive profile */
    firstName?: Maybe<Scalars['String']>
    /** The internal pulse id for this user */
    id: Scalars['UUID']
    /** The user's last name, as per their Thrive profile */
    lastName?: Maybe<Scalars['String']>
    /** The user's locale, as per their Thrive profile */
    locale?: Maybe<Scalars['String']>
    /** The global thrive id for this user, if known */
    thriveId?: Maybe<Scalars['UUID']>
    /** The user's timezone, as per their Thrive profile */
    timeZone?: Maybe<Scalars['String']>
    /** When the user was last update in Pulse */
    updatedAt: Scalars['Instant']
}

export type UserFlag = {
    __typename?: 'UserFlag'
    createdAt?: Maybe<Scalars['Instant']>
    /** The flag's key */
    key: Scalars['String']
    updatedAt?: Maybe<Scalars['Instant']>
    /** The flag's value */
    value?: Maybe<Scalars['String']>
}

export type UserGroup = {
    __typename?: 'UserGroup'
    attributes: Array<UserGroupAttribute>
    id: Scalars['String']
    name: Scalars['String']
    path: Scalars['String']
    roles: Array<Scalars['String']>
    subGroups: Array<UserGroup>
}

export type UserGroupAttribute = {
    __typename?: 'UserGroupAttribute'
    key: Scalars['String']
    value?: Maybe<Array<Scalars['String']>>
}

export type UserHabitHistory = {
    __typename?: 'UserHabitHistory'
    completedDate: Scalars['ISODate']
    id: Scalars['UUID']
    planId: Scalars['UUID']
}

export type UserHistoryMutation = {
    __typename?: 'UserHistoryMutation'
    /** @deprecated Just placeholder for testing, replace with pulse */
    hello: Scalars['String']
}

export type UserHistoryQuery = {
    __typename?: 'UserHistoryQuery'
    myHistory: Array<Event>
}

export type UserHistoryQueryMyHistoryArgs = {
    from: Scalars['Instant']
    to?: InputMaybe<Scalars['Instant']>
}

export type UserIdLocale = {
    __typename?: 'UserIdLocale'
    id: Scalars['UUID']
    locale: Scalars['Locale']
}

export type UserInfo = {
    __typename?: 'UserInfo'
    companyId?: Maybe<Scalars['UUID']>
    id: Scalars['UUID']
    locale?: Maybe<Scalars['Locale']>
    measureUnit?: Maybe<MeasureUnit>
    notificationsActivated?: Maybe<Scalars['Boolean']>
    subscriptionId?: Maybe<Scalars['UUID']>
    timeZone?: Maybe<Scalars['String']>
    weekendNotificationsActivated?: Maybe<Scalars['Boolean']>
}

export type UserInfoInput = {
    companyId?: InputMaybe<Scalars['UUID']>
    userId: Scalars['UUID']
}

export type UserInventoryInfo = {
    __typename?: 'UserInventoryInfo'
    createdAt?: Maybe<Scalars['Instant']>
    /** the product upc */
    productUpc?: Maybe<Scalars['String']>
    /** the transaction id */
    transactionId?: Maybe<Scalars['UUID']>
    updatedAt?: Maybe<Scalars['Instant']>
    /** The user id */
    userId: Scalars['UUID']
    /** the user plant Id */
    userPlantId?: Maybe<Scalars['UUID']>
}

export type UserJourneyProgress = {
    __typename?: 'UserJourneyProgress'
    contentId: Scalars['UUID']
    contentInfo?: Maybe<JourneyDailyStepV2>
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    journeyDay: Scalars['Int']
    journeyId: Scalars['UUID']
    journeyLevel: Scalars['Int']
    journeyRepetition: Scalars['Int']
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export type UserJourneyProgressV2 = {
    __typename?: 'UserJourneyProgressV2'
    currentJourney: JourneyProgressV2
    previousJourney?: Maybe<JourneyProgressV2>
}

export type UserJourneys = {
    __typename?: 'UserJourneys'
    /** @deprecated No longer supported */
    preferredJourneys: Array<Journey>
    /** Journey selected for Journey progress */
    selectedJourney?: Maybe<Journey>
    /** @deprecated No longer supported */
    subscriptionJourneys: Array<Journey>
}

export type UserMutation = {
    __typename?: 'UserMutation'
    /** Create a new user */
    createUser?: Maybe<WearablesUserDto>
    /** Create a new well-being source */
    createWellBeingSource?: Maybe<UserSourceDto>
    /** Update the connection status of a well-being source */
    updateWellBeingConnectionStatus: Scalars['Boolean']
}

export type UserMutationCreateWellBeingSourceArgs = {
    source: WellBeingSourceInput
}

export type UserMutationUpdateWellBeingConnectionStatusArgs = {
    connected: Scalars['Boolean']
    sourceId: Scalars['UUID']
}

export type UserNpsResult = {
    __typename?: 'UserNPSResult'
    comment?: Maybe<Scalars['String']>
    completionDate: Scalars['Instant']
    locationPath: Scalars['String']
    npsScore?: Maybe<Scalars['Float']>
    platform: Scalars['String']
    sessionId?: Maybe<Scalars['UUID']>
    userId: Scalars['UUID']
    userLocale: Scalars['String']
}

export type UserNudgeStatusFetchFailure = {
    __typename?: 'UserNudgeStatusFetchFailure'
    error: Scalars['String']
}

export type UserNudgeStatusFetchResponse =
    | UserNudgeStatusFetchFailure
    | UserNudgeStatusFetchSuccess

export type UserNudgeStatusFetchSuccess = {
    __typename?: 'UserNudgeStatusFetchSuccess'
    result: Array<UserNudgeStatusResult>
}

export type UserNudgeStatusResult = {
    __typename?: 'UserNudgeStatusResult'
    enabled: Scalars['Boolean']
    nudgeType: NudgeType
    thriveUserId: Scalars['UUID']
    updatedAt: Scalars['Instant']
}

export type UserNudgeStatusUpsertFailure = {
    __typename?: 'UserNudgeStatusUpsertFailure'
    error: Scalars['String']
}

export type UserNudgeStatusUpsertResponse =
    | UserNudgeStatusUpsertFailure
    | UserNudgeStatusUpsertSuccess

export type UserNudgeStatusUpsertSuccess = {
    __typename?: 'UserNudgeStatusUpsertSuccess'
    result: UserNudgeStatusResult
}

export type UserNudgesSentResult = {
    __typename?: 'UserNudgesSentResult'
    expiresAt: Scalars['Instant']
    nudgeMetadata: NudgeMetadata
    nudgeType: NudgeType
    producedAt: Scalars['Instant']
    sendDate: Scalars['ISODate']
    sendTime: Scalars['String']
    sentAt?: Maybe<Scalars['Instant']>
    targetTimestamp: Scalars['Instant']
    thriveUserId: Scalars['UUID']
    whereToShow: Array<DisplayTarget>
}

export type UserObjectInfo = {
    __typename?: 'UserObjectInfo'
    createdAt: Scalars['Instant']
    /** The garden id the object belongs to */
    gardenId?: Maybe<Scalars['UUID']>
    /** The object id */
    id: Scalars['UUID']
    /** The product upc associated to the object */
    productUpc: Scalars['String']
    updatedAt: Scalars['Instant']
    /** The user id the object belongs to */
    userId: Scalars['UUID']
}

export type UserOnboarding = {
    __typename?: 'UserOnboarding'
    createdAt: Scalars['Instant']
    id: Scalars['UUID']
    status: OnboardingAssessmentStatus
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
    version: Scalars['Float']
}

export type UserOnboardingAssessment = {
    __typename?: 'UserOnboardingAssessment'
    assessmentChoices: Scalars['String']
    createdAt: Scalars['Instant']
    onBoardingId: Scalars['UUID']
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
}

export enum UserOnboardingStatus {
    Completed = 'COMPLETED',
    NotStarted = 'NOT_STARTED',
    Skipped = 'SKIPPED'
}

export type UserPendingNudgesFetchFailure = {
    __typename?: 'UserPendingNudgesFetchFailure'
    error: Scalars['String']
}

export type UserPendingNudgesFetchResponse =
    | UserPendingNudgesFetchFailure
    | UserPendingNudgesFetchSuccess

export type UserPendingNudgesFetchSuccess = {
    __typename?: 'UserPendingNudgesFetchSuccess'
    result: Array<UserNudgesSentResult>
}

export type UserPlanResponse =
    | UserPlanResponseErrorResult
    | UserPlanResponseResult

export enum UserPlanResponseError {
    ErrorCreatingHabit = 'ERROR_CREATING_HABIT',
    ErrorCreatingPlan = 'ERROR_CREATING_PLAN',
    NonExistentGoal = 'NON_EXISTENT_GOAL',
    NonExistentIntention = 'NON_EXISTENT_INTENTION',
    NonExistentPlan = 'NON_EXISTENT_PLAN',
    NonExistentToken = 'NON_EXISTENT_TOKEN'
}

export type UserPlanResponseErrorResult = {
    __typename?: 'UserPlanResponseErrorResult'
    error: UserPlanResponseError
}

export type UserPlanResponseResult = {
    __typename?: 'UserPlanResponseResult'
    planResponseType: PlanResponseType
    userPlan: PlanEntity
}

export type UserPlant = {
    __typename?: 'UserPlant'
    colorLayer1?: Maybe<Scalars['String']>
    colorLayer2?: Maybe<Scalars['String']>
    colorLayer3?: Maybe<Scalars['String']>
    colorLayer4?: Maybe<Scalars['String']>
    createdAt: Scalars['Instant']
    gardenChallengeId?: Maybe<Scalars['UUID']>
    gardenSocialGroupId?: Maybe<Scalars['UUID']>
    id: Scalars['UUID']
    plantedGardenId?: Maybe<Scalars['UUID']>
    productUpc: Scalars['String']
    progressSteps: Scalars['Int']
    trophyChallengeId?: Maybe<Scalars['UUID']>
    updatedAt: Scalars['Instant']
    userId: Scalars['UUID']
    wateredAt?: Maybe<Scalars['Instant']>
}

export type UserPlantInfo = {
    __typename?: 'UserPlantInfo'
    /** Indicates if the plant can be watered */
    canBeWatered: Scalars['Boolean']
    /** Challenge id the plant belongs to */
    challengeId?: Maybe<Scalars['UUID']>
    /** Challenge name the plant belongs to */
    challengeName?: Maybe<Scalars['String']>
    /** Plant color for layer 1 */
    colorLayer1?: Maybe<Scalars['String']>
    /** Plant color for layer 2 */
    colorLayer2?: Maybe<Scalars['String']>
    /** Plant color for layer 3 */
    colorLayer3?: Maybe<Scalars['String']>
    /** Plant color for layer 4 */
    colorLayer4?: Maybe<Scalars['String']>
    createdAt: Scalars['Instant']
    /** The plant id */
    id: Scalars['UUID']
    /** Number of microsteps used to grow this plant */
    microstepsNumber: Scalars['Int']
    /** The plant's owner display name */
    ownerDisplayName?: Maybe<Scalars['String']>
    /** Plant image for TROPHY_PLANT type */
    plantImage?: Maybe<Scalars['String']>
    /** The garden id where the plant is planted */
    plantedGardenId?: Maybe<Scalars['UUID']>
    /** The product upc associated to the plant */
    productUpc: Scalars['String']
    /** The number of times a plant has been watered */
    progressSteps: Scalars['Int']
    /** Number of resets used to grow this plant */
    resetsNumber: Scalars['Int']
    updatedAt: Scalars['Instant']
    /** The user id the plant belongs to */
    userId: Scalars['UUID']
    /** Last time this plant was watered */
    wateredAt?: Maybe<Scalars['Instant']>
}

export type UserQuery = {
    __typename?: 'UserQuery'
    /** Get a user */
    getUser?: Maybe<WearablesUserDto>
    /** Get a user's well-being sources */
    sources: UserSourcesResponse
}

export type UserRecommendationInput = {
    /** The most recent survey response for the user. */
    mostRecentSurveyResponse?: InputMaybe<SurveyResponseInput>
    /** The user's journey UUID. */
    userJourneyUUID?: InputMaybe<Scalars['String']>
}

export enum UserRecommendationsContentType {
    CompanyResource = 'COMPANY_RESOURCE',
    Microstep = 'MICROSTEP'
}

export enum UserRecommendationsInteractionType {
    CheckIn = 'CHECK_IN',
    Seen = 'SEEN',
    Skip = 'SKIP'
}

export type UserSourceDto = {
    __typename?: 'UserSourceDTO'
    connected: Scalars['Boolean']
    created: Scalars['Instant']
    externalSourceId?: Maybe<Scalars['String']>
    lastSynced?: Maybe<Scalars['Instant']>
    sourceId: Scalars['UUID']
    sourceName: Scalars['String']
}

export type UserSourcesResponse = {
    __typename?: 'UserSourcesResponse'
    directSources: Array<UserSourceDto>
    humanApiSources: Array<UserSourceDto>
}

/** User's subscription associated with Company. Type renamed as UserSubscription to avoid clash with GraphQL Subscription */
export type UserSubscription = {
    __typename?: 'UserSubscription'
    companyId: Scalars['UUID']
    enabled: Scalars['Boolean']
    expiryDate?: Maybe<Scalars['Instant']>
    id: Scalars['UUID']
    internalDescription?: Maybe<Scalars['String']>
    isDefault: Scalars['Boolean']
    license: LicenseType
    scopes: Array<Scalars['String']>
    /** @deprecated Subscription level seat count has been deprecated in favour of company level seat count, replace with company.seatsAllowed */
    seatsAllowed: Scalars['Int']
    startDate?: Maybe<Scalars['Instant']>
    subscriptionType: SubscriptionType
}

export type UserTimezoneInput = {
    time: Scalars['Instant']
    timezone: Scalars['String']
}

export type UserTotalCheckins = {
    __typename?: 'UserTotalCheckins'
    checkedInCount: Scalars['Int']
    userId: Scalars['UUID']
}

export type UsersByTimezone = {
    __typename?: 'UsersByTimezone'
    timezone: Scalars['String']
    users: Array<UserIdLocale>
}

export type UsersQuery = {
    __typename?: 'UsersQuery'
    /**
     * Returns the calling user's pulse profile, if one exists
     * @deprecated Don't use Pulse user information. Go to Identity
     */
    me?: Maybe<UserDto>
}

export type ValidateDisplayNameOperationSucceeded = {
    __typename?: 'ValidateDisplayNameOperationSucceeded'
    /** The validity of the display name */
    valid: Scalars['Boolean']
}

export enum VerificationStatus {
    Unverified = 'unverified',
    Verifiedviacompanyclaimeddomain = 'verifiedviacompanyclaimeddomain',
    Verifiedviaconfirmationemail = 'verifiedviaconfirmationemail',
    Verifiedviasociallogin = 'verifiedviasociallogin',
    Verifiedviasso = 'verifiedviasso'
}

export enum Visibility {
    Private = 'PRIVATE',
    Public = 'PUBLIC'
}

export enum VisibleProfileAttribute {
    EmployeeId = 'employeeId',
    FacilityNumber = 'facilityNumber',
    FirstName = 'firstName',
    GroupCode = 'groupCode',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    ProfileAvatar = 'profileAvatar',
    ZipCode = 'zipCode'
}

export type WaterDailyStats = {
    __typename?: 'WaterDailyStats'
    /** Calendar day */
    day: Scalars['Int']
    /** Calendar month */
    month: Scalars['Int']
    /** User water intake */
    water: Scalars['Int']
    /** Calendar year */
    year: Scalars['Int']
}

export type WaterGoalDetail = {
    __typename?: 'WaterGoalDetail'
    water: Scalars['Int']
}

export type WaterIntake = {
    __typename?: 'WaterIntake'
    /** Amount of water for the day */
    amount: Scalars['Int']
    /** Challenge id */
    challengeId: Scalars['UUID']
    /** Intake water day */
    intakeDate: Scalars['ISODate']
    /** Social group the user belongs to */
    socialGroupId?: Maybe<Scalars['UUID']>
}

export type WaterOperationResult = ErrorMessageWater | IntakeResult

export enum WaterPlantError {
    DuplicatedEvent = 'DUPLICATED_EVENT',
    ErrorAutomaticPlant = 'ERROR_AUTOMATIC_PLANT',
    ErrorWateringPlant = 'ERROR_WATERING_PLANT',
    NoPlantGrowing = 'NO_PLANT_GROWING',
    PlantAlreadyFullyGrown = 'PLANT_ALREADY_FULLY_GROWN',
    PlantAlreadyWatered = 'PLANT_ALREADY_WATERED',
    PlantNotFoundForUser = 'PLANT_NOT_FOUND_FOR_USER'
}

export type WaterPlantErrorResult = {
    __typename?: 'WaterPlantErrorResult'
    error: WaterPlantError
    plantError?: Maybe<PlantActionError>
}

export type WaterPlantResponse = WaterPlantErrorResult | WaterPlantResult

export type WaterPlantResult = {
    __typename?: 'WaterPlantResult'
    plantActionResult?: Maybe<PlantActionResult>
    userPlant: UserPlantInfo
}

export type WaterStats = {
    __typename?: 'WaterStats'
    /** Average individual water intake through the whole challenge */
    averageIndividualWater: Scalars['Int']
    /** Average team water intake through the whole challenge */
    averageTeamWater?: Maybe<Scalars['Int']>
    dailyIndividualStats: Array<WaterDailyStats>
    dailyTeamStats: Array<WaterDailyStats>
}

export type WearablesAccess = {
    __typename?: 'WearablesAccess'
    isActivityDataEnabled: Scalars['Boolean']
    isSleepDataEnabled: Scalars['Boolean']
    isSleepRecordsEnabled: Scalars['Boolean']
}

export type WearablesInsightsQuery = {
    __typename?: 'WearablesInsightsQuery'
    /** Get daily activity summaries for a user grouped by day, week or month over a time period */
    activitySummaryStats?: Maybe<Array<ActivitySummaryStats>>
    /** Get daily sleep summaries for a user grouped by day, week or month over a time period */
    sleepSummaryStats?: Maybe<Array<SleepSummaryStats>>
}

export type WearablesInsightsQueryActivitySummaryStatsArgs = {
    intervalType: WearablesReportInterval
}

export type WearablesInsightsQuerySleepSummaryStatsArgs = {
    intervalType: WearablesReportInterval
}

export type WearablesPlatformMutation = {
    __typename?: 'WearablesPlatformMutation'
    /** Modify activity data for a user */
    activity: ActivityMutation
    /** Modify Human API user data */
    humanApi: HumanApiMutation
    /** Modify oura user data */
    oura: OuraMutation
    /** Modify sleep data for a user */
    sleep: SleepMutation
    /** Update step count and run auto check-in for a user */
    updateActivitySummary?: Maybe<HapiActivitySummaryModel>
    /** Modify user data */
    users: UserMutation
}

export type WearablesPlatformMutationUpdateActivitySummaryArgs = {
    localTimestamp?: InputMaybe<Scalars['String']>
    localUTCTimezoneOffsetInMinutes?: InputMaybe<Scalars['Int']>
}

export type WearablesPlatformQuery = {
    __typename?: 'WearablesPlatformQuery'
    /** Get manual activity data for a user */
    activity: ActivityQuery
    /** Get HumanAPI session or id token required to open the HumanAPI connect dialog */
    getHapiToken: ThriveHapiResponse
    /** Get last minutes asleep for a user */
    getLastSleep?: Maybe<Scalars['Int']>
    /** Get step count for a user */
    getStepCount?: Maybe<Scalars['Int']>
    /** Get human api specific data */
    humanApi: HumanApiQuery
    /** Get insights/reporting data from Wearables */
    insights: WearablesInsightsQuery
    /** Get Oura specific data */
    oura: OuraQuery
    /** Get user data */
    users: UserQuery
    /** Get user's permissions to access/store steps and sleep via wearables */
    wearablesAccess: WearablesAccess
}

export type WearablesPlatformQueryGetHapiTokenArgs = {
    email: Scalars['String']
    thriveUserId: Scalars['String']
}

export type WearablesPlatformQueryInsightsArgs = {
    from: Scalars['String']
    to: Scalars['String']
}

export enum WearablesReportInterval {
    Daily = 'DAILY',
    Monthly = 'MONTHLY',
    Ungrouped = 'UNGROUPED',
    Weekly = 'WEEKLY'
}

export type WearablesUserDto = {
    __typename?: 'WearablesUserDTO'
    companyId: Scalars['UUID']
    created: Scalars['Instant']
    hasActiveAppleHealthDirectConnection: Scalars['Boolean']
    hasActiveHumanApiConnection: Scalars['Boolean']
    hasActiveOuraDirectConnection: Scalars['Boolean']
    hasAppleHealthDirectConnection: Scalars['Boolean']
    hasHumanApiConnection: Scalars['Boolean']
    hasOuraDirectConnection: Scalars['Boolean']
    isHumanApiUser: Scalars['Boolean']
    userId: Scalars['UUID']
}

export type WebexIntegrationMutation = {
    __typename?: 'WebexIntegrationMutation'
    /** Disables Webex integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Webex integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type WebexIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type WebexIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    orgId: Scalars['String']
}

export type WebexTstIntegrationMutation = {
    __typename?: 'WebexTstIntegrationMutation'
    /** Disables Webex TST integration for given company. If company doesn't exist it is a NOOP. Returns true if operation actually changed anything */
    disable: Scalars['Boolean']
    /** Add a new Webex TST integration to given company. If company doesn't exist, will return an error. If config already exists, it will be enabled without changing anything else. Returns true if operation actually changed anything */
    enable: Scalars['Boolean']
}

export type WebexTstIntegrationMutationDisableArgs = {
    companyId: Scalars['UUID']
}

export type WebexTstIntegrationMutationEnableArgs = {
    companyId: Scalars['UUID']
    orgId: Scalars['String']
}

export type Webinar = {
    __typename?: 'Webinar'
    description?: Maybe<Scalars['String']>
    events: Array<WebinarEvent>
    excludedSubscriptions: Array<Scalars['UUID']>
    id: Scalars['ID']
    imageUrl?: Maybe<Scalars['String']>
    includedSubscriptions: Array<Scalars['UUID']>
    isUnrestricted: Scalars['Boolean']
    title: Scalars['String']
    webinarProgress: WebinarProgress
}

export type WebinarEvent = {
    __typename?: 'WebinarEvent'
    id: Scalars['ID']
    imageUrl: Scalars['String']
    sessions: Array<WebinarSession>
    subtitle?: Maybe<Scalars['String']>
    tasks: Array<WebinarResource>
    title: Scalars['String']
    webinarEventProgress?: Maybe<WebinarEventProgress>
}

export type WebinarEventProgress = {
    __typename?: 'WebinarEventProgress'
    durationInSeconds: Scalars['Int']
    hasAttended: Scalars['Boolean']
    hasCompleted: Scalars['Boolean']
    inProgress: Scalars['Boolean']
    isPristine: Scalars['Boolean']
    numberOfPristineSessions: Scalars['Int']
    numberOfSessionsInProgress: Scalars['Int']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    updatedAt: Scalars['Instant']
}

export type WebinarPage = {
    __typename?: 'WebinarPage'
    hasMore?: Maybe<Scalars['Boolean']>
    items: Array<Webinar>
    page: Scalars['Int']
    pageCount?: Maybe<Scalars['Int']>
    pageSize: Scalars['Int']
}

export type WebinarProgress = {
    __typename?: 'WebinarProgress'
    attendedEvents: Scalars['Int']
    completedEvents: Scalars['Int']
    hasAttended: Scalars['Boolean']
    hasCompleted: Scalars['Boolean']
    percentageProgress: Scalars['Float']
}

export type WebinarRegistrationResponse = {
    __typename?: 'WebinarRegistrationResponse'
    isForbidden: Scalars['Boolean']
    isNewRegistration: Scalars['Boolean']
    isNotFound: Scalars['Boolean']
    webinar?: Maybe<Webinar>
}

/** Webinar Resource to contain PDF or Survey */
export type WebinarResource = {
    __typename?: 'WebinarResource'
    createdAt: Scalars['Instant']
    id: Scalars['ID']
    /** Course resource type */
    resourceType: CourseResourceType
    /** link to the resource */
    source: Scalars['String']
    summary?: Maybe<Scalars['String']>
    title: Scalars['String']
    updatedAt: Scalars['Instant']
}

export type WebinarSession = {
    __typename?: 'WebinarSession'
    endTime?: Maybe<Scalars['Instant']>
    id: Scalars['ID']
    liveUrl: Scalars['String']
    startTime: Scalars['Instant']
    videoUrl?: Maybe<Scalars['String']>
    webinarSessionProgress?: Maybe<WebinarSessionProgress>
}

export type WebinarSessionProgress = {
    __typename?: 'WebinarSessionProgress'
    durationInSeconds: Scalars['Int']
    hasAttended: Scalars['Boolean']
    hasCompleted: Scalars['Boolean']
    inProgress: Scalars['Boolean']
    progressInPercent: Scalars['Int']
    progressInSeconds: Scalars['Int']
    updatedAt: Scalars['Instant']
}

export type WebinarVideoContentStatusInput = {
    /** Webinar Video id */
    contentId: Scalars['String']
    /** Webinar Video progress in seconds */
    progressInSeconds?: InputMaybe<Scalars['Int']>
    progressType: LearnContentStatusProgressType
    totalInSeconds?: InputMaybe<Scalars['Int']>
}

export type WeeklyActivitySummaryStatsResponse = ActivitySummaryStats & {
    __typename?: 'WeeklyActivitySummaryStatsResponse'
    averageSteps: Scalars['Float']
    weekOfYear: Scalars['Int']
    year: Scalars['Int']
}

export type WeeklyAssessmentStatsResponse = AssessmentStats & {
    __typename?: 'WeeklyAssessmentStatsResponse'
    average: Scalars['Float']
    month: Scalars['Int']
    survey?: Maybe<Surveys>
    totalNumberOfResponses: Scalars['Int']
    valuePercentage: Scalars['Int']
    weekOfYear: Scalars['Int']
    year: Scalars['Int']
}

export type WeeklySleepSummaryStatsResponse = SleepSummaryStats & {
    __typename?: 'WeeklySleepSummaryStatsResponse'
    averageBedTimeStart?: Maybe<Scalars['Long']>
    averageMinutesAsleep: Scalars['Float']
    weekOfYear: Scalars['Int']
    year: Scalars['Int']
}

export type WellBeingSourceInput = {
    connected: Scalars['Boolean']
    sourceName: Scalars['String']
}

export type WhenToSAccepted = {
    __typename?: 'WhenToSAccepted'
    serverTime: Scalars['Instant']
    userId: Scalars['UUID']
    userTime?: Maybe<Scalars['Instant']>
    userZoneId?: Maybe<Scalars['TimeZone']>
}

export type WithBookmarkMutation = {
    bookmark: BookmarkMutation
}

export type WithBookmarkQuery = {
    bookmark: BookmarkQuery
}

export type WithLikeMutation = {
    like: LikeMutation
}

export type WithLikeQuery = {
    like: LikeQuery
}

export type SaveMutationVariables = Exact<{
    attributes: Array<UserAttributeInput> | UserAttributeInput
}>

export type SaveMutation = {
    __typename?: 'Mutation'
    identity: {
        __typename?: 'IdentityMutation'
        userAttributes: {
            __typename?: 'UserAttributeMutation'
            save: Array<{
                __typename?: 'IdentityUserAttribute'
                name: string
                userId: any
                value: string
            }>
        }
    }
}

export type SetUserTncAcceptanceTimestampMutationVariables = Exact<{
    [key: string]: never
}>

export type SetUserTncAcceptanceTimestampMutation = {
    __typename?: 'Mutation'
    identity: {
        __typename?: 'IdentityMutation'
        user: {
            __typename?: 'IdentityUserMutation'
            acceptToS: {
                __typename?: 'WhenToSAccepted'
                userId: any
                serverTime: any
                userTime?: any | null
                userZoneId?: any | null
            }
        }
    }
}

export type WriteReferralMutationVariables = Exact<{
    referringUserId: Scalars['UUID']
}>

export type WriteReferralMutation = {
    __typename?: 'Mutation'
    identity: {
        __typename?: 'IdentityMutation'
        user: { __typename?: 'IdentityUserMutation'; writeReferral: boolean }
    }
}

export type UpdateTimezoneLocaleMutationVariables = Exact<{
    locale: Scalars['AllowedLocale']
    timeZone: Scalars['TimeZone']
}>

export type UpdateTimezoneLocaleMutation = {
    __typename?: 'Mutation'
    identity: {
        __typename?: 'IdentityMutation'
        userAttributes: {
            __typename?: 'UserAttributeMutation'
            update: Array<{
                __typename?: 'IdentityUserAttribute'
                name: string
                value: string
            }>
        }
    }
}

export type GetMagicLinkQueryVariables = Exact<{ [key: string]: never }>

export type GetMagicLinkQuery = {
    __typename?: 'Query'
    identity: {
        __typename?: 'IdentityQuery'
        me: { __typename?: 'User'; magicLink?: string | null }
    }
}

export type GetSubscriptionGroupCodesQueryVariables = Exact<{
    [key: string]: never
}>

export type GetSubscriptionGroupCodesQuery = {
    __typename?: 'Query'
    identity: {
        __typename?: 'IdentityQuery'
        referrals: {
            __typename?: 'IdentityReferralsQuery'
            subscriptionGroupCodes: {
                __typename?: 'SubscriptionGroupCodesResponse'
                items: Array<{
                    __typename?: 'SubscriptionGroupCodes'
                    groupCodes: Array<string>
                    subscription: {
                        __typename?: 'UserSubscription'
                        internalDescription?: string | null
                        isDefault: boolean
                    }
                }>
            }
        }
    }
}

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never }>

export type RefreshTokenMutation = {
    __typename?: 'Mutation'
    identity: {
        __typename?: 'IdentityMutation'
        user: {
            __typename?: 'IdentityUserMutation'
            refreshToken: { __typename?: 'TokenResponse'; token: string }
        }
    }
}

export type GetLocaleTimezoneQueryVariables = Exact<{ [key: string]: never }>

export type GetLocaleTimezoneQuery = {
    __typename?: 'Query'
    identity: {
        __typename?: 'IdentityQuery'
        userAttributes: {
            __typename?: 'UserAttributeQuery'
            get: Array<{
                __typename?: 'IdentityUserAttribute'
                name: string
                value: string
            }>
        }
    }
}

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetProfileQuery = {
    __typename?: 'Query'
    identity: {
        __typename?: 'IdentityQuery'
        me: { __typename?: 'User'; email?: string | null }
    }
}

export const SaveDocument = gql`
    mutation Save($attributes: [UserAttributeInput!]!) {
        identity {
            userAttributes {
                save(attributes: $attributes) {
                    name
                    userId
                    value
                }
            }
        }
    }
`
export type SaveMutationFn = Apollo.MutationFunction<
    SaveMutation,
    SaveMutationVariables
>

/**
 * __useSaveMutation__
 *
 * To run a mutation, you first call `useSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMutation, { data, loading, error }] = useSaveMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useSaveMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SaveMutation,
        SaveMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<SaveMutation, SaveMutationVariables>(
        SaveDocument,
        options
    )
}
export type SaveMutationHookResult = ReturnType<typeof useSaveMutation>
export type SaveMutationResult = Apollo.MutationResult<SaveMutation>
export type SaveMutationOptions = Apollo.BaseMutationOptions<
    SaveMutation,
    SaveMutationVariables
>
export const SetUserTncAcceptanceTimestampDocument = gql`
    mutation SetUserTncAcceptanceTimestamp {
        identity {
            user {
                acceptToS {
                    userId
                    serverTime
                    userTime
                    userZoneId
                }
            }
        }
    }
`
export type SetUserTncAcceptanceTimestampMutationFn = Apollo.MutationFunction<
    SetUserTncAcceptanceTimestampMutation,
    SetUserTncAcceptanceTimestampMutationVariables
>

/**
 * __useSetUserTncAcceptanceTimestampMutation__
 *
 * To run a mutation, you first call `useSetUserTncAcceptanceTimestampMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserTncAcceptanceTimestampMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserTncAcceptanceTimestampMutation, { data, loading, error }] = useSetUserTncAcceptanceTimestampMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetUserTncAcceptanceTimestampMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SetUserTncAcceptanceTimestampMutation,
        SetUserTncAcceptanceTimestampMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        SetUserTncAcceptanceTimestampMutation,
        SetUserTncAcceptanceTimestampMutationVariables
    >(SetUserTncAcceptanceTimestampDocument, options)
}
export type SetUserTncAcceptanceTimestampMutationHookResult = ReturnType<
    typeof useSetUserTncAcceptanceTimestampMutation
>
export type SetUserTncAcceptanceTimestampMutationResult =
    Apollo.MutationResult<SetUserTncAcceptanceTimestampMutation>
export type SetUserTncAcceptanceTimestampMutationOptions =
    Apollo.BaseMutationOptions<
        SetUserTncAcceptanceTimestampMutation,
        SetUserTncAcceptanceTimestampMutationVariables
    >
export const WriteReferralDocument = gql`
    mutation WriteReferral($referringUserId: UUID!) {
        identity {
            user {
                writeReferral(referringUserId: $referringUserId)
            }
        }
    }
`
export type WriteReferralMutationFn = Apollo.MutationFunction<
    WriteReferralMutation,
    WriteReferralMutationVariables
>

/**
 * __useWriteReferralMutation__
 *
 * To run a mutation, you first call `useWriteReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWriteReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [writeReferralMutation, { data, loading, error }] = useWriteReferralMutation({
 *   variables: {
 *      referringUserId: // value for 'referringUserId'
 *   },
 * });
 */
export function useWriteReferralMutation(
    baseOptions?: Apollo.MutationHookOptions<
        WriteReferralMutation,
        WriteReferralMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        WriteReferralMutation,
        WriteReferralMutationVariables
    >(WriteReferralDocument, options)
}
export type WriteReferralMutationHookResult = ReturnType<
    typeof useWriteReferralMutation
>
export type WriteReferralMutationResult =
    Apollo.MutationResult<WriteReferralMutation>
export type WriteReferralMutationOptions = Apollo.BaseMutationOptions<
    WriteReferralMutation,
    WriteReferralMutationVariables
>
export const UpdateTimezoneLocaleDocument = gql`
    mutation updateTimezoneLocale(
        $locale: AllowedLocale!
        $timeZone: TimeZone!
    ) {
        identity {
            userAttributes {
                update(locale: $locale, timeZone: $timeZone) {
                    name
                    value
                }
            }
        }
    }
`
export type UpdateTimezoneLocaleMutationFn = Apollo.MutationFunction<
    UpdateTimezoneLocaleMutation,
    UpdateTimezoneLocaleMutationVariables
>

/**
 * __useUpdateTimezoneLocaleMutation__
 *
 * To run a mutation, you first call `useUpdateTimezoneLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimezoneLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimezoneLocaleMutation, { data, loading, error }] = useUpdateTimezoneLocaleMutation({
 *   variables: {
 *      locale: // value for 'locale'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useUpdateTimezoneLocaleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTimezoneLocaleMutation,
        UpdateTimezoneLocaleMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateTimezoneLocaleMutation,
        UpdateTimezoneLocaleMutationVariables
    >(UpdateTimezoneLocaleDocument, options)
}
export type UpdateTimezoneLocaleMutationHookResult = ReturnType<
    typeof useUpdateTimezoneLocaleMutation
>
export type UpdateTimezoneLocaleMutationResult =
    Apollo.MutationResult<UpdateTimezoneLocaleMutation>
export type UpdateTimezoneLocaleMutationOptions = Apollo.BaseMutationOptions<
    UpdateTimezoneLocaleMutation,
    UpdateTimezoneLocaleMutationVariables
>
export const GetMagicLinkDocument = gql`
    query getMagicLink {
        identity {
            me {
                magicLink
            }
        }
    }
`

/**
 * __useGetMagicLinkQuery__
 *
 * To run a query within a React component, call `useGetMagicLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMagicLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMagicLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMagicLinkQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetMagicLinkQuery,
        GetMagicLinkQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetMagicLinkQuery, GetMagicLinkQueryVariables>(
        GetMagicLinkDocument,
        options
    )
}
export function useGetMagicLinkLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMagicLinkQuery,
        GetMagicLinkQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetMagicLinkQuery, GetMagicLinkQueryVariables>(
        GetMagicLinkDocument,
        options
    )
}
export type GetMagicLinkQueryHookResult = ReturnType<
    typeof useGetMagicLinkQuery
>
export type GetMagicLinkLazyQueryHookResult = ReturnType<
    typeof useGetMagicLinkLazyQuery
>
export type GetMagicLinkQueryResult = Apollo.QueryResult<
    GetMagicLinkQuery,
    GetMagicLinkQueryVariables
>
export const GetSubscriptionGroupCodesDocument = gql`
    query getSubscriptionGroupCodes {
        identity {
            referrals {
                subscriptionGroupCodes {
                    items {
                        groupCodes
                        subscription {
                            internalDescription
                            isDefault
                        }
                    }
                }
            }
        }
    }
`

/**
 * __useGetSubscriptionGroupCodesQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionGroupCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionGroupCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionGroupCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionGroupCodesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetSubscriptionGroupCodesQuery,
        GetSubscriptionGroupCodesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetSubscriptionGroupCodesQuery,
        GetSubscriptionGroupCodesQueryVariables
    >(GetSubscriptionGroupCodesDocument, options)
}
export function useGetSubscriptionGroupCodesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSubscriptionGroupCodesQuery,
        GetSubscriptionGroupCodesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetSubscriptionGroupCodesQuery,
        GetSubscriptionGroupCodesQueryVariables
    >(GetSubscriptionGroupCodesDocument, options)
}
export type GetSubscriptionGroupCodesQueryHookResult = ReturnType<
    typeof useGetSubscriptionGroupCodesQuery
>
export type GetSubscriptionGroupCodesLazyQueryHookResult = ReturnType<
    typeof useGetSubscriptionGroupCodesLazyQuery
>
export type GetSubscriptionGroupCodesQueryResult = Apollo.QueryResult<
    GetSubscriptionGroupCodesQuery,
    GetSubscriptionGroupCodesQueryVariables
>
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
        identity {
            user {
                refreshToken {
                    token
                }
            }
        }
    }
`
export type RefreshTokenMutationFn = Apollo.MutationFunction<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
>

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RefreshTokenMutation,
        RefreshTokenMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        RefreshTokenMutation,
        RefreshTokenMutationVariables
    >(RefreshTokenDocument, options)
}
export type RefreshTokenMutationHookResult = ReturnType<
    typeof useRefreshTokenMutation
>
export type RefreshTokenMutationResult =
    Apollo.MutationResult<RefreshTokenMutation>
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
>
export const GetLocaleTimezoneDocument = gql`
    query getLocaleTimezone {
        identity {
            userAttributes {
                get {
                    name
                    value
                }
            }
        }
    }
`

/**
 * __useGetLocaleTimezoneQuery__
 *
 * To run a query within a React component, call `useGetLocaleTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocaleTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocaleTimezoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocaleTimezoneQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetLocaleTimezoneQuery,
        GetLocaleTimezoneQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetLocaleTimezoneQuery,
        GetLocaleTimezoneQueryVariables
    >(GetLocaleTimezoneDocument, options)
}
export function useGetLocaleTimezoneLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLocaleTimezoneQuery,
        GetLocaleTimezoneQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetLocaleTimezoneQuery,
        GetLocaleTimezoneQueryVariables
    >(GetLocaleTimezoneDocument, options)
}
export type GetLocaleTimezoneQueryHookResult = ReturnType<
    typeof useGetLocaleTimezoneQuery
>
export type GetLocaleTimezoneLazyQueryHookResult = ReturnType<
    typeof useGetLocaleTimezoneLazyQuery
>
export type GetLocaleTimezoneQueryResult = Apollo.QueryResult<
    GetLocaleTimezoneQuery,
    GetLocaleTimezoneQueryVariables
>
export const GetProfileDocument = gql`
    query getProfile {
        identity {
            me {
                email
            }
        }
    }
`

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetProfileQuery,
        GetProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
        GetProfileDocument,
        options
    )
}
export function useGetProfileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetProfileQuery,
        GetProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
        GetProfileDocument,
        options
    )
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>
export type GetProfileLazyQueryHookResult = ReturnType<
    typeof useGetProfileLazyQuery
>
export type GetProfileQueryResult = Apollo.QueryResult<
    GetProfileQuery,
    GetProfileQueryVariables
>

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[]
    }
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        ActivitySummaryStats: [
            'ActivitySummaryStatsResponse',
            'DailyActivitySummaryStatsResponse',
            'MonthlyActivitySummaryStatsResponse',
            'WeeklyActivitySummaryStatsResponse'
        ],
        AllAssessmentAttemptsResult: [
            'AllAssessmentAttemptsError',
            'AllAssessmentAttemptsSuccess'
        ],
        AssessmentAttemptResult: [
            'AssessmentAttemptError',
            'AssessmentAttemptSuccess'
        ],
        AssessmentResult: ['AssessmentError', 'AssessmentSuccess'],
        AssessmentStats: [
            'DailyAssessmentStatsResponse',
            'MonthlyAssessmentStatsResponse',
            'UngroupedAssessmentStatsResponse',
            'WeeklyAssessmentStatsResponse'
        ],
        BehavioralProfileResult: [
            'BehavioralProfileError',
            'BehavioralProfileSuccess'
        ],
        BookmarkedContent: ['ThriveReset', 'ThriveResetAudio'],
        CalendarServiceFields: ['Google', 'Office365', 'Outlook'],
        CallsCommand: ['TriggerReset'],
        ChallengeCustomizationOperationResult: [
            'ChallengeCustomizationResult',
            'ErrorMessageCustomization'
        ],
        CnsAsyncTriggerEventLaunchedResponse: [
            'CnsAsyncTriggerEventLaunchFailed',
            'CnsAsyncTriggerEventLaunched'
        ],
        CnsBatchTriggerEventResponse: [
            'CnsBatchTriggerEventFailure',
            'CnsBatchTriggerEventSuccess'
        ],
        CnsLargeBatchTriggerEventResponse: [
            'CnsLargeBatchTriggerEventAttempted',
            'CnsLargeBatchTriggerEventTotalFailure'
        ],
        CnsTriggerEventResponse: [
            'CnsTriggerEventFailure',
            'CnsTriggerEventSuccess'
        ],
        CourseMutation: ['LearnMutationV2'],
        CourseQuery: ['LearnQueryV2'],
        DislikeStepResponse: ['DislikeStepError', 'DislikeStepResult'],
        FeedbackQuestionResponse: ['FeedbackQuestionYesNoResponse'],
        FeelingQuestionOperationResult: [
            'ErrorMessageFeelingQuestion',
            'FeelingQuestionResult'
        ],
        Frame: ['Image', 'PersonalizedQuote', 'ThriveQuote'],
        GetCoachGqlResponse: ['GetCoachFailure', 'GetCoachSuccess'],
        GetCoachesGqlResponse: ['GetCoachesFailure', 'GetCoachesSuccess'],
        GetRecommendedCoachesGqlResponse: [
            'GetRecommendedCoachesFailure',
            'GetRecommendedCoachesSuccess'
        ],
        GoalEntityDetail: [
            'ArticleDetail',
            'AudioResetGoalDetail',
            'DailyCheckinDetail',
            'ExternalLinkDetail',
            'FeelingQuestionDetail',
            'MicrostepGoalDetail',
            'ResetGoalDetail',
            'StepsGoalDetail',
            'WaterGoalDetail'
        ],
        IntentionToJourneysResponseV2: [
            'IntentionToJourneysV2Error',
            'IntentionToJourneysV2Result'
        ],
        JourneyRestartResponse: ['JourneyRestartError', 'JourneyRestartResult'],
        LearnContentParent: [
            'AudioChapterPartParent',
            'CourseModuleParent',
            'PodcastEpisodeParent'
        ],
        MicrostepMomentumResult: [
            'GraphQlMicrostepMomentum',
            'MicrostepMomentumErrorResponse'
        ],
        ModuleMutation: ['LearnMutationV2'],
        NotificationSettingsOperationResult: ['NotificationSettingsResult'],
        NudgeMetadata: [
            'InChallengeGoalReminder',
            'NoMetadata',
            'SmartNudgeReset',
            'TestNudge'
        ],
        PlantActionResponse: ['PlantActionErrorResult', 'PlantActionResult'],
        PulseOnDemand: [
            'PulseDispatchEventResponse',
            'PulseOnDemandErrorResponse'
        ],
        PurchaseResponse: ['PurchaseErrorResult', 'PurchaseResult'],
        RecommendedEntities: [
            'ArticleV2',
            'GraphQlMicrostep',
            'JourneyDailyStep'
        ],
        RecommendedJourneysResult: [
            'RecommendedJourneysError',
            'RecommendedJourneysInconclusive',
            'RecommendedJourneysSuccess'
        ],
        RecommendedPlantsResponse: [
            'RecommendedPlantsErrorResult',
            'RecommendedPlantsResult'
        ],
        RecommendedResourceV3: [
            'ArticleV2',
            'CompanyResourceGroup',
            'GraphQlMicrostep',
            'JourneyDailyStep',
            'ModuleV2',
            'PodcastEpisode',
            'ThriveReset'
        ],
        RemoveChallengeResult: ['RemoveErrorMessage', 'RemoveSuccess'],
        Reset: ['PersonalizedReset', 'ThriveReset', 'ThriveResetAudio'],
        SaveFeedbackResponse: ['SaveFeedbackError', 'SaveFeedbackResult'],
        SetGroupChallengeTypeResult: ['SetTypeErrorMessage', 'SetTypeSuccess'],
        SetIntentionResponse: ['SetIntentionError', 'SetIntentionResult'],
        SleepHabitsOperationResult: [
            'ErrorMessageSleepHabits',
            'SleepHabitsResult'
        ],
        SleepSummaryStats: [
            'DailySleepSummaryStatsResponse',
            'MonthlySleepSummaryStatsResponse',
            'SleepSummaryStatsResponse',
            'WeeklySleepSummaryStatsResponse'
        ],
        SocialGroupsResult: [
            'InvalidAction',
            'InvalidInput',
            'PostOperationSucceeded',
            'PostReactionCreationSucceeded',
            'SocialGroup',
            'SocialGroupOperationSucceeded',
            'SocialGroupUndoPostReactionSucceed',
            'UpdateDisplayNameOperationSucceeded',
            'UpdateIsPublicOperationSucceeded',
            'ValidateDisplayNameOperationSucceeded'
        ],
        StepContent: ['GraphQlMicrostep'],
        TemplateOperationResult: [
            'ChallengeTemplateInstance',
            'ErrorMessageTemplate',
            'UpdateResult'
        ],
        ThriveHapiResponse: [
            'HapiAccessToken',
            'HapiIdToken',
            'HapiSessionToken'
        ],
        UnifiedResult: [
            'EnablingChallengeStatus',
            'ErrorMessage',
            'JoinChallengeSucceeded',
            'UpdateUserChallengeSucceeded'
        ],
        UpdateNudgeSentAtResponse: [
            'UpdateNudgeSentAtFailure',
            'UpdateNudgeSentAtSuccess'
        ],
        UserNudgeStatusFetchResponse: [
            'UserNudgeStatusFetchFailure',
            'UserNudgeStatusFetchSuccess'
        ],
        UserNudgeStatusUpsertResponse: [
            'UserNudgeStatusUpsertFailure',
            'UserNudgeStatusUpsertSuccess'
        ],
        UserPendingNudgesFetchResponse: [
            'UserPendingNudgesFetchFailure',
            'UserPendingNudgesFetchSuccess'
        ],
        UserPlanResponse: [
            'UserPlanResponseErrorResult',
            'UserPlanResponseResult'
        ],
        WaterOperationResult: ['ErrorMessageWater', 'IntakeResult'],
        WaterPlantResponse: ['WaterPlantErrorResult', 'WaterPlantResult'],
        WithBookmarkMutation: ['ResetAudioMutationV2', 'ResetVideoMutationV2'],
        WithBookmarkQuery: ['ResetAudioQueryV2', 'ResetVideoQueryV2'],
        WithLikeMutation: ['ResetAudioMutationV2', 'ResetVideoMutationV2'],
        WithLikeQuery: ['ResetAudioQueryV2', 'ResetVideoQueryV2']
    }
}
export default result
