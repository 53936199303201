import { captureError } from './datadog'

const REDUX_LOCALSTORAGE_NAME = 'THRIVEGLOBAL_STATE'

type ReduxStore = {
  achievement: Record<string, any>
  announcement: Record<string, any>
  auth: Record<string, any>
  garden: Record<string, any>
  globalAppVariables: Record<string, any>
  hybrid: Record<string, any>
  notification: Record<string, any>
  settings: Record<string, any>
  user: {
    analyticsId: string
    companyId: string
    ddSessionId: string
    email: string
    fullName: string
    isLegacy: boolean
    userId: string
  }
  wallet: Record<string, any>
}

export const getReduxStore = (): ReduxStore | null => {
  let localStorageStore = null
  try {
    localStorageStore = window.localStorage.getItem(REDUX_LOCALSTORAGE_NAME)
  } catch (error) {
    captureError(error as Error, {
      message: `Unable to get ${REDUX_LOCALSTORAGE_NAME} from localstorage: ${error}`,
    })
  }

  try {
    return localStorageStore !== null ? JSON.parse(localStorageStore) : null
  } catch (err) {
    captureError(err as Error, {
      message: `Unable to parse JSON Redux object malformed: ${err}`,
    })
    return null
  }
}
