import { IdpType } from '../../graphql/generated/autogenerated'
import {
    store,
    userUpdateEmail,
    userUpdateFullName,
    authIsLoggedIn,
    authSetTokenExpiration,
    userUpdateUserId,
    authSetAccessToken,
    userUpdateCompanyId,
    authSetThriveToken,
    authSetAccessTokenExpiration,
    authSetTokenId
} from '@thriveglobal/thrive-web-core'
import { AuthType } from '../../store/selectors/auth'

export type TokenType = `saml2` | `azure`

export type TokenExchangeType = {
    (
        token: string,
        tokenType: TokenType,
        referrerId?: string,
        userAcceptedTnC?: boolean
    ): Promise<SigninResult | void>
}
export interface AuthAdapter {
    signIn: Function
    signUp?: Function
    signOut: Function
    redirect?: (payload: RedirectPayload) => Promise<void>
    isLoggedIn?: Function
    getCompanyId?: Function
    getIdToken?: Function
    getEmail?: Function
    getUserInfo?: Function
    configure: Function
    type: string
    sendResetPasswordEmail?: Function
    resetPassword?: Function
    loadTokenIntoStore?: Function
    refreshToken?: Function
    tokenExchange?: TokenExchangeType
    isServerSessionStillActive?(): Promise<boolean>
}

export interface AuthInfo {
    email: string
    name: string
    accessToken: string
    idToken?: string
    userId: string
    tokenExpiration: number
    companyId: string
    // Deprecating thrive token over time
    thriveToken?: string
    accessTokenExpiration?: number
}

export interface SignupOptions {
    email: string
    firstName: string
    lastName: string
    password: string
    groupCode: string
    userAcceptedTnC?: boolean
    // custom fields for some customers
    employeeId?: string
    postalCode?: string
    facilityNumber?: string
    referrerId?: string
    payrollNumber?: string
    company?: string
}

export type identityProviderTypes = keyof typeof AuthType

export const enum SigninResultType {
    success = 'success',
    successZp = 'successZp',
    unauthorized = 'unauthorized',
    userNotFound = 'userNotFound',
    failed = 'failed',
    expired = 'expired',
    unknown = 'unknown'
}

export const enum SendResetEmailResultType {
    success = 'success',
    userNotFound = 'userNotFound',
    error = 'error'
}

export interface SigninResult {
    resultType: SigninResultType
    reason?: string
}

export interface SendResetEmailResult {
    resultType: SendResetEmailResultType
    reason?: string
    idpType?: IdpType
}

export interface AzureJWTPayload {
    aud: string
    iss: string
    iat: number
    nbf: number
    exp: number
    aio: string
    azp: string
    azpacr: string
    name: string
    oid: string
    preferred_username: string
    rh: string
    scp: string
    sub: string
    tid: string
    uti: string
    ver: string
}

export interface ThriveJWTPayload {
    sub: string
    uid: string
    companyId: string
    provider: {
        id: string
        type: string
        role: string
    }
    scope: string
    iss: string
    subscriptionId: string
    exp: number
    version: string
    iat: number
}

export interface RedirectPayload {
    userAcceptedTnC?: boolean
    referrerId?: string
}

export const updateStore = (authConfiguration: AuthInfo) => {
    const {
        email,
        name,
        accessToken,
        userId,
        tokenExpiration,
        accessTokenExpiration,
        companyId,
        thriveToken,
        idToken
    } = authConfiguration

    store.dispatch(authIsLoggedIn(true))
    store.dispatch(userUpdateEmail(email))
    store.dispatch(userUpdateFullName(name))
    store.dispatch(authSetAccessToken(accessToken))
    if (idToken) {
        store.dispatch(authSetTokenId(idToken))
    }
    store.dispatch(userUpdateUserId(userId))
    store.dispatch(userUpdateCompanyId(companyId))
    store.dispatch(authSetTokenExpiration(tokenExpiration))
    if (thriveToken) {
        store.dispatch(authSetThriveToken(thriveToken))
    }
    store.dispatch(authSetAccessTokenExpiration(accessTokenExpiration))
}
